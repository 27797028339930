import React, { useState } from 'react';
import Topsection from '../components/sharing/topsection';
import Breadcrumb from '../components/sharing/breadcrumbs';
import axios from "axios";
import { useHistory } from 'react-router-dom';

function AdvancedSearch() {
    const [data, setData] = useState([]);
    const [loadingPage, setLoadingPage] = useState(true);
    let history = useHistory();
    
    React.useEffect(() => {
        axios
            .post(process.env.REACT_APP_BACKEND_URL + "/latestReleases/list")
            .then(response2 => {
                setData(response2.data.addData);
                setLoadingPage(false);
            });

    }, []);


    const breadCurmbData = [
        {
            title: "أحدث الإصدارات",
            link: '',
        },
    ];

    return (
        <div>
            <Topsection data={{ text: 'أحدث الإصدارات', img: require('../assets/images/11.png') }} />
            <Breadcrumb data={breadCurmbData} />
            <div className="loadingData" style={{
                visibility: loadingPage ? 'visible' : 'hidden',
                opacity: loadingPage ? 1 : 0,
                transition: 'visibility 0s, opacity 0.3s linear'

            }}>
                <img src={require('../assets/images/bookLoading.gif')} alt="loading" />
                <div className="textLoading">جاري التحميل...</div>
            </div>
            <div className="latest_releases">
                {
                    data.length != 0 && data.map((item, index) => {
                        return (
                            <div onClick={() =>
                                history.push(
                                    "/view/" + item._id.split("_")[1] + "/" + item._id.split("_")[0]
                                )} className="col-xs-12 col-sm-4" key={item._id}>
                                <div className="image">
                                    <img src={item.cover.Location} alt={item.nameAr} />
                                </div>
                                <div className="title">{item.nameAr}</div>
                            </div>
                        )
                    })
                }


            </div>
        </div>
    );
}

export default AdvancedSearch;
