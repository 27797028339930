import React from 'react';
import { useEffect, useState } from 'react';

function ScrollTop() {
    const [back, setBack] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 100) {
                setBack(true);
            }
            else {
                setBack(false);
            }
        })
    }, [])
    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    return (
        <div>
            {back && (
            <span
            style={{
              position: "fixed",
              bottom: "100px",
              right: "40px",
              height: "40px",
              width: "40px",
              backgroundColor: "#147BFC",
              color: "white",
              fontWeight: "bold",
              padding: "5px",
              borderRadius: "50%",
              fontFamily: "sans-serif",
              cursor: "pointer",
              transition: "0.3s",
              zIndex: "1000",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
              animation: "bounce 1s infinite"

            }}
            onClick={scrollUp}
          >
            <svg className="arrows-top" viewBox="0 0 16 16" width="20" height="20">
              <path
                className="a1"
                d="M8 0 L16 10 L12 10 L12 16 L4 16 L4 10 L0 10 L8 0 Z"
                fill="#FFF"
              ></path>
            </svg>
          </span>
            )}
        </div>
    )
}

export default ScrollTop;
