import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";

export default function Popup1(props) {
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    setShowPopup(props.objPopup.showPopup);
  }, []);

  const deleteFromHighlights = idHigh => {
    let tempNotes = props.objPopup.notes.filter(item => item.id !== idHigh);
    let formData = new FormData();
    formData.append("lang", "ar");
    formData.append("id", props.objPopup.idPage);
    formData.append("type", props.objPopup.type);
    
    formData.append("notes", JSON.stringify(tempNotes));
    
    formData.append("access_token", localStorage.getItem("access_token"));
    axios
      .post(
        process.env.REACT_APP_BACKEND_URL + "/app/content/addhighlight",
        formData
      )
      .then(response2 => {
        props.objPopup.setNotes(tempNotes);
        setShowPopup(false)
      });
  };

  return (
    <Dialog
      open={showPopup}
      onClose={() => setShowPopup(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <spam style={{ fontFamily: "ArbFONTS-DroidKufi-bold" }}>
          {props.objPopup.title}
        </spam>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.objPopup.body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="btnNo" onClick={() => setShowPopup(false)}>
          لا
        </div>
        <div
          className="btnyes"
          onClick={() => deleteFromHighlights(props.objPopup.id)}
          autoFocus
        >
          نعم
        </div>
      </DialogActions>
    </Dialog>
  );
}
