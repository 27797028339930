import React, { useState } from 'react';
import Topsection from '../components/sharing/topsection';
import Breadcrumb from '../components/sharing/breadcrumbs';
import { Typography, Tooltip, withStyles } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import Error from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { Base64 } from 'js-base64';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Popup1 from '../components/pdf/popup/popup_1';
import { getResultFromBack} from '../store/search'
import { useDispatch } from 'react-redux'

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        left: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    changefont: {
        fontFamily: 'ArbFONTS-DroidKufi-bold'

    }
});

const yearArray = [];
var d = new Date();
var n = d.getFullYear() + 1;
for (let i = n; i >= 1930; i--) {
    yearArray.push({ title: 'سنة ' + i, id: i + '', count: '0' });
}
function AdvancedSearch() {
    let history = useHistory();
    const [popupData, setPopupData] = useState(null)
    const dispatch = useDispatch();
    const [searchKey, setSearchKey] = useState('')
    const [OpenErrorMsg, setOpenErrorMsg] = useState(false)
    const [TextErrorMsg, setTextErrorMsg] = useState('')
    const [TitleErrorMsg, setTitleErrorMsg] = useState('')
    const [filters, setFilters] = useState([{ id: 0, value1: 0, value2: 0, value3: '' }])
    const [value, setValue] = React.useState([]);


    const handleChange = (event, id) => {
        let yy = value;
        yy[id] = parseInt(event.target.value);
        setValue([...yy]);
    };

    const setShowPopupMethod = o => {
        setPopupData(null)
        setTimeout(() => {
          setPopupData(o)
        }, 100)
      }
    const setDatePopUp = () => {
        setShowPopupMethod({
          showPopup: true,
          titlePopup: 'عزيزي المستخدم',
          bodyPopup: 'يرجى تحديد سنه النشر من و الى',
          btnobj: {
            label: 'اشترك الآن',
            show: true,
            methodbtn: ''
          }
        })
      }

    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(20, 123, 252, 0.77)',
            boxShadow: theme.shadows[1],
            fontSize: 12,
            fontFamily: 'ArbFONTS-DroidKufi-bold'
        },
    }))(Tooltip);


    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    const DialogContent = withStyles((theme) => ({
        root: {
            padding: theme.spacing(2),
        },
    }))(MuiDialogContent);

    const DialogActions = withStyles((theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(1),
        },
    }))(MuiDialogActions);

    const breadCurmbData = [
        {
            title: "بحث متقدم",
            link: '',
        },
    ];

    const addFilter = () => {
        setFilters([...filters, { id: filters.length + 1, value1: 0, value2: 0, value3: '' }])
    }
    const removeFilter = (index) => {
        let temp = filters;
        setFilters([...filters.filter(item => item.id !== (index + 1))])
    }
    const changeValue = (value, rowIndex, type) => {
        let currentRowOtherValue = filters;
        currentRowOtherValue[rowIndex][type] = value;
        setFilters([...currentRowOtherValue]);
    }
    const goSearch = () => {
        if(value.length!==0){
        dispatch(getResultFromBack(null))
        filters.push(value)
        history.push(process.env.REACT_APP_FRONT_URL + 'result/' + Base64.encode(`"${JSON.stringify(filters)}"`, true))
    }
    else{
        setDatePopUp()

    }
}
    return (
        <div>
                  {popupData !== null ? <Popup1 objPopup={popupData} /> : ''}

            <Topsection data={{ text: 'بحث متقدم', img: require('../assets/images/11.png') }} />
            <Breadcrumb data={breadCurmbData} />
   /         <div className="AdvancedSearch">
                <Dialog onClose={() => setOpenErrorMsg(false)} aria-labelledby="customized-Dialog-title" open={OpenErrorMsg}>
                    <DialogTitle id="customized-dialog-title" onClose={() => setOpenErrorMsg(false)}
                    >
                        <div className="titlePopupMsg"><Error className="iconError" />{TitleErrorMsg}</div>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            <div className="bodyPopupMsg">{TextErrorMsg}</div>
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button className="ButtonErrorMsg" autoFocus onClick={() => setOpenErrorMsg(false)} color="primary">
                            موافق
                        </Button>
                    </DialogActions>
                </Dialog>
                <div className="filter">
                    <div className="titleSec">استخدم المرشحات لتصفية نتائج البحث </div>
                    <div className="filtersList">
                        {filters.map((item, index) =>
                            <div className="filterItem">
                                {index !== 0 ?
                                    <div className="select222 select2221">
                                        <select onChange={(e) => changeValue(e.target.value, index, 'value2')} value={item.value2}>
                                            <option value="0">و</option>
                                            <option value="1">أو</option>
                                            <option value="2">ليس</option>
                                        </select>
                                    </div>

                                    : ''}
                                <div className="select222">
                                    <select onChange={(e) => changeValue(e.target.value, index, 'value1')} value={item.value1}>
                                        <option value="0">العنوان</option>
                                        <option value="1">الكلمات المفتاحية</option>
                                        <option value="2">المؤلف</option>
                                        <option value="3">الموضوع</option>
                                        <option value="4">الناشر</option>
                                    </select>
                                </div>
                                <input type="text" onChange={(e) => changeValue(e.target.value, index, 'value3')} value={item.value3}

                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            goSearch()
                                        }
                                    }}
                                />
                                <div className="plusandDelte">
                                    <LightTooltip title="اضافة مرشح جديد" style={{ cursor: 'pointer' }} onClick={() => addFilter()}>
                                        <div className="addfilterStyle"></div>
                                    </LightTooltip>
                                    <LightTooltip title="ازالة هذا المرشح" style={{ cursor: 'pointer' }} onClick={() => removeFilter(index)}>
                                        <div className="removefilterStyle"></div>
                                    </LightTooltip>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="yearsSlider">
                        <Typography id="range-slider" className="lableslider" gutterBottom>
                            سنة النشر
                        </Typography>
                        <div className="sliderDiv">
                            <div className="fromto">من: </div>
                            <div className="select222">
                                <select onChange={(e) => handleChange(e, 0)} >
                                <option ></option>

                                    {yearArray.map(item =>
                                        
                                        <option >{item.id}</option>
                                    )}

                                </select>
                            </div>
                            <div className="fromto">الى: </div>
                            <div className="select222">
                                <select onChange={(e) => handleChange(e, 1)}  >
                                <option ></option>

                                    {yearArray.map(item =>
                                        <option >{item.id}</option>
                                    )}
                                </select>
                            </div>
                        </div>

                    </div>
                    <div className="btns">
                        <div className="colseBtn" onClick={() => {
                            setFilters([{ id: 0, value1: 0, value2: 0, value3: '' }]);
                            setSearchKey('');
                        }}>الغاء</div>
                        <div
                            className="searchBtn"
                            onClick={() => goSearch()}

                        >بحث</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdvancedSearch;