import {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import axios from "axios";
import React from "react"
export default function Footer (props) {
  
  
const [facebook, setFaceBook]=useState("");
const [twitter, setTwitter]= useState("");
const [instagram, setInstagran]=useState("");
const [linkind, setLinkined]=useState("");

useEffect(() => {
  let backend_url = process.env.REACT_APP_BACKEND_URL;
  let formData = new FormData();
  axios({
    url: `${backend_url}/infowebsite/list`,
    data: formData,
    method: "post",
    headers: { "Content-Type": "multipart/form-data" },
  }).then((res) => {
    setFaceBook(res.data.result.facebookUrl);
    setTwitter(res.data.result.twitterUrl);
    setLinkined(res.data.result.linkindUrl);
    setInstagran(res.data.result.youtubeUrl);
  });

}, [])

  let lang = require('./../../lang/ar').default
  return (
    <div className='footer'>
      <div className='contactus'>
        <a href='https://wa.me/962770351974' target='_block'>
          <img
            src={require('../../assets/images/Group 9176.png')}
            alt='whatsapp'
          />
        </a>
      </div>
      <div className='copyright'>
        {lang.footer.copyright} {new Date().getFullYear()}{' '}
      </div>
      <div className='socialMedia'>
        <a href={instagram} target='_block'>
          <svg
            id='Group_8593'
            dataname='Group 8593'
            xmlns='http://www.w3.org/2000/svg'
            width='34.477'
            height='34.477'
            viewBox='0 0 34.477 34.477'
          >
            <g id='Group_8592' dataname='Group 8592' transform='translate(0 0)'>
              <ellipse
                id='Ellipse_33'
                dataname='Ellipse 33'
                cx='17.238'
                cy='17.238'
                rx='17.238'
                ry='17.238'
                transform='translate(0 0)'
                fill='#972bab'
              />
              <path
                id='Path_8823'
                dataname='Path 8823'
                d='M410.956,48.844a3.913,3.913,0,0,0-1.2-2.523,4.1,4.1,0,0,0-2.71-1.1,53.525,53.525,0,0,0-7.307.082,3.731,3.731,0,0,0-3.2,2.87,41.206,41.206,0,0,0-.076,8.293,3.742,3.742,0,0,0,2.969,3.224,44.047,44.047,0,0,0,8.179.053,3.743,3.743,0,0,0,3.236-2.954A46.294,46.294,0,0,0,410.956,48.844Zm-1.36,7.381a2.5,2.5,0,0,1-2.352,2.232,43.8,43.8,0,0,1-7.621-.107,2.422,2.422,0,0,1-1.838-2.085,49.411,49.411,0,0,1,0-7.457,2.5,2.5,0,0,1,2.34-2.222,52.068,52.068,0,0,1,7.275.029,2.5,2.5,0,0,1,2.226,2.356A52.765,52.765,0,0,1,409.6,56.225Zm-5.907-7.492a3.788,3.788,0,1,0,3.788,3.792A3.784,3.784,0,0,0,403.689,48.734Zm-.027,6.239a2.451,2.451,0,1,1,2.478-2.441A2.449,2.449,0,0,1,403.662,54.972Zm4.848-6.381a.887.887,0,1,1-.88-.891A.881.881,0,0,1,408.51,48.591Z'
                transform='translate(-386.466 -35.539)'
                fill='#fff'
              />
            </g>
          </svg>
        </a>

        <a href={linkind} target='_block'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='34.477'
            height='34.477'
            viewBox='0 0 24 24'
          >
            <path
              fill='#0e76a8'
              d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 16h-2v-6h2v6zm-1-6.891c-.607 0-1.1-.496-1.1-1.109 0-.612.492-1.109 1.1-1.109s1.1.497 1.1 1.109c0 .613-.493 1.109-1.1 1.109zm8 6.891h-1.998v-2.861c0-1.881-2.002-1.722-2.002 0v2.861h-2v-6h2v1.093c.872-1.616 4-1.736 4 1.548v3.359z'
            />
          </svg>
        </a>

        <a href={twitter} target='_block'>
          <svg
            id='Group_8595'
            dataname='Group 8595'
            xmlns='http://www.w3.org/2000/svg'
            width='34.477'
            height='34.477'
            viewBox='0 0 34.477 34.477'
          >
            <ellipse
              id='Ellipse_30'
              dataname='Ellipse 30'
              cx='17.238'
              cy='17.238'
              rx='17.238'
              ry='17.238'
              fill='#1ba5d4'
            />
            <path
              id='Path_8819'
              dataname='Path 8819'
              d='M293.844,89.024a2.934,2.934,0,0,1-2.721-2.03,2.9,2.9,0,0,0,1.253-.038.3.3,0,0,0,.042-.019,2.922,2.922,0,0,1-1.9-1.343,2.837,2.837,0,0,1-.427-1.556,2.876,2.876,0,0,0,1.3.356,2.952,2.952,0,0,1-1.206-1.775,2.914,2.914,0,0,1,.314-2.12,8.367,8.367,0,0,0,6.035,3.056c-.017-.114-.036-.215-.048-.318a2.851,2.851,0,0,1,.427-1.914A2.816,2.816,0,0,1,298.9,80a2.853,2.853,0,0,1,2.561.817.15.15,0,0,0,.156.046,5.886,5.886,0,0,0,1.7-.655l.04-.019.019,0a2.991,2.991,0,0,1-1.253,1.592,5.59,5.59,0,0,0,1.628-.438l.011.013c-.111.147-.219.3-.335.44a5.748,5.748,0,0,1-1.064,1.009.1.1,0,0,0-.048.091,8,8,0,0,1-.053,1.3,8.621,8.621,0,0,1-.754,2.605,8.476,8.476,0,0,1-1.581,2.306,7.81,7.81,0,0,1-4.022,2.239,8.663,8.663,0,0,1-1.628.194,8.254,8.254,0,0,1-4.681-1.257c-.019-.01-.036-.025-.068-.044a5.9,5.9,0,0,0,2.925-.4A5.829,5.829,0,0,0,293.844,89.024Z'
              transform='translate(-279.334 -67.569)'
              fill='#fff'
              fillRule='evenodd'
            />
          </svg>
        </a>

        <a href={facebook} target='_block'>
          <svg
            id='Group_8596'
            dataname='Group 8596'
            xmlns='http://www.w3.org/2000/svg'
            width='34.477'
            height='34.477'
            viewBox='0 0 34.477 34.477'
          >
            <ellipse
              id='Ellipse_29'
              dataname='Ellipse 29'
              cx='17.238'
              cy='17.238'
              rx='17.238'
              ry='17.238'
              fill='#1e4fad'
            />
            <path
              id='Path_8818'
              dataname='Path 8818'
              d='M297.274,47.887c.476-.015.95,0,1.428,0h.2V45.408c-.253-.027-.52-.063-.786-.076-.487-.021-.977-.046-1.466-.034a3.58,3.58,0,0,0-2.07.64A3.1,3.1,0,0,0,293.333,48a7.331,7.331,0,0,0-.086,1.065c-.015.554,0,1.11,0,1.668v.208h-2.371v2.765h2.358v6.955h2.877V53.722h2.35c.12-.922.238-1.832.362-2.78h-.529c-.667,0-2.2,0-2.2,0s.006-1.371.023-1.967C296.138,48.16,296.621,47.912,297.274,47.887Z'
              transform='translate(-278.116 -35.398)'
              fill='#fff'
              fillRule='evenodd'
            />
          </svg>
        </a>
      </div>

      <div className='mediaplus'>

        <Link to='/privacy_policy'>
          سياسة الخصوصية
        </Link>
        &nbsp;|&nbsp;
        <Link to='/terms_conditions'>
          الشروط والأحكام
        </Link>
        <div className='footer__payment'>
          <img src={require('../../assets/images/visa.png')} alt='visa' />
        </div>
      </div>
    </div>
  )
}
