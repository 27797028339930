import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link, useHistory } from 'react-router-dom'
import CountryList from '../components/country.json'
import ReCAPTCHA from 'react-google-recaptcha'

function AdvancedSearch () {
  let history = useHistory()

  const [formObj, setFormObj] = useState({
    name: '',
    email: '',
    phone: '',
    password: ''
  })
  let [mainAccount, setMainAccount] = useState(null)
  const [captcha, setCaptcha] = useState(false)
  const [country, setCountry] = useState('JO')
  const [success, setSuccess] = useState({
    status: false,
    msg: ''
  })

  useEffect(() => {
    let token = localStorage.getItem('custom_token')
    if (token) {
      ///users/getInfoMainAccountQrCode
      axios
        .get(
          process.env.REACT_APP_BACKEND_URL +
            '/users/getInfoMainAccountQrCode?token=' +
            token
        )
        .then(res => {
          if (res.data.booleanVaule) {
            setMainAccount(res.data.user)
          } else {
            history.push('/')
          }
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      history.push('/')
    }
  }, [])

  function onChange (value) {
    if (value) {
      setCaptcha(true)
    }
  }
  const validateEmail = email => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }
  const sendData = () => {
    let e = true
    if (formObj.name.length < 2) {
      setSuccess({
        status: false,
        msg: 'الاسم قصير جدا'
      })
      e = false
      return
    }
    if (!validateEmail(formObj.email)) {
      setSuccess({
        status: false,
        msg: 'البريد الالكتروني غير صحيح'
      })
      e = false
      return
    }

    if (formObj.password.length < 6) {
      setSuccess({
        status: false,
        msg: 'كلمة المرور قصيرة جدا'
      })
      e = false
      return
    }

    if (formObj.phone.length < 10) {
      setSuccess({
        status: false,
        msg: 'رقم الهاتف غير صحيح'
      })
      e = false
      return
    }

    if (!captcha) {
      setSuccess({
        status: false,
        msg: 'الرجاء التحقق من انك لست روبوت'
      })
      e = false
      return
    }

    if (e) {
      formObj.mainAccount = mainAccount._id
      formObj.country = country
      axios
        .post(
          process.env.REACT_APP_BACKEND_URL + '/users/registerQrCode',
          formObj
        )
        .then(res => {
          if (res.data.booleanVaule) {
            localStorage.removeItem('custom_token')
            setSuccess({
              status: true,
              msg: res.data.msg
            })
          } else {
            setSuccess({
              status: false,
              msg: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  return (
    <div className='register'>
      <div className='stepOne'>
        <div className='containerReg1'>
          <div className='mainAccountName'>{mainAccount?.name}</div>
          <div className='sectionTitle'>إنشاء حساب في بوابة الكتاب العلمي</div>
          <div className='formcontact'>
            <div className='container'>
              <form id='contact'>
                {success.msg !== '' && (
                  <fieldset>
                    {success.status === false && (
                      <div class='xd-message msg-danger'>
                        <div class='xd-message-icon'></div>
                        <div class='xd-message-content'>
                          <p>{success.msg}</p>
                        </div>
                      </div>
                    )}
                    {success.status === true && (
                      <div class='xd-message msg-success'>
                        <div class='xd-message-icon'> </div>
                        <div class='xd-message-content'>
                          <p>{success.msg}</p>
                        </div>
                      </div>
                    )}
                  </fieldset>
                )}

                {!success.status && (
                  <div>
                    <fieldset>
                      <label>الاسم</label>
                      <input
                        value={formObj.name}
                        onChange={e =>
                          setFormObj({
                            ...formObj,
                            name: e.target.value
                          })
                        }
                        placeholder='الاسم'
                        type='text'
                        tabindex='1'
                        required
                        autofocus
                      />
                    </fieldset>

                    <fieldset>
                      <label>البلد</label>
                      <select
                        value={country}
                        onChange={e => setCountry(e.target.value)}
                        tabindex='2'
                      >
                        {Object.keys(CountryList).map((keyName, i) => (
                          <option value={keyName}>
                            {CountryList[keyName]}
                          </option>
                        ))}
                      </select>
                    </fieldset>

                    <fieldset>
                      <label>البريد الإلكتروني</label>
                      <input
                        value={formObj.email}
                        onChange={e =>
                          setFormObj({
                            ...formObj,
                            email: e.target.value
                          })
                        }
                        placeholder='البريد الإلكتروني'
                        type='email'
                        tabindex='3'
                        required
                      />
                    </fieldset>

                    <fieldset>
                      <label>رقم الهاتف</label>
                      <input
                        value={formObj.phone}
                        onChange={e =>
                          setFormObj({
                            ...formObj,
                            phone: e.target.value
                          })
                        }
                        placeholder='رقم الهاتف'
                        type='tel'
                        name='phone'
                        id='phone'
                        tabindex='4'
                        autocomplete='off'
                      />
                    </fieldset>

                    <fieldset>
                      <label>كلمة المرور</label>
                      <input
                        value={formObj?.password}
                        onChange={e =>
                          setFormObj({
                            ...formObj,
                            password: e.target.value
                          })
                        }
                        placeholder='كلمة المرور'
                        type='password'
                        tabindex='5'
                      />
                    </fieldset>

                    <fieldset>
                      <ReCAPTCHA
                        sitekey='6Ldu6BkfAAAAAAcWW9lR0IwFpWfALfHRlmIuuhJG'
                        onChange={onChange}
                      />
                    </fieldset>
                    <fieldset>
                      <button name='button' type='button' onClick={sendData}>
                        تسجيل
                      </button>
                    </fieldset>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdvancedSearch
