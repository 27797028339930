import React, { useEffect, useState } from 'react';
import Topsection from '../components/sharing/topsection';
import Breadcrumb from '../components/sharing/breadcrumbs';
import { Typography, withStyles, TextField, Select, MenuItem, FormControl } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import Error from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Axios from 'axios';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        left: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    changefont: {
        fontFamily: 'ArbFONTS-DroidKufi-bold'

    }
});

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120, fontSize: '10px'
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
}));
function AdvancedSearch() {
    let history = useHistory();
    const classes = useStyles();


    const [OpenErrorMsg, setOpenErrorMsg] = useState(false)
    const [TextErrorMsg, setTextErrorMsg] = useState('')
    const [TitleErrorMsg, setTitleErrorMsg] = useState('')
    const [parentAccount, setParentAccount] = useState(null)

    const [valueEdit, setValueEdit] = React.useState([false, false, false, false, false]);
    const [error, setError] = React.useState([true, true, true, true, true]);

    function timeConverter(UNIX_timestamp) {
        var a = new Date(String(UNIX_timestamp).length === 10 ? parseInt(UNIX_timestamp) * 1000 : parseInt(UNIX_timestamp));
        var months = ['كانون الثاني', 'شباط', 'آذار', 'نيسان', 'أيار', 'حزيران', 'تموز', 'آب', 'أيلول', 'تشرين الأول', 'تشرين الثاني', 'كانون الأول'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var time = date + ' ' + month + ' ' + year;
        return time;
    }

    const [data, setData] = useState({
        fName: 'Ahmad',
        lName: 'Maharmeh',
        country: 'JO',
        email: '',
        phone: '',
        img: null
        // img: require('../assets/images/Mask Group 21.png')
    })



    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    const DialogContent = withStyles((theme) => ({
        root: {
            padding: theme.spacing(2),
        },
    }))(MuiDialogContent);

    const DialogActions = withStyles((theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(1),
        },
    }))(MuiDialogActions);


    const breadCurmbData = [
        {
            title: "صفحتي الشخصية",
            link: '',
        },
    ];


    const changeData = (value, type) => {
        let tempAr = data;
        tempAr[type] = value;
        setData({ ...tempAr });
    }

    const countres = require('../assets/countries.json');
    const cookieSet = (name, value, days) => {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    };
    const eraseCookie = name => {
        document.cookie =
            name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    };
    const fileUpload = (e, id) => {

        var crypto = require('crypto');
        let hash = crypto.createHash('sha256').update(JSON.stringify(formatDate())).digest('hex');
        let formData = new FormData();
        formData.append('hashTime', hash);
        formData.append('access_token', localStorage.getItem('access_token'));
        formData.append('id', getCookie('id'));
        formData.append('img', e.target.files[0]);

        Axios.post(process.env.REACT_APP_BACKEND_URL + '/app/users/uploadImg', formData,
        ).then(response2 => {

            let parentAccountTemp = parentAccount;
            parentAccountTemp.userImage = response2.data.result;
            setParentAccount(parentAccountTemp);
            eraseCookie('objectUser');
            cookieSet("objectUser", JSON.stringify(parentAccountTemp), 70);

            setParentAccount({ ...parentAccountTemp });

        })
    }

    function formatDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
    useEffect(() => {

        if (localStorage.getItem('access_token') == undefined) {
            history.push(process.env.REACT_APP_FRONT_URL + '');
        }
        var crypto = require('crypto');
        let hash = crypto.createHash('sha256').update(JSON.stringify(formatDate())).digest('hex');
        let formData = new FormData();
        formData.append('hashTime', hash);
        formData.append('access_token', localStorage.getItem('access_token'));
        formData.append('id', getCookie('id'));
        formData.append('lang', 'ar');
        Axios.post(process.env.REACT_APP_BACKEND_URL + '/app/users/getUser', formData,
        ).then(response2 => {
            setParentAccount(JSON.parse(getCookie("objectUser")));

            if (response2.data.result === undefined) { return }
            
            let temp = data;
            temp.email = response2.data.result.email;
            setData({ ...data, email: response2.data.result.email });
        })
    }, [])

    const getCookie = (name) => {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
    return (
        <div>
            <Topsection data={{ text: 'صفحتي الشخصية', img: require('../assets/images/profile.png') }} />
            <Breadcrumb data={breadCurmbData} />
            <div className="profile">
                <Dialog onClose={() => setOpenErrorMsg(false)} aria-labelledby="customized-Dialog-title" open={OpenErrorMsg}>
                    <DialogTitle id="customized-dialog-title" onClose={() => setOpenErrorMsg(false)}
                    >
                        <div className="titlePopupMsg"><Error className="iconError" />{TitleErrorMsg}</div>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            <div className="bodyPopupMsg">{TextErrorMsg}</div>
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button className="ButtonErrorMsg" autoFocus onClick={() => setOpenErrorMsg(false)} color="primary">
                            موافق
                        </Button>
                    </DialogActions>
                </Dialog>


                <div className="profileBody">
                    <div className="imgProfile">
                        <div className="img">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 222 222" style={{
                                display: parentAccount?.userImage !== null &&
                                    parentAccount?.userImage !== undefined && parentAccount?.userImage !== '' ? 'none' : 'block'
                            }}>

                                <defs>
                                    <clipPath id="clipPath">
                                        <circle id="Ellipse_871" data-name="Ellipse_871 87" cx="111" cy="111" r="111" fill="#7187ff" stroke="#dddee8" strokeWidth="10" />
                                    </clipPath>
                                </defs>
                                <g id="Mask_Group_101" data-name="Mask Group 101" clipPath="url(#clipPath)">
                                    <g id="Group_2162" data-name="Group 2162" transform="translate(44 41.295)">
                                        <rect id="Rectangle_497" data-name="Rectangle 497" width="134" height="152" rx="67" transform="translate(0 120.705)" fill="#f7f8fc" />
                                        <rect id="Rectangle_498" data-name="Rectangle 498" width="32" height="70" rx="16" transform="translate(51 85.705)" fill="#dddee8" />
                                        <g id="Rectangle_496" data-name="Rectangle 496" transform="translate(10.598)" fill="#fff" stroke="#fff" strokeWidth="1">
                                            <rect width="113.166" height="120.71" rx="56.583" stroke="none" />
                                            <rect x="0.5" y="0.5" width="112.166" height="119.71" rx="56.083" fill="none" />
                                        </g>
                                    </g>
                                </g>
                            </svg>

                            <img id="img_profile" src={process.env.REACT_APP_BACKEND_URL + '/public/' + parentAccount?.userImage}
                                style={{
                                    display: parentAccount?.userImage !== null
                                        && parentAccount?.userImage !== undefined && parentAccount?.userImage !== '' ? 'block' : 'none'
                                }} />


                        </div>

                        {parentAccount?.login && (
                            <Button variant="contained" component="label" className="btnUpluadImg">
                                <div className="editImg">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.856 41.857">
                                        <g id="Group_9557" data-name="Group 9557" transform="translate(-1507 -527.071)">
                                            <path id="Path_9857" data-name="Path 9857" d="M20,0A20,20,0,1,1,0,20,20,20,0,0,1,20,0Z" transform="translate(1508 528)" fill="#147bfc" />
                                            <g id="Group_9556" data-name="Group 9556" transform="translate(1387.928 408)">
                                                <g id="Group_9555" data-name="Group 9555" transform="translate(119.072 119.071)">
                                                    <path id="Path_9856" data-name="Path 9856" d="M140,119.071A20.929,20.929,0,1,0,160.928,140,20.928,20.928,0,0,0,140,119.071Zm9.949,15.061-1.988,1.987-4.046-4.046-1.535,1.535,4.046,4.046-9.925,9.924-4.046-4.046-1.535,1.535,4.046,4.046-.989.989-.019-.019a.781.781,0,0,1-.5.359l-3.773.842a.82.82,0,0,1-.171.019.785.785,0,0,1-.765-.956l.841-3.772a.785.785,0,0,1,.36-.5l-.02-.019L145.9,130.085a.6.6,0,0,1,.849,0l3.2,3.2A.6.6,0,0,1,149.948,134.132Z" transform="translate(-119.072 -119.071)" fill="#fff" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <input
                                    type="file"
                                    onChange={(e) => {
                                        fileUpload(e, 'img');
                                    }}
                                    accept="image/gif, image/jpeg, image/png"
                                    style={{ display: "none" }}
                                />
                            </Button>
                        )}


                        <div className="nameProfile">{getCookie('fName')}</div>
                    </div>

                    <div className="infoProfle">
                        <div className="boxInfo">
                            <div className="rowInfo">


                                <div className="rowLabel">
                                    <div className="labelRow">الاسم </div>
                                    <div className={!valueEdit[0] ? "labelRow show" : "labelRow hide"}>{getCookie('fName')}</div>
                                    <div className={valueEdit[0] ? "inputEdit show" : "inputEdit hide"}>
                                        <TextField id="outlined-basic" value={getCookie('fName')} variant="outlined" onChange={(e) => changeData(e.target.value, 'fName')} />
                                    </div>
                                </div>

                             
                            </div>

                        

                            <div className="rowInfo">
                                <div className="rowLabel">
                                    <div className="labelRow">البلد</div>
                                    <div className={!valueEdit[2] ? "labelRow show" : "labelRow hide"}>{
                                        countres.filter(item => item.FIPS === data.country)[0]["official_name_ar"]
                                    }</div>
                                    <div className={valueEdit[2] ? "inputEdit show" : "inputEdit hide"}>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <Select
                                                value={data.country}
                                                // onChange={handleChange} 
                                                style={{ fontFamily: 'ArbFONTS-DroidKufi-bold', fontSize: '12px' }}

                                                onChange={(e) => {
                                                    let tempAr = data;
                                                    tempAr['country'] = e.target.value;
                                                    setData({ ...tempAr });
                                                }}
                                            >
                                                {countres.map(item =>
                                                    <MenuItem value={item["FIPS"]}>{item["official_name_ar"]}</MenuItem>
                                                )}

                                            </Select>

                                        </FormControl>




                                    </div>
                                </div>

                            </div>

                            <div className="rowInfo">

                                <div className="rowLabel">
                                    <div className="labelRow">البريد الإلكتروني</div>
                                    <div className={!valueEdit[3] ? "labelRow show" : "labelRow hide"}>{data.email !== '' ? data.email : ''}</div>
                                    <div className={valueEdit[3] ? "inputEdit show" : "inputEdit hide"}>
                                        <TextField id="outlined-basic" value={data.email !== '' ? data.email : ''} variant="outlined" onChange={(e) => changeData(e.target.value, 'email')}
                                            className={!error[3] ? "errorFildes" : ""}
                                        />
                                    </div>
                                </div>
                            </div>


                            <div className="rowInfo">
                                <div className="rowLabel">
                                    <div className="labelRow">رقم الهاتف</div>
                                    <div className={!valueEdit[4] ? "labelRow show" : "labelRow hide"}>{data.phone.substr(1, data.phone.length)} {countres.filter(item => item.FIPS === data.country)[0]["Dial"] + '+'}</div>
                                    <div className={valueEdit[4] ? "inputEdit show" : "inputEdit hide"}>
                                        <TextField id="outlined-basic" value={data.phone} variant="outlined" onChange={(e) => changeData(e.target.value, 'phone')} />
                                    </div>
                                </div>

                            </div>
                        </div>
                        {parentAccount !== null && (
                            <div className="boxInfo">
                                <div className="rowInfo">
                                    <div className="rowLabel">
                                        <div className="labelRow">تاريخ بداية الإشتراك</div>
                                        <div className="labelRow">
                                            {timeConverter(parentAccount.subscriptionPeriod.startDate)}
                                        </div>
                                    </div>
                                </div>


                                <div className="rowInfo">
                                    <div className="rowLabel">
                                        <div className="labelRow">تاريخ نهاية الإشتراك</div>
                                        <div className="labelRow">
                                            {timeConverter(parentAccount.subscriptionPeriod.endDate)}
                                        </div>
                                    </div>
                                </div>


                            </div>
                        )}


                    </div>
                </div>

            </div>
        </div>
    );
}

export default AdvancedSearch;
