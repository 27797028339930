import React, { useState } from 'react';
import axios from 'axios';
const PaymentForm = () => {
    const [cardNumber, setCardNumber]=useState(null);
    const [CardDate, setCardDate]= useState(null);
    const [CardCode, setCardCode]= useState(null);
  
    const handlePaymentResponse =async (e) => {
        e.preventDefault();
        try {
            // Send payment data to Node.js server
            const response = await axios.post('/app/processPayment', {
              cardNumber,
              CardDate,
              CardCode,
            });
      
            // Handle the response (e.g., update UI with success or failure)
          } catch (error) {
            // Handle error (e.g., display error message)
          }
    };

  return (
    <form id="payform"  style={{height:"100%", marginTop:"200px", textAlign:'center'}} onSubmit={handlePaymentResponse}>
      <span id="paymentErrors"></span>
      <div className="row">
        <label>Card Number</label>
        <input type="text" data-paylib="number" size="20" onChange={(e)=>setCardNumber(e.target.value)}/>
      </div>
      <div className="row">
        <label>Expiry Date (MM/YYYY)</label>
        <input type="text"  onChange={(e) => setCardDate(e.target.value)} />

      </div>
      <div className="row">
        <label>Security Code</label>
        <input type="text" data-paylib="cvv" size="4" onChange={(e)=>(setCardCode(e.target.value))}/>
      </div>
      <input type="submit" value="Place order" />

    </form>
  );
};

export default PaymentForm;