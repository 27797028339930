import React from 'react';
import { render } from "react-dom";
import Apps from './App';

import '../src/assets/index.scss';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from './store/store';

const App = () => (
  <Provider store={store}>
  <Apps />
  </Provider>
);


render(
  <App />, document.getElementById("root"));
serviceWorker.unregister();