import React, { useEffect, useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";

export default function PopupShare(props) {
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    setShowPopup(props.objPopup.showPopup);
  }, []);
  const addLogShares = (t) => {
    if (props.objPopup.loginStatus) {
      let formData = new FormData();
      formData.append("lang", "ar");
      formData.append("id", props.objPopup.id);
      formData.append("type", props.objPopup.type);
      formData.append("shareType", t);
      formData.append("access_token", localStorage.getItem("access_token"));
      axios
        .post(
          process.env.REACT_APP_BACKEND_URL + "/app/content/documentationSet",
          formData
        )
        .then(response2 => {});
    }
  };
  return (
    <div
      className="BookmarksBG"
      style={{
        visibility: showPopup ? "visible" : "hidden",
        opacity: showPopup ? "1" : "0",
        transition: "opacity 0.4s"
      }}
    >
      <div onClick={() => setShowPopup(false)} className="bgwithout" />
      <motion.div
        animate={{ y:100 }}
        transition={{ ease: "easeOut", duration: 1 }}
      >
      <div className="Bookmarks">
        <div className="titleBookmatks">
          مشاركة
          <div className="closeBox" onClick={() => setShowPopup(false)}>
            {" "}✖{" "}
          </div>
        </div>
        <div className="share">
          <a
            onClick={() => {
              addLogShares("email");
              window.open(
                `mailto:?subject=${props.objPopup.Item != null
                  ? props.objPopup.Item.nameAr
                  : ""}&body=يمكنك قرأته من خلال الرابط التالي: http://www.beta.thelearnbook.com/ViewBook/${props.objPopup.id}/${props.objPopup.type}`,
                "newwindow",
                "width=400,height=350"
              );
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="150"
              height="150"
              viewBox="0 0 150 150"
            >
              <g
                id="Group_9561"
                data-name="Group 9561"
                transform="translate(3 -21)"
              >
                <circle
                  id="Ellipse_238"
                  data-name="Ellipse 238"
                  cx="75"
                  cy="75"
                  r="75"
                  transform="translate(-3 21)"
                  fill="#235bd8"
                />
                <path
                  id="Path_9858"
                  data-name="Path 9858"
                  d="M136.511,74.207h-70.9A10.1,10.1,0,0,0,55.3,84.084v44.5a10.1,10.1,0,0,0,10.308,9.876h70.906a10.1,10.1,0,0,0,10.308-9.876v-44.5A10.1,10.1,0,0,0,136.511,74.207Zm-2.218,53.774a2.8,2.8,0,0,1-3.843.071l-17.066-15.737-2.459,2.585a13.751,13.751,0,0,1-19.735,0l-2.422-2.547L72.423,128.018a2.806,2.806,0,0,1-3.845,0,2.526,2.526,0,0,1,0-3.682L83.6,109.94l1.5-1.439L67.775,90.281a2.529,2.529,0,0,1,.178-3.679,2.8,2.8,0,0,1,3.839.17L95.208,111.39a8.151,8.151,0,0,0,11.7,0l4.388-4.613,19.028-20a2.8,2.8,0,0,1,3.839-.17,2.529,2.529,0,0,1,.178,3.679l-17.3,18.183L134.217,124.3A2.527,2.527,0,0,1,134.293,127.981Z"
                  transform="translate(-29.06 -10.332)"
                  fill="#fff"
                />
              </g>
            </svg>
          </a>
          <a
            onClick={() => {
              addLogShares("facebook");

              let url =
                "https://web.facebook.com/sharer.php?display=popup&u=" +
                `http://www.beta.thelearnbook.com/ViewBook/${props.objPopup.id}/${props.objPopup.type}&quote=${props.objPopup.Item !=
                null
                  ? props.objPopup.Item.nameAr
                  : ""}&hashtag=بوابة_الكتاب_العلمي`;
              let options =
                "toolbar=0,status=0,resizable=1,width=626,height=436";
              window.open(url, "sharer", options);
            }}
          >
            <svg
              id="Group_8596"
              dataname="Group 8596"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 34.477 34.477"
            >
              <ellipse
                id="Ellipse_29"
                dataname="Ellipse 29"
                cx="17.238"
                cy="17.238"
                rx="17.238"
                ry="17.238"
                fill="#1e4fad"
              />
              <path
                id="Path_8818"
                dataname="Path 8818"
                d="M297.274,47.887c.476-.015.95,0,1.428,0h.2V45.408c-.253-.027-.52-.063-.786-.076-.487-.021-.977-.046-1.466-.034a3.58,3.58,0,0,0-2.07.64A3.1,3.1,0,0,0,293.333,48a7.331,7.331,0,0,0-.086,1.065c-.015.554,0,1.11,0,1.668v.208h-2.371v2.765h2.358v6.955h2.877V53.722h2.35c.12-.922.238-1.832.362-2.78h-.529c-.667,0-2.2,0-2.2,0s.006-1.371.023-1.967C296.138,48.16,296.621,47.912,297.274,47.887Z"
                transform="translate(-278.116 -35.398)"
                fill="#fff"
                fillRule="evenodd"
              />
            </svg>
          </a>

          <a
            onClick={() => {
              addLogShares("twitter");
              window.open(
                `https://twitter.com/intent/tweet?text=${props.objPopup.Item != null
                  ? props.objPopup.Item.nameAr
                  : ""}, https://www.beta.thelearnbook.com/ViewBook/${props.objPopup.id}/${props.objPopup.type}`,
                "newwindow",
                "width=400,height=350"
              );
            }}
          >
            <svg
              id="Group_8595"
              dataname="Group 8595"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 34.477 34.477"
            >
              <ellipse
                id="Ellipse_30"
                dataname="Ellipse 30"
                cx="17.238"
                cy="17.238"
                rx="17.238"
                ry="17.238"
                fill="#1ba5d4"
              />
              <path
                id="Path_8819"
                dataname="Path 8819"
                d="M293.844,89.024a2.934,2.934,0,0,1-2.721-2.03,2.9,2.9,0,0,0,1.253-.038.3.3,0,0,0,.042-.019,2.922,2.922,0,0,1-1.9-1.343,2.837,2.837,0,0,1-.427-1.556,2.876,2.876,0,0,0,1.3.356,2.952,2.952,0,0,1-1.206-1.775,2.914,2.914,0,0,1,.314-2.12,8.367,8.367,0,0,0,6.035,3.056c-.017-.114-.036-.215-.048-.318a2.851,2.851,0,0,1,.427-1.914A2.816,2.816,0,0,1,298.9,80a2.853,2.853,0,0,1,2.561.817.15.15,0,0,0,.156.046,5.886,5.886,0,0,0,1.7-.655l.04-.019.019,0a2.991,2.991,0,0,1-1.253,1.592,5.59,5.59,0,0,0,1.628-.438l.011.013c-.111.147-.219.3-.335.44a5.748,5.748,0,0,1-1.064,1.009.1.1,0,0,0-.048.091,8,8,0,0,1-.053,1.3,8.621,8.621,0,0,1-.754,2.605,8.476,8.476,0,0,1-1.581,2.306,7.81,7.81,0,0,1-4.022,2.239,8.663,8.663,0,0,1-1.628.194,8.254,8.254,0,0,1-4.681-1.257c-.019-.01-.036-.025-.068-.044a5.9,5.9,0,0,0,2.925-.4A5.829,5.829,0,0,0,293.844,89.024Z"
                transform="translate(-279.334 -67.569)"
                fill="#fff"
                fillRule="evenodd"
              />
            </svg>
          </a>
        </div>
      </div>
      </motion.div>
    </div>
  );
}
