import { configureStore } from "@reduxjs/toolkit";
import searchSlice from './search';
import myLibrary from "./myLibrary";
import browse from "./browse";
import view from "./view";
import header from "./header";
import viewBook from "./viewBook";
import resultAll from "./resultAll";


export const store = configureStore({
    reducer: {
        search: searchSlice,
        mylibrary: myLibrary,
        browse: browse,
        view: view,
        header:header,
        viewBook:viewBook,
        resultAll:resultAll
    }
});

