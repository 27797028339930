import React, { useEffect, useRef } from 'react';
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import AWS from "aws-sdk";
import { Base64 } from 'js-base64'
import { useDispatch, useSelector } from 'react-redux';
import {
    setItem,
    setItem2,
    setRelated,
    setAuthors,
    setYearsNumbers,
    setYearsNumbers2,
    setYearsNumbers3,
    setLoadingPage,
    setShowPopup2,
    setShowPopup3,
    setOpenAlert,
    setmarkUrl,
    setMarcData,
    setLoginStatus,
    setPopupShareData,
    setYears1Selected,
    setYears2Selected,
    setPdfFile,
     viewState
} from '../store/view';
import Topsection from '../components/sharing/topsection';
import Breadcrumb from '../components/sharing/breadcrumbs';
import { MarcRecord } from '@natlibfi/marc-record';

import { Alert, Skeleton } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
import PopupShare from '../components/pdf/popup/popup_share';

function View() {
    let history = useHistory();
    const dispatch = useDispatch();
    const viewData = useSelector(viewState);
    const { id, type } = useParams();
    
    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth' })
   
    var downloadBlob, downloadURL;

    downloadBlob = function (data, fileName, mimeType) {
        var blob, url;
        blob = new Blob([data], {
            type: mimeType
        });
        url = window.URL.createObjectURL(blob);
        downloadURL(url, fileName);
        setTimeout(function () {
            return window.URL.revokeObjectURL(url);
        }, 1000);
    };

    downloadURL = function (data, fileName) {
        var a;
        a = document.createElement('a');
        a.href = data;
        a.download = fileName;
        document.body.appendChild(a);
        a.style = 'display: none';
        a.click();
        a.remove();
    };
    useEffect(() => {
        const BUCKET_NAME = "pdfthelearnbook";
        const IAM_USER_KEY = "AKIAZWMPNC53C2EVF62C";
        const IAM_USER_SECRET = "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";

        let s3bucket = new AWS.S3({
            accessKeyId: IAM_USER_KEY,
            secretAccessKey: IAM_USER_SECRET
        });
       dispatch(setLoadingPage(true))
        var crypto = require('crypto');
        let hash = crypto.createHash('sha256').update(JSON.stringify(formatDate())).digest('hex');


        let formData = new FormData();
        formData.append('hashTime', hash);
        formData.append('lang', 'ar');
        formData.append('id', id);
        formData.append('type', type);

        axios.post(process.env.REACT_APP_BACKEND_URL + '/app/view/get', formData,
        ).then(response2 => {
            dispatch(setItem(response2.data.result))
            localStorage.setItem('pdf', JSON.stringify(response2.data.result))
            dispatch(setItem2(response2.data))
            let arraySubject = [];
            arraySubject.push(response2.data.hhhh.map(item => item.subject._id));

            let access_token = localStorage.getItem('access_token');
            if (access_token !== undefined && access_token !== "" && access_token !== null) {
                dispatch(setLoginStatus(true))

                if (response2.data.result.s3obj !== undefined) {
                    s3bucket.getObject(
                        {
                            Bucket: "pdfthelearnbook", // your bucket name,
                            Key: response2.data.result.s3obj // path to the object you're looking for
                        },
                        function (err, data) {
                            if (err) console.log("1111,", err, err.stack);
                            else {
                                // error
                                dispatch(setPdfFile(data.Body));
                            }
                        }
                    );
                }
                else {

                    dispatch(setPdfFile(process.env.REACT_APP_BACKEND_URL + '/public/' + response2.data.result.fileData));
                }
            }


            formData.append('viewby', arraySubject);
            formData.append('viewbysub', response2.data.hhhh[0]?.subSubject[0]?.id);
            axios.post(process.env.REACT_APP_BACKEND_URL + '/app/view/getRelatedContent', formData,
            ).then(RelatedFeed => {
                dispatch(setRelated(RelatedFeed.data.result1));
                dispatch(setAuthors(RelatedFeed.data.feed))
                //////////////
                if (type === 'journal' && response2.data.result.titleDawreaa !== undefined && viewData.years2Selected !== undefined) {
                    formData.append('titleDawreaa', response2.data.result.titleDawreaa._id);
                    formData.append('years1Selected', viewData.years1Selected);
                    formData.append('years2Selected', viewData.years2Selected);
                    axios.post(process.env.REACT_APP_BACKEND_URL + '/app/view/getYearsNumbers', formData,
                    ).then(getYearsNumbers => {
                        dispatch(setYearsNumbers(getYearsNumbers.data.result1.filter(item => item._id !== '')))
                        dispatch(setYearsNumbers2(getYearsNumbers.data.result2))
                        dispatch(setYearsNumbers3(getYearsNumbers.data.result3))
                        dispatch(setYears1Selected(viewData.years1Selected !== null ? viewData.years1Selected : getYearsNumbers.data.result1[0]._id))
                        dispatch(setYears2Selected(viewData.years2Selected !== null ? viewData.years2Selected : getYearsNumbers.data.result2[0]._id))
                        dispatch(setLoadingPage(false))
                    })
                }
                else {
                    dispatch(setLoadingPage(false))
                }

                /////////////////////
            })
            executeScroll()
        })



    }, [id, type, viewData.years1Selected, viewData.years2Selected]);
    useEffect(() => {
        let access_token = localStorage.getItem('access_token')
        if (
            access_token !== undefined &&
            access_token !== '' &&
            access_token !== null
        ) {
            dispatch(setLoginStatus(true))
        } else {
            dispatch(setLoginStatus(false))
        }
    }, [])

    function formatDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
    const breadCurmbData = [
        {
            title: type === 'book' ? "كتب" : type === 'research' ? 'أبحاث' : type === 'these' ? 'رسائل' : 'مجلات',
            link: '/browse',
        },
        {
            title: viewData.Item != null ? viewData.Item.nameAr : '',
            link: '',
        },
    ];

    const checkIfTextArabic = (text) => {
        var arabicUnicodes = /[\u0600-\u06FF]/;
        return arabicUnicodes.test(text)
    }


    const timeConverter = (UNIX_timestamp) => {
        var a = new Date(parseInt(UNIX_timestamp));
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var year = a.getFullYear();
        var month = (a.getMonth() + 1).toString().length == 1 ? '0' + (a.getMonth() + 1) : (a.getMonth() + 1);
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();

        var time = date + '' + month + '' + (year.toString().substring(2, 4));



        return time;
    }


    const exportToMark = () => {
        let lang2 = checkIfTextArabic(viewData.Item.nameAr) ? 'ARA' : 'ENG';
        var GregorianYear = viewData.Item.yearsValue;
        var HijriYear = Math.round((GregorianYear - 622) * (33 / 32));

        const record = new MarcRecord();
        // Insert field to the record. Proper ordering is handled automatically.
        record.leader = "00492cam a2200145 a 4500";

        record.insertField({
            tag: "=001",
            value: viewData.Item._id.split('_')[1],
        });

        record.insertField({
            tag: "=008",
            value: timeConverter(viewData.Item.created) + ' ' + (viewData.Item.yearsValue !== 'null' ? viewData.Item.yearsValue + HijriYear : '') + 'JOR' + '    ' + lang2
        });
        if (viewData.Item.ISBNValue !== '' && viewData.Item.ISBNValue !== null && viewData.Item.ISBNValue !== 'null' && viewData.Item.ISBNValue !== undefined && viewData.Item.ISBNValue !== ' ')
            record.appendField({
                tag: '=020',
                ind2: ' ',
                subfields: [
                    {
                        code: "a",
                        value: viewData.Item.ISBNValue
                    },
                ]
            });

        record.appendField({
            tag: '=100',
            ind1: '0',
            ind2: '4',
            subfields: [
                {
                    code: "a",
                    value: viewData.Item2.hhh[0].name.nameAr
                },
            ]
        });


        record.appendField({
            tag: '=245',
            ind1: '1',
            ind2: '0',
            subfields: [
                {
                    code: "a",
                    value: viewData.Item.nameAr
                },
            ]
        });

        if (viewData.Item.yearsValue !== 'null' && viewData.Item.yearsValue !== '' && viewData.Item.yearsValue !== null && viewData.Item.yearsValue !== undefined && viewData.Item.yearsValue !== ' ')
            record.appendField({
                tag: '=264',
                subfields: [
                    {
                        code: "a",
                        value: "الأردن"
                    },
                    {
                        code: "b",
                        value: viewData.Item.publisherValue.length !== 0 ? viewData.Item.publisherValue[0].nameAr : ''
                    },
                    {
                        code: "c",
                        value: viewData.Item.yearsValue + ''
                    }

                ]
            });
        else
            record.appendField({
                tag: '=264',
                subfields: [
                    {
                        code: "a",
                        value: "الأردن"
                    },
                    {
                        code: "b",
                        value: viewData.Item.publisherValue.length !== 0 ? viewData.Item.publisherValue[0].nameAr : ''
                    }
                ]
            });

        record.appendField({
            tag: '=300',
            subfields: [
                {
                    code: "a",
                    value: viewData.Item.numberPage + ' ص'
                },
            ]
        });

        if (viewData.Item2.hhhh.length !== 0)
            record.appendField({
                tag: '=650',
                ind1: '0',
                ind2: '4',
                subfields: [
                    {
                        code: "a",
                        value: viewData.Item2.hhhh[0].subject.nameAr
                    },
                    {
                        code: "x",
                        value: viewData.Item2.hhhh[0].subSubject[0].NameAR
                    }
                ]
            });


        record.appendField({
            tag: '=949',
            subfields: [
                {
                    code: "t",
                    value: type === 'book' ? 'كتاب' : viewData.Item.bowlShape
                },
            ]
        });



        record.appendField({
            tag: '=856',
            subfields: [
                {
                    code: "u",
                    value: `https://thelearnbook.com/view/${viewData.Item._id.split('_')[1]}/${viewData.Item._id.split('_')[0]}`
                },
            ]
        });



        dispatch(setMarcData(record.toString()))


        dispatch(setLoadingPage(true))
        var crypto = require('crypto');
        let hash = crypto.createHash('sha256').update(JSON.stringify(formatDate())).digest('hex');


        let formData = new FormData();
        formData.append('hashTime', hash);
        formData.append('lang', 'ar');
        formData.append('id', viewData.Item._id);
        formData.append('title', viewData.Item.nameAr);
        formData.append('publisher', viewData.Item.publisherValue.length !== 0 ? viewData.Item.publisherValue[0].nameAr : '');
        formData.append('type', type === 'book' ? 'كتاب' : viewData.Item.bowlShape);
        formData.append('subject', viewData.Item2.hhhh.length !== 0 ? viewData.Item2.hhhh[0].subject.nameAr : '');
        formData.append('subSubject', viewData.Item2.hhhh.length !== 0 ? viewData.Item2.hhhh[0].subSubject[0].NameAR : "");
        formData.append('numberPage', viewData.Item.numberPage);
        formData.append('langDoc', checkIfTextArabic(viewData.Item.nameAr) ? 'ARA' : 'ENG');
        formData.append('auther', viewData.Item2.hhh[0].name.nameAr);
        formData.append('years', viewData.Item.yearsValue);
        formData.append('ISBNValue', viewData.Item.ISBNValue);
        formData.append('created', viewData.Item.created);


        axios.post(process.env.REACT_APP_BACKEND_URL + '/marc/get', formData,
        ).then(response2 => {
            dispatch(setLoadingPage(false));
            dispatch(setmarkUrl(response2.data.url))
            dispatch(setShowPopup2(true));
        })
    }

    return (
        <div ref={myRef}>
            <Topsection data={{ text: type === 'book' ? "تفاصيل الكتب" : type === 'research' ? 'تفاصيل الأبحاث' : type === 'these' ? 'تفاصيل الرسائل' : 'تفاصيل المجلات', img: require('../assets/images/44.png') }} />
            <Breadcrumb data={breadCurmbData} />
            {viewData.popupShareData !== null ? <PopupShare objPopup={viewData.popupShareData} /> : ""}


            <div className="BookmarksBG"
                style={{ visibility: viewData.showPopup2 ? 'visible' : 'hidden', opacity: viewData.showPopup2 ? '1' : '0', transition: 'opacity 0.4s' }}
            >
                <div className="bgwithout" onClick={() => dispatch(setShowPopup2(false))}></div>
                <div className="Bookmarks">
                    <div className="titleBookmatks">إختر نوع ملف مارك
                        <div className="closeBox" onClick={() => dispatch(setShowPopup2(false))} > ✖ </div>
                    </div>
                    <a onClick={() => dispatch(setShowPopup3(true))} target="_block" className="btnMarc">MRK</a>
                    <a href={`https://apis.thelearnbook.com/${viewData.markUrl}/Book.mrc`} target="_block" className="btnMarc">MRC</a>
                </div>
            </div>


            <div className="BookmarksBG"
                style={{ visibility: viewData.showPopup3 ? 'visible' : 'hidden', opacity: viewData.showPopup3 ? '1' : '0', transition: 'opacity 0.4s' }}
            >
                <div className="bgwithout" onClick={() => dispatch(setShowPopup3(false))}></div>

                <div className="Bookmarks">
                    <div className="titleBookmatks">MRK
                        <div className="closeBox" onClick={() => dispatch(setShowPopup3(false))} > ✖ </div>
                    </div>
                    <textarea value={viewData.marcData}></textarea>

                </div>
            </div>
            <Snackbar open={viewData.openAlert} autoHideDuration={3000} onClose={() => dispatch(setOpenAlert(false))}>
                <Alert severity="success" color="info">تم نسخ الإستشهاد المرجعي بنجاح!</Alert>
            </Snackbar>



            <div className="loadingData" style={{
                visibility: viewData.loadingPage ? 'visible' : 'hidden',
                opacity: viewData.loadingPage ? 1 : 0,
                transition: 'visibility 0s, opacity 0.3s linear'

            }}>
                <img src={require('../assets/images/bookLoading.gif')} alt="loading" />
                <div className="textLoading">جاري التحميل...</div>
            </div>
            <div className="ViewBook" style={{ backgroundColor: '#F2F6FA' }}>
                <div className="viewBigDiv">

                    <div className="view">


                        {
                            viewData.Item === null ?
                                <>
                                    <div className='imgandRead'>
                                        <Skeleton variant="rect" width={250} height={350} animation="wave" style={{ borderRadius: '10px' }} />
                                    </div>
                                    <div className="centerSection" >
                                        <div className="rowInSec1">
                                            <Skeleton variant="rect" width={100} height={40} animation="wave" style={{ borderRadius: '10px' }} />
                                            <Skeleton variant="rect" width={150} height={50} animation="wave" style={{ borderRadius: '10px' }} />
                                        </div>

                                        <div className="rowInSec w-60">
                                            <Skeleton variant="rect" width={500} height={40} animation="wave" style={{ borderRadius: '10px' }} />
                                        </div>






                                        <div className="rowInSec w-60">
                                            <Skeleton variant="rect" width={400} height={40} animation="wave" style={{ borderRadius: '10px' }} />
                                        </div>

                                        <div className="rowInSec w-60 ltrww">

                                            <Skeleton variant="rect" width={330} height={40} animation="wave" style={{ borderRadius: '10px' }} />
                                        </div>

                                        <div className="rowInSec w-60" key={55}>
                                            <Skeleton variant="rect" width={330} height={40} animation="wave" style={{ borderRadius: '10px' }} />
                                        </div>


                                        <div className="rowInSec">
                                            <div className="tags">
                                                <Skeleton variant="rect" width={100} height={40} animation="wave" style={{ borderRadius: '10px', marginLeft: '10px' }} />
                                                <Skeleton variant="rect" width={100} height={40} animation="wave" style={{ borderRadius: '10px', marginLeft: '10px' }} />
                                                <Skeleton variant="rect" width={100} height={40} animation="wave" style={{ borderRadius: '10px', marginLeft: '10px' }} />
                                                <Skeleton variant="rect" width={100} height={40} animation="wave" style={{ borderRadius: '10px', marginLeft: '10px' }} />
                                            </div>

                                        </div>

                                        <div className="btnActions">
                                            <Skeleton variant="rect" width={150} height={50} animation="wave" style={{ borderRadius: '10px', marginLeft: '10px' }} />
                                            <Skeleton variant="rect" width={150} height={50} animation="wave" style={{ borderRadius: '10px', marginLeft: '10px' }} />
                                            <Skeleton variant="rect" width={150} height={50} animation="wave" style={{ borderRadius: '10px', marginLeft: '10px' }} />
                                        </div>
                                    </div>

                                </>
                                :
                                <>
                                    <div className='imgandRead'>
                                        {viewData.Item.s3objcover != undefined && viewData.Item.s3objcover != '' ?
                                            <img src={viewData.Item != null ? viewData.Item.s3objcover : ''} onClick={() => history.push("/ViewBook/" + viewData.Item._id.split('_')[1] + '/' + type + '/' + Base64.encode(`${viewData.Item.yearsValue}`, true))} />
                                            :
                                            <img src={'https://apis.thelearnbook.com/public/learnBookCover.jpg'} onClick={() => history.push("/ViewBook/" + viewData.Item._id.split('_')[1] + '/' + type + '/' + Base64.encode(`${viewData.Item.yearsValue}`, true))} />
                                        }
                                    </div>
                                    <div className="centerSection" >
                                        <div className="rowInSec1">
                                            {type === 'book' ? <div className="btnBook">كتاب</div> : type === 'research' ? <div className="btnma2">مقال مؤتمر</div> : type === 'these' ? <div className="btnMag">رسالة </div> : <div className="btnma">مقال مجلة</div>}

                                            {viewData.loginStatus && (
                                                <div className="copylink" onClick={exportToMark}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="37.537" height="27.292" viewBox="0 0 37.537 27.292">
                                                        <g id="Group_9392" data-name="Group 9392" transform="translate(-319.233 -553.024)">
                                                            <g id="Group_8874" data-name="Group 8874" transform="translate(-420.948 -278.168)">
                                                                <g id="Group_8873" data-name="Group 8873" transform="translate(-3 -7.98)" opacity="0.5">
                                                                    <path id="Path_9503" data-name="Path 9503" d="M744.181,840.381v3.164a5.234,5.234,0,0,0,5.235,5.234h4.847" fill="none" stroke="#147bfc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                                    <path id="Path_3" data-name="Path 3" d="M751.061,852.819l4.04-4.04-4.04-4.04" fill="none" stroke="#147bfc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                                </g>
                                                            </g>
                                                            <g id="Group_9038" data-name="Group 9038" transform="translate(-141.883 2561.154)">
                                                                <path id="Path_9582" data-name="Path 9582" d="M483.315-2007.13h12.9a1.437,1.437,0,0,1,1.436,1.437v22.42a1.437,1.437,0,0,1-1.436,1.436h-16.77a1.437,1.437,0,0,1-1.436-1.436v-18.3" transform="translate(0)" fill="none" stroke="#147bfc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                                <path id="Path_9583" data-name="Path 9583" d="M478.01-2001.575l5.305-5.555v4.124a1.433,1.433,0,0,1-1.436,1.431H478.01" transform="translate(0)" fill="none" stroke="#147bfc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                    تصدير إلى مارك
                                                </div>
                                            )}

                                        </div>
                                        <div className="rowInSec titleCountent" onClick={() => history.push("/ViewBook/" + viewData.Item._id.split('_')[1] + '/' + type + '/' + Base64.encode(`${viewData.Item.yearsValue}`, true))}>
                                            {viewData.Item != null ? viewData.Item.nameAr : ''}
                                        </div>
                                        <div className="rowInSec w-60">
                                        </div>
                                        {
                                            viewData.Item != null && viewData.Item.yearsValue !== 'null' ? <div className="rowInSec w-60">

                                                <div>تاريخ النشر:&nbsp;<a onClick={() => history.push("/browseBy/" + viewData.Item.yearsValue + "/" + 'years' + "/" + viewData.Item.yearsValue)}> {viewData.Item.yearsValue} </a></div>

                                            </div> : ''
                                        }




                                        {viewData.Item != null ?

                                            viewData.Item.publisherValue != undefined || viewData.Item.publisherValue.length != 0 ?

                                                <div className="rowInSec w-60">
                                                    <div>الناشر:&nbsp;
                                                        {viewData.Item.publisherValue.map((item333, index) =>
                                                            <a key={index} onClick={() => history.push("/browseBy/" + item333._id + "/" + 'publisherValue' + "/" + item333.nameAr)}>{viewData.Item.publisherValue.length != 0 ? item333.nameAr : ''}

                                                                {(index + 1) < viewData.Item.publisherValue.length ? '، ' : ' '}
                                                            </a>


                                                        )}

                                                    </div>
                                                </div>
                                                : ''
                                            : ''
                                        }

                                        {viewData.Item != null && viewData.Item.degree != null ? <div className="rowInSec w-60">

                                            <div>نوع البرنامج: &nbsp;<a onClick={() => history.push("/browseBy/" + viewData.Item.degree + "/" + 'degree' + "/" + viewData.Item.degree)}> {viewData.Item.degree} </a></div>

                                        </div> : ''}


                                        {viewData.Item != null && viewData.Item.bowlShape != 'null' ? <div className="rowInSec w-60">

                                            <div>نوع الوثيقة: &nbsp;{type === 'book' ? 'كتاب' : viewData.Item.bowlShape}</div>

                                        </div> : ''}




                                        <div className="rowInSec">

                                            <div>المواضيع الرئيسية:&nbsp;
                                                {
                                                    viewData.Item2 !== null ?
                                                        viewData.Item2.hhhh.map((item, index) =>

                                                            viewData.Item2.hhh.length - 1 != index ?
                                                                <span key={index}>
                                                                    <a onClick={() => history.push("/browseBy/" + item.subject._id + "/" + 'subjectsValue' + "/" + item.subject.nameAr)}>{item.subject?.nameAr} | {item.subSubject[0]?.NameAR}</a> ,
                                                                </span>
                                                                :
                                                                <span key={index}>
                                                                    <a onClick={() => history.push("/browseBy/" + item.subject._id + "/" + 'subjectsValue' + "/" + item.subject?.nameAr)}>{item.subject?.nameAr} | {item.subSubject[0]?.NameAR}</a>
                                                                </span>
                                                        ) : ''}
                                            </div>
                                        </div>




                                        <div className="rowInSec w-60">
                                            <div>عدد الصفحات: {viewData.Item != null ? viewData.Item.numberPage : ''}</div>
                                        </div>

                                        {
                                            viewData.Item.ISBNValue !== 'null' ?
                                                <div className="rowInSec w-60 ltrww">
                                                    {
                                                        viewData.Item.ISBNValue !== '' ?
                                                            <div style={{ display: 'flex', flexDirection: 'row' }}>{type === 'journal' ? 'ISSN' : 'ISBN'}: &nbsp;<div>  {viewData.Item.ISBNValue}  </div></div>
                                                            : ''
                                                    }
                                                </div>
                                                : ''}
                                        <div className="rowInSec w-60">
                                            <div>لغة الوثيقة: {viewData.Item != null ? checkIfTextArabic(viewData.Item.nameAr) ? 'العربيّة' : 'الإنجليزية ' : ''}</div>
                                        </div>
                                        {viewData.Item2 !== null ?
                                            <div className="rowInSec w-60" key={55}>
                                                <div>
                                                    المؤلف:&nbsp;
                                                    {viewData.Item2.hhh.length !== 0 ?
                                                        viewData.Item2.hhh.map((i, index) =>
                                                            viewData.Item2.hhh.length - 1 != index ?
                                                                <><a onClick={() => history.push("/browseBy/" + i.name._id + "/" + 'authorValue' + "/" + (i.name != null ? i.name.nameAr : ''))}>{i.name != null ? i.name.nameAr : ''}</a><b>،</b> </>
                                                                :
                                                                <><a onClick={() => history.push("/browseBy/" + i.name._id + "/" + 'authorValue' + "/" + (i.name != null ? i.name.nameAr : ''))}>{i.name != null ? i.name.nameAr : ''}</a> </>
                                                        ) : ''}
                                                </div>
                                            </div>

                                            : ''
                                        }
                                        {viewData.Item != null && type === 'journal' ?
                                            <>
                                                {viewData.Item.titleDawreaa ?
                                                    <div className="rowInSec w-60">
                                                        <div>المجلة: <a onClick={() => history.push("/browseBy/" + viewData.Item.titleDawreaa._id + "/" + 'titleDawreaa' + "/" + (viewData.Item.titleDawreaa != null ? viewData.Item.titleDawreaa.nameAr : ''))}>{viewData.Item.titleDawreaa?.nameAr}</a></div>
                                                    </div>
                                                    : ''
                                                }

                                                <div className="rowInSec w-60">
                                                    <div>المجلد والعدد: {viewData.Item.mojaldandNumber}</div>
                                                </div>
                                                <div className="rowInSec w-60">
                                                    <div>الصفحات: {viewData.Item.pagesJournals}</div>
                                                </div>
                                                <div className="rowInSec w-60">
                                                    <div> تردد الدورية: {viewData.Item.rotatingFrequency}</div>
                                                </div>
                                                <div className="rowInSec w-60">
                                                    <div> شكل الوعاء: {viewData.Item.bowlShape}</div>
                                                </div>
                                            </>

                                            : ''}


                                        {viewData.Item != null && type === 'research' ?
                                            <>
                                                <div className="rowInSec w-60">
                                                    <div>اسم المؤتمر: <a onClick={() => history.push("/browseBy/" + viewData.Item.conferencesObj._id + "/" + 'nameMoatamer' + "/" + (viewData.Item.conferencesObj != null ? viewData.Item.conferencesObj.nameAr : ''))}>{viewData.Item.conferencesObj.nameAr}</a></div>
                                                </div>
                                                <div className="rowInSec w-60">
                                                    <div >
                                                        الاسم الثانوي للمؤتمر: <a>{viewData.Item?.otherenterName}</a></div>
                                                </div>
                                                {viewData.Item.numberRes !== 'null' ?
                                                    <div className="rowInSec w-60">
                                                        <div>رقم المؤتمر: {viewData.Item.numberRes}</div>
                                                    </div>
                                                    : ''
                                                }

                                                {viewData.Item.yearEneqad !== 'null' ?
                                                    <div className="rowInSec w-60">
                                                        <div>سنة الانعقاد: {viewData.Item.yearEneqad}</div>
                                                    </div>
                                                    : ''
                                                }

                                                {viewData.Item.enqadLoctions !== 'null' ?
                                                    <div className="rowInSec w-60">
                                                        <div>مكان الانعقاد: {viewData.Item.enqadLoctions}</div>
                                                    </div>
                                                    : ''
                                                }

                                                {viewData.Item.bowlShape !== 'null' ?
                                                    <div className="rowInSec w-60">
                                                        <div> شكل الوعاء: {viewData.Item.bowlShape}</div>
                                                    </div>
                                                    : ''
                                                }


                                            </>

                                            : ''}



                                        <div className="rowInSec">
                                            <div className="tags">كلمات مفتاحية:
                                                {/* {Item != null ? Item.tagsValue.map(tag => <div className="tag" key={tag._id}>{tag.nameAr}</div>) : ''} */}

                                                {viewData.Item != null ? viewData.Item.tagsValue.map((i, index) => <div key={i._id} className="tag" onClick={() => history.push("/browseBy/" + i._id + "/" + 'tags' + "/" + i.nameAr)}>{i.nameAr}</div>) : ''}
                                            </div>

                                        </div>

                                        <div className="btnActions">
                                            <div className="readBookBtn" onClick={() => history.push("/ViewBook/" + viewData.Item._id.split('_')[1] + '/' + type + '/' + Base64.encode(`${viewData.Item.yearsValue}`, true))}>
                                                اقرأ الوثيقة
                                            </div>
                                            <div className="shereBtn"
                                                onClick={() => {
                                                    dispatch(setPopupShareData(null));
                                                    setTimeout(() => {
                                                        dispatch(setPopupShareData({
                                                            showPopup: true,
                                                            Item: viewData.Item,
                                                            id: viewData.Item._id,
                                                            type: `${viewData.Item.type}s`,
                                                            loginStatus: () => { }
                                                        }));
                                                    }, 100);
                                                }}
                                            >
                                                <span>مشاركة</span>
                                            </div>
                                            {/* {loginStatus &&
                                                (
                                                    <div className="downloadBtn"
                                                        onClick={() => downloadLogs()}
                                                    >
                                                        <span>تحميل</span>
                                                    </div>
                                                )} */}


                                        </div>
                                    </div>

                                </>
                        }

                    </div>
                    {
                        type === 'journal' && viewData.Item?.titleDawreaa !== undefined ?
                            <div className="journalNumers">
                                <div className="numbersTitle">الأعداد</div>
                                <div className="rowNumbers">
                                    <div className="callNumbers">
                                        <div className="selectedOtions">
                                            <div className="select222">
                                                <select onChange={(e) => {
                                                    dispatch(setYears1Selected(e.target.value))
                                                    dispatch(setYears2Selected(null))
                                                }} value={viewData.years1Selected}>
                                                    {
                                                        viewData.yearsNumbers !== null ?
                                                            viewData.yearsNumbers.map(item =>
                                                                <option value={item._id}>{item._id}</option>
                                                            )
                                                            :
                                                            ''
                                                    }
                                                </select>
                                            </div>
                                            <div className="select222">
                                                <select onChange={(e) => dispatch(setYears2Selected(e.target.value))} value={viewData.years2Selected}>
                                                    {
                                                        viewData.yearsNumbers2 !== null ?
                                                            viewData.yearsNumbers2.map(item =>
                                                                <option value={item._id}>{item._id}</option>
                                                            )
                                                            :
                                                            ''
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="callNumbers2">
                                        <div className="contentList">
                                            {
                                                viewData.yearsNumbers3 !== null ?
                                                    viewData.yearsNumbers3.map(item =>
                                                        <div className="itemOfContent" key={item._id}
                                                            onClick={() => history.push("/view/" + item._id.split('_')[1] + '/' + item._id.split('_')[0])}
                                                        >{item.nameAr}</div>
                                                    )
                                                    :
                                                    ''
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>

                            : ''

                    }

                </div>
                <div className="leftSection">
                    <div className="backtopsearch" onClick={() => history.goBack()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18.599" height="18.578" viewBox="0 0 18.599 18.578">
                            <g id="Group_9232" data-name="Group 9232" transform="translate(423.147 2964.411)">
                                <path id="Path_3" data-name="Path 3" d="M-404.04-2961.462l4.292,4.292-4.292,4.292" transform="translate(-6.3 -0.828)" fill="none" stroke="#147bfc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                                <path id="Path_9617" data-name="Path 9617" d="M-414.821-2945.391h-1.493a5.333,5.333,0,0,1-5.333-5.333h0a5.333,5.333,0,0,1,5.333-5.332h9.12" transform="translate(0 -1.942)" fill="none" stroke="#147bfc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                            </g>
                        </svg>

                        العودة إلى نتائج البحث
                    </div>
                    <div className="titleSect">مصنفات ذات صلة بالموضوع</div>
                    <div className="books">
                        {viewData.related != null ?

                            viewData.related.map((item, index) =>
                                <div className="item" key={item._id} onClick={() => history.push("/view/" + item._id.split('_')[1] + '/' + item._id.split('_')[0])}>
                                    {item.s3objcover != undefined && item.s3objcover != '' ? <img src={item.s3objcover.Location} /> : ''}

                                    <div className="nameBook">{item.nameAr}</div>
                                    {/* <div className="aoutBook">المؤلف: {viewData.authors != null && authors[index] != undefined ? authors[index].nameAr != undefined ? authors[index].nameAr : '' : ''}</div> */}
                                </div>
                            )
                            :
                            ['', '', ''].map((item, index) =>
                                <Skeleton key={index} variant="rect" width={160} height={300} animation="wave" style={{ borderRadius: '10px', marginTop: '15px' }} />
                            )


                        }

                    </div>
                </div>
            </div>
        </div >
    );
}

export default View;
