import React, { useEffect, useState } from 'react';
import Topsection from '../components/sharing/topsection';
import Breadcrumb from '../components/sharing/breadcrumbs';
import { Typography, withStyles, TextField } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Axios from 'axios';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        left: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    changefont: {
        fontFamily: 'ArbFONTS-DroidKufi-bold'

    }
});

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120, fontSize: '10px'
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
}));


function AdvancedSearch() {
    let history = useHistory();
    const classes = useStyles();
    if (localStorage.getItem('access_token') == undefined) {
        history.push(process.env.REACT_APP_FRONT_URL + '');
    }

    const [OpenErrorMsg, setOpenErrorMsg] = useState(false)
    const [TextErrorMsg, setTextErrorMsg] = useState('')
    const [TitleErrorMsg, setTitleErrorMsg] = useState('')

    const [valueEdit, setValueEdit] = React.useState([false, false, false, false, false]);
  

    const [data, setData] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    })



    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    const DialogContent = withStyles((theme) => ({
        root: {
            padding: theme.spacing(2),
        },
    }))(MuiDialogContent);

    const DialogActions = withStyles((theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(1),
        },
    }))(MuiDialogActions);


    const breadCurmbData = [
        {
            title: "تغير كلمة المرور",
            link: '',
        },
    ];

    const changeData = (value, type) => {
        let tempAr = data;
        tempAr[type] = value;
        setData({ ...tempAr });
    }

    const saveBtn = () => {
        if (data.oldPassword == '') {
            setOpenErrorMsg(true)
            setTextErrorMsg('يجب ادخال كلمة المرور القديمة')
            setTitleErrorMsg('خطأ')
        }
        else if (data.newPassword == '') {
            setOpenErrorMsg(true)
            setTextErrorMsg('يجب ادخال كلمة المرور الجديدة')
            setTitleErrorMsg('خطأ')
        }
        else if (data.confirmPassword == '') {
            setOpenErrorMsg(true)
            setTextErrorMsg('يجب ادخال تأكيد كلمة المرور')
            setTitleErrorMsg('خطأ')
        }
        else if (data.newPassword != data.confirmPassword) {
            setOpenErrorMsg(true)
            setTextErrorMsg('كلمة المرور الجديدة غير متطابقة')
            setTitleErrorMsg('خطأ')
        }
        else if(data.newPassword.length < 8){
            setOpenErrorMsg(true)
            setTextErrorMsg('يجب ان تكون كلمة المرور اكثر من 8 احرف')
            setTitleErrorMsg('خطأ')
        }
        else if(data.newPassword.length > 20){
            setOpenErrorMsg(true)
            setTextErrorMsg('يجب ان تكون كلمة المرور اقل من 20 حرف')
            setTitleErrorMsg('خطأ')
        }
        else if(data.newPassword == data.oldPassword){
            setOpenErrorMsg(true)
            setTextErrorMsg('كلمة المرور الجديدة يجب ان تكون مختلفة عن كلمة المرور القديمة')
            setTitleErrorMsg('خطأ')
        } 
        else {
            Axios.post(process.env.REACT_APP_BACKEND_URL + '/auth/changePassword', {
                oldPassword: data.oldPassword,
                newPassword: data.newPassword,
                confirmPassword: data.confirmPassword,
            }, {
                headers: {
                    'Authorization': localStorage.getItem('access_token'),
                }
            }).then((res) => {
                if (res.data.status == 200) {
                    setData({
                        oldPassword: '',
                        newPassword: '',
                        confirmPassword: '',
                    })
                    setOpenErrorMsg(true)
                    setTextErrorMsg('تم تغير كلمة المرور بنجاح')
                    setTitleErrorMsg('تم')
                }
                else {
                    setOpenErrorMsg(true)
                    setTextErrorMsg(res.data.message)
                    setTitleErrorMsg('خطأ')
                }
            })
        }
    }


    return (
        <div>
            <Topsection data={{ text: 'تغير كلمة المرور', img: require('../assets/images/profile.png') }} />
            <Breadcrumb data={breadCurmbData} />
            <div className="profile">
                <Dialog onClose={() => setOpenErrorMsg(false)} aria-labelledby="customized-Dialog-title" open={OpenErrorMsg}>
                    <DialogTitle id="customized-dialog-title" onClose={() => setOpenErrorMsg(false)}
                    >
                        <div className="titlePopupMsg"> {TitleErrorMsg}</div>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            <div className="bodyPopupMsg">{TextErrorMsg}</div>
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button className="ButtonErrorMsg" autoFocus onClick={() => setOpenErrorMsg(false)} color="primary">
                            موافق
                        </Button>
                    </DialogActions>
                </Dialog>


                <div className="profileBody">

                    <div className="infoProfle">
                        <div className="boxInfo">


                            <div className="rowInfo">
                                <div className="rowLabel">
                                    <div className="labelRow">كلمة المرور القديمة </div>
                                    <div className={"labelRow show"}>
                                        <TextField
                                            id="password_old"
                                            placeholder='******'
                                            type="password"
                                            variant="outlined"
                                            value={data.oldPassword}
                                            onChange={(e) => changeData(e.target.value, 'oldPassword')}
                                        />
                                    </div>
                                </div>
                            </div>



                            <div className="rowInfo">
                                <div className="rowLabel">
                                    <div className="labelRow">كلمة المرور الجديدة</div>
                                    <div className={"labelRow show"}>
                                        <TextField
                                            id="password_new"
                                            placeholder='******'
                                            type="password"
                                            variant="outlined"
                                            value={data.newPassword}
                                            onChange={(e) => changeData(e.target.value, 'newPassword')}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="rowInfo">
                                <div className="rowLabel">
                                    <div className="labelRow">إعادة كلمة المرور الجديدة</div>
                                    <div className={"labelRow show"}>   <TextField
                                        id="password_confirm"
                                        placeholder='******'
                              ظ          type="password"
                                        variant="outlined"
                                        value={data.confirmPassword}
                                        onChange={(e) => changeData(e.target.value, 'confirmPassword')}
                                    />
                                    </div>
                                </div>
                            </div>

                            <div className="rowInfo">
                                <Button variant="contained" color="success"
                                    onClick={() => saveBtn()}
                                >
                                    حفظ
                                </Button>
                            </div>

                        </div>


                    </div>
                </div>

            </div>
        </div >
    );
}

export default AdvancedSearch;
