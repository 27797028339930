import React, { useEffect, useRef, useState } from 'react';
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { browseState, setDataZero } from '../store/browse'
import Topsection from '../components/sharing/topsection';
import Breadcrumb from '../components/sharing/breadcrumbs';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Skeleton } from '@material-ui/lab';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Error from '@material-ui/icons/Error';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import InfiniteScroll from "react-infinite-scroll-component";
import { Base64 } from 'js-base64';
import CustomImage from '../components/sharing/CustomImage';
import FlipMove from 'react-flip-move';
import PopupShare from "../components/pdf/popup/popup_share";

import {
    isMobile
} from "react-device-detect";
import { isNull } from 'util';
function Browse() {
    let history = useHistory();
    const dispatch = useDispatch();
    const browserData = useSelector(browseState);
    const getCookie = (name) => {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    const [subjects, setSubjects] = useState(null)
    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth' })
    const breadCurmbData = [
        {
            title: "تصفح",
            link: '',
        },
    ];
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(20, 123, 252, 0.77)',
            boxShadow: theme.shadows[1],
            fontSize: 12,
            fontFamily: 'ArbFONTS-DroidKufi-bold'
        },
    }))(Tooltip);


    const styles = (theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            left: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        changefont: {
            fontFamily: 'ArbFONTS-DroidKufi-bold'

        }
    });

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    const DialogContent = withStyles((theme) => ({
        root: {
            padding: theme.spacing(2),
        },
    }))(MuiDialogContent);

    const DialogActions = withStyles((theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(1),
        },
    }))(MuiDialogActions);



    useEffect(() => {
        var crypto = require('crypto');
        let hash = crypto.createHash('sha256').update(JSON.stringify(formatDate())).digest('hex');
        console.log("hash>>>>", hash)

        let formData = new FormData();
        formData.append('hashTime', hash);
        formData.append('activeTab', browserData.activeTab);
        formData.append('lang', 'ar');
        formData.append('sortby', JSON.stringify(browserData.sortby));
        formData.append('searchKey', browserData.searchKey);
        formData.append('activeTab2', browserData.activeTab2);
        formData.append('collectionName', browserData.activeTab === 1 ? 'books' : browserData.activeTab === 2 ? 'theses' : browserData.activeTab === 3 ? 'journals' : 'researches');
        formData.append('countQuery', browserData.countQuery);
        formData.append('subject', subjects);
        axios.post(process.env.REACT_APP_BACKEND_URL + '/app/getCount', formData,
        ).then(response2 => {
            dispatch(setDataZero({ name: "countItems", data: response2.data.count }))
        })
        axios.post(process.env.REACT_APP_BACKEND_URL + '/app/subjects/get', formData,
        ).then(response2 => {
            if (response2.data.booleanVaule) {
                setSubjects(response2.data.result)
                axios.post(process.env.REACT_APP_BACKEND_URL + '/app/getCountsAll', formData,
                ).then(response3 => {
                    let newSub2 = [];
                    newSub2 = response3.data.resultCount;
                    let newSub = response2.data.result;
                    newSub.forEach(function (obj) {
                        obj.count = '0';
                    });
                    newSub2.map(item =>
                        newSub.map(itemSub => {
                            if (itemSub._id === item._id[0]) {
                                itemSub.count = parseInt(itemSub.count) + item.count;
                            }
                        })
                    )
                    setSubjects([...newSub])
                })
            }
        })
    }, [browserData.activeTab])

    useEffect(() => {
        // setLoadingPage(true)
        var crypto = require('crypto');
        let hash = crypto.createHash('sha256').update(JSON.stringify(formatDate())).digest('hex');
        let formData = new FormData();

        formData.append('hashTime', hash);
        formData.append('activeTab', browserData.activeTab);
        formData.append('lang', 'ar');
        formData.append('sortby', JSON.stringify(browserData.sortby));
        formData.append('searchKey', browserData.searchKey);
        formData.append('activeTab2', browserData.activeTab2);
        formData.append('collectionName', browserData.activeTab === 1 ? 'books' : browserData.activeTab === 2 ? 'theses' : browserData.activeTab === 3 ? 'journals' : 'researches');
        formData.append('countQuery', browserData.countQuery);
        formData.append('subject', JSON.stringify(browserData.checkedValues));
        axios.post(process.env.REACT_APP_BACKEND_URL + '/app/browse/getV2', formData,
        ).then(response2 => {
            if (response2.data.booleanVaule) {
                if (response2.data.result.length === 0 && browserData.countQuery > 1) {
                    dispatch(setDataZero({ name: "finshed", data: true }))
                }
                if (response2.data.result.length === 0 && browserData.countQuery === 0) {
                    dispatch(setDataZero({ name: "items", data: [] }))
                } else {
                    if (browserData.items && browserData.items.length > 0) {
                        if (browserData.items[browserData.items.length - 1]?.type === response2.data.result[0].type)
                            dispatch(setDataZero({ name: "items", data: [...browserData.items, ...response2.data.result] }))
                        else
                            dispatch(setDataZero({ name: "items", data: [...response2.data.result] }))
                    }
                    else
                        dispatch(setDataZero({ name: "items", data: response2.data.result }))
                }

                dispatch(setDataZero({ name: "loadingPage", data: false }))
            }
        })
    }, [browserData.sortby, browserData.activeTab, browserData.countQuery, browserData.checkedValues, browserData.checkedValues2]);



    function formatDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const goSearch = () => {
        history.push(process.env.REACT_APP_FRONT_URL + 'result/' + Base64.encode(`${browserData.searchKey}`, true))
    }


    function setActiveTabMethod(id) {
        dispatch(setDataZero({ name: "activeTab", data: id }));
        localStorage.setItem('ActiveTab', id)
        dispatch(setDataZero({ name: "countQuery", data: 0 }))
        dispatch(setDataZero({ name: "checkedValues", data: [] }))
    }

    const updateOpenTab = (index) => {
        if (index === 'n')
            return subjects;
        let temp1 = subjects;
        temp1[index].openTab = temp1[index].openTab === 'false' ? 'true' : 'false';
        setSubjects([...temp1])
    }

    const filtterBySubSubjects = (e, id, subId) => {
        if (e.target.checked) {
            if (subId === null) {
                let tSub = subjects.filter(item => item._id === id)[0];
                tSub = tSub.subSubjects;
                let tFilnal = [];
                tSub.forEach((item, index) => {
                    tFilnal.push([{ "id": 0, "subject": id, "subSubject": item.id }])
                })
                dispatch(setDataZero({ name: "checkedValues", data: tFilnal }))
            }
            else if (!browserData.checkedValues.includes(id)) {
                dispatch(setDataZero({
                    name: "checkedValues", data: [...browserData.checkedValues,
                    [{ "id": 0, "subject": id, "subSubject": subId }]
                    ]
                }))
            }
        }
        else {
            let temp = browserData.checkedValues;
            if (subId === null) {
                temp = temp.filter(item => item[0].subject !== id);
                dispatch(setDataZero({ name: "checkedValues", data: temp }))

            }
            else {

                temp = temp.filter(item => item[0].subSubject !== subId);
                dispatch(setDataZero({ name: "checkedValues", data: temp }))
            }

        }
        dispatch(setDataZero({ name: "loadingPage", data: true }))
        dispatch(setDataZero({ name: "items", data: null }))
        dispatch(setDataZero({ name: "countQuery", data: 0 }))

    }

    const sortResult = (v) => {

        if (v)
            dispatch(setDataZero({ name: "sortby", data: v }));
        dispatch(setDataZero({ name: "countQuery", data: browserData.countQuery + 1 }))

    }



    const getrowofsubject = () => {
        return (
            subjects.map((item, index) =>
                item.count > 1 ? (
                    <div key={index}>
                        <div className="head">
                            <label className="container" key={item._id}  >
                                <input
                                    type="checkbox"
                                    onChange={(e) => filtterBySubSubjects(e, item._id, null)}
                                />
                                <span className="checkmark"></span>
                                {item.name} ({item.count}0)
                                {/* <samp id="ColorBlue">11</samp> */}
                            </label>

                            <div className="plusMin" style={{ zIndex: 9999999999 }} onClick={() => updateOpenTab(index)}>{item.openTab === 'false' ? '+' : '-'}</div>
                        </div>

                        {/* bodyTabEnd */}
                        <div className="bodyTab" style={{ overflow: 'hidden', borderTop: index + 1 === subjects.length ? item.openTab !== 'false' ? '2px #87A2BE solid' : 'none' : '2px #87A2BE solid' }}>

                            <div className="subBody" style={{ maxHeight: item.openTab !== 'false' ? '150px' : '0', transition: 'all 0.4s', overflowY: 'auto', padding: item.openTab !== 'false' ? '10px 0' : '' }}>

                                {item.subSubjects.map(subitem =>
                                    <div key={subitem.id}>
                                        <div className="rowSubject">
                                            <div className="titleSubject">
                                                <label className="container" key={subitem.id}>
                                                    <input type="checkbox"
                                                        onChange={(e) => filtterBySubSubjects(e, item._id, subitem.id)}
                                                        checked={browserData.checkedValues.filter(item2 => item2[0].subSubject === subitem.id && item2[0].subject === item._id).length > 0 ? true : false}
                                                    />
                                                    <span className="checkmark"></span>
                                                    {subitem?.NameAR}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ) : ''

            )

        )
    }



    return (
        <div ref={myRef}>
            {browserData.popupShareData !== null ? <PopupShare objPopup={browserData.popupShareData} /> : ""}

            <Dialog onClose={() => dispatch(setDataZero({ name: "OpenErrorMsg", data: false }))} aria-labelledby="customized-Dialog-title" open={browserData.OpenErrorMsg}>
                <DialogTitle id="customized-dialog-title" onClose={() => dispatch(setDataZero({ name: "OpenErrorMsg", data: false }))}
                >
                    <div className="titlePopupMsg"><Error className="iconError" />{browserData.TitleErrorMsg}</div>
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        <div className="bodyPopupMsg">{browserData.TextErrorMsg}</div>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button className="ButtonErrorMsg" autoFocus onClick={() => dispatch(setDataZero({ name: "OpenErrorMsg", data: false }))} color="primary">
                        موافق
                    </Button>
                </DialogActions>
            </Dialog>



            <Topsection data={{ text: 'تصفح', img: require('../assets/images/33.png') }} />
            <Breadcrumb data={breadCurmbData} />
            <div className="loadingData" style={{
                visibility: browserData.loadingPage ? 'visible' : 'hidden',
                opacity: browserData.loadingPage ? 1 : 0,
                transition: 'visibility 0s, opacity 0.3s linear'

            }}>
                <img src={require('../assets/images/bookLoading.gif')} alt="loading" />
                <div className="textLoading">جاري التحميل...</div>
            </div>
            <div className="browse">
                <div className="searchAdv">
                    <div className="inputGroup">
                        <div className="select222">
                            <select name="slct" id="slct" value={browserData.activeTab2} onChange={(e) => dispatch(({ name: "activeTab2", data: e.target.value }))}>
                                <option value="1">في العنوان</option>
                                <option value="3">في المؤلف</option>
                                <option value="4">في الموضوع</option>
                                <option value="5">في كامل المحتوى</option>
                            </select>
                        </div>

                        <input type="text"
                            placeholder="ابحث في المحتوى الكتروني العربي"
                            autoFocus
                            value={browserData.searchKey}
                            onChange={(e) => dispatch(setDataZero({ name: "searchKey", data: e.target.value }))}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    goSearch()
                                }
                            }}
                        />
                    </div>
                    <LightTooltip title="ابحث" style={{ cursor: 'pointer' }} onClick={() => goSearch()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="75" height="60" viewBox="0 0 60 75">
                            <g id="Group_9395" data-name="Group 9395" transform="translate(-190 -541)">
                                <circle id="Ellipse_43" data-name="Ellipse 43" cx="37.5" cy="37.5" r="37.5" transform="translate(190 541)" fill="#147bfc" />
                                <g id="Group_9185" data-name="Group 9185" transform="translate(215.171 566.171)">
                                    <circle id="Ellipse_86" data-name="Ellipse 86" cx="10.814" cy="10.814" r="10.814" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                                    <line id="Line_5" data-name="Line 5" x2="5.371" y2="5.371" transform="translate(19.286 19.286)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="4" />
                                </g>
                            </g>
                        </svg>
                    </LightTooltip>
                    <div
                        className='btnSearchinResult'
                        onClick={() =>
                            history.push(process.env.REACT_APP_FRONT_URL + 'advancedsearch')
                        }
                    >
                        بحث متقدم
                    </div>
                </div>
                <div className="tabsTop">
                    {JSON.parse(getCookie("objectUser")) ?
                        <>
                            {JSON.parse(getCookie("objectUser"))?.hiddenContent?.books_all && (<div className={browserData.activeTab === 1 ? "tabtop active1" : "tabtop"} onClick={() => setActiveTabMethod(1)}>كتب</div>)}
                            {JSON.parse(getCookie("objectUser"))?.hiddenContent?.researches_all && (<div className={browserData.activeTab === 2 ? "tabtop active2" : "tabtop"} onClick={() => setActiveTabMethod(2)}>رسائل</div>)}
                            {JSON.parse(getCookie("objectUser"))?.hiddenContent?.journalsMain_all && (<div className={browserData.activeTab === 3 ? "tabtop active3" : "tabtop"} onClick={() => setActiveTabMethod(3)}>مجلات</div>)}
                            {JSON.parse(getCookie("objectUser"))?.hiddenContent?.conferences_all && (<div className={browserData.activeTab === 4 ? "tabtop active4" : "tabtop"} onClick={() => setActiveTabMethod(4)}>أبحاث</div>)}

                        </>

                        :

                        <>
                            <div className={browserData.activeTab === 1 ? "tabtop active1" : "tabtop"} onClick={() => setActiveTabMethod(1)}>كتب</div>
                            <div className={browserData.activeTab === 2 ? "tabtop active2" : "tabtop"} onClick={() => setActiveTabMethod(2)}>رسائل</div>
                            <div className={browserData.activeTab === 3 ? "tabtop active3" : "tabtop"} onClick={() => setActiveTabMethod(3)}>مجلات</div>
                            <div className={browserData.activeTab === 4 ? "tabtop active4" : "tabtop"} onClick={() => setActiveTabMethod(4)}>أبحاث</div>
                        </>


                    }


                </div>
                <div className="reslutlist">
                    <div className="topSec">
                        <div className="titleSec">المواضيع</div>

                        <div className="numberofitem">
                            <div className="itemsCount">
                            </div>
                        </div>


                        <div className="orderResult">
                            {!isMobile ?
                                <>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19.687" height="14.872" viewBox="0 0 19.687 14.872" style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            dispatch(setDataZero({ name: "items", data: null }));
                                            dispatch(setDataZero({ name: "sortby", data: { id: browserData.sortby.id === -1 ? 1 : -1, value: browserData.sortby.value } }));
                                        }}
                                    >
                                        <g id="Group_9212" data-name="Group 9212" transform="translate(1.414 1)">
                                            <g id="arrow-right" transform="translate(8.933 0)">
                                                <line id="Line_3" data-name="Line 3" y1="12.575" transform="translate(3.963 0.297)" fill="none" stroke={browserData.sortby.id === 1 ? "#33384A" : "#87a2be"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                <path id="Path_3" data-name="Path 3" d="M251.991,489.261l-3.963-3.963-3.963,3.963" transform="translate(-244.066 -485.298)" fill="none" stroke={browserData.sortby.id === 1 ? "#33384A" : "#87a2be"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                            </g>
                                            <g id="arrow-right-2" data-name="arrow-right" transform="translate(0)">
                                                <line id="Line_3-2" data-name="Line 3" y1="12.575" transform="translate(3.963)" fill="none" stroke={browserData.sortby.id === -1 ? "#33384A" : "#87a2be"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                <path id="Path_3-2" data-name="Path 3" d="M231.881,497.45l3.963,3.963,3.963-3.963" transform="translate(-231.881 -488.541)" fill="none" stroke={browserData.sortby.id === -1 ? "#33384A" : "#87a2be"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                            </g>
                                        </g>
                                    </svg>
                                    ترتيب حسب:</>

                                :
                                ''
                            }



                            <select className='custom windows' value={browserData.sortby.value} onChange={(e) => {
                                dispatch(setDataZero({ name: "countQuery", data: 0 }));
                                dispatch(setDataZero({ name: "items", data: null }))
                                dispatch(setDataZero({ name: "sortby", data: { id: 1, value: e.target.value } }));
                            }}>
                                <option value="none">اختر</option>
                                <option value="yearsValue">تاريخ النشر</option>
                                <option value="nameAr">العنوان</option>
                                <option value="publisherValue">الناشر</option>
                                {/* <option value="subjectsValue">الموضوع</option> */}
                            </select>

                        </div>


                    </div>



                    <div className="bodyResult">
                        <div className="typesReslt">
                            <div className="tab">
                                {subjects !== null ?
                                    getrowofsubject()
                                    : ''}
                            </div>





                        </div>


                        <div className="results">

                            {
                                browserData.items === null ?
                                    ['', '', ''].map((item, index) =>
                                        <div className="itemResult" key={index}>
                                            <div className='imgandRead'>

                                                <Skeleton variant="rect" width={210} height={300} animation="wave" style={{ borderRadius: '10px' }} />


                                            </div>
                                            <div className="centerSection">
                                                <div className="rowInSec titleCountent boldFont">
                                                    <Skeleton variant="text" width={280} height={60} style={{ borderRadius: '5px' }} />
                                                </div>



                                                <div className="rowInSec w-60">
                                                    <Skeleton variant="text" width={210} height={40} style={{ borderRadius: '5px' }} />
                                                </div>


                                                <div className="rowInSec w-60">
                                                    <Skeleton variant="text" width={210} height={40} style={{ borderRadius: '5px' }} />
                                                </div>



                                                <div className="rowInSec  w-60">
                                                    <div>
                                                        <Skeleton variant="text" width={250} height={40} style={{ borderRadius: '5px' }} />
                                                    </div>
                                                </div>

                                                <div className="rowInSec  w-60">
                                                    <Skeleton variant="text" width={260} height={40} style={{ borderRadius: '5px' }} />

                                                </div>

                                                <div className="rowInSec">
                                                    <div className="tags">
                                                        <Skeleton variant="text" width={80} height={50} style={{ borderRadius: '5px' }} />
                                                        <Skeleton variant="text" width={80} height={50} style={{ borderRadius: '5px', margin: '0 1vw' }} />
                                                        <Skeleton variant="text" width={80} height={50} style={{ borderRadius: '5px', margin: '0 1vw' }} />
                                                        <Skeleton variant="text" width={80} height={50} style={{ borderRadius: '5px', margin: '0 1vw' }} />
                                                        <Skeleton variant="text" width={80} height={50} style={{ borderRadius: '5px', margin: '0 1vw' }} />
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="leftSec">
                                                <Skeleton variant="text" width={80} height={60} style={{ borderRadius: '5px' }} />
                                                <Skeleton variant="circle" width={35} height={35} style={{ marginBottom: '10px' }} />
                                                <Skeleton variant="circle" width={35} height={35} style={{ marginBottom: '10px' }} />
                                                <Skeleton variant="circle" width={35} height={35} style={{ marginBottom: '10px' }} />

                                            </div>
                                        </div>
                                    )
                                    :
                                    <InfiniteScroll
                                        dataLength={browserData.items.length}
                                        next={browserData.countItems !== browserData.items.length || browserData.countItems > 10 ? sortResult : ''}
                                        hasMore={true}
                                        loader={browserData.finshed || (browserData.countItems === browserData.items.length || browserData.countItems < 10) ? <h4 style={{ textAlign: 'center' }}>نهاية النتائج</h4> : <h4>جاري التحميل... </h4>}
                                    >

                                        <FlipMove typeName={null}>
                                            {
                                                browserData.items.map((item, index) =>
                                                    <div className="itemResult" key={index}>
                                                        <div className='imgandRead'>
                                                            {item !== null ?
                                                                item.s3objcover !== undefined && item.s3objcover !== '' ?
                                                                    <CustomImage
                                                                        src={item !== null ?
                                                                            item.s3objcover !== undefined ?
                                                                                item.s3objcover : '' : ''}
                                                                        src2={item.s3objcover !== undefined ?
                                                                            item.s3objcover : ''}
                                                                        effect="opacity"
                                                                        onClick={() => history.push("/view/" + item._id.split('_')[1] + '/' + item._id.split('_')[0])}
                                                                        style={{ cursor: 'pointer' }}
                                                                    />
                                                                    : <LazyLoadImage
                                                                        src={'https://apis.thelearnbook.com/public/learnBookCover.jpg'}
                                                                        key={index}
                                                                        effect="opacity"
                                                                        onClick={() => history.push("/view/" + item._id.split('_')[1] + '/' + item._id.split('_')[0])}
                                                                        style={{ cursor: 'pointer' }}
                                                                    />

                                                                :
                                                                ''
                                                            }

                                                            <div className="btnRead" onClick={() => history.push('/ViewBook/' + item._id.split('_')[1] + '/' + item._id.split('_')[0] + '/' + Base64.encode(`${item.yearsValue}`, true))}>اقرأ الوثيقة</div>
                                                        </div>
                                                        <div className="centerSection">
                                                            <div className="rowInSec titleCountent boldFont"
                                                                onClick={() => history.push("/view/" + item._id.split('_')[1] + '/' + item._id.split('_')[0])}
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                {item.nameAr}
                                                            </div>

                                                            {item.authorValueNew !== '' ?
                                                                <div className="rowInSec w-60" key={index}>
                                                                    <div>
                                                                        مؤلف:&nbsp;
                                                                        {item.authorValueNew !== null && item.authorValueNew !== undefined ? item.authorValueNew.map((i, index) =>
                                                                            item.authorValueNew.length - 1 !== index ?
                                                                                <><a onClick={() => history.push("/browseBy/" + i.name._id + "/" + 'authorValue' + "/" + (i.name !== null ? i.name.nameAr : ''))}>{i.name !== null ? i.name.nameAr : ''}</a><b>،</b> </>
                                                                                :
                                                                                <><a onClick={() => history.push("/browseBy/" + i.name._id + "/" + 'authorValue' + "/" + (i.name !== null ? i.name.nameAr : ''))}>{i.name !== null ? i.name.nameAr : ''}</a> </>
                                                                        ) : ''}
                                                                    </div>
                                                                </div>
                                                                : ''}

                                                            {item.publishersOBJ !== undefined || item.publishersOBJ.length !== 0 ?

                                                                <div className="rowInSec w-60">
                                                                    <div>الناشر:&nbsp;
                                                                        {item.publishersOBJ.map((item333, index) =>
                                                                            <a onClick={() => history.push("/browseBy/" + item333._id + "/" + 'publisherValue' + "/" + item333.nameAr)}>{item.publishersOBJ.length !== 0 ? item333.nameAr : ''}

                                                                                {(index + 1) < item.publishersOBJ.length ? '، ' : ' '}
                                                                            </a>


                                                                        )}

                                                                    </div>
                                                                </div>
                                                                : ''
                                                            }


                                                            {item.yearsValue !== 'null' && item.yearsValue !== '' ?
                                                                <div className="rowInSec w-60">
                                                                    <div>تاريخ النشر:&nbsp;<a onClick={() => history.push("/browseBy/" + item.yearsValue + "/" + 'years' + "/" + item.yearsValue)}> {item.yearsValue} </a></div>
                                                                </div>
                                                                : ''
                                                            }


                                                            {item.degree !== 'null' && item.degree !== null && item.degree !== '' && item.degree !== undefined ?
                                                                <div className="rowInSec w-60">
                                                                    <div>نوع البرنامج:&nbsp;<a onClick={() => history.push("/browseBy/" + item.degree + "/" + 'degree' + "/" + item.degree)}> '{item.degree}' </a></div>
                                                                </div>
                                                                : ''
                                                            }

                                                            {item.hhh !== undefined ?
                                                                item.hhh.length > 0 ? <div className="rowInSec  w-60">
                                                                    <div>المواضيع الرئيسية:&nbsp;
                                                                        {item.hhh.map((i, index) =>

                                                                            item.hhh.length - 1 !== index ?
                                                                                <span key={index}>
                                                                                    <a onClick={() => history.push("/browseBy/" + i.subject._id + "/" + 'subjectsValue' + "/" + i.subject.nameAr)}>{i.subject.nameAr} | {i.subSubject[0]?.NameAR}</a> ,
                                                                                </span>
                                                                                :
                                                                                <span key={index}>
                                                                                    <a onClick={() => history.push("/browseBy/" + i.subject._id + "/" + 'subjectsValue' + "/" + i.subject.nameAr)}>{i.subject.nameAr} | {i.subSubject[0]?.NameAR}</a>
                                                                                </span>
                                                                        )}
                                                                    </div>
                                                                </div> : '' : ''}

                                                            {item.titleDawreaa?.nameAr && <div className="rowInSec  w-60">
                                                                {
                                                                    item.titleDawreaa !== '' && item.titleDawreaa !== 'null' ?
                                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>{browserData.activeTab === 3 ? 'اسم المجله' : ''}: &nbsp;<div style={{ color: '#147BFC' }}>  {item.titleDawreaa?.nameAr}  </div></div>
                                                                        : ''
                                                                }
                                                            </div>}
                                                            {item.conferencesObj?.nameAr && <div className="rowInSec  w-40">
                                                                {
                                                                    item.conferencesObj !== '' && item.conferencesObj !== 'null' ?
                                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>{browserData.activeTab === 4 ? 'اسم المؤتمر' : ''}: &nbsp;<div style={{ color: '#147BFC' }}>  {item.conferencesObj?.nameAr}  </div></div>
                                                                        : ''
                                                                }
                                                            </div>
                                                            }
                                                            <div className="rowInSec  w-60">
                                                                {
                                                                    item.ISBNValue !== '' && item.ISBNValue !== 'null' ?
                                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>{browserData.activeTab === 3 ? 'ISSN' : 'ISBN'}: &nbsp;<div>  {item.ISBNValue}  </div></div>
                                                                        : ''
                                                                }
                                                            </div>

                                                            {item.tagsValue.length > 0 ?
                                                                <div className="rowInSec">
                                                                    <div className="tags">كلمات مفتاحية:
                                                                        {item.tagsValue.map((i, index) => <div key={i._id} className="tag" onClick={() => history.push("/browseBy/" + i._id + "/" + 'tags' + "/" + i.nameAr)}>{i.nameAr}</div>)}
                                                                    </div>

                                                                </div>
                                                                : ''
                                                            }


                                                        </div>
                                                        <div className="leftSec">
                                                            {item.type === 'book' ?
                                                                <div className="btnBook">كتاب</div>
                                                                :
                                                                item.type === 'msg' ?
                                                                    <div className="btnMag">رسالة</div>
                                                                    :
                                                                    item.type === 'journals' ?
                                                                        <div className="btnma">مقال مجلة</div>
                                                                        :
                                                                        <div className="btnma2">مقال مؤتمر </div>

                                                            }

                                                            <LightTooltip title="معلومات الوثيقة">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" onClick={() => history.push("/view/" + item._id.split('_')[1] + '/' + item._id.split('_')[0])}>
                                                                    <g id="Group_8833" data-name="Group 8833" transform="translate(1 1.273)">
                                                                        <g id="Group_111" data-name="Group 111">
                                                                            <circle id="Ellipse_28" data-name="Ellipse 28" cx="13.5" cy="13.5" r="13.5" transform="translate(0 -0.273)" fill="none" stroke="#727c89" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                                        </g>
                                                                        <g id="Group_112" data-name="Group 112" transform="translate(10.216 6.652)">
                                                                            <ellipse id="Ellipse_29" data-name="Ellipse 29" cx="1.65" cy="1.553" rx="1.65" ry="1.553" transform="translate(2.665)" fill="#727c89" />
                                                                            <path id="Path_3385" data-name="Path 3385" d="M992.8,717.719c-.013.283.295,1.18,1.892.686a.115.115,0,0,1,.145.142l-.093.34a.38.38,0,0,1-.229.256,8.211,8.211,0,0,1-2.623.578,1.757,1.757,0,0,1-1.942-1.551c-.006-.055-.01-.11-.011-.165.2-1.776,1.108-4.757,1.119-5.313.006-.283-.294-1.179-1.891-.686a.115.115,0,0,1-.144-.078.11.11,0,0,1,0-.065l.093-.339a.38.38,0,0,1,.229-.256,8.207,8.207,0,0,1,2.623-.578,1.763,1.763,0,0,1,1.943,1.561c.005.051.009.1.01.155C993.922,714.138,992.857,716.538,992.8,717.719Z" transform="translate(-989.021 -706.092)" fill="#727c89" />
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </LightTooltip>








                                                            <LightTooltip title="مشاركة">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="28.701" height="28.664" viewBox="0 0 28.701 28.664"
                                                                    onClick={() => {
                                                                        dispatch(setDataZero({ name: "popupShareData", data: null }));
                                                                        setTimeout(() => {
                                                                            dispatch(setDataZero({
                                                                                name: "popupShareData", data: {
                                                                                    showPopup: true,
                                                                                    Item: item,
                                                                                    id: item._id,
                                                                                    type: `${item.type}s`,
                                                                                    loginStatus: () => { }
                                                                                }
                                                                            }));
                                                                        }, 100);
                                                                    }}
                                                                >
                                                                    <g id="Group_8832" data-name="Group 8832" transform="translate(1 1.026)">
                                                                        <path id="Path_9495" data-name="Path 9495" d="M937.98,693.32l-7.484-7.772a.863.863,0,0,0-1.485.6v3.742h-.288A11.239,11.239,0,0,0,917.5,701.115v1.727a.851.851,0,0,0,.672.825.832.832,0,0,0,.19.023.891.891,0,0,0,.789-.49,9.445,9.445,0,0,1,8.495-5.251h1.368v3.742a.863.863,0,0,0,1.485.6l7.484-7.772A.863.863,0,0,0,937.98,693.32Z" transform="translate(-911.622 -685.283)" fill="none" stroke="#727c89" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                                        <path id="Path_9496" data-name="Path 9496" d="M910.642,696.657H907.96a2.3,2.3,0,0,0-2.3,2.3v16.39a2.3,2.3,0,0,0,2.3,2.3h21a2.3,2.3,0,0,0,2.3-2.3v-7.2" transform="translate(-905.659 -691.012)" fill="none" stroke="#727c89" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                                    </g>
                                                                </svg>
                                                            </LightTooltip>
                                                            <LightTooltip title='تصفحها في نافذه جديده'>
                                                                <svg
                                                                    onClick={() =>
                                                                        window.open(
                                                                            '/view/' +
                                                                            item._id.split('_')[1] +
                                                                            '/' +
                                                                            item._id.split('_')[0], "_blank"
                                                                        )
                                                                    }
                                                                    width='60.701'
                                                                    height='60.664'
                                                                    viewBox='0 0 28.701 28.664'
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M12 13C12.5523 13 13 12.5523 13 12V3C13 2.44771 12.5523 2 12 2H3C2.44771 2 2 2.44771 2 3V6.5C2 6.77614 2.22386 7 2.5 7C2.77614 7 3 6.77614 3 6.5V3H12V12H8.5C8.22386 12 8 12.2239 8 12.5C8 12.7761 8.22386 13 8.5 13H12ZM9 6.5C9 6.5001 9 6.50021 9 6.50031V6.50035V9.5C9 9.77614 8.77614 10 8.5 10C8.22386 10 8 9.77614 8 9.5V7.70711L2.85355 12.8536C2.65829 13.0488 2.34171 13.0488 2.14645 12.8536C1.95118 12.6583 1.95118 12.3417 2.14645 12.1464L7.29289 7H5.5C5.22386 7 5 6.77614 5 6.5C5 6.22386 5.22386 6 5.5 6H8.5C8.56779 6 8.63244 6.01349 8.69139 6.03794C8.74949 6.06198 8.80398 6.09744 8.85143 6.14433C8.94251 6.23434 8.9992 6.35909 8.99999 6.49708L8.99999 6.49738" fill="currentColor" />


                                                                </svg>

                                                            </LightTooltip>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </FlipMove>
                                    </InfiniteScroll>


                            }









                        </div>
                    </div>
                </div>


            </div>
        </div >
    );
}

export default Browse;
