import React from 'react';


function Loading(props) {


    return (
        <div className="loadingDataHome" style={{
            visibility: props.loadingValue ? 'visible' : 'hidden',
            opacity: props.loadingValue ? 1 : 0,
            transition: 'visibility 0s, opacity 0.3s linear'

        }}>
            <img src={require('../../assets/images/bookLoading.gif')} alt="loading" />
            <div className="textLoading">جاري التحميل...</div>
        </div>
    );
}
export default Loading;