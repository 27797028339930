import { createSlice } from "@reduxjs/toolkit";



const initialState = {
    items: null,
    countries: null,
    publishers: null,
    subjects: null,
    loadingPage: false,
    checkedAll: true,
    OpenErrorMsg:false,
    loginStatus:false,
    searchKey2: '',
    nameConferences:'',
    coverConferences:'',
    timeText:'',
    countItems: [],
    checkedValues: [],
    checkedValues2: [],
    checkedValues3: [],
    checkedValues4: [],
    checkedValues5: [],
    filterCheck2: [],
    filterCheck3: [],
    filterCheck4: [],
    searchTypes: [true, false, false, false, false],
    searchTypesFilter: [true, false, false, false, false],

};

export const searchSlice = createSlice({
    name: 'items',
    initialState,
    reducers: {
        getResultFromBack: (state, action) => {
            const results = action.payload;
            state.items = results
        },
        getResultNew: (state, action) => {
            const newResult = action.payload;
            state.items = [...state.items, ...newResult]
        },
        getResultNull: (state, action) => {
            const results = action.payload;
            state.items = [...results]
        },
        setResultCounts: (state, action) => {
            const newCount = action.payload;
            state.countItems = newCount
        },
        setResultsSubject: (state, action) => {
            state.subjects = action.payload
        },
        setResultPublishers: (state, action) => {
            state.publishers = action.payload
        },
        setResultsCounteries: (state, action) => {
            state.countries = action.payload
        },
        setLoadingPageValue: (state, action) => {
            state.loadingPage = action.payload
        },
        setCheckedValuesRedux: (state, action) => {
            state.checkedValues = action.payload
        },
        setCheckedValues2Redux: (state, action) => {
            state.checkedValues2 = action.payload
        },
        setCheckedValues3Redux: (state, action) => {
            state.checkedValues3 = action.payload
        },
        setCheckedValues4Redux: (state, action) => {
            state.checkedValues4 = action.payload
        },
        setCheckedValues5Redux: (state, action) => {
            state.checkedValues5 = action.payload
        },
        setSearchTypesRedux: (state, action) => {
            state.searchTypes = action.payload;
        },
        setSearchTypesFilterRedux: (state, action) => {
            state.searchTypesFilter = action.payload
        },

        setFilterCheck2Redux: (state, action) => {
            state.filterCheck2 = action.payload
        },
        setFilterCheck3Redux: (state, action) => {
            state.filterCheck3 = action.payload
        },
        setFilterCheck4Redux: (state, action) => {
            state.filterCheck4 = action.payload
        },
        setSearchKey2Redux: (state, action) => {
            state.searchKey2 = action.payload
        },
        setOpenErrorMsgRedux: (state, action) =>{
            state.OpenErrorMsg = action.payload
        },
        setLoginStatusRedux: (state, action) =>{
            state.loginStatus = action.payload
        },
        setNameConferencesRedux:(state,action)=>{
            state.nameConferences = action.payload
        },
        setConverConferencesRedux: (state, action)=>{
            state.coverConferences = action.payload
        },
        setTimeTextRedux: (state, action) =>{
            state.timeText = action.payload
        }
    }
})

export const itemsRedux = (state) => state.search

export const {
    setCheckedValuesRedux,
    setCheckedValues2Redux,
    setCheckedValues3Redux,
    setCheckedValues4Redux,
    setCheckedValues5Redux,
    setLoadingPageValue,
    getResultFromBack,
    getResultNull,
    getResultNew,
    setResultCounts,
    setResultsSubject,
    setResultPublishers,
    setResultsCounteries,
    setSearchTypesRedux,
    setSearchTypesFilterRedux,
    setFilterCheck2Redux,
    setFilterCheck3Redux,
    setFilterCheck4Redux,
    setSearchKey2Redux,
    setOpenErrorMsgRedux,
    setLoginStatusRedux,
    setNameConferencesRedux,
    setConverConferencesRedux,
    setTimeTextRedux
} = searchSlice.actions;

export default searchSlice.reducer;