export default {
    footer: {
        copyright: '© جميع الحقوق محفوظة. بوابة الكتاب العلمي ',
        mediaplus: 'تصميم وتطوير '
    },
    header: {
        login: 'تسجيل الدخول',
        signup: 'التسجيل'
    },
    countact: {
        address: 'العنوان',
        mailBox: 'صندوق البريد',
        phone: 'هاتف',
        email: 'البريد الإلكتروني',
    },
    breadcrumbs: {
        home: 'الرئيسية'
    }
};