import React, { useEffect, useState } from 'react'
import CountryList from '../../country.json'
import ReCAPTCHA from 'react-google-recaptcha'
import PhoneInput from 'react-phone-number-input'
import Stepwizard from './Stepwizard'
import axios from 'axios'

export default function StepOne (props) {
  React.useEffect(() => {}, [])

  const [formObj, setFormObj] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    work: ''
  })
  const getCookie = name => {
    var nameEQ = name + '='
    var ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  }
  useEffect(()=>{
    if(getCookie('emailGoogle')!==""){
      setFormObj({
        ...formObj,
        email:getCookie('emailGoogle'),
        name:getCookie('Name')
      })
    }

  },[])

  const [captcha, setCaptcha] = useState(false)
  const [country, setCountry] = useState('الأردن')
  const [success, setSuccess] = useState({
    status: false,
    msg: ''
  })
  function onChange (value) {
    if (value) {
      setCaptcha(true)
    }
  }
  const validateEmail = email => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  const emailCheck = async () => {
    const res = await axios.post(
      process.env.REACT_APP_BACKEND_URL + '/app/users/emailCheck',
      {
        email: formObj.email
      }
    )
    if (res.data.available) return true
    else return false
  }
  const sendData = async () => {
    let e = true
    if (formObj.name.length < 2) {
      setSuccess({
        status: false,
        msg: 'الاسم قصير جدا'
      })
      e = false
      return
    }
    if (!validateEmail(formObj.email)) {
      setSuccess({
        status: false,
        msg: 'البريد الالكتروني غير صحيح'
      })
      e = false
      return
    }

    let emailavailable = await emailCheck()
    if (!emailavailable) {
      setSuccess({
        status: false,
        msg: 'البريد الالكتروني مستخدم من قبل'
      })
      e = false
      return
    }
    if(getCookie('emailGoogle')===""){
    if (formObj.password.length < 6) {
      setSuccess({
        status: false,
        msg: 'كلمة المرور قصيرة جدا'
      })
      e = false
      return
    }
  
    if (formObj.password !== formObj.repassword) {
      setSuccess({
        status: false,
        msg: 'كلمة المرور غير متطابقة'
      })
      e = false
      return
    }
  }
    if (formObj.phone.length < 10) {
      setSuccess({
        status: false,
        msg: 'رقم الهاتف غير صحيح'
      })
      e = false
      return
    }

    if (!captcha) {
      setSuccess({
        status: false,
        msg: 'الرجاء التحقق من صحة البيانات'
      })
      e = false
      return
    }

    if (e) {
      formObj.country = country
      delete formObj.repassword
      localStorage.setItem('register', JSON.stringify(formObj))

      props.setStep(4)
      setFormObj({
        name: '',
        email: '',
        phone: '',
        password: '',
        work: ''
      })
    }
  }
  return (
    <div className='stepOne'>
      <div className='containerReg1'>
        <div className='regsteps'>
          <Stepwizard step={3} />
        </div>
        <div className='sectionTitle'>إنشاء حساب في بوابة الكتاب العلمي</div>
        <div className='formcontact'>
          <div className='container'>
            <form id='contact'>
              {success.msg !== '' && (
                <fieldset>
                  {success.status === false && (
                    <div class='xd-message msg-danger'>
                      <div class='xd-message-icon'></div>
                      <div class='xd-message-content'>
                        <p>{success.msg}</p>
                      </div>
                    </div>
                  )}
                  {success.status === true && (
                    <div class='xd-message msg-success'>
                      <div class='xd-message-icon'> </div>
                      <div class='xd-message-content'>
                        <p>{success.msg}</p>
                      </div>
                    </div>
                  )}
                </fieldset>
              )}

              <fieldset>
                <label>الاسم</label>
                <input
                  value={formObj.name}
                  onChange={e =>
                    setFormObj({
                      ...formObj,
                      name: e.target.value
                    })
                  }
                  placeholder='الاسم'
                  type='text'
                  tabindex='1'
                  required
                  autofocus
                />
              </fieldset>

              <fieldset>
                <label>البلد</label>
                <select
                  value={country}
                  onChange={e => setCountry(e.target.value)}
                >
                  {Object.values(CountryList).map((keyName, i) => (
                    <option value={keyName}>{keyName}</option>
                  ))}
                </select>
              </fieldset>

              <fieldset>
                <label>مكان العمل</label>
                <input
                  value={formObj?.work}
                  onChange={e =>
                    setFormObj({
                      ...formObj,
                      work: e.target.value
                    })
                  }
                  placeholder='مكان العمل'
                  type='text'
                  tabindex='1'
                  required
                />
              </fieldset>

              <fieldset>
                <label>البريد الإلكتروني</label>
                <input
                  value={formObj.email}
                  onChange={e =>
                    setFormObj({
                      ...formObj,
                      email: e.target.value
                    })
                  }
                  placeholder='البريد الإلكتروني'
                  type='email'
                  tabindex='2'
                  required
                />
              </fieldset>

              <fieldset>
                <label>رقم الهاتف</label>
                <PhoneInput
                  international
                  initialValueFormat='national'
                  countryCallingCodeEditable={false}
                  defaultCountry='JO'
                  value={formObj.phone}
                  tabindex='3'
                  onChange={phone =>
                    setFormObj({
                      ...formObj,
                      phone: phone
                    })
                  }
                />
              </fieldset>
              {getCookie('emailGoogle')!==""?"":<div>
              <fieldset>
                <label>كلمة المرور</label>
                <input
                  value={formObj?.password}
                  onChange={e =>
                    setFormObj({
                      ...formObj,
                      password: e.target.value
                    })
                  }
                  placeholder='كلمة المرور'
                  type='password'
                  tabindex='3'
                />
              </fieldset>
              <fieldset>
                <label>تأكيد كلمة المرور </label>
                <input
                  value={formObj?.repassword}
                  onChange={e =>
                    setFormObj({
                      ...formObj,
                      repassword: e.target.value
                    })
                  }
                  placeholder='تأكيد كلمة المرور'
                  type='password'
                  tabindex='3'
                />
              </fieldset>
              </div>}
              <fieldset>
                <ReCAPTCHA
                  sitekey='6Ldu6BkfAAAAAAcWW9lR0IwFpWfALfHRlmIuuhJG'
                  onChange={onChange}
                />
              </fieldset>
              <fieldset>
                <button name='button' type='button' onClick={sendData}>
                  المتابعة
                </button>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
