import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core'
import { Button } from '@react-pdf-viewer/core'
import Error from '@material-ui/icons/Error'
import { Base64 } from 'js-base64'
export default function Search(props) {
  let history = useHistory()
  const [activeTab, setActiveTab] = useState(1)
  const [searchKey, setSearchKey] = useState('')
  const [OpenErrorMsg, setOpenErrorMsg] = useState(false)
  const [TextErrorMsg, setTextErrorMsg] = useState('')
  const [TitleErrorMsg, setTitleErrorMsg] = useState('')
  const [loginStatus, setLoginStatus] = useState(false)

  const LightTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(20, 123, 252, 0.77)',
      boxShadow: theme.shadows[1],
      fontSize: 12,
      fontFamily: 'ArbFONTS-DroidKufi-bold'
    }
  }))(Tooltip)

  React.useEffect(() => {
    let access_token = localStorage.getItem('access_token')
    if (
      access_token !== undefined &&
      access_token !== '' &&
      access_token !== null
    ) {
      setLoginStatus(true)
    } else {
      setLoginStatus(false)
    }
  }, [])

  const searchByKey = () => {
    if (searchKey.length === 0) {
      history.push(process.env.REACT_APP_FRONT_URL + 'resultAll')
      return
    } else if (searchKey.length >= 3) {
      localStorage.setItem('ActiveTab2', activeTab)
      let search = searchKey
      if (Base64.encode(search).search('/') == -1)
        search = Base64.encode(`${JSON.stringify(search)}`, true)
      else search = Base64.encode(`${search}`, true)
      history.push(process.env.REACT_APP_FRONT_URL + 'result/' + search)
    } else {
      history.push(process.env.REACT_APP_FRONT_URL + 'resultAll')
    }
  }

  React.useEffect(() => { }, [])

  return (
    <div className='search'>
      <Dialog
        onClose={() => setOpenErrorMsg(false)}
        aria-labelledby='customized-Dialog-title'
        open={OpenErrorMsg}
      >
        <DialogTitle
          id='customized-dialog-title'
          onClose={() => setOpenErrorMsg(false)}
        >
          <div className='titlePopupMsg'>
            <Error className='iconError' />
            {TitleErrorMsg}
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <div className='bodyPopupMsg'>{TextErrorMsg}</div>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            className='ButtonErrorMsg'
            autoFocus
            onClick={() => setOpenErrorMsg(false)}
            color='primary'
          >
            موافق
          </Button>
        </DialogActions>
      </Dialog>

      <div className='searchAdv2'>
        <div className='inputGroup'>
          <div className='select222'>
            <select
              name='slct'
              id='slct'
              value={activeTab}
              onChange={e => setActiveTab(e.target.value)}
            >
              <option value='1'>في العنوان</option>
              {loginStatus && <option value='5'>في كامل المحتوى</option>}
              <option value='3'>في المؤلف</option>
              <option value='4'>في الموضوع</option>
              <option value='6'>في عنوان المجله</option>
              <option value='7'>في عنوان مؤتمر</option>
            </select>
          </div>

          <input
            type='text'
            placeholder='ابحث في المحتوى الكتروني العربي'
            autoFocus
            value={searchKey}
            onChange={e =>
              e.key === 'Enter' ? '' : setSearchKey(e.target.value)
            }
            onKeyPress={e => {
              if (e.key === 'Enter') {
                searchByKey()
              }
            }}
          />
        </div>
        <LightTooltip
          title='ابحث'
          style={{ cursor: 'pointer', marginRight: '1vw' }}
          onClick={() => searchByKey()}
        >
          <div className='btnSearchBTN'></div>
        </LightTooltip>
      </div>

      <div
        className='btnSearch'
        onClick={() =>
          history.push(process.env.REACT_APP_FRONT_URL + 'advancedsearch')
        }
      >
        بحث متقدم
      </div>
    </div>
  )
}
