import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    activeTab: 1,
    activeTab2: parseInt(localStorage.getItem('ActiveTab2') !== undefined ? localStorage.getItem('ActiveTab2') : 1),
    countQuery: 0,
    countItems: 0,
    items: null,
    checkedValues: [],
    checkedValues2: [],
    loadingPage: true,
    finshed: false,
    OpenErrorMsg: false,
    popupShareData: null,
    sortby: { id: -1, value: 'yearsValue' },
    searchKey: "",
    TextErrorMsg: "",
    TitleErrorMsg: ""
};


const browseSlice = createSlice({
    name: "browse",
    initialState,
    reducers: {
        setDataZero: (state, actions) => {
            const { name, data } = actions.payload;
            state[name] = data;
        }
    }
})


export const browseState = (state) => state.browse
export const { setDataZero, setSubjects } = browseSlice.actions
export default browseSlice.reducer