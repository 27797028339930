import React, { useState ,useEffect} from 'react';
import Topsection from '../components/sharing/topsection';
import Breadcrumb from '../components/sharing/breadcrumbs';
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
function AdvancedSearch() {

    const [loadingPage, setLoadingPage] = useState(true);
    const [formObj, setFormObj] = useState({
        password: "",
        email: "",
        phone: "",
        msg: ""
    });
    const [data, setData] = useState("");
    const [captcha, setCaptcha] = useState(false);
    const [userId, setUserId] = useState('');
    const [change, setChange] = useState(false);
    const [success, setSuccess] = useState({
        status: false,
        msg: ""
    });

    const token = new URL(window.location.href).searchParams.get("token");

 

    useEffect(() => {
        
        const token = new URL(window.location.href).searchParams.get("token");
        let isMounted = true;

        let formData = new FormData();
        formData.append('code', token);
        axios.post(process.env.REACT_APP_BACKEND_URL + '/auth/forgetpassword/get', formData,
        ).then(response2 => {
            if (response2.data.open == 1) {
                setChange(true);
                setLoadingPage(false);
                // setUserId('');
                setUserId(response2.data.user_id);
            }
            else {
                setChange(false);
                setLoadingPage(false);
            }
        });
        
        return () => {
            isMounted = false;
          };
    }, []);
  
  
 
    function onChange(value) {
        if (value) {
            setCaptcha(true);
        }
    }

    const breadCurmbData = [
        {
            title: "تغيير كلمة المرور",
            link: '',
        },
    ];
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const changePassword = () => {
        let e = true;

        if (formObj.password.length < 6) {
            setSuccess({
                status: false,
                msg: "كلمة المرور يجب ان تكون أكثر من 6 حروف"
            })
            e = false;
            return;
        }

        if (e) {
            let formData = new FormData();
            const token = new URL(window.location.href).searchParams.get("token");


            formData.append('password', formObj.password);
            formData.append('id', userId);
            formData.append('code', token);
            axios.post(process.env.REACT_APP_BACKEND_URL + '/auth/forgetpassword/update', formData,
            ).then(response2 => {
                if (response2.data.booleanValue) {
                    setSuccess({
                        status: true,
                        msg: response2.data.message
                    })
                    setChange(false);
                    setFormObj({
                        password: "",
                        email: "",
                        phone: "",
                        msg: ""
                    });
                }
                else {
                    setSuccess({
                        status: false,
                        msg: 'حدث خطأ'
                    })
                }

            })
        }
    }
    const sendMsg = () => {
        let e = true;

        if (!validateEmail(formObj.email)) {
            setSuccess({
                status: false,
                msg: 'البريد الالكتروني غير صحيح'
            })
            e = false;
            return;
        }

        if (!captcha) {
            setSuccess({
                status: false,
                msg: 'الرجاء التحقق من صحة البيانات'
            })
            e = false;
            return;
        }
        if (e) {
            let formData = new FormData();
            formData.append('email', formObj.email);
            axios.post(process.env.REACT_APP_BACKEND_URL + '/auth/forgetpassword', formData,
            ).then(response2 => {
                if (response2.data.booleanValue) {
                    setSuccess({
                        status: true,
                        msg: response2.data.message
                    })
                    setFormObj({
                        name: "",
                        email: "",
                        phone: "",
                        msg: ""
                    });
                }
                else {
                    setSuccess({
                        status: false,
                        msg: response2.data.message
                    })
                }

            })
        }
    }
    return (
        <div>
            <Topsection data={{ text: 'تغيير كلمة المرور', img: require('../assets/images/11.png') }} />
            <Breadcrumb data={breadCurmbData} />
            <div className="loadingData" style={{
                visibility: loadingPage ? 'visible' : 'hidden',
                opacity: loadingPage ? 1 : 0,
                transition: 'visibility 0s, opacity 0.3s linear'

            }}>
                <img src={require('../assets/images/bookLoading.gif')} alt="loading" />
                <div className="textLoading">جاري التحميل...</div>
            </div>
            <div className="contact-us" style={{
                marginBottom: '15vw'
            }}>
                <div className="formcontact">
                    <div className="container">
                        <form id="contact">

                            {success.msg !== "" && (
                                <fieldset>
                                    {success.status === false && (
                                        <div class="xd-message msg-danger">
                                            <div class="xd-message-icon">
                                            </div>
                                            <div class="xd-message-content">
                                                <p>{success.msg}</p>
                                            </div>
                                        </div>
                                    )}
                                    {success.status === true && (
                                        <div class="xd-message msg-success">
                                            <div class="xd-message-icon">  </div>
                                            <div class="xd-message-content">
                                                <p>{success.msg}</p>
                                            </div>
                                        </div>
                                    )}

                                </fieldset>
                            )}

                            {!change&&<>
                                        <fieldset>
                                            <input value={formObj.email}
                                                onChange={(e) =>
                                                    setFormObj({
                                                        ...formObj,
                                                        email: e.target.value
                                                    })
                                                }
                                                placeholder="البريد الإلكتروني*"
                                                type="email"
                                                tabIndex="2"
                                                required />
                                        </fieldset>


                                        <fieldset>
                                            <ReCAPTCHA
                                                sitekey="6Ldu6BkfAAAAAAcWW9lR0IwFpWfALfHRlmIuuhJG"
                                                onChange={onChange}
                                            />
                                        </fieldset>
                                        <fieldset>
                                            <button name="button" type="button" onClick={sendMsg}>طلب كلمة مرور جديدة</button>
                                        </fieldset>
                                    </>
}
                                {change&&<p>

                                        <fieldset>
                                            <input
                                                value={formObj.password}
                                                onChange={(e) =>
                                                    setFormObj({
                                                        ...formObj,
                                                        password: e.target.value
                                                    })
                                                }
                                                placeholder="كلمة السر الجديدة*"
                                                type="password"
                                                tabIndex="0"
                                                required />
                                        </fieldset>


                                        <fieldset>
                                            <button name="button" type="button" onClick={changePassword}>تغير</button>
                                        </fieldset>
                                    </p>
}
                               

                        </form>
                    </div>
                </div>

            </div>
        </div >
    );
}

export default AdvancedSearch;
