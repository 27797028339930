import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Snackbar,
  Typography
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return (
    <MuiAlert
      elevation={6}
      variant="filled"
      {...props}
      style={{ fontFamily: "ArbFONTS-DroidKufi" }}
    />
  );
}
export default function Popup2quotation(props) {
  const [showPopup, setShowPopup] = useState(false);
  const [expanded, setExpanded] = useState("");
  const [openAlert, setOpenAlert] = useState(false);

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    setShowPopup(props.objPopup.showPopup);
  }, []);

  return (
    <div
      className="quotationBG"
      style={{
        visibility: showPopup ? "visible" : "hidden",
        opacity: showPopup ? "1" : "0",
        transition: "opacity 0.4s"
      }}
    >
      <div onClick={() => setShowPopup(false)} className="bgwithout" />
      <div className="Bookmarks">
        <div className="titleBookmatks">
          نسخ الإستشهاد المرجعي
          <div className="closeBox" onClick={() => setShowPopup(false)}>
            {" "}✖{" "}
          </div>
        </div>
        <div className="bodyBookMark">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={
                <svg width="19.359" height="11.179" viewBox="0 0 19.359 11.179">
                  <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M231.881,497.45l3.779,3.779,3.779,3.779L247,497.45"
                    transform="translate(-229.76 -495.329)"
                    fill="none"
                    stroke="#147bfc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                  />
                </svg>
              }
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography className="titleTab">
                الإستشهاد المرجعي APA
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="bodyQuotation">
                <div className="textQutation">
                  {props.objPopup.Item2 !== null &&
                    props.objPopup.Item2.hhh[0].name !== null
                    ? <div>
                      {
                        props.objPopup.Item2.hhh[0].name.nameAr.split(" ")[
                        props.objPopup.Item2.hhh[0].name.nameAr.split(" ")
                          .length - 1
                        ]
                      }،{" "}
                      {
                        props.objPopup.Item2.hhh[0].name.nameAr.split(" ")[0]
                      }{" "}
                      ( {props.objPopup.Item.yearsValue} ) .{" "}
                      <u>
                        <i>
                          {props.objPopup.Item.nameAr}
                        </i>
                      </u>{" "}
                      . ( {props.objPopup.Item.tabaaValue} ) .{" "}
                      {props.objPopup.Item2.hhhhh !== null
                        ? props.objPopup.Item2.hhhhh.arabic
                        : ""}{" "}
                      : {props.objPopup.Item.publisherValue[0].nameAr}.
                    </div>
                    : ""}
                </div>

                <Snackbar
                  open={openAlert}
                  autoHideDuration={3000}
                  onClose={() => setOpenAlert(false)}
                >
                  <Alert severity="success" color="info">
                    تم نسخ الإستشهاد المرجعي بنجاح!
                  </Alert>
                </Snackbar>
                <div
                  className="copyText"
                  onClick={() => {
                    var dummy = document.createElement("input");

                    document.body.appendChild(dummy);
                    dummy.value =
                      props.objPopup.Item2.hhh[0].name.nameAr.split(" ")[
                      props.objPopup.Item2.hhh[0].name.nameAr.split(" ")
                        .length - 1
                      ] +
                      "، " +
                      props.objPopup.Item2.hhh[0].name.nameAr.split(" ")[0] +
                      " ( " +
                      props.objPopup.Item.yearsValue +
                      " ) ." +
                      props.objPopup.Item.nameAr +
                      " . " +
                      "(" +
                      props.objPopup.Item.tabaaValue +
                      " ) . " +
                      (props.objPopup.Item2.hhhhh !== null
                        ? props.objPopup.Item2.hhhhh.arabic
                        : "") +
                      " : " +
                      props.objPopup.Item.publisherValue[0].nameAr +
                      ".";
                    dummy.select();
                    document.execCommand("copy");
                    document.body.removeChild(dummy);
                    setOpenAlert(true);
                  }}
                >
                  <svg
                    id="Group_8870"
                    data-name="Group 8870"
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.13"
                    height="30.395"
                    viewBox="0 0 25.13 30.395"
                  >
                    <rect
                      id="Rectangle_323"
                      data-name="Rectangle 323"
                      width="20.382"
                      height="25.647"
                      rx="3"
                      transform="translate(0 4.748)"
                      fill="#147bfc"
                      opacity="0.33"
                    />
                    <rect
                      id="Rectangle_324"
                      data-name="Rectangle 324"
                      width="20.382"
                      height="25.647"
                      rx="3"
                      transform="translate(4.748)"
                      fill="#147bfc"
                    />
                    <g
                      id="Group_8869"
                      data-name="Group 8869"
                      transform="translate(10.191 7.618)"
                    >
                      <line
                        id="Line_20"
                        data-name="Line 20"
                        x2="9.7"
                        transform="translate(0 4.727)"
                        fill="none"
                        stroke="#e2efff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                      <line
                        id="Line_21"
                        data-name="Line 21"
                        x2="9.7"
                        fill="none"
                        stroke="#e2efff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                      <line
                        id="Line_22"
                        data-name="Line 22"
                        x2="4.411"
                        transform="translate(0 9.454)"
                        fill="none"
                        stroke="#e2efff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={
                <svg width="19.359" height="11.179" viewBox="0 0 19.359 11.179">
                  <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M231.881,497.45l3.779,3.779,3.779,3.779L247,497.45"
                    transform="translate(-229.76 -495.329)"
                    fill="none"
                    stroke="#147bfc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                  />
                </svg>
              }
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography className="titleTab">
                الإستشهاد المرجعي MLA
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="bodyQuotation">
                <div className="textQutation">
                  {props.objPopup.Item2 !== null &&
                    props.objPopup.Item2.hhh[0].name !== null
                    ? <div>
                      {
                        props.objPopup.Item2.hhh[0].name.nameAr.split(" ")[
                        props.objPopup.Item2.hhh[0].name.nameAr.split(" ")
                          .length - 1
                        ]
                      }،{" "}
                      {props.objPopup.Item2.hhh[0].name.nameAr.split(" ")[0]}.{" "}
                      <u>{props.objPopup.Item.nameAr}</u>.
                      {props.objPopup.Item2.hhhhh !== null
                        ? props.objPopup.Item2.hhhhh.arabic
                        : ""}: {props.objPopup.Item.publisherValue[0].nameAr}،{" "}
                      {props.objPopup.Item.yearsValue}.
                    </div>
                    : ""}
                </div>

                <Snackbar
                  open={openAlert}
                  autoHideDuration={3000}
                  onClose={() => setOpenAlert(false)}
                >
                  <Alert severity="success" color="info">
                    تم نسخ الإستشهاد المرجعي بنجاح!
                  </Alert>
                </Snackbar>
                <div
                  className="copyText"
                  onClick={() => {
                    var dummy = document.createElement("input");

                    document.body.appendChild(dummy);
                    dummy.value =
                      props.objPopup.Item2.hhh[0].name.nameAr.split(" ")[
                      props.objPopup.Item2.hhh[0].name.nameAr.split(" ")
                        .length - 1
                      ] +
                      "، " +
                      props.objPopup.Item2.hhh[0].name.nameAr.split(" ")[0] +
                      ". " +
                      props.objPopup.Item.nameAr +
                      ". " +
                      (props.objPopup.Item2.hhhhh !== null
                        ? props.objPopup.Item2.hhhhh.arabic
                        : "") +
                      ": " +
                      props.objPopup.Item.publisherValue[0].nameAr +
                      "، " +
                      props.objPopup.Item.yearsValue +
                      ".";
                    dummy.select();
                    document.execCommand("copy");
                    document.body.removeChild(dummy);
                    setOpenAlert(true);
                  }}
                >
                  <svg
                    id="Group_8870"
                    data-name="Group 8870"
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.13"
                    height="30.395"
                    viewBox="0 0 25.13 30.395"
                  >
                    <rect
                      id="Rectangle_323"
                      data-name="Rectangle 323"
                      width="20.382"
                      height="25.647"
                      rx="3"
                      transform="translate(0 4.748)"
                      fill="#147bfc"
                      opacity="0.33"
                    />
                    <rect
                      id="Rectangle_324"
                      data-name="Rectangle 324"
                      width="20.382"
                      height="25.647"
                      rx="3"
                      transform="translate(4.748)"
                      fill="#147bfc"
                    />
                    <g
                      id="Group_8869"
                      data-name="Group 8869"
                      transform="translate(10.191 7.618)"
                    >
                      <line
                        id="Line_20"
                        data-name="Line 20"
                        x2="9.7"
                        transform="translate(0 4.727)"
                        fill="none"
                        stroke="#e2efff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                      <line
                        id="Line_21"
                        data-name="Line 21"
                        x2="9.7"
                        fill="none"
                        stroke="#e2efff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                      <line
                        id="Line_22"
                        data-name="Line 22"
                        x2="4.411"
                        transform="translate(0 9.454)"
                        fill="none"
                        stroke="#e2efff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={
                <svg width="19.359" height="11.179" viewBox="0 0 19.359 11.179">
                  <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M231.881,497.45l3.779,3.779,3.779,3.779L247,497.45"
                    transform="translate(-229.76 -495.329)"
                    fill="none"
                    stroke="#147bfc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                  />
                </svg>
              }
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography className="titleTab">
                الإستشهاد المرجعي Harvard{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="bodyQuotation">
                <div className="textQutation">
                  {props.objPopup.Item2 !== null &&
                    props.objPopup.Item2.hhh[0].name !== null
                    ? <div>
                      {
                        props.objPopup.Item2.hhh[0].name.nameAr.split(" ")[
                        props.objPopup.Item2.hhh[0].name.nameAr.split(" ")
                          .length - 1
                        ]
                      }،{" "}
                      {props.objPopup.Item2.hhh[0].name.nameAr.split(" ")[0]}،{" "}
                      {props.objPopup.Item.yearsValue}،{" "}
                      {props.objPopup.Item.nameAr}،{" "}
                      {props.objPopup.Item.tabaaValue}،{" "}
                      {props.objPopup.Item.publisherValue[0].nameAr}.
                    </div>
                    : ""}


                  {/* {props.objPopup.Item2 !== null &&
                  props.objPopup.Item2.hhh[0].name != null
                    ? <div>
                        ”{props.objPopup.selectText}“. ({
                          props.objPopup.Item2.hhh[0].name.nameAr.split(" ")[
                            props.objPopup.Item2.hhh[0].name.nameAr.split(" ")
                              .length - 1
                          ]
                        }، {props.objPopup.Item.yearsValue}،{" "}
                        {props.objPopup.pagesNumer}).
                      </div>
                    : ""} */}
                </div>

                <Snackbar
                  open={openAlert}
                  autoHideDuration={3000}
                  onClose={() => setOpenAlert(false)}
                >
                  <Alert severity="success" color="info">
                    تم نسخ الإستشهاد المرجعي بنجاح!
                  </Alert>
                </Snackbar>
                <div
                  className="copyText"
                  onClick={() => {
                    var dummy = document.createElement("input");

                    document.body.appendChild(dummy);
                    dummy.value =

                      props.objPopup.Item2.hhh[0].name.nameAr.split(" ")[
                      props.objPopup.Item2.hhh[0].name.nameAr.split(" ")
                        .length - 1
                      ]
                      + "، " +
                      props.objPopup.Item2.hhh[0].name.nameAr.split(" ")[0] + "، " +
                      props.objPopup.Item.yearsValue
                      + "، " +
                      props.objPopup.Item.nameAr + "، " +

                      props.objPopup.Item.tabaaValue + "، " +
                      props.objPopup.Item.publisherValue[0].nameAr
                      + "."
                      ;
                    dummy.select();
                    document.execCommand("copy");
                    document.body.removeChild(dummy);
                    setOpenAlert(true);
                  }
                  }
                >
                  <svg
                    id="Group_8870"
                    data-name="Group 8870"
                    xmlns="http://www.w3.org/2000/svg"
                    width="25.13"
                    height="30.395"
                    viewBox="0 0 25.13 30.395"
                  >
                    <rect
                      id="Rectangle_323"
                      data-name="Rectangle 323"
                      width="20.382"
                      height="25.647"
                      rx="3"
                      transform="translate(0 4.748)"
                      fill="#147bfc"
                      opacity="0.33"
                    />
                    <rect
                      id="Rectangle_324"
                      data-name="Rectangle 324"
                      width="20.382"
                      height="25.647"
                      rx="3"
                      transform="translate(4.748)"
                      fill="#147bfc"
                    />
                    <g
                      id="Group_8869"
                      data-name="Group 8869"
                      transform="translate(10.191 7.618)"
                    >
                      <line
                        id="Line_20"
                        data-name="Line 20"
                        x2="9.7"
                        transform="translate(0 4.727)"
                        fill="none"
                        stroke="#e2efff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                      <line
                        id="Line_21"
                        data-name="Line 21"
                        x2="9.7"
                        fill="none"
                        stroke="#e2efff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                      <line
                        id="Line_22"
                        data-name="Line 22"
                        x2="4.411"
                        transform="translate(0 9.454)"
                        fill="none"
                        stroke="#e2efff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="bottomNote">
          <b>ملاحظة</b>: تعتمد الاستشهادات المرجعية على قواعد مرجعية محددة, مع
          الأخذ بعين الاعتبار بأن أشكالها تختلف فيما بينها في التطبيقات ومجالات
          الاهتمام والدراسة, ولذا يجب تطبيق المتطلبات الخاصة لتتوافق مع شروط
          الناشر, المشرف, المعهد او المؤسسة.
        </div>
      </div>
    </div >
  );
}
