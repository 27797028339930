import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import {
  Button,
  Icon,
  Position,
  Tooltip,
  Viewer,
  PrimaryButton
} from '@react-pdf-viewer/core'
import { printPlugin } from '@react-pdf-viewer/print'
import { NextIcon, PreviousIcon, searchPlugin } from '@react-pdf-viewer/search'
import { bookmarkPlugin } from '@react-pdf-viewer/bookmark'
import '@react-pdf-viewer/bookmark/lib/styles/index.css'
import { ScrollMode, scrollModePlugin } from '@react-pdf-viewer/scroll-mode'
import { zoomPlugin } from '@react-pdf-viewer/zoom'
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation'
import { highlightPlugin } from '@react-pdf-viewer/highlight'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import Popup1 from '../components/pdf/popup/popup_1'
import PopupQuotation from '../components/pdf/popup/popup_2_quotation'
import PopupInfo from '../components/pdf/popup/popup_info'
import PopupSeasons from '../components/pdf/popup/popup_seasons'
import PopupShare from '../components/pdf/popup/popup_share'
import PopupNotes from '../components/pdf/popup/popup_notes'
import Dialog from '../components/pdf/popup/Dialog'
// Import styles
import '@react-pdf-viewer/highlight/lib/styles/index.css'
import '@react-pdf-viewer/search/lib/styles/index.css'
// import "@react-pdf-viewer/scroll-mode/lib/styles/index.css";
import '@react-pdf-viewer/zoom/lib/styles/index.css'
import '@react-pdf-viewer/page-navigation/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/print/lib/styles/index.css'
import { Helmet } from 'react-helmet'
import { Worker } from '@react-pdf-viewer/core'

import AWS from 'aws-sdk'
import printJS from 'print-js'
import { Box, CircularProgress, Typography } from '@material-ui/core'
import { Base64 } from 'js-base64'
import { useDispatch, useSelector } from 'react-redux'
import {
  viewBookState,
  setCurrentPage,
  setCurrentPageFromServer,
  setLoginStatus,
  setIsModalOpen,
  setIsModalOpen2,
  setCurrentKeyword,
  setColor,
  setMessage,
  setNotes,
  setItem,
  setItem2,
  setPdfFile,
  setStateBookmark,
  setStateMylibrar,
  setBookmarkId,
  seMylibraryID,
  setPagesNumer,
  setDialogObject,
  setLoadingPopup
} from '../store/viewBook';

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant='determinate' {...props} color='inherit' />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography variant='caption' component='div' color='text.secondary'>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  )
}

export default function ViewBook(props) {

  const inputEl = useRef(null)
  const inputEl2 = useRef(null)
  const myRef = useRef(null)
  const { id, type } = useParams()
  const dispatch = useDispatch();
  const viewBookData = useSelector(viewBookState)
  console.log(viewBookData)
  // const [currentPageForBook, setCurrentPage] = useState(null)
  // const [currentPageForBookFromServer, setCurrentPageFromServer] = useState(null)
  // const [loginStatus, setLoginStatus] = useState(false)
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem('access_token') || ''
  )
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isModalOpen2, setIsModalOpen2] = useState(false);

  const pageNavigationPluginInstance = pageNavigationPlugin()
  // const [currentKeyword, setCurrentKeyword] = useState({
  //   keyword: '',
  //   matchCase: false,
  //   wholeWords: false
  // })

  const {
    CurrentPageInput,
    GoToFirstPageButton,
    GoToLastPageButton,
    GoToNextPageButton
  } = pageNavigationPluginInstance

  // const [color, setColor] = useState('#ff4362')
  // const [message, setMessage] = useState('')
  // const [notes, setNotes] = useState([])
  // const [Item, setItem] = useState(null)
  // const [pdfFile, setPdfFile] = useState(null)

  // const [Item2, setItem2] = useState(null)
  // const [stateBookmark, setStateBookmark] = useState(0)
  // const [stateMylibrark, setStateMylibrar] = useState(0)
  // const [bookmarkId, setBookmarkId] = useState('')
  // const [mylibraryID, seMylibraryID] = useState('')
  // const [pagesNumer, setPagesNumer] = useState(0)
  // const [pagesNumer2, setPagesNumer2] = useState(0)
  const [popupData, setPopupData] = useState(null)
  const [popupQuotationData, setPopupQuotationData] = useState(null)
  const [popupInfoData, setPopupInfoData] = useState(null)
  const [popupSeasonsData, setPopupSeasonsData] = useState(null)
  const [popupShareData, setPopupShareData] = useState(null)
  const [popupNotesData, setPopupNotesData] = useState(null)
  // const [dialogObject, setDialogObject] = useState(null)
  // const [loadingPopup, setLoadingPopup] = useState({
  //   show: false,
  //   message: '',
  //   percentCompleted: 1
  // })
  const [numberOfPagesForD, setNuberOfPage] = useState(0);
  const [numberOfPagesorP, setNumberPrint] = useState(0);

  const [CanDownload, setCanDownload] = useState(false);
  const [numberStatus, setNumberStatus] = useState(false);
  const getCookie = name => {
    var nameEQ = name + '='
    var ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  }
  const [yearFrom, setYearFrom] = useState(parseInt(JSON.parse(getCookie('objectUser'))?.hiddenContent.yearFrom));
  const [yearTo, setYearTo] = useState(parseInt(JSON.parse(getCookie('objectUser'))?.hiddenContent.yearTo));
  const [yearValueForBook, setyearBook] = useState(parseInt(Base64.decode(props?.location?.pathname?.split('/')[props.location.pathname.split('/').length - 1])))
  const [typeOfContent, setTypeOfContent] = useState(props?.location?.pathname?.split('/')[props.location.pathname.split('/').length - 2]);
  const [idOftypeOfContent, setIdOfTypeOfContent] = useState(props?.location?.pathname?.split('/')[props.location.pathname.split('/').length - 3]);
  const printPluginInstance = printPlugin()
  const { Print } = printPluginInstance
  console.log("yearFrom>>>" ,typeof yearFrom, yearFrom);
  console.log("year>>",typeof yearTo, yearTo)
  const scrollModePluginInstance = scrollModePlugin({
    scrollMode: ScrollMode.Vertical
  })

  const zoomPluginInstance = zoomPlugin()
  const { SwitchScrollMode } = scrollModePluginInstance

  const { ZoomIn, ZoomOut, CurrentScale } = zoomPluginInstance
  const bookmarkPluginInstance = bookmarkPlugin()

  const { Bookmarks } = bookmarkPluginInstance

  const handle = useFullScreenHandle()
  const searchPluginInstance = searchPlugin()
  const {
    highlight,
    jumpToNextMatch,
    jumpToPreviousMatch
  } = searchPluginInstance
  const search = keyword => {
    dispatch(setCurrentKeyword(keyword))
    highlight(keyword)
  }

  let noteId = viewBookData.notes.length

  const noteEles = new Map()

  const checkToken = () => {
    const token = localStorage.getItem('access_token')
    if (token) {
      setAccessToken(token)
    dispatch(setLoginStatus(true))
      return true
    }
    dispatch(setLoginStatus(false))
    return false
  }
  useEffect(() => {
    localStorage.setItem('numberOfPages', 0)
    localStorage.setItem('currentPage', 0)
  }, [])

  function getInfoDownlads() {
    if (getCookie('id') !== '') {
      let formData = new FormData();
      formData.append('content_id', type + '_' + id);
      formData.append('user_id', getCookie('id'))
      axios.post(process.env.REACT_APP_BACKEND_URL + '/app/content/getDownloadInfo', formData).then((result) => {
        let allPages = result.data?.reduce((total, obj) => total + parseInt(obj.numberOfPages), 0);
        setNuberOfPage(0);
        setNuberOfPage(allPages);
      }).catch(err => {
        console.log(err);
      })
    }
  }
  function getInfoPrints() {
    if (getCookie('id') !== '') {
      let formData = new FormData();
      formData.append('content_id', type + '_' + id);
      formData.append('user_id', getCookie('id'))
      axios.post(process.env.REACT_APP_BACKEND_URL + '/app/content/getPrintInfo', formData).then((result) => {
        let allPages = result.data?.reduce((total, obj) => total + parseInt(obj.numberOfPages), 0);
        setNumberPrint(0);
        setNumberPrint(allPages);
      }).catch(err => {
        console.log(err);
      })
    }
  }
  useEffect(() => {
    getInfoDownlads();
    getInfoPrints();
  }, [])

  useEffect(() => {
    try {
      let formatData = new FormData();
      let backend_url = process.env.REACT_APP_BACKEND_URL;
      formatData.append('id', idOftypeOfContent);
      formatData.append('type', typeOfContent);
      axios.post(`${backend_url}/app/view/getInfoPages`, formatData).then((response) => {
        if (response.data.result?.length !== 0) {
          if (response.data.result[0].numberPage?.includes("صفحة")) {
            let numOfPags = parseInt(response.data.result[0].numberPage?.replace(/صفحة/g, ""));
            dispatch(setPagesNumer(numOfPags))
          }
          else {
            dispatch(setPagesNumer(response.data.result[0].numberPage))
          }
        }
      })
    } catch (error) {
      console.log(error);
    }
  }, [])

  useEffect(() => {
    const IAM_USER_KEY = 'AKIAZWMPNC53C2EVF62C'
    const IAM_USER_SECRET = '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'

    let s3bucket = new AWS.S3({
      accessKeyId: IAM_USER_KEY,
      secretAccessKey: IAM_USER_SECRET
    })

    // setAccessToken
    var crypto = require('crypto')
    let hash = crypto
      .createHash('sha256')
      .update(JSON.stringify(formatDate()))
      .digest('hex')

    let formData = new FormData()
    formData.append('hashTime', hash)
    formData.append('lang', 'ar')
    formData.append('id', id)
    formData.append('type', type)
    axios
      .post(process.env.REACT_APP_BACKEND_URL + '/app/view/get', formData)
      .then(response2 => {
        dispatch(setItem(response2.data.result))
        dispatch(setItem2(response2.data))
        window.scrollTo(0, 0)
        if (response2.data.result.s3obj !== undefined) {
          s3bucket.getObject(
            {
              Bucket: 'pdfthelearnbook', // your bucket name,
              Key: response2.data.result.s3obj // path to the object you're looking for
            },
            function (err, data) {
              if (err) console.log('err,', err, err.stack)
              else {
                dispatch(setPdfFile(data.Body))
              }
            }
          )
        } else {
          dispatch(setPdfFile(
            process.env.REACT_APP_BACKEND_URL +
            '/public/' +
            response2.data.result.fileData
          ))
        }
        dispatch(setCurrentKeyword({
          keyword: decodeURIComponent(
            props.location.search.substring(11)
          ).replace(/\\/g, ''),
          matchCase: false,
          wholeWords: false
        }))
        if (
          decodeURIComponent(props.location.search.substring(11))
            .replace(/\\/g, '')
            .split(' ')[0] !== ''
        )
          setTimeout(() => {
            highlight({
              keyword: decodeURIComponent(props.location.search.substring(11))
                .replace(/\\/g, '')
                .split(' ')[0],
              matchCase: false,
              wholeWords: false
            })
          }, 1000)
      })
  }, [])

  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays, { type: contentType })
    return blob
  }

  useEffect(() => {
    if (checkToken()) {
      let formDatahighlight = new FormData()
      formDatahighlight.append('lang', 'ar')
      formDatahighlight.append('id', id)
      formDatahighlight.append('type', type)
      formDatahighlight.append('access_token', accessToken)
      axios
        .post(
          process.env.REACT_APP_BACKEND_URL + '/app/content/gethighlight',
          formDatahighlight
        )
        .then(gethighlight => {
          if (
            gethighlight.data.message !== 'Failed Auth.' &&
            gethighlight.data.result !== undefined &&
            gethighlight.data.result.notes !== null
          )
            dispatch(setNotes(gethighlight.data.result.notes))
          else dispatch(setNotes([]))
        })

      setTimeout(() => {
        axios.post(
          process.env.REACT_APP_BACKEND_URL + '/app/content/views',
          formDatahighlight
        )
      }, 1000)
    } else {
      dispatch(setNotes([]))
    }
  }, [])
  const checkBookMark = () => {
    if (checkToken()) {
      let formData = new FormData()
      formData.append('lang', 'ar')
      formData.append('id', id)
      formData.append('type', type)
      /////////////////////
      formData.append('access_token', accessToken)
      axios
        .post(
          process.env.REACT_APP_BACKEND_URL + '/app/content/bookmarkCheck',
          formData
        )
        .then(response2 => {
          dispatch(setStateBookmark(response2.data.state ? 1 : 0))
          dispatch(setCurrentPageFromServer(response2.data?.currentPageForBook))
          if (response2.data.state) dispatch(setBookmarkId(response2.data.bookmark_id))
        })
    }
  }
  useEffect(() => {
    checkBookMark();
  }, [])

  useEffect(() => {
    if (checkToken()) {
      let formData = new FormData()
      formData.append('lang', 'ar')
      formData.append('id', id)
      formData.append('type', type)
      /////////////////////
      formData.append('access_token', accessToken)
      axios
        .post(
          process.env.REACT_APP_BACKEND_URL + '/app/content/mylibraryCheck',
          formData
        )
        .then(response2 => {
          dispatch(setStateMylibrar(response2.data.state ? 1 : 0))
          if (response2.data.state) dispatch(seMylibraryID(response2.data.bookmark_id))
        })
    }
  }, [])

  const renderHighlightTarget = props => {
    if (checkToken()) {
      localStorage.setItem('selectText', props.selectedText)
      return (
        <div
          style={{
            background: '#fff',
            display: viewBookData.loginStatus ? 'flex' : 'none',
            position: 'absolute',
            left: `${props.selectionRegion.left}%`,
            top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
            transform: 'translate(0, 8px)'
          }}
          className='TooltipHughlit'
        >
          <Tooltip
            position={Position.TopCenter}
            target={
              <Button onClick={props.toggle}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='27.638'
                  height='26.521'
                  viewBox='0 0 27.638 26.521'
                >
                  <g
                    id='Group_8962'
                    data-name='Group 8962'
                    transform='translate(-749.159 -707.186)'
                  >
                    <path
                      id='Path_9524'
                      data-name='Path 9524'
                      d='M753.94,721.38l7.525,7.536-6.3.9a.473.473,0,0,0-.241.129l-.959.959-2.085-2.085.959-.958a.48.48,0,0,0,.129-.242Z'
                      transform='translate(0.132 1.003)'
                      fill='none'
                      stroke='#2680eb'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                    />
                    <path
                      id='Path_9525'
                      data-name='Path 9525'
                      d='M754.123,730.342l-.836.836-3.128-1.042,1.879-1.879Z'
                      transform='translate(0 1.529)'
                      fill='none'
                      stroke='#2680eb'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                    />
                    <path
                      id='Path_9526'
                      data-name='Path 9526'
                      d='M775.141,713.587l-13.823,16.332-7.525-7.536,16.332-13.823a1.191,1.191,0,0,1,1.6.075l3.348,3.337A1.205,1.205,0,0,1,775.141,713.587Z'
                      transform='translate(0.278)'
                      fill='none'
                      stroke='#2680eb'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                    />
                  </g>
                </svg>
              </Button>
            }
            content={() => <div style={{ width: '100px' }}>إضافة ملاحظة</div>}
            offset={{ left: 0, top: -8 }}
          />
          <Tooltip
            position={Position.TopCenter}
            target={
              <Button onClick={addquotation}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='27.54'
                  height='26.041'
                  viewBox='0 0 31.54 32.041'
                >
                  <g
                    id='Group_8966'
                    data-name='Group 8966'
                    transform='translate(-621.111 -746.286)'
                  >
                    <g
                      id='Group_8964'
                      data-name='Group 8964'
                      transform='translate(622.134 747.309)'
                    >
                      <path
                        id='Path_9530'
                        data-name='Path 9530'
                        d='M651.375,759.357a14.744,14.744,0,1,0-26.927,10.632,16.534,16.534,0,0,1-2.314,7.338h4.56a16.543,16.543,0,0,0,4.941-1.488,14.745,14.745,0,0,0,19.74-16.482Z'
                        transform='translate(-622.134 -747.309)'
                        fill='none'
                        stroke='#2680eb'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        fillRule='evenodd'
                      />
                    </g>
                    <g
                      id='Group_8965'
                      data-name='Group 8965'
                      transform='translate(629.475 756.573)'
                    >
                      <path
                        id='Path_9531'
                        data-name='Path 9531'
                        d='M640.151,767.573a.69.69,0,0,0-.347.926l.568,1.193a.692.692,0,0,0,.9.336,7.986,7.986,0,0,0,2.5-1.621,6.056,6.056,0,0,0,1.679-2.652,14.536,14.536,0,0,0,.463-4.075v-3.554a.7.7,0,0,0-.695-.695h-4.55a.7.7,0,0,0-.695.695v4.55a.7.7,0,0,0,.695.695h2.177a4.881,4.881,0,0,1-.811,2.813A4.391,4.391,0,0,1,640.151,767.573Z'
                        transform='translate(-630.965 -757.429)'
                        fill='#2680eb'
                      />
                      <path
                        id='Path_9532'
                        data-name='Path 9532'
                        d='M630.566,767.573a.69.69,0,0,0-.347.926l.568,1.181a.692.692,0,0,0,.9.336,8.381,8.381,0,0,0,2.49-1.609,6.18,6.18,0,0,0,1.691-2.664,14.372,14.372,0,0,0,.451-4.063v-3.555a.7.7,0,0,0-.695-.695h-4.55a.7.7,0,0,0-.695.695v4.55a.7.7,0,0,0,.695.695h2.188a4.882,4.882,0,0,1-.81,2.814A4.392,4.392,0,0,1,630.566,767.573Z'
                        transform='translate(-630.154 -757.429)'
                        fill='#2680eb'
                      />
                    </g>
                  </g>
                </svg>
              </Button>
            }
            content={() => <div style={{ width: '100px' }}>إقتباس</div>}
            offset={{ left: 0, top: -8 }}
          />
        </div>
      )
    }
  }

  const renderHighlightContent = props => {
    const addNote = () => {
      // if (message !== "") {
      const note = {
        id: ++noteId,
        content: viewBookData.message,
        highlightAreas: props.highlightAreas,
        quote: props.selectedText,
        color: viewBookData.color
      }
      dispatch(setNotes(viewBookData.notes.concat([note])))
      let tempNote = viewBookData.notes.concat([note])
      let formData = new FormData()
      formData.append('lang', 'ar')
      formData.append('id', id)
      formData.append('type', type)
      /////////////////
      formData.append('notes', JSON.stringify(tempNote))
      /////////////////////
      formData.append('access_token', accessToken)
      axios
        .post(
          process.env.REACT_APP_BACKEND_URL + '/app/content/addhighlight',
          formData
        )
        .then(response2 => { })

      props.cancel()
      // }
    }

    return (
      <div
        style={{
          background: '#fff',
          border: '1px solid rgba(0, 0, 0, .3)',
          borderRadius: '2px',
          padding: '8px',
          position: 'absolute',
          left: `${props.selectionRegion.left}%`,
          top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
          zIndex: 1
        }}
        className='addnoteclass'
      >
        <div>
          <textarea
            rows={3}
            style={{
              border: '1px solid rgba(0, 0, 0, .3)'
            }}
            onChange={e => dispatch(setMessage(e.target.value))}
          />
        </div>

        <div className='HighlightColor'>
          <div
            className={
              viewBookData.color === '#ff4362'
                ? 'HighlightIcon redcolor selected'
                : 'HighlightIcon redcolor'
            }
            onClick={() => dispatch(setColor('#ff4362'))}
          />
          <div
            className={
              viewBookData.color === '#ffcc00'
                ? 'HighlightIcon yallowcolor selected'
                : 'HighlightIcon yallowcolor'
            }
            onClick={() => dispatch(setColor('#ffcc00'))}
          />
          <div
            className={
              viewBookData.color === '#0054ff'
                ? 'HighlightIcon bluecolor selected'
                : 'HighlightIcon bluecolor'
            }
            onClick={() => dispatch(setColor('#0054ff'))}
          />

          <div
            className={
              viewBookData.color === '#fca600'
                ? 'HighlightIcon color3 selected'
                : 'HighlightIcon color3'
            }
            onClick={() => dispatch(setColor('#fca600'))}
          />

          <div
            className={
              viewBookData.color === '#8fee90'
                ? 'HighlightIcon color4 selected'
                : 'HighlightIcon color4'
            }
            onClick={() => dispatch(setColor('#8fee90'))}
          />
        </div>
        <div
          style={{
            display: 'flex',
            marginTop: '8px'
          }}
        >
          <div style={{ marginLeft: '8px' }}>
            <PrimaryButton onClick={addNote}>إضافة</PrimaryButton>
          </div>
          <Button onClick={props.cancel}>الغاء</Button>
        </div>
      </div>
    )
  }

  const renderHighlights = props => (
    <div>
      {viewBookData.notes.map(note => (
        <React.Fragment key={note.id}>
          {note.highlightAreas
            .filter(area => area.pageIndex === props.pageIndex)
            .map((area, idx) => (
              <div
                key={idx}
                style={Object.assign(
                  {},
                  {
                    background: note.color,
                    opacity: 0.4
                  },
                  props.getCssProperties(area, props.rotation)
                )}
                onClick={() => jumpToNote(note)}
                ref={ref => {
                  noteEles.set(note.id, ref)
                }}
              >
                {idx === 0 ? (
                  <div
                    className='deleteHighlight'
                    onClick={() => {
                      dispatch(setDialogObject(null))
                      setTimeout(() => {
                        dispatch(setDialogObject({
                          showPopup: true,
                          title: 'هل انت متأكد من الحذف؟ ',
                          body: '',
                          id: note.id,
                          type: type,
                          idPage: id,
                          notes: viewBookData.notes,
                          setNotes: dispatch(setNotes)
                        }))
                      }, 100)
                    }}
                  >
                    ✖
                  </div>
                ) : (
                  ' '
                )}
              </div>
            ))}
        </React.Fragment>
      ))}
    </div>
  )

  const highlightPluginInstance = highlightPlugin({
    renderHighlightTarget,
    renderHighlightContent,
    renderHighlights
  })
  const jumpToNote = note => {
    if (noteEles.has(note.id)) {
      noteEles.get(note.id).scrollIntoView()
    }
  }
  const { jumpToHighlightArea } = highlightPluginInstance

  const {
    GoToPreviousPage,
    CurrentPageLabel
  } = pageNavigationPluginInstance

  function formatDate() {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }

  const addbookmark = () => {
    if (checkToken()) {
      let formData = new FormData()
      formData.append('lang', 'ar')
      formData.append('id', id)
      formData.append('type', type)
      /////////////////
      formData.append('state', viewBookData.stateBookmark)
      formData.append("currentPageForBook", viewBookData.currentPageForBook)
      if (viewBookData.stateBookmark === 1) formData.append('bookmark_id', viewBookData.bookmarkId)
      /////////////////////
      formData.append('access_token', accessToken)
      axios
        .post(
          process.env.REACT_APP_BACKEND_URL + '/app/content/addbookmark',
          formData
        )
        .then(response2 => {
          dispatch(setStateBookmark(viewBookData.stateBookmark === 1 ? 0 : 1))

          setShowPopupMethod({
            showPopup: true,
            titlePopup:
              viewBookData.stateBookmark === 0
                ? 'تمت إضافة علامة مرجعية بنجاح!'
                : 'تمت إزالة علامة مرجعية بنجاح!',
            body:
              viewBookData.stateBookmark === 0
                ? 'تمت إضافة علامة مرجعية بنجاح!'
                : 'تمت إزالة علامة مرجعية بنجاح!',
            titleClassPlus: 'Succ',
            bodyPopup: '',
            btnobj: {
              label: '',
              showbtn: false,
              methodbtn: ''
            }
          })
        }).then((resc) => {
          checkBookMark();
        }).catch(err => console.log(err))
    }
  }
  const addquotation = () => {
    setPopupQuotationData({
      showPopup: true,
      Item2: viewBookData.Item2,
      Item: viewBookData.Item,
      selectText: localStorage.getItem('selectText'),
      pagesNumer: viewBookData.pagesNumer2
    })

    // if (checkToken()) {
    //   setPopupQuotationData(null);
    let formData = new FormData()
    formData.append('lang', 'ar')
    formData.append('id', id)
    formData.append('type', type)
    formData.append('quotation', localStorage.getItem('selectText'))
    formData.append('access_token', accessToken)
    axios
      .post(
        process.env.REACT_APP_BACKEND_URL + '/app/content/quotationSet',
        formData
      )
      .then(response2 => { })
    // }
  }


  const calPage = (page, totlePages) => {
    if (page >= Math.round(totlePages * 0.05) && totlePages !== 0) {
      if (!checkToken()) {
        if (myRef.current) {
          pageLimitColse()
          pageNavigationPluginInstance.jumpToPage(0)
          setTimeout(() => {
            myRef.current.scrollIntoView({
              block: 'start',
              inline: 'nearest'
            })
          }, 500)
          // setTimeout(() => {
          //   window.location.reload();
          // }, 2000);
        }
      }
      if (!(yearValueForBook <= yearTo && yearValueForBook >= yearFrom)) {
        if (checkToken()) {
          if (myRef.current) {
            pageLimitColse()
            pageNavigationPluginInstance.jumpToPage(0)
            setTimeout(() => {
              myRef.current.scrollIntoView({
                block: 'start',
                inline: 'nearest'
              })
            }, 500)
            // setTimeout(() => {
            //   window.location.reload();
            // }, 2000);
          }
        }
      }
    }
    return '  '
  }

  const pageLimitColse = () => {
    setShowPopupMethod({
      showPopup: true,
      titlePopup: 'عزيزي المستخدم المجاني',
      bodyPopup: 'لقد وصلت للحد الأقصى من الصفحات المتاحة للاستخدام المجاني',
      btnobj: {
        label: 'اشترك الآن',
        show: true,
        methodbtn: ''
      }
    })
  }

  const setShowPopupMethod = o => {
    setPopupData(null)
    setTimeout(() => {
      setPopupData(o)
    }, 100)
  }

  const addToLibrary = () => {
    if (checkToken()) {
      let formData = new FormData()
      formData.append('lang', 'ar')
      formData.append('id', id)
      formData.append('type', type)
      formData.append('library', 'add')
      /////////////////
      formData.append('state', viewBookData.stateMylibrark)
      if (viewBookData.stateMylibrark === 1) formData.append('bookmark_id', viewBookData.mylibraryID)
      /////////////////////
      formData.append('access_token', accessToken)
      axios
        .post(
          process.env.REACT_APP_BACKEND_URL + '/app/content/addmylibrary',
          formData
        )
        .then(response2 => {
          dispatch(setStateMylibrar(viewBookData.stateMylibrark == 1 ? 0 : 1))

          setShowPopupMethod({
            showPopup: true,
            titlePopup:
              viewBookData.stateMylibrark == 0
                ? 'تمت الإضافة الى مكتبتك!'
                : 'تمت الإزالة من مكتبتك!',
            body:
              viewBookData.BoxstateMylibrark == 0
                ? 'تمت الإضافة الى مكتبتك!'
                : 'تمت الإزالة من مكتبتك!',
            titleClassPlus: 'Succ',
            bodyPopup: '',
            btnobj: {
              label: '',
              showbtn: false,
              methodbtn: ''
            }
          })
        })
    }
  }
  const openModal = () => {
    dispatch(setIsModalOpen(true));
  }
  const closeModal = () => {
    dispatch(setIsModalOpen(false));
    setCanDownload(false);
    setNumberStatus(false);
  }

  const openModal2 = () => {
    dispatch(setIsModalOpen2(true));
  }
  const closeModalPrint = () => {
    dispatch(setIsModalOpen2(false))
    setCanDownload(false);
    setNumberStatus(false);
  }

  const handlerSubmitModal = (e) => {
    e.preventDefault();
    let start = parseInt(e.target.start.value);
    let end = parseInt(e.target.end.value);
    if (end > start) {
      if (!((end - start) > (Math.floor(viewBookData.pagesNumer * parseInt(JSON.parse(getCookie('objectUser'))?.hiddenContent?.pdfReader
        ?.downloadPercentage) / 100)) - numberOfPagesForD)) {
        if (checkToken()) {
          dispatch(setLoadingPopup({
            show: true,
            message: 'جاري تحضير الملف، يرجى الانتظار',
            percentCompleted: 0
          }))
          var crypto = require('crypto')
          closeModal(false);
          let hash2 = crypto
            .createHash('sha256')
            .update(JSON.stringify(formatDate()))
            .digest('hex')
          let formData3 = new FormData()
          formData3.append('hashTime', hash2)
          formData3.append('startPage', start);
          formData3.append('endPage', end);
          formData3.append('key', viewBookData.Item.s3obj)
          formData3.append(
            'printPercentage',
            JSON.parse(getCookie('objectUser'))?.hiddenContent?.pdfReader
              ?.downloadPercentage !== undefined
              ? JSON.parse(getCookie('objectUser'))?.hiddenContent?.pdfReader
                ?.downloadPercentage
              : 1
          )
          formData3.append("pdfV", JSON.parse(localStorage.getItem('pdf'))?.fileData)
          axios
            .post(
              process.env.REACT_APP_BACKEND_URL + '/app/getPrintPages',
              formData3,
              {
                onDownloadProgress: progressEvent => {
                  let percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  )
                  //percentCompleted: percentCompleted
                  dispatch(setLoadingPopup({
                    show: true,
                    message: 'جاري تحضير الملف، يرجى الانتظار',
                    percentCompleted: percentCompleted
                  }))
                }
              }
            )
            .then(response2 => {
              // console.log(response2.data)
              if (true) {
                const blob = b64toBlob(response2.data.ba, 'pdf')
                const blobUrl = URL.createObjectURL(blob)
                var a = document.createElement('a') //Create <a>
                a.href = blobUrl
                a.download = `${viewBookData.Item?.nameAr}.pdf` //File name Here
                a.click() //Downloaded file

                dispatch(setLoadingPopup({
                  show: false,
                  message: '',
                  percentCompleted: 0
                }))
                let formData = new FormData()
                formData.append('lang', 'ar')
                formData.append('id', id)
                formData.append('type', type)
                formData.append('access_token', accessToken)
                formData.append('numberOfPages', end - start)
                formData.append('user_id', getCookie('id'));
                axios.post(
                  process.env.REACT_APP_BACKEND_URL + '/app/content/downloadsPerc',
                  formData
                ).then((result) => {
                  axios.post(process.env.REACT_APP_BACKEND_URL + '/app/content/downloadSet', formData)
                }).then((result2) => {
                  getInfoDownlads();
                }).catch((err) => { console.log(err) })
              }
            }).catch(err => console.log(err))
        }
      }
      else {
        setCanDownload(true);
      }
    }
    else {
      setNumberStatus(true);
    }
  }
  const handlerSubmitPrint = (e) => {
    e.preventDefault();
    let start = parseInt(e.target.start2.value);
    let end = parseInt(e.target.end2.value);
    if (end > start) {
      if (!((end - start) > (Math.floor(viewBookData.pagesNumer * parseInt(JSON.parse(getCookie('objectUser'))?.hiddenContent?.pdfReader
        ?.downloadPercentage) / 100) - numberOfPagesorP))) {
        var crypto = require('crypto')

        let hash2 = crypto
          .createHash('sha256')
          .update(JSON.stringify(formatDate()))
          .digest('hex')
        dispatch(setLoadingPopup({
          show: true,
          message: 'جاري تحضير الملف، يرجى الانتظار',
          percentCompleted: 0
        }))
        closeModalPrint(false);
        let formData2 = new FormData()
        formData2.append('hashTime', hash2)
        formData2.append('key', viewBookData.Item.s3obj)
        formData2.append('startPage', start);
        formData2.append('endPage', end);
        formData2.append(
          'printPercentage',
          JSON.parse(getCookie('objectUser'))?.hiddenContent?.pdfReader
            ?.printPercentage
        )
        formData2.append("pdfV", JSON.parse(localStorage.getItem('pdf'))?.fileData)

        axios
          .post(
            process.env.REACT_APP_BACKEND_URL + '/app/getPrintPages',
            formData2,
            {
              onDownloadProgress: progressEvent => {
                let percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                )
                //percentCompleted: percentCompleted
                dispatch(setLoadingPopup({
                  show: true,
                  message: 'جاري تحضير الملف، يرجى الانتظار',
                  percentCompleted: percentCompleted
                }))
              }
            }
          )
          .then(response2 => {
            let formData = new FormData()
            formData.append('lang', 'ar')
            formData.append('id', id)
            formData.append('type', type)
            formData.append('numberOfPages', end - start)
            formData.append('user_id', getCookie('id'));
            formData.append('access_token', localStorage.getItem('access_token'))
            axios
              .post(
                process.env.REACT_APP_BACKEND_URL + '/app/content/printsPerc',
                formData
              )
              .then(response2 => {
                axios.post(process.env.REACT_APP_BACKEND_URL + '/app/content/printSet', formData)

              }).then((resx) => {
                getInfoPrints();
              }).catch(err => console.log(err));
            printJS({
              printable: response2.data.ba,
              type: 'pdf',
              base64: true,

              onLoadingStart: function () { },

              onLoadingEnd: function (ev) {
                dispatch(setLoadingPopup({
                  show: false,
                  titlePopup: ' '
                }))
              }
            })
          })
      }
      else {
        setCanDownload(true);
      }
    }
    else {
      setNumberStatus(true);
    }
  }
  const Modal = ({ isOpen, onClose }) => {
    if (!isOpen) return <div></div>;
    return (
      <div className='modal'>
        <div className='modal-content'>
          <span className='close__button' onClick={onClose}>&times;</span>
          <p style={{ fontSize: "15px" }}>يرجى تحديد عدد الصفحات المراد تحميلها</p>
          <p style={{ fontSize: "15px" }}>تستطيع تحميل  {Math.floor(viewBookData.pagesNumer * parseInt(JSON.parse(getCookie('objectUser'))?.hiddenContent?.pdfReader
            ?.downloadPercentage) / 100) - numberOfPagesForD}  صفحه فقط</p>
          <form onSubmit={handlerSubmitModal}>
            <div className='inputs__class'>
              <label>من</label>
              <input placeholder='بداية' min={1} type='number' className='input__start' id='start' max={viewBookData.pagesNumer - 1} required />
              <label>الى</label>
              <input placeholder='نهاية' min={2} type='number' className='input__end' id='end' max={viewBookData.pagesNumer} required />
            </div>
            <button type='submit' className='button__download'>تحميل</button>
            {CanDownload && <p style={{ fontSize: "12px", color: 'red' }}>عدد الصفحات المراد تحميلها اكبر من المسموح لك</p>}
            {numberStatus && <p style={{ fontSize: "12px", color: 'red' }}>يجب ان يكون رقم نهاية الصفحه اكبر  بداية الصفحه</p>}
          </form>
        </div>
      </div>
    );
  };
  const ModalForPrint = ({ isOpen, onClose }) => {
    if (!isOpen) return <div></div>;
    return (
      <div className='modal'>
        <div className='modal-content'>
          <span className='close__button' onClick={onClose}>&times;</span>
          <p style={{ fontSize: "15px" }}>يرجى تحديد عدد الصفحات المراد طباعتها</p>
          <p style={{ fontSize: "15px" }}>تستطيع طباعة  {Math.floor(viewBookData.pagesNumer * parseInt(JSON.parse(getCookie('objectUser'))?.hiddenContent?.pdfReader
            ?.downloadPercentage) / 100 - numberOfPagesorP)} صفحه فقط</p>
          <form onSubmit={handlerSubmitPrint}>
            <div className='inputs__class'>
              <label>من</label>
              <input placeholder='بداية' min={1} type='number' className='input__start' id='start2' max={viewBookData.pagesNumer - 1} required />
              <label>الى</label>
              <input placeholder='نهاية' min={2} type='number' className='input__end' id='end2' max={viewBookData.pagesNumer} required />
            </div>
            <button type='submit' className='button__download' >طباعة</button>
            {CanDownload && <p style={{ fontSize: "12px", color: 'red' }}>عدد الصفحات المراد طباعتها اكبر من المسموح لك</p>}
            {numberStatus && <p style={{ fontSize: "12px", color: 'red' }}>يجب ان يكون رقم نهاية الصفحه اكبر  بداية الصفحه</p>}
          </form>
        </div>
      </div>
    );
  };
  return (
    <div
      ref={myRef}
      onPaste={e => {
        e.preventDefault()
        return false
      }}
      onCopy={e => {
        e.preventDefault()
        return false
      }}
    >
      <Modal isOpen={viewBookData.isModalOpen} onClose={closeModal} />
      <ModalForPrint isOpen={viewBookData.isModalOpen2} onClose={closeModalPrint} />
      <Helmet>
        <title>{`${viewBookData.Item != null ? viewBookData.Item.nameAr : ''} | ${process.env.REACT_APP_FRONT_TITLE
          }`}</title>
      </Helmet>

      {/* Popup */}
      {popupData !== null ? <Popup1 objPopup={popupData} /> : ''}
      {popupQuotationData !== null ? (
        <PopupQuotation objPopup={popupQuotationData} />
      ) : (
        ''
      )}
      {popupInfoData !== null ? <PopupInfo objPopup={popupInfoData} /> : ''}
      {popupSeasonsData !== null ? (
        <PopupSeasons objPopup={popupSeasonsData} />
      ) : (
        ''
      )}
      {popupShareData !== null ? <PopupShare objPopup={popupShareData} /> : ''}
      {popupNotesData !== null ? <PopupNotes objPopup={popupNotesData} /> : ''}
      {viewBookData.dialogObject !== null ? <Dialog objPopup={viewBookData.dialogObject} /> : ''}

      {/* end Popup */}
      <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js'>
        <div className='pdf' onContextMenu={e => e.preventDefault()}>
          <div className='searchandnavbook'>
            <div className='searchsec'>
              <div
                style={{
                  display: 'flex',
                  padding: '0 2px'
                }}
              >
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    padding: '4px'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      padding: '0 2px'
                    }}
                  >
                    <input
                      placeholder='أدخل كلمة. البحث هنا'
                      type='text'
                      value={viewBookData.currentKeyword.keyword}
                      // autoFocus={true}
                      onChange={e => {
                        dispatch(setCurrentKeyword({
                          keyword: e.target.value,
                          matchCase: viewBookData.currentKeyword.matchCase,
                          wholeWords: viewBookData.currentKeyword.wholeWords
                        }))
                      }}
                      onKeyDown={e => {
                        if (e.key === 'Enter' && viewBookData.currentKeyword.keyword) {
                          highlight(viewBookData.currentKeyword)
                        }
                      }}
                    />

                    <Tooltip
                      position={Position.BottomCenter}
                      target={
                        <button
                          style={{
                            background: '#fff',
                            border: 'none',
                            borderBottom: `2px solid ${viewBookData.currentKeyword.matchCase
                              ? '#147BFC'
                              : 'transparent'
                              }`,
                            cursor: 'pointer',
                            height: '100%',
                            padding: '0 2px'
                          }}
                          onClick={() =>
                            search({
                              keyword: viewBookData.currentKeyword.keyword,
                              matchCase: !viewBookData.currentKeyword.matchCase,
                              wholeWords: viewBookData.currentKeyword.wholeWords
                            })
                          }
                        >
                          <Icon>
                            <path d='M15.979,21.725,9.453,2.612a.5.5,0,0,0-.946,0L2,21.725' />
                            <path d='M4.383 14.725L13.59 14.725' />
                            <path d='M0.5 21.725L3.52 21.725' />
                            <path d='M14.479 21.725L17.5 21.725' />
                            <path d='M22.5,21.725,18.377,9.647a.5.5,0,0,0-.946,0l-1.888,5.543' />
                            <path d='M16.92 16.725L20.794 16.725' />
                            <path d='M21.516 21.725L23.5 21.725' />
                          </Icon>
                        </button>
                      }
                      content={() => 'تطابق  الحالة'}
                      offset={{ left: 0, top: 8 }}
                    />
                    <Tooltip
                      position={Position.BottomCenter}
                      target={
                        <button
                          style={{
                            background: '#fff',
                            border: 'none',
                            borderBottom: `2px solid ${viewBookData.currentKeyword.wholeWords
                              ? '#147BFC'
                              : 'transparent'
                              }`,
                            cursor: 'pointer',
                            height: '100%',
                            padding: '0 2px'
                          }}
                          onClick={() =>
                            search({
                              keyword: viewBookData.currentKeyword.keyword,
                              matchCase: viewBookData.currentKeyword.matchCase,
                              wholeWords: !viewBookData.currentKeyword.wholeWords
                            })
                          }
                        >
                          <Icon>
                            <path d='M0.500 7.498 L23.500 7.498 L23.500 16.498 L0.500 16.498 Z' />
                            <path d='M3.5 9.498L3.5 14.498' />
                          </Icon>
                        </button>
                      }
                      content={() => 'تطابق كامل العبارة '}
                      offset={{ left: 0, top: 8 }}
                    />
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    <Tooltip
                      position={Position.BottomCenter}
                      target={
                        <Button onClick={jumpToPreviousMatch}>
                          <PreviousIcon />
                        </Button>
                      }
                      content={() => 'نتيجة البحث السابقة'}
                      offset={{ left: 0, top: 8 }}
                    />
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    <Tooltip
                      position={Position.BottomCenter}
                      target={
                        <Button onClick={jumpToNextMatch}>
                          <NextIcon />
                        </Button>
                      }
                      content={() => 'نتيجة البحث التالية'}
                      offset={{ left: 0, top: 8 }}
                    />
                  </div>
                </div>
              </div>
              <div style={{ padding: '0 2px' }}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='searchSvgBtn'
                  viewBox='0 0 75 75'
                  onClick={() => {
                    highlight(viewBookData.currentKeyword)
                  }}
                >
                  <g
                    id='Group_9392'
                    data-name='Group 9392'
                    transform='translate(-7400 2892)'
                  >
                    <circle
                      id='Ellipse_136'
                      data-name='Ellipse 136'
                      cx='37.5'
                      cy='37.5'
                      r='37.5'
                      transform='translate(7400 -2892)'
                      fill='#147bfc'
                    />
                    <g
                      id='Group_9263'
                      data-name='Group 9263'
                      transform='translate(7424.942 -2866.829)'
                    >
                      <circle
                        id='Ellipse_86'
                        data-name='Ellipse 86'
                        cx='10.814'
                        cy='10.814'
                        r='10.814'
                        fill='none'
                        stroke='#fff'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='4'
                      />
                      <line
                        id='Line_5'
                        data-name='Line 5'
                        x2='5.371'
                        y2='5.371'
                        transform='translate(19.286 19.286)'
                        fill='none'
                        stroke='#fff'
                        strokeLinecap='round'
                        strokeWidth='4'
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <div className='navbook'>
              <div className='zoominout'>
                <ZoomIn>
                  {props => (
                    <svg
                      onClick={props.onClick}
                      xmlns='http://www.w3.org/2000/svg'
                      width='30'
                      height='30'
                      viewBox='0 0 39 39'
                    >
                      <g
                        id='Group_9394'
                        data-name='Group 9394'
                        transform='translate(-632 -170)'
                      >
                        <g
                          id='Group_9267'
                          data-name='Group 9267'
                          transform='translate(661.002 198.002) rotate(180)'
                        >
                          <line
                            id='Line_16'
                            data-name='Line 16'
                            y2='18.002'
                            transform='translate(9.001 0)'
                            fill='none'
                            stroke='#a0acbc'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2.4'
                          />
                          <line
                            id='Line_17'
                            data-name='Line 17'
                            x1='18.002'
                            transform='translate(0 9.001)'
                            fill='none'
                            stroke='#a0acbc'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2.4'
                          />
                        </g>
                        <g
                          id='Ellipse_138'
                          data-name='Ellipse 138'
                          transform='translate(632 170)'
                          fill='none'
                          stroke='#a0acbc'
                          strokeWidth='2'
                        >
                          <circle cx='19.5' cy='19.5' r='19.5' stroke='none' />
                          <circle cx='19.5' cy='19.5' r='18.5' fill='none' />
                        </g>
                      </g>
                    </svg>
                  )}
                </ZoomIn>
                <div style={{ padding: '0px 2px' }}>
                  <CurrentScale>
                    {props => (
                      <div
                        style={{ fontFamily: 'Arial', fontSize: '13px' }}
                      >{`${Math.round(props.scale * 100)}%`}</div>
                    )}
                  </CurrentScale>
                </div>
                <ZoomOut>
                  {props => (
                    <svg
                      onClick={props.onClick}
                      xmlns='http://www.w3.org/2000/svg'
                      width='30'
                      height='30'
                      viewBox='0 0 39 39'
                    >
                      <g
                        id='Group_9393'
                        data-name='Group 9393'
                        transform='translate(-559 -170)'
                      >
                        <g
                          id='Group_9266'
                          data-name='Group 9266'
                          transform='translate(588.002 198.002) rotate(180)'
                        >
                          <line
                            id='Line_17'
                            data-name='Line 17'
                            x1='18.002'
                            transform='translate(0 9.001)'
                            fill='none'
                            stroke='#a0acbc'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2.4'
                          />
                        </g>
                        <g
                          id='Ellipse_137'
                          data-name='Ellipse 137'
                          transform='translate(559 170)'
                          fill='none'
                          stroke='#a0acbc'
                          strokeWidth='2'
                        >
                          <circle cx='19.5' cy='19.5' r='19.5' stroke='none' />
                          <circle cx='19.5' cy='19.5' r='18.5' fill='none' />
                        </g>
                      </g>
                    </svg>
                  )}
                </ZoomOut>
              </div>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '4px'
                }}
              >
                <div style={{ padding: '0px 2px' }}>
                  <SwitchScrollMode mode={ScrollMode.Vertical}>
                    {props => {
                      return (
                        <div style={{ padding: '0px 2px' }}>
                          <img
                            className={
                              props.isSelected
                                ? 'fullScreen selected'
                                : 'fullScreen'
                            }
                            onClick={props.onClick}
                            src={require('../assets/images/vertical.png')}
                            alt='full screen'
                          />
                        </div>
                      )
                    }}
                  </SwitchScrollMode>
                </div>
                <div style={{ padding: '0px 2px' }}>
                  <SwitchScrollMode mode={ScrollMode.Horizontal}>
                    {props => {
                      return (
                        <div style={{ padding: '0px 2px' }}>
                          <img
                            className={
                              props.isSelected
                                ? 'fullScreen selected'
                                : 'fullScreen'
                            }
                            onClick={props.onClick}
                            src={require('../assets/images/vertical2.png')}
                            alt='full screen'
                          />
                        </div>
                      )
                    }}
                  </SwitchScrollMode>
                </div>
                <div style={{ padding: '0px 2px' }}>
                  <img
                    className='fullScreen'
                    onClick={handle.enter}
                    src={require('../assets/images/full.png')}
                    alt='full screen'
                  />
                </div>
              </div>

              <div className='navbooknumber'>
                <div
                  style={{
                    alignItems: 'center',
                    backgroundColor: '#eeeeee',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '4px',
                    borderRadius: '8px'
                  }}
                >
                  <div style={{ padding: '0px 2px' }}>
                    <GoToFirstPageButton />
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    <GoToPreviousPage />
                  </div>
                  <div style={{ padding: '0px 2px' }} className='gotopagediv'>
                    <CurrentPageInput>
                      {props => {
                        return (
                          <input
                            type='number'
                            min='1'
                            max={props.pageCount}
                            value={props.value}
                            onChange={e => {
                              console.log(e.target.value)
                            }}
                            style={{
                              width: '50px',
                              height: '30px',
                              borderRadius: '8px',
                              border: '1px solid #727c89',
                              textAlign: 'center',
                              fontSize: '14px',
                              color: '#727c89',
                              outline: 'none'
                            }}
                          />
                        )
                      }}
                    </CurrentPageInput>
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    <GoToNextPageButton />
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    <GoToLastPageButton />
                  </div>
                </div>
                <div className='totalPage'>
                  <CurrentPageLabel>
                    {props => {
                      // console.log(props);
                      // calPage(props.currentPage, props.numberOfPages);
                      return (
                        <>
                          {/* {props.numberOfPages} */}
                          {/* <div className="numbernav2">
                          <span>أنت الان في الصفحة رقم {n2words(parseInt(localStorage.getItem('currentPage')) + 1, { lang: 'ar' })}</span>
                        </div> */}
                        </>
                      )
                    }}
                  </CurrentPageLabel>
                  {viewBookData.pagesNumer}
                </div>
              </div>
            </div>
          </div>

          <FullScreen handle={handle}>
            <div className='viewContent'>
              <div className='bookView' ref={inputEl}>
                <div className='pdfReader' ref={inputEl2}>
                  <div className='bookmark'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='27.948'
                      height='38'
                      viewBox='0 0 27.948 38'
                      onClick={addbookmark}
                    >
                      <path
                        id='Path_9740'
                        data-name='Path 9740'
                        d='M722.11,768.191l9.759-6.056a1.23,1.23,0,0,1,1.3,0l9.759,6.056a1.234,1.234,0,0,0,1.884-1.048V731.61a1.232,1.232,0,0,0-1.233-1.232H721.46c-.681,0-1.261.7-4.6,4H720.2l.028,32.767A1.234,1.234,0,0,0,722.11,768.191Z'
                        transform='translate(-716.864 -730.378)'
                        fill={viewBookData.stateBookmark === 1 ? '#FFB100' : '#a0acbc'}
                      />
                    </svg>
                  </div>
                  {viewBookData.pdfFile !== null ? (
                    <>
                      <Viewer
                        fileUrl={viewBookData.pdfFile}
                        plugins={[
                          printPluginInstance,
                          searchPluginInstance,
                          scrollModePluginInstance,
                          zoomPluginInstance,
                          pageNavigationPluginInstance,
                          bookmarkPluginInstance,
                          highlightPluginInstance
                        ]}
                        // defaultScale={SpecialZoomLevel.PageFit}
                        httpHeaders={{
                          'Access-Control-Allow-Origin': '*',
                          'Access-Control-Allow-Methods':
                            'DELETE, POST, GET, OPTIONS',
                          'Access-Control-Allow-Headers':
                            'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
                        }}
                        initialPage={viewBookData.currentPageForBookFromServer !== null ? parseInt(viewBookData.currentPageForBookFromServer) : 0}
                        onPageChange={page => {
                          localStorage.setItem('currentPage', page)
                          calPage(page.currentPage, page.doc._pdfInfo.numPages)
                          dispatch(setCurrentPage(page.currentPage))
                          if (page.currentPage === parseInt(viewBookData.currentPageForBookFromServer)) {
                            dispatch(setStateBookmark(1));
                          } else {
                            dispatch(setStateBookmark(0));
                          }
                        }}
                      />
                    </>
                  ) : (
                    <div
                      style={{
                        textAlign: 'center',
                        backgroundColor: '#fff',
                        padding: '4vw'
                      }}
                    >

                      <img
                        src={require('../assets/images/bookLoading.gif')}
                        width='250'
                        style={{ opacity: '0.9' }}
                      />
                      <br />
                      جاري التحميل
                    </div>
                  )}
                </div>
              </div>
            </div>

            {viewBookData.loadingPopup.show && (
              <div
                style={{
                  textAlign: 'center',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  padding: '4vw',
                  zIndex: '999999',
                  position: 'fixed',
                  top: '0',
                  left: '0',
                  right: '0',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100vh',
                  color: '#fff',
                  fontSize: '15px'
                }}
              >
                <CircularProgressWithLabel
                  value={viewBookData.loadingPopup.percentCompleted}
                />

                <br />
                {viewBookData.loadingPopup.message}
              </div>
            )}

            <div className='leftActions'>
              <div
                className='itemAction'
                style={{ left: '-130px', top: '39%' }}
                onClick={() => {
                  setPopupInfoData(null)
                  setTimeout(() => {
                    setPopupInfoData({
                      showPopup: true,
                      type: type,
                      Item: viewBookData.Item,
                      Item2: viewBookData.Item2
                    })
                  }, 100)
                }}
              >
                <img src={require('../assets/images/Group 9104.png')} alt='' />
                معلومات الوثيقة
              </div>
              <div
                className='itemAction'
                style={{ left: '-137px', top: '46%' }}
                onClick={() => {
                  if (checkToken()) {
                    addToLibrary()
                  } else
                    setShowPopupMethod({
                      showPopup: true,
                      titlePopup: 'عزيزي المستخدم المجاني',
                      bodyPopup: 'لديك فقط الحق في معاينة بعض الوظائف.',
                      btnobj: {
                        label: 'اشترك الآن',
                        show: true,
                        methodbtn: ''
                      }
                    })
                }}
              >
                <img src={require('../assets/images/Group 9108.png')} alt='' />
                اضافة الى مكتبتي
              </div>
              {typeOfContent === "book" && <div
                className='itemAction'
                style={{ left: '-60px', top: '53%' }}
                onClick={() => {
                  setPopupSeasonsData(null)
                  setTimeout(() => {
                  setPopupSeasonsData({
                      showPopup: true,
                      Bookmarks: Bookmarks
                    })
                  }, 100)
                }}
              >
                <img src={require('../assets/images/Group 9106.png')} alt='' />
                الفصول
              </div>}
              <Print>
                {props => (
                  <div
                    className='itemAction'
                    style={{ left: '-50px', top: '60%' }}
                    onClick={async () => {
                      if (checkToken()) {
                        if (
                          JSON.parse(getCookie('objectUser'))?.hiddenContent
                            ?.pdfReader?.print && yearValueForBook <= yearTo && yearValueForBook >= yearFrom
                        ) {
                          if (viewBookData.Item.s3obj) openModal2()
                          else openModal2()
                        } else
                          setShowPopupMethod({
                            showPopup: true,
                            titlePopup: 'عزيزي المستخدم',
                            bodyPopup: 'لديك فقط الحق في معاينة بعض الوظائف.',
                            btnobj: {
                              label: 'اشترك الآن',
                              show: false,
                              methodbtn: ''
                            }
                          })
                      } else
                        setShowPopupMethod({
                          showPopup: true,
                          titlePopup: 'عزيزي المستخدم المجاني',
                          bodyPopup: 'لديك فقط الحق في معاينة بعض الوظائف.',
                          btnobj: {
                            label: 'اشترك الآن',
                            show: true,
                            methodbtn: ''
                          }
                        })
                    }}
                  >
                    <img
                      src={require('../assets/images/Group 9105.png')}
                      alt=''
                    />
                    طباعة
                  </div>
                )}
              </Print>

              <div
                className='itemAction'
                style={{ left: '-50px', top: '67%' }}
                onClick={() =>
                  checkToken()
                    ? JSON.parse(getCookie('objectUser'))?.hiddenContent
                      ?.pdfReader?.download && yearValueForBook <= yearTo && yearValueForBook >= yearFrom
                      ? openModal()
                      : setShowPopupMethod({
                        showPopup: true,
                        titlePopup: 'عزيزي المستخدم',
                        bodyPopup: 'لديك فقط الحق في معاينة بعض الوظائف.',
                        btnobj: {
                          label: 'اشترك الآن',
                          show: false,
                          methodbtn: ''
                        }
                      })
                    : setShowPopupMethod({
                      showPopup: true,
                      titlePopup: 'عزيزي المستخدم المجاني',
                      bodyPopup: 'لديك فقط الحق في معاينة بعض الوظائف.',
                      btnobj: {
                        label: 'اشترك الآن',
                        show: true,
                        methodbtn: ''
                      }
                    })
                }
              >
                <img src={require('../assets/images/Group 9107.png')} alt='' />
                تحميل
              </div>

              <div
                className='itemAction'
                style={{ left: '-65px', top: '74%' }}
                onClick={() => {
                  setPopupShareData(null)
                  setTimeout(() => {
                  setPopupShareData({
                      showPopup: true,
                      Item: viewBookData.Item,
                      id: id,
                      type: type,
                      loginStatus: checkToken()
                    })
                  }, 100)
                }}
              >
                <img src={require('../assets/images/Group 9109.png')} alt='' />
                مشاركة
              </div>

              <div
                className='itemAction'
                style={{ left: '-154px', top: '81%' }}
                onClick={() =>
                  checkToken()
                    ? JSON.parse(getCookie('objectUser'))?.hiddenContent
                      ?.pdfReader?.bookmark
                      ? addbookmark()
                      : setShowPopupMethod({
                        showPopup: true,
                        titlePopup: 'عزيزي المستخدم',
                        bodyPopup: 'لديك فقط الحق في معاينة بعض الوظائف.',
                        btnobj: {
                          label: 'اشترك الآن',
                          show: false,
                          methodbtn: ''
                        }
                      })
                    : setShowPopupMethod({
                      showPopup: true,
                      titlePopup: 'عزيزي المستخدم المجاني',
                      bodyPopup: 'لديك فقط الحق في معاينة بعض الوظائف.',
                      btnobj: {
                        label: 'اشترك الآن',
                        show: true,
                        methodbtn: ''
                      }
                    })
                }
              >
                <img src={require('../assets/images/Path 9591.png')} alt='' />
                {viewBookData.stateBookmark == 1 ? 'إزالة' : 'إضافة'} علامة مرجعية
              </div>

              <div
                className='itemAction'
                style={{ left: '-50px', top: '88%' }}
                onClick={() => {
                  if (checkToken()) {
                    if (
                      JSON.parse(getCookie('objectUser'))?.hiddenContent
                        ?.pdfReader?.highlight
                    ) {
                      setPopupNotesData(null)
                      setTimeout(() => {
                        setPopupNotesData({
                          showPopup: true,
                          jumpToHighlightArea: jumpToHighlightArea,
                          notes: viewBookData.notes
                        })
                      }, 100)
                    } else
                      setShowPopupMethod({
                        showPopup: true,
                        titlePopup: 'عزيزي المستخدم',
                        bodyPopup: 'لديك فقط الحق في معاينة بعض الوظائف.',
                        btnobj: {
                          label: 'اشترك الآن',
                          show: false,
                          methodbtn: ''
                        }
                      })
                  } else
                    setShowPopupMethod({
                      showPopup: true,
                      titlePopup: 'عزيزي المستخدم المجاني',
                      bodyPopup: 'لديك فقط الحق في معاينة بعض الوظائف.',
                      btnobj: {
                        label: 'اشترك الآن',
                        show: true,
                        methodbtn: ''
                      }
                    })
                }}
              >
                <img src={require('../assets/images/Group 8962.png')} alt='' />
                تظليل
              </div>

              <div
                className='itemAction'
                style={{ left: '-68px', top: '95%' }}
                onClick={
                  () => addquotation()

                }
              >
                <img src={require('../assets/images/Group 8965.png')} alt='' />
                اقتباس
              </div>
            </div>
          </FullScreen>
        </div>
      </Worker>
    </div>
  )
}
