import React, { useEffect, useState } from 'react'
import Stepwizard from './Stepwizard'
const plans = [
  {
    _id: 1,
    name: '3 أشهر',
    duration: 3,
    tax: 0.5,
    img: require('../../../assets/images/Group 9514.png'),
    text: 'أشهر',
    price: 8,
    selected: false,
    viewsPercentage: 100,
    downloadsPercentage: 10,
    printPercentage: 10,
    description: 'الوصول الكامل لبوابة الكتاب العلمي تدفع كل 3 أشهر'
  },
  {
    _id: 2,
    name: '9 أشهر',
    duration: 9,
    img: require('../../../assets/images/Group 9523.png'),
    tax: 0.5,
    basicPrice: 8,
    text: 'أشهر',
    price: 6,
    selected: true,
    viewsPercentage: 100,
    downloadsPercentage: 20,
    printPercentage: 20,
    description: 'الوصول الكامل لبوابة الكتاب العلمي تدفع كل 9 أشهر'
  },
  {
    _id: 3,
    name: '12 شهر',
    duration: 12,
    img: require('../../../assets/images/Group 9533.png'),
    tax: 0.5,

    text: 'شهر',
    price: 5,
    selected: false,
    viewsPercentage: 100,
    downloadsPercentage: 50,
    printPercentage: 50,
    description: 'الوصول الكامل لبوابة الكتاب العلمي تدفع سنوياً'
  }
]
export default function Summary (props) {
  const [planData, setPlanData] = useState({})
  const sendData = (id, id2) => {
    props.setStep(id)
  }

  useEffect(() => {
    let planId = parseInt(localStorage.getItem('plan'))
    let plan = plans.find(p => p._id === planId)
    // console.log(plan)
    setPlanData(plan)
  }, [])
  return (
    <div className='stepOne'>
      <div className='containerReg1'>
        <div className='regsteps'>
          <Stepwizard step={2} />
        </div>
        <div className='sectionTitle'>يرجى التأكد من طلبك والموافقة عليه</div>
        <div className='Summary'>
          <div className='titleSummary'>مخلص الطلب</div>
          <div className='summaryTable'>
            <div className='summaryItem'>
              <div className='summaryItemTitle'>المنتج</div>
              <div className='summaryItemValue'>
                الوصول الكامل لبوابة الكتاب العلمي
              </div>
            </div>
            <div className='summaryItem'>
              <div className='summaryItemTitle'>المدة</div>
              <div className='summaryItemValue'>
                {planData.duration} {planData.text} <span> </span>
                {/* 25/12/20222 */}
                <span> من </span>
                {new Date().toLocaleDateString('ar-EG', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric'
                })}
                <span> الى </span>
                {new Date(
                  new Date().setMonth(new Date().getMonth() + planData.duration)
                ).toLocaleDateString('ar-EG', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric'
                })}
              </div>
            </div>

            <div className='summaryItem'>
              <div className='summaryItemTitle'>السعر</div>
              <div className='summaryItemValue'>
                $
                {planData.price * planData.duration * (1 + planData.tax) - 0.01}
              </div>
            </div>

            <div className='summaryItem'>
              <div className='summaryItemTitle'>رمز الخصم</div>
              <div className='summaryItemValue'>
                <input type='text' placeholder='أدخل رمز الخصم' />
              </div>
            </div>

            {/* total summation */}
            <div className='summaryItem total'>
              <div className='summaryItemTitle'>المجموع</div>
              <div className='summaryItemValue'>$
                {planData.price * planData.duration * (1 + planData.tax) - 0.01}</div>
            </div>
          </div>

          <div className='summaryBtns'>
            <button className='' onClick={() => sendData(3, 1)}>
              تأكيد
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
