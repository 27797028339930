import React from "react";
import Slider from "infinite-react-carousel";
import {
  isMobile
} from "react-device-detect";
import { Link } from "react-router-dom";

export default function Header(props) {
  React.useEffect(() => { }, []);
  const settings = {
    arrows: false,
    arrowsBlock: false,
    arrowsScroll: 3,
    dots: true,
    duration: 400,
    shift: 50,
    autoplaySpeed: 4000,
    autoplay: true
  };
  return (
    <div className="slider" style={{ marginTop: isMobile ? "10vw" : "4vw" }}>
      <Slider {...settings}>
        {props.images.map((i, index) =>
          <div className="Slide" key={index}>
            <div className={"title2" + i.postion}>
              {i.t1}
            </div>
            <div className={"details" + i.postion}>
              {i.t2}
            </div>
            <div className={"btnall" + i.postion}>
              <Link className="btn" to={"/browse"}>
                تصفح الآن
              </Link>
            </div>
            <img src={i.img} alt="img" />
          </div>
        )}
      </Slider>
    </div>
  );
}
