import React, { useEffect, useState } from "react";

export default function PopupSeasons(props) {
  const [showPopup, setShowPopup] = useState(false);
  const Bookmarks = props.objPopup.Bookmarks;
  useEffect(() => {
    setShowPopup(props.objPopup.showPopup);
  }, []);
  return (
    <div
      className="BookmarksBG"
      style={{
        visibility: showPopup ? "visible" : "hidden",
        opacity: showPopup ? "1" : "0",
        transition: "opacity 0.4s"
      }}
    >
      <div onClick={() => setShowPopup(false)} className="bgwithout" />
      <div className="Bookmarks">
        <div className="titleBookmatks">
          الفصول
          <div className="closeBox" onClick={() => setShowPopup(false)}>
            {" "}✖{" "}
          </div>
        </div>
        <div className="bodyBookMark" onClick={() => setShowPopup(false)}>
          <Bookmarks />
        </div>
      </div>
    </div>
  );
}
