import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    scrollTop: 0,
    show: false,
    show2: false,
    errorShow: false,
    errorMsg: "",
    email: "",
    password: "",
    flag: "",
    loading: false,
    menuMobile: false,
    logoutDevisePopup: false,
    logoutDevisePopup2: false,
    loginStatus: false,
    popupData: null,
    parentAccount: {},
    deviceList: null,
}

const headerSlice = createSlice({
    name: "header",
    initialState,
    reducers: {
        setScrollTop: (state, actions) => {
            state.scrollTop = actions.payload
        },
        setShow: (state, actions) => {
            state.show = actions.payload;
        },
        setShow2: (state, actions) => {
            state.show2 = actions.payload
        },
        setErrorShow: (state, actions) => {
            state.errorShow = actions.payload
        },
        setErrorMsg: (state, actions) => {
            state.errorMsg = actions.payload
        },
        setLoading: (state, actions) => {
            state.loading = actions.payload
        },
        setMenuMobile: (state, actions) => {
            state.menuMobile = actions.payload
        },
        setPopupData: (state, actions) => {
            state.popupData = actions.payload
        },
        setParentAccount: (state, actions) => {
            state.parentAccount = actions.payload
        },
        setEmail: (state, actions) => {
            state.email = actions.payload
        },
        setLogoutDevisePopup: (state, actions) => {
            state.logoutDevisePopup = actions.payload
        },
        setLogoutDevisePopup2: (state, actions) => {
            state.logoutDevisePopup2 = actions.payload
        },
        setDeviceList: (state, actions) => {
            state.deviceList = actions.payload
        },
        setPassword: (state, actions) => {
            state.password = actions.payload
        },
        setFlag: (state, actions) => {
            state.flag = actions.payload
        },
        setLoginStatus: (state, actions) => {
            state.loginStatus = actions.payload
        }
    }
})


export const headerState = (state) => state.header;
export const {
    setScrollTop,
    setShow,
    setShow2,
    setErrorShow,
    setErrorMsg,
    setLoading,
    setMenuMobile,
    setPopupData,
    setParentAccount,
    setEmail,
    setLogoutDevisePopup,
    setLogoutDevisePopup2,
    setDeviceList,
    setPassword,
    setFlag,
    setLoginStatus
} = headerSlice.actions;

export default headerSlice.reducer