import React, { useEffect } from 'react'
import Stepwizard from './Stepwizard'
const plans = [
  {
    _id: 1,
    name: '3 أشهر',
    duration: 3,
    tax: 0.5,
    img: require('../../../assets/images/Group 9514.png'),
    text: 'أشهر',
    price: 8,
    selected: false,
    viewsPercentage: 100,
    downloadsPercentage: 10,
    printPercentage: 10,
    description: 'الوصول الكامل لبوابة الكتاب العلمي تدفع كل 3 أشهر'
  },
  {
    _id: 2,
    name: '9 أشهر',
    duration: 9,
    img: require('../../../assets/images/Group 9523.png'),
    tax: 0.5,
    basicPrice: 8,
    text: 'أشهر',
    price: 6,
    selected: true,
    viewsPercentage: 100,
    downloadsPercentage: 20,
    printPercentage: 20,
    description: 'الوصول الكامل لبوابة الكتاب العلمي تدفع كل 9 أشهر'
  },
  {
    _id: 3,
    name: '12 شهر',
    duration: 12,
    img: require('../../../assets/images/Group 9533.png'),
    tax: 0.5,

    text: 'شهر',
    price: 5,
    selected: false,
    viewsPercentage: 100,
    downloadsPercentage: 50,
    printPercentage: 50,
    description: 'الوصول الكامل لبوابة الكتاب العلمي تدفع سنوياً'
  }
]
export default function Subscriptions (props) {
  useEffect(() => {}, [])
  const sendData = (id, id2) => {
    localStorage.setItem('plan', id2)
    props.setStep(id)
  }
  return (
    <div className='stepOne'>
      <div className='containerReg1'>
        <div className='regsteps'>
          <Stepwizard step={1} />
        </div>
        <div className='sectionTitle'>اختر الخطة المناسبة لك</div>
        <div className='plans'>
          {plans.map((plan, index) => (
            <div className={plan?.selected ? 'plan slected' : 'plan'}>
              <div className='planImg'>
                <div className='numberMonth'>
                  {plan.duration} {plan.text}
                </div>
                {/* <div className='textMonth'>{plan.text}</div> */}

                <img src={plan?.img} />

                <div className='textMonth'>ابتداء من</div>
              </div>
              <div className='planPrice'>
                <div className='planPriceTitle'>
                  {plan?.price} <sup>$</sup>
                  <span>/ شهر</span>
                </div>
              </div>

              <div className='planDesc'>{plan?.description}</div>
              <div className='planBtn'>
                <button
                  className='btn btn-primary'
                  onClick={() => sendData(2, plan._id)}
                >
                  اختر المدة
                </button>
              </div>
              {/* <div className='planItemsTitle'>
              الوصول الكامل لبوابة الكتاب العلمي<br/>  تدفع كل
              <b> {plan.duration} أشهر</b>
              </div> */}
              {/* <div className='planItem'>
                نسبة العرض <span>{plan.viewsPercentage}</span>
                <span>%</span>
              </div>
              <div className='planItem'>
                نسبة التحميل <span>{plan?.downloadsPercentage}</span>
                <span>%</span>
              </div>

              <div className='planItem'>
                نسبة الطباعة <span>{plan?.printPercentage}</span>
                <span>%</span>
              </div> */}
            </div>
          ))}
        </div>
        {/* <div className="colseAnytime">يمكنك إلغاء الاشتراك في أي وقت</div> */}
      </div>
    </div>
  )
}
