import React, { useEffect, useState } from 'react';

import Carousel from 'react-elastic-carousel';
import axios from "axios";
import Fade from 'react-reveal/Fade';
import {
    isMobile
} from "react-device-detect";
import { useHistory } from 'react-router-dom';
export default function Search(props) {
    let history = useHistory();

    const [currentItem, setCurrentItem] = useState(0)

    const [data, setData] = useState([])

    useEffect(() => {
        axios.post(process.env.REACT_APP_BACKEND_URL + '/clients/list',
        ).then(response2 => {
            setData(response2.data.result)
        })
    }, [currentItem]);
    function onCurrentChange(current) {
        setCurrentItem(current.index)
    }
    return (
        <div className="clients" id='clients'>

            <Fade bottom>
                <div className="titleSec">عملاؤنا</div>
            </Fade>

            <div className="clientsList">

                <Carousel focusOnSelect={false}
                    itemsToShow={isMobile ? 1 : 4}
                    pagination={false}
                    outerSpacing={10}
                    itemPosition={"flex-start"}
                    showEmptySlots={true}
                    onNextEnd={onCurrentChange}
                    onPrevEnd={onCurrentChange}
                    enableAutoPlay autoPlaySpeed={2500}
                    isRTL={true}
                >
                    {data.length != 0 ?
                        data.map((i, index) =>

                            <Fade bottom>
                                <div key={i._id} className="item">
                                    {i.url != null && i.url != 'null' ?
                                        <a href={i.url} target="_block"> <img src={process.env.REACT_APP_BACKEND_URL + '/public/' + i.cover} alt={i.clientName} /></a>
                                        :
                                        <img src={process.env.REACT_APP_BACKEND_URL + '/public/' + i.cover} alt={i.clientName} />
                                    }

                                </div>
                            </Fade>
                        )
                        : <div></div>
                    }



                </Carousel>


            </div>

            <Fade bottom>
                <div className="btn" onClick={() =>
                    history.push(
                        "/" + "clients"
                    )}>عرض المزيد</div>

            </Fade>
        </div>
    );
}