import React from 'react';
import { Link } from "react-router-dom";


export default function Breadcrumbs(props) {

    let lang = require('./../../lang/ar').default;

    React.useEffect(() => {

    }, []);

    return (
        <div className="breadcrumbs">
            <div className="breadcrumbsSub">
                <Link className="home" to="/">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.347" viewBox="0 0 16 15.347">
                        <path id="home-2" d="M724.924,667.276l-6.422-6.423a1.579,1.579,0,0,0-2.232,0l-6.422,6.423a1.577,1.577,0,0,0-.462,1.115v7.347h4.343a.657.657,0,0,0,.657-.658V670.4a.657.657,0,0,1,.657-.657h4.686a.657.657,0,0,1,.657.657v4.685a.657.657,0,0,0,.657.658h4.343v-7.347A1.577,1.577,0,0,0,724.924,667.276Z" transform="translate(-709.386 -660.391)" fill="#33384a" fillRule="evenodd" />
                    </svg>
                    {lang.breadcrumbs.home}
                </Link>


                {props.data.map((item, index) => item.link === '' ?
                    <div className="sub" key={index}><div className="coma">/</div>{item.title.length > 80 ? item.title.substring(0, 80) + '...' : item.title}</div>
                    :
                    <Link to={item.link} className="sub" key={index}><div className="coma">/</div>{item.title}</Link>
                )}
            </div>
        </div>
    );
}