import React, { useEffect, useRef, useState } from 'react';
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";

import Topsection from '../components/sharing/topsection';
import Breadcrumb from '../components/sharing/breadcrumbs';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Skeleton } from '@material-ui/lab';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core';

import InfiniteScroll from "react-infinite-scroll-component";

function Browse() {
    const { id, type, name } = useParams();
    let history = useHistory();
    const [activeTab, setActiveTab] = useState(1)
    const [items, setItems] = useState(null)
    const [loadingPage, setLoadingPage] = useState(false)

    const [countQuery, setCountQuery] = useState(0)
    const [countItems, setCountItems] = useState(0)

    const [sortby, setSortby] = useState('yearsValue')

    const [checkedValues, setCheckedValues] = useState([])
    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth' })
    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 12,
            fontFamily: 'ArbFONTS-DroidKufi-bold'
        },
    }))(Tooltip);


    useEffect(() => {
        if (type === 'titleDawreaa')
            setActiveTab(3)
        if (type === 'nameMoatamer')
            setActiveTab(4)
        setLoadingPage(true)
        var crypto = require('crypto');
        let hash = crypto.createHash('sha256').update(JSON.stringify(formatDate())).digest('hex');

        let formData = new FormData();
        formData.append('hashTime', hash);
        formData.append('activeTab', type === 'titleDawreaa' ? 3 : type === 'nameMoatamer' ? 4 : activeTab);
        formData.append('lang', 'ar');
        formData.append('sortby', sortby);
        formData.append('viewby', id);
        formData.append('viewType', type === 'tags' ? 'tagsValue' : type === 'years' ? 'yearsValue' : type);
        formData.append('collectionName', activeTab === 1 ? 'books' : activeTab === 2 ? 'theses' : activeTab === 3 ? 'journals' : 'researches');
        axios.post(process.env.REACT_APP_BACKEND_URL + '/app/counterBy', formData,
        ).then(response2 => {
            setCountItems(response2.data.count)
        })


        let beURL = type === 'authorValue' || type === 'subjectsValue' ? '/app/browseBy/get2' : '/app/browseBy/get';
        axios.post(process.env.REACT_APP_BACKEND_URL + beURL, formData,
        ).then(response2 => {
            if (response2.data.booleanVaule) {
                setItems(response2.data.result)
                setCountItems(response2.data.count)
                setLoadingPage(false)
                executeScroll()
            }
        })
    }, []);



    function formatDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
    function setActiveTabMethod(idTab) {
        setActiveTab(idTab);
        localStorage.setItem('ActiveTab', idTab)

        setLoadingPage(true)
        var crypto = require('crypto');
        let hash = crypto.createHash('sha256').update(JSON.stringify(formatDate())).digest('hex');

        let formData = new FormData();
        formData.append('hashTime', hash);
        formData.append('activeTab', idTab);
        formData.append('lang', 'ar');
        formData.append('sortby', sortby);
        formData.append('viewby', id);
        formData.append('viewType', type === 'tags' ? 'tagsValue' : type === 'years' ? 'yearsValue' : type);

        let beURL = type === 'authorValue' || type === 'subjectsValue' ? '/app/browseBy/get2' : '/app/browseBy/get';
        axios.post(process.env.REACT_APP_BACKEND_URL + beURL, formData,
        ).then(response2 => {
            if (response2.data.booleanVaule) {
                setItems(response2.data.result)
                setCountItems(response2.data.count)

                setLoadingPage(false)
            }
        })
    }
    const breadCurmbData = [
        {
            title: "تصفح",
            link: '/browse',
        },
        {
            title: type === 'tags' ? 'كلمة مفتاحية' : type === 'years' ? 'سنة النشر' : type === 'publisherValue' ? 'الناشر' : type === 'authorValue' ? 'المؤلف' : type === 'subjectsValue' ? 'الموضوع' : type === 'degree' ? 'نوع البرنامج' : type === 'titleDawreaa' ? 'المجلة' : type === 'nameMoatamer' ? 'المؤتمرات' : '',
            link: '',
        },
    ];



    const sortResult = (v) => {
        if (v !== undefined)
            setSortby(v);
        var crypto = require('crypto');
        let hash = crypto.createHash('sha256').update(JSON.stringify(formatDate())).digest('hex');
        let formData = new FormData();
        formData.append('hashTime', hash);
        formData.append('activeTab', activeTab);
        if (v !== undefined)
            formData.append('sortby', v);
        else
            formData.append('sortby', sortby);
        formData.append('lang', 'ar');
        formData.append('countQuery', (countQuery + 1));

        if (checkedValues.length !== 0) {
            formData.append('checkedValues', JSON.stringify(checkedValues));
            axios.post(process.env.REACT_APP_BACKEND_URL + '/app/filters/bysubjects', formData,
            ).then(response2 => {
                setItems(response2.data.result)

                setLoadingPage(false)
                executeScroll()
            })
        }
        else {
            formData.append('viewby', id);
            formData.append('viewType', type === 'tags' ? 'tagsValue' : type === 'years' ? 'yearsValue' : type);
            let beURL = type === 'authorValue' || type === 'subjectsValue' ? '/app/browseBy/get2' : '/app/browseBy/get';
            axios.post(process.env.REACT_APP_BACKEND_URL + beURL, formData,
            ).then(response2 => {
                if (response2.data.booleanVaule) {
                    setItems([...items, ...response2.data.result])
                    setCountItems(response2.data.count)

                    setCountQuery(countQuery + 1)
                }
            })
        }
    }


    return (
        <div ref={myRef}>
            <Topsection data={{ text: 'تصفح', img: require('../assets/images/33.png') }} />
            <Breadcrumb data={breadCurmbData} />
            <div className="loadingData" style={{
                visibility: loadingPage ? 'visible' : 'hidden',
                opacity: loadingPage ? 1 : 0,
                transition: 'visibility 0s, opacity 0.3s linear'

            }}>
                <img src={require('../assets/images/bookLoading.gif')} />
                <div className="textLoading">جاري التحميل...</div>
            </div>
            <div className="browse">

                <div className="tabsTop" >
                    <div className={activeTab === 1 ? "tabtop active1" : "tabtop"} onClick={() => setActiveTabMethod(1)}>كتب</div>
                    <div className={activeTab === 2 ? "tabtop active2" : "tabtop"} onClick={() => setActiveTabMethod(2)}>رسائل</div>
                    <div className={activeTab === 3 ? "tabtop active3" : "tabtop"} onClick={() => setActiveTabMethod(3)}>مجلات</div>
                    <div className={activeTab === 4 ? "tabtop active4" : "tabtop"} onClick={() => setActiveTabMethod(4)}>أبحاث</div>
                </div>
                <div className="reslutlist">
                    <div className="topSec">
                        <div className="titleSec">
                            {type === 'tags' ? 'كلمة مفتاحية' : type === 'years' ? 'سنة النشر' : type === 'publisherValue' ? 'الناشر' : type === 'authorValue' ? 'المؤلف' : type === 'subjectsValue' ? 'الموضوع' : type === 'degree' ? 'نوع البرنامج' : type === 'titleDawreaa' ? 'المجلة' : type === 'nameMoatamer' ? 'المؤتمر' : ''}:&nbsp;
                            {name}</div>

                        <div className="numberofitem" style={{ marginRight: '0' }}>
                            <div className="itemsCount">
                                {items !== null ? countItems > 0 ? countItems : items.length : 0} نتيجة
                            </div>

                        </div>

                    </div>


                    <div className="bodyResult">



                        <div className="results width-100">

                            {
                                items === null ?
                                    ['', '', ''].map(item =>
                                        <div className="itemResult">
                                            <div className='imgandRead'>

                                                <Skeleton variant="rect" width={210} height={300} animation="wave" style={{ borderRadius: '10px' }} />


                                            </div>
                                            <div className="centerSection">
                                                <div className="rowInSec titleCountent boldFont">
                                                    <Skeleton variant="text" width={280} height={60} style={{ borderRadius: '5px' }} />
                                                </div>



                                                <div className="rowInSec w-60">
                                                    <Skeleton variant="text" width={210} height={40} style={{ borderRadius: '5px' }} />
                                                </div>


                                                <div className="rowInSec w-60">
                                                    <Skeleton variant="text" width={210} height={40} style={{ borderRadius: '5px' }} />
                                                </div>



                                                <div className="rowInSec  w-60">
                                                    <div>
                                                        <Skeleton variant="text" width={250} height={40} style={{ borderRadius: '5px' }} />
                                                    </div>
                                                </div>

                                                <div className="rowInSec  w-60">
                                                    <Skeleton variant="text" width={260} height={40} style={{ borderRadius: '5px' }} />

                                                </div>

                                                <div className="rowInSec">
                                                    <div className="tags">
                                                        <Skeleton variant="text" width={80} height={50} style={{ borderRadius: '5px' }} />
                                                        <Skeleton variant="text" width={80} height={50} style={{ borderRadius: '5px', margin: '0 1vw' }} />
                                                        <Skeleton variant="text" width={80} height={50} style={{ borderRadius: '5px', margin: '0 1vw' }} />
                                                        <Skeleton variant="text" width={80} height={50} style={{ borderRadius: '5px', margin: '0 1vw' }} />
                                                        <Skeleton variant="text" width={80} height={50} style={{ borderRadius: '5px', margin: '0 1vw' }} />
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="leftSec">
                                                <Skeleton variant="text" width={80} height={60} style={{ borderRadius: '5px' }} />
                                                <Skeleton variant="circle" width={50} height={50} style={{ marginBottom: '5px' }} />
                                                <Skeleton variant="circle" width={50} height={50} style={{ marginBottom: '5px' }} />
                                                <Skeleton variant="circle" width={50} height={50} style={{ marginBottom: '5px' }} />

                                            </div>
                                        </div>
                                    )
                                    :
                                    <InfiniteScroll
                                        dataLength={items.length}
                                        next={countItems !== items.length || countItems > 10 ? sortResult : ''}
                                        hasMore={true}
                                        loader={countItems === items.length || countItems < 10 ? <h4 style={{ textAlign: 'center' }}>نهاية النتائج</h4> : <h4>جاري التحميل... </h4>}
                                    >
                                        {
                                            items.map((item, index) =>
                                                <div className="itemResult" key={index}>
                                                    <div className='imgandRead'>
                                                        {item !== null ?
                                                            item.s3objcover !== undefined && item.s3objcover !== '' ?
                                                                <LazyLoadImage
                                                                    src={item !== null ?

                                                                        item.s3objcover !== undefined ?
                                                                            item.s3objcover

                                                                            : '' : ''}
                                                                    key={index}
                                                                    effect="blur"
                                                                    onClick={() => history.push("/view/" + item._id.split('_')[1] + '/' + item._id.split('_')[0])}
                                                                    style={{ cursor: 'pointer' }}
                                                                />
                                                                :
                                                                <LazyLoadImage
                                                                    src={'https://apis.thelearnbook.com/public/learnBookCover.jpg'}
                                                                    key={index}
                                                                    effect="blur"
                                                                    onClick={() => history.push("/view/" + item._id.split('_')[1] + '/' + item._id.split('_')[0])}
                                                                    style={{ cursor: 'pointer' }}
                                                                />
                                                            :
                                                            ''
                                                        }


                                                        <div className="btnRead" onClick={() => history.push("/ViewBook/" + item._id.split('_')[1] + '/' + item._id.split('_')[0])}>اقرأ الوثيقة</div>
                                                    </div>
                                                    <div className="centerSection">
                                                        <div className="rowInSec titleCountent boldFont"
                                                            onClick={() => history.push("/view/" + item._id.split('_')[1] + '/' + item._id.split('_')[0])}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            {item.nameAr}
                                                        </div>

                                                        {item.authorValueNew !== '' ?
                                                            <div className="rowInSec w-60" key={index}>
                                                                <div>
                                                                    مؤلف:&nbsp;
                                                                    {item.authorValueNew !== null && item.authorValueNew !== undefined ? item.authorValueNew.map((i, index) =>
                                                                        item.authorValueNew.length - 1 !== index ?
                                                                            <><a onClick={() => history.push("/browseBy/" + i.name._id + "/" + 'authorValue' + "/" + i.name.nameAr)}>{i.name.nameAr}</a><b>،</b> </>
                                                                            :
                                                                            <><a onClick={() => history.push("/browseBy/" + i.name._id + "/" + 'authorValue' + "/" + i.name.nameAr)}>{i.name.nameAr}</a> </>
                                                                    ) : ''}
                                                                </div>
                                                            </div>
                                                            : ''}

                                                        {item.publishersOBJ !== undefined || item.publishersOBJ.length !== 0 ?

                                                            <div className="rowInSec w-60">
                                                                <div>الناشر:&nbsp;
                                                                    {item.publishersOBJ.map((item333, index) =>
                                                                        <a onClick={() => history.push("/browseBy/" + item333._id + "/" + 'publisherValue' + "/" + item333.nameAr)}>{item.publishersOBJ.length !== 0 ? item333.nameAr : ''}

                                                                            {(index + 1) < item.publishersOBJ.length ? '، ' : ' '}
                                                                        </a>


                                                                    )}

                                                                </div>
                                                            </div>
                                                            : ''
                                                        }


                                                        {item.yearsValue !== 'null' ?
                                                            <div className="rowInSec w-60">
                                                                <div>تاريخ النشر:&nbsp;<a onClick={() => history.push("/browseBy/" + item.yearsValue + "/" + 'years' + "/" + item.yearsValue)}> {item.yearsValue} </a></div>
                                                            </div>
                                                            : ''
                                                        }
                                                        {item !== null && item.degree !== 'null' && item.degree !== null && item.type !== 'book' ?
                                                            <div className="rowInSec w-60">
                                                                <div>نوع البرنامج:&nbsp;<a onClick={() => history.push("/browseBy/" + item.degree + "/" + 'degree' + "/" + item.degree)}> {item.degree} </a></div>
                                                            </div>
                                                            : ''
                                                        }

                                                        {item.hhh !== undefined ? item.hhh.length > 0 ? <div className="rowInSec  w-60">
                                                            <div>المواضيع الرئيسية:&nbsp;
                                                                {item.hhh.map((i, index) =>

                                                                    item.hhh.length - 1 !== index ?
                                                                        <span key={index}>
                                                                            <a onClick={() => history.push("/browseBy/" + i.subject._id + "/" + 'subjectsValue' + "/" + i.subject.nameAr)}>{i.subject.nameAr} | {i.subSubject[0].NameAR}</a> ,
                                                                        </span>
                                                                        :
                                                                        <span key={index}>
                                                                            <a onClick={() => history.push("/browseBy/" + i.subject._id + "/" + 'subjectsValue' + "/" + i.subject.nameAr)}>{i.subject.nameAr} | {i.subSubject[0].NameAR}</a>
                                                                        </span>
                                                                )}
                                                            </div>
                                                        </div> : '' : ''}

                                                        {
                                                            item.ISBNValue !== 'null' ?

                                                                <div className="rowInSec  w-60">
                                                                    {
                                                                        item.ISBNValue !== '' ?
                                                                            <div style={{ display: 'flex', flexDirection: 'row' }}>{activeTab === 3 ? 'ISSN' : 'ISBN'}: &nbsp;<div>  {item.ISBNValue}  </div></div>
                                                                            : ''
                                                                    }
                                                                </div>
                                                                : ''
                                                        }
                                                        {item.tagsValue.length > 0 ?
                                                            <div className="rowInSec">
                                                                <div className="tags">كلمات مفتاحية:
                                                                    {item.tagsValue.map((i, index) => <div key={i._id} className="tag" onClick={() => history.push("/browseBy/" + i._id + "/" + 'tags' + "/" + i.nameAr)}>{i.nameAr}</div>)}
                                                                </div>

                                                            </div>
                                                            : ''
                                                        }
                                                    </div>
                                                    <div className="leftSec">
                                                        {item.type === 'book' ?
                                                            <div className="btnBook">كتاب</div>
                                                            :
                                                            item.type === 'msg' ?
                                                                <div className="btnMag">رسالة</div>
                                                                :
                                                                item.type === 'journals' ?
                                                                    <div className="btnma">مقال مجلة</div>
                                                                    :
                                                                    <div className="btnma2">مقال مؤتمر </div>

                                                        }

                                                        <LightTooltip title="معلومات الوثيقة">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" onClick={() => history.push("/view/" + item._id.split('_')[1] + '/' + item._id.split('_')[0])}>
                                                                <g id="Group_8833" data-name="Group 8833" transform="translate(1 1.273)">
                                                                    <g id="Group_111" data-name="Group 111">
                                                                        <circle id="Ellipse_28" data-name="Ellipse 28" cx="13.5" cy="13.5" r="13.5" transform="translate(0 -0.273)" fill="none" stroke="#727c89" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                                    </g>
                                                                    <g id="Group_112" data-name="Group 112" transform="translate(10.216 6.652)">
                                                                        <ellipse id="Ellipse_29" data-name="Ellipse 29" cx="1.65" cy="1.553" rx="1.65" ry="1.553" transform="translate(2.665)" fill="#727c89" />
                                                                        <path id="Path_3385" data-name="Path 3385" d="M992.8,717.719c-.013.283.295,1.18,1.892.686a.115.115,0,0,1,.145.142l-.093.34a.38.38,0,0,1-.229.256,8.211,8.211,0,0,1-2.623.578,1.757,1.757,0,0,1-1.942-1.551c-.006-.055-.01-.11-.011-.165.2-1.776,1.108-4.757,1.119-5.313.006-.283-.294-1.179-1.891-.686a.115.115,0,0,1-.144-.078.11.11,0,0,1,0-.065l.093-.339a.38.38,0,0,1,.229-.256,8.207,8.207,0,0,1,2.623-.578,1.763,1.763,0,0,1,1.943,1.561c.005.051.009.1.01.155C993.922,714.138,992.857,716.538,992.8,717.719Z" transform="translate(-989.021 -706.092)" fill="#727c89" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </LightTooltip>









                                                        <LightTooltip title="تحميل">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="27.755" height="27.97" viewBox="0 0 27.755 27.97">
                                                                <g id="Group_8834" data-name="Group 8834" transform="translate(1 1)">
                                                                    <g id="arrow-right" transform="translate(8.246 0)">
                                                                        <line id="Line_3" data-name="Line 3" y1="13.43" transform="translate(4.632)" fill="none" stroke="#727c89" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                                        <path id="Path_3" data-name="Path 3" d="M1052.97,712.107l4.632,5.489,4.632-5.489Z" transform="translate(-1052.97 -699.267)" fill="#727c89" stroke="#727c89" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                                    </g>
                                                                    <path id="Path_9497" data-name="Path 9497" d="M1039.233,717.879v3.781a4.875,4.875,0,0,0,4.875,4.875h16a4.875,4.875,0,0,0,4.875-4.875v-3.781" transform="translate(-1039.233 -700.566)" fill="none" stroke="#727c89" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                                </g>
                                                            </svg>
                                                        </LightTooltip>



                                                        <LightTooltip title="مشاركة">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="28.701" height="28.664" viewBox="0 0 28.701 28.664">
                                                                <g id="Group_8832" data-name="Group 8832" transform="translate(1 1.026)">
                                                                    <path id="Path_9495" data-name="Path 9495" d="M937.98,693.32l-7.484-7.772a.863.863,0,0,0-1.485.6v3.742h-.288A11.239,11.239,0,0,0,917.5,701.115v1.727a.851.851,0,0,0,.672.825.832.832,0,0,0,.19.023.891.891,0,0,0,.789-.49,9.445,9.445,0,0,1,8.495-5.251h1.368v3.742a.863.863,0,0,0,1.485.6l7.484-7.772A.863.863,0,0,0,937.98,693.32Z" transform="translate(-911.622 -685.283)" fill="none" stroke="#727c89" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                                    <path id="Path_9496" data-name="Path 9496" d="M910.642,696.657H907.96a2.3,2.3,0,0,0-2.3,2.3v16.39a2.3,2.3,0,0,0,2.3,2.3h21a2.3,2.3,0,0,0,2.3-2.3v-7.2" transform="translate(-905.659 -691.012)" fill="none" stroke="#727c89" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                                </g>
                                                            </svg>
                                                        </LightTooltip>

                                                    </div>
                                                </div>
                                            )
                                        }
                                    </InfiniteScroll>
                            }









                        </div>
                    </div>
                </div>


            </div>
        </div >
    );
}

export default Browse;
