import React, { useEffect, useState } from "react";
export default function Popup1(props) {
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    setShowPopup(props.objPopup.showPopup);
  }, []);
  return (
    <div
      className="BookmarksBG"
      style={{
        visibility: showPopup ? "visible" : "hidden",
        opacity: showPopup ? "1" : "0",
        transition: "opacity 0.4s"
      }}
    >
      <div onClick={() => setShowPopup(false)} className="bgwithout" />
      <div className="Bookmarks">
        <div className="titleBookmatks">
          النصوص المظللة
          <div className="closeBox" onClick={() => setShowPopup(false)}>
            {" "}✖{" "}
          </div>
        </div>
        <div className="bodyBookMark" onClick={() => setShowPopup(false)}>
          {props.objPopup.notes.length === 0 &&
            <div style={{ textAlign: "center" }}>لا توجد ملاحظة</div>}
          {props.objPopup.notes.map(note => {
            return (
              <div
                key={note.id}
                style={{
                  borderBottom: "4px solid " + note.color,
                  cursor: "pointer",
                  marginBottom: "5px",
                  padding: "8px"

                }}
                // Jump to the associated highlight area
                onClick={() => props.objPopup.jumpToHighlightArea(note.highlightAreas[0])}
              >
                <div
                  style={{

                    fontSize: ".75rem",
                    lineHeight: 1.5,
                    margin: "0 0 8px 0",
                    paddingLeft: "8px",
                    textAlign: "justify"
                  }}
                >
                  {note.content}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
