import React, { useEffect, useState } from "react";
import { motion } from "framer-motion"

export default function Popup1(props) {
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    setShowPopup(props.objPopup.showPopup);
  }, []);
  return (
    <div
      className="BookmarksBG"
      style={{
        visibility: showPopup ? "visible" : "hidden",
        opacity: showPopup ? "1" : "0",
        transition: "opacity 0.4s"
      }}
    >
      <div onClick={() => setShowPopup(false)} className="bgwithout" />
      <motion.div
        animate={{ y:100 }}
        transition={{ ease: "easeOut", duration: 1 }}
      >
        <div className="Bookmarks">
          <div
            className={
              props.objPopup.titleClassPlus !== undefined
                ? `titleBookmatks ${props.objPopup.titleClassPlus}`
                : "titleBookmatks small"
            }
          >
            {props.objPopup.titlePopup}
            <div className="closeBox" onClick={() => setShowPopup(false)}>
              {" "}✖{" "}
            </div>
          </div>
          <div className="msg">
            {props.objPopup.bodyPopup}
          </div>
          <div
            className="btnSub"
            style={{
              display: props.objPopup.btnobj.showbtn ? "block" : "none"
            }}
          >
            {props.objPopup.btnobj.label}
          </div>
        </div>
      </motion.div>
    </div>
  );
}
