import React, { useEffect } from 'react'

export default function Stepwizard(props) {
  useEffect(() => { }, [])

  return (
    <div class='stepwizard'>
      <div class='stepwizard-row'>
        <div class='stepwizard-step'>
          <div
            className={
              props.step === 1
                ? 'cercTop active'
                : props.step > 1
                  ? 'cercTop done'
                  : 'cercTop'
            }
          ></div>
          <p className={props.step === 1 ? 'active' : ''}> الإشتراكات </p>
        </div>
        <div class='stepwizard-step'>
          <div
            className={
              props.step === 2
                ? 'cercTop active'
                : props.step > 2
                  ? 'cercTop done'
                  : 'cercTop'
            }
          ></div>{' '}
          <p className={props.step === 2 ? 'active' : ''}>الملخص</p>
        </div>
        <div class='stepwizard-step'>
          <div
            className={
              props.step === 3
                ? 'cercTop active'
                : props.step > 3
                  ? 'cercTop done'
                  : 'cercTop'
            }
          ></div>{' '}
          <p className={props.step === 3 ? 'active' : ''}>التسجيل</p>
        </div>
        <div class='stepwizard-step'>
          <div
            className={
              props.step === 4
                ? 'cercTop active'
                : props.step > 4
                  ? 'cercTop done'
                  : 'cercTop'
            }
          ></div>{' '}
          <p className={props.step === 4 ? 'active' : ''}>الدفع</p>
        </div>
      </div>
    </div>
  )
}
