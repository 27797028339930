import React, { useEffect, useState } from 'react'
import { PayPalButton } from 'react-paypal-button-v2'
import Stepwizard from './Stepwizard'
import axios from 'axios'
const plans = [
  {
    _id: 1,
    name: '3 أشهر',
    duration: 3,
    tax: 0.5,
    img: require('../../../assets/images/Group 9514.png'),
    text: 'أشهر',
    price: 8,
    selected: false,
    viewsPercentage: 100,
    downloadsPercentage: 10,
    printPercentage: 10,
    description: 'الوصول الكامل لبوابة الكتاب العلمي تدفع كل 3 أشهر'
  },
  {
    _id: 2,
    name: '9 أشهر',
    duration: 9,
    img: require('../../../assets/images/Group 9523.png'),
    tax: 0.5,
    basicPrice: 8,
    text: 'أشهر',
    price: 6,
    selected: true,
    viewsPercentage: 100,
    downloadsPercentage: 20,
    printPercentage: 20,
    description: 'الوصول الكامل لبوابة الكتاب العلمي تدفع كل 9 أشهر'
  },
  {
    _id: 3,
    name: '12 شهر',
    duration: 12,
    img: require('../../../assets/images/Group 9533.png'),
    tax: 0.5,

    text: 'شهر',
    price: 5,
    selected: false,
    viewsPercentage: 100,
    downloadsPercentage: 50,
    printPercentage: 50,
    description: 'الوصول الكامل لبوابة الكتاب العلمي تدفع سنوياً'
  }
]
export default function StepOne (props) {
  const [planData, setPlanData] = useState({})
  useEffect(() => {
    let planId = parseInt(localStorage.getItem('plan'))
    let plan = plans.find(p => p._id === planId)
    // console.log(plan)
    setPlanData(plan)
  }, [])
  const [slected, setSlected] = useState(0)

  return (
    <div className='stepOne'>
      <div className='containerReg1'>
        <div className='regsteps'>
          <Stepwizard step={4} />
        </div>
        <div className='sectionTitle'>اختر طريقة الدفع المناسبة لك</div>
        <div className='pays'>
          <div className='methods'>
            <div
              className={slected === 1 ? 'method selected' : 'method'}
              onClick={() => {
                setSlected(1)
              }}
            >
              <div className='imageMedthod'>
                <PayPalButton
                  id='paypal-button-container'
                  className='paypal-button'
                  amount={
                    planData.price * planData.duration * (1 + planData.tax) -
                    0.01
                  }
                  shippingPreference='GET_FROM_FILE' // default is "GET_FROM_FILE"
                  onSuccess={(details, data) => {
                    // alert("Transaction completed by " + details.payer.name.given_name);
                    // handleSubmitEnd(data, details)
                    console.log(details, data)
                    if (details.status === 'COMPLETED') {
                      //planData
                      let userData = JSON.parse(
                        localStorage.getItem('register')
                      )
                      userData.plan = planData._id
                      userData.planData = planData
                      userData.paymentMethod = 'paypal'
                      userData.paymentStatus = 'paid'
                      userData.expiryDate = new Date(
                        new Date().setMonth(
                          new Date().getMonth() + planData.duration
                        )
                      ).getTime()
                      userData.paymentId = data.orderID
                      userData.paymentDetails = details

                      ///app/users/createUser

                      let formData = new FormData()
                      formData.append('userData', JSON.stringify(userData))
                      axios
                        .post(
                          process.env.REACT_APP_BACKEND_URL +
                            '/app/users/createUser',
                          formData
                        )
                        .then(res => {
                          console.log(res)
                          if (res.data.booleanValue) {
                            localStorage.clear()
                            props.setStep(5)
                          }
                        })

                      // axios.post('/api/paypal', {
                      //   planId: planData._id,
                      // props.setStep(5)
                    }
                    // OPTIONAL: Call your server to save the transaction
                    // return fetch("/paypal-transaction-complete", {
                    //   method: "post",
                    //   body: JSON.stringify({
                    //     orderId: data.orderID
                    //   })
                    // });
                  }}
                  options={{
                    //'AQ4iXtOhY5I_GwN8AWkNcL6pgDvLfoFg4keWP51zTM3wy7-dVXL1yEaOsJ7IY9XAkYhMuGWmwzJiNaV5' live
                    //'AQ3m-jDX7oslO3JzKDwwyCT-0LIWd4rfxfjVynaTG6WRL68Ys-qRnvuNOi5Sf22Vvg6hgJvP_hfh2QVr'
                    clientId:
                      'AQ4iXtOhY5I_GwN8AWkNcL6pgDvLfoFg4keWP51zTM3wy7-dVXL1yEaOsJ7IY9XAkYhMuGWmwzJiNaV5'
                  }}
                  style={{
                    layout: 'horizontal',
                    Size: 'large',
                    color: 'blue',
                    shape: 'pill',
                    label: 'pay'
                  }}
                  onCancel={feed => {
                    console.log(feed)
                    // setLoad(true)
                  }}
                  onClick={feed => {
                    console.log(feed)
                    // setLoad(false)
                  }}
                />
              </div>
              <div className='titleMethod'>
                الدفع الإلكتروني عن طريق باي بال
              </div>
            </div>
            {/* <div className="method">
              <div className="imageMedthod">
                <img src={require("../../../assets/images/Group 9561.png")} />
              </div>
              <div className="titleMethod">الدفع الإلكتروني عن طريق ماستر كارد أو فيزا كارد</div>
            </div> */}
            <div
              className={slected === 2 ? 'method selected' : 'method'}
              onClick={() => setSlected(2)}
            >
              <div className='imageMedthod'>
                <img src={require('../../../assets/images/Group 9484.png')} />
              </div>
              <div className='titleMethod'>حوالة بنكية</div>
            </div>
          </div>
        </div>
        {slected === 2 && (
          <div className='BodyPay'>
            تتوفر خدمة الدفع عن طريق التحويل البنكي للعملاء حيث يتم اختيار هذا
            الخدمة عند إتمام الطلب ثم تحويل المبلغ إلى أحد حساباتنا البنكية ثم
            ملء نموذج تأكيد عملية الدفع وإرساله عبر البريد الإلكتروني
            info@thelearnbook.com. بعدها سيتم تأكيد الطلب وتفعيل حسابكم.
            <p>
              بنك العربي
              <br />
              بوابة الكتاب العلمي
              <br />
              التحويل من داخل البنك
              <br />
              000000000000000
              <br />
              الايبان
              <br />
              000000000000000
            </p>
            <div className='btnSub'>اشترك</div>
          </div>
        )}
      </div>
    </div>
  )
}
