import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Homepage from './pages/Homepage'
import AdvancedSearch from './pages/AdvancedSearch'
import Result from './pages/Result'
import ResultAll from './pages/ResultAll'
import Browse from './pages/Browse'
import View from './pages/View'
import ViewBook from './pages/ViewBook'
import BrowseBy from './pages/BrowseBy'
import Demo from './pages/demo'
import AboutUs from './pages/AboutUs'
import Profile from './pages/profile'
import MyLibrary from './pages/MyLibrary'
import Help from './pages/Help'
import LatestReleases from './pages/LatestReleases'
import Clients from './pages/Clients'
import Contactus from './pages/Contactus'
import Register from './pages/register'
import Forgetpassword from './pages/forgetpassword'
import ChangePassword from './pages/changePassword'
import RegQRcode from './pages/regqrcode'
import Login from './pages/Login'
// import registerUnv from './components/sharing/register/Unviersty'
import privacyPolicy from './pages/privacy_policy'
import termsConditions from './pages/terms_conditions'
import Header from './../src/components/sharing/header'
import Footer from './../src/components/sharing/footer'
import Logo from './assets/images/logo.png'
import PaymentForm from './pages/Payment'
import ScroolTop from './components/Scrolltop';

const footer = {
  socialMedia: {
    facebook: 'https://www.facebook.com/learnbookgate',
    twitter: 'https://twitter.com/learnbookgate/',
    instagram: 'https://www.instagram.com/learnbookgate/',
    youtube:
      'https://www.linkedin.com/company/%D8%A8%D9%88%D8%A7%D8%A8%D8%A9-%D8%A7%D9%84%D9%83%D8%AA%D8%A7%D8%A8-%D8%A7%D9%84%D8%B9%D9%84%D9%85%D9%8A/'
  }
}
const RouterPublic = () => (

  <Router>
    <Route
      render={({ location }) => {
        return (
          <div>
            <ScroolTop/>
            {location.pathname !== '/Demo' ? <Header logo={Logo} /> : ''}

            <Switch location={location}>
              <Route
                exact
                path={process.env.REACT_APP_FRONT_URL + ''}
                component={Homepage}
              />
              {/* <Route
                exact
                path={process.env.REACT_APP_FRONT_URL + 'resgisterunver'}
                component={registerUnv}
              /> */}
              <Route
                exact
                path={process.env.REACT_APP_FRONT_URL + 'advancedsearch'}
                component={AdvancedSearch}
              />
              <Route
                exact
                path={process.env.REACT_APP_FRONT_URL + 'PaymentForm'}
                component={PaymentForm}
              />
              <Route
                exact
                path={process.env.REACT_APP_FRONT_URL + 'aboutus'}
                component={AboutUs}
              />
              <Route
                exact
                path={process.env.REACT_APP_FRONT_URL + 'result/:key'}
                component={Result}
              />
              <Route
                exact
                path={process.env.REACT_APP_FRONT_URL + 'resultAll'}
                component={ResultAll}
              />
              <Route
                exact
                path={process.env.REACT_APP_FRONT_URL + 'browse'}
                component={Browse}
              />
              <Route
                exact
                path={process.env.REACT_APP_FRONT_URL + 'view/:id/:type'}
                component={View}
              />
              <Route
                exact
                path={process.env.REACT_APP_FRONT_URL + 'ViewBook/:id/:type/:year'}
                component={ViewBook}
              />
              <Route
                exact
                path={
                  process.env.REACT_APP_FRONT_URL + 'browseBy/:id/:type/:name'
                }
                component={BrowseBy}
              />
              <Route
                exact
                path={process.env.REACT_APP_FRONT_URL + 'profile'}
                component={Profile}
              />
              <Route
                exact
                path={process.env.REACT_APP_FRONT_URL + 'mylibrary'}
                component={MyLibrary}
              />
              <Route
                exact
                path={process.env.REACT_APP_FRONT_URL + 'help'}
                component={Help}
              />
              <Route
                exact
                path={process.env.REACT_APP_FRONT_URL + 'latest_releases'}
                component={LatestReleases}
              />
              <Route
                exact
                path={process.env.REACT_APP_FRONT_URL + 'clients'}
                component={Clients}
              />
              <Route
                exact
                path={process.env.REACT_APP_FRONT_URL + 'contact-us'}
                component={Contactus}
              />
              <Route
                exact
                path={process.env.REACT_APP_FRONT_URL + 'register'}
                component={Register}
              />
              <Route
                exact
                path={process.env.REACT_APP_FRONT_URL + 'forgetpassword'}
                component={Forgetpassword}
              />
              <Route
                exact
                path={process.env.REACT_APP_FRONT_URL + 'changePassword'}
                component={ChangePassword}
              />
              <Route
                exact
                path={process.env.REACT_APP_FRONT_URL + 'login/:token'}
                component={Login}
              />
              <Route
                exact
                path={process.env.REACT_APP_FRONT_URL + 'regqrcode'}
                component={RegQRcode}
              />

              <Route
                exact
                path={process.env.REACT_APP_FRONT_URL + 'privacy_policy'}
                component={privacyPolicy}
              />
              <Route
                exact
                path={process.env.REACT_APP_FRONT_URL + 'terms_conditions'}
                component={termsConditions}
              />

              <Route
                exact
                path={process.env.REACT_APP_FRONT_URL + 'demo'}
                component={Demo}
              />
              <Route
                render={() => (
                  <div className='error404'>هذه الصفحة غير متوفرة</div>
                )}
              />
            </Switch>

            {location.pathname !== '/Demo' ? <Footer footer={footer} /> : ''}
          </div>
        )
      }}
    />
  </Router>
)

export default RouterPublic
