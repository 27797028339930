import React, { useState ,useEffect} from 'react';
import Topsection from '../components/sharing/topsection';
import Breadcrumb from '../components/sharing/breadcrumbs';
import axios from "axios";

function AdvancedSearch() {
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [loadingPage, setLoadingPage] = useState(true);
    const [selectedItem, setSelectedItem] = useState(0);


    let alf = ['أ', 'ب', 'ت', 'ث', 'ج', 'ح', 'خ', 'د', 'ذ', 'ر', 'ز', 'س', 'ش', 'ص', 'ض', 'ط', 'ظ', 'ع', 'غ', 'ف', 'ق', 'ك', 'ل', 'م', 'ن', 'ه', 'و', 'ي'];
    useEffect(() => {
        axios.post(process.env.REACT_APP_BACKEND_URL + '/clients/list',
        ).then(response2 => {
            setData(response2.data.result);
            setData2(response2.data.result);
            setLoadingPage(false);
        })

    }, []);


    const breadCurmbData = [
        {
            title: "عملاؤنا",
            link: '',
        },
    ];
    const filterClients = (l, index) => {
        l = l === 'أ' ? 'ا' : l;
        if (l === '') {
            setSelectedItem(index);
            setData(data2);
            return true;
        } 
        let tempD = data2.filter(item => (item.clientName.trim()[0] === 'أ' ? 'ا' : item.clientName.trim()[0]) === l);
        setData(tempD);
        setSelectedItem(index);
    }
    return (
        <div>
            <Topsection data={{ text: 'عملاؤنا', img: require('../assets/images/11.png') }} />
            <Breadcrumb data={breadCurmbData} />
            <div className="loadingData" style={{
                visibility: loadingPage ? 'visible' : 'hidden',
                opacity: loadingPage ? 1 : 0,
                transition: 'visibility 0s, opacity 0.3s linear'

            }}>
                <img src={require('../assets/images/bookLoading.gif')} alt="loading" />
                <div className="textLoading">جاري التحميل...</div>
            </div>
            <div className='alphabetical'>
                <a className={selectedItem === (0) ? "active" : ''}
                    onClick={() => filterClients('', 0)}
                >الكل ({data2?.length})</a>
                {
                    alf.map((item, index) => {
                        return (
                            <> <a className={selectedItem === (index + 1) ? "active" : ''} onClick={() => filterClients(item, (index + 1))}>{item}</a>  </>
                        )
                    })
                }
            </div>
            <div className="clients_list">
                {
                    data.length != 0 && data.map((item, index) => {
                        return (
                            <div className="col-xs-12 col-sm-4" key={item._id}>
                                <div className="image">
                                    <img src={process.env.REACT_APP_BACKEND_URL + '/public/' + item.cover} alt={item.clientName} />
                                </div>
                                <div className="title">{item.clientName}</div>
                            </div>
                        )
                    })
                }


            </div>
        </div >
    );
}

export default AdvancedSearch;
