import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import {
  getResultFromBack,
  setLoadingPageValue,
  getResultNew,
  setResultCounts,
  getResultNull,
  setResultsSubject,
  setResultPublishers,
  setResultsCounteries,
  setCheckedValuesRedux,
  setCheckedValues2Redux,
  setCheckedValues3Redux,
  setCheckedValues4Redux,
  setSearchTypesRedux,
  setSearchTypesFilterRedux,
  setFilterCheck2Redux,
  setFilterCheck3Redux,
  setFilterCheck4Redux,
  setSearchKey2Redux,
  setOpenErrorMsgRedux,
  setLoginStatusRedux,
  setNameConferencesRedux,
  setConverConferencesRedux,
  setTimeTextRedux
} from '../store/search'
import Topsection from '../components/sharing/topsection'
import Breadcrumb from '../components/sharing/breadcrumbs'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Skeleton } from '@material-ui/lab'
import Tooltip from '@material-ui/core/Tooltip'
import { Typography, withStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Error from '@material-ui/icons/Error'
import { Base64 } from 'js-base64'
import InfiniteScroll from 'react-infinite-scroll-component'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import Snackbar from '@material-ui/core/Snackbar'
import Slide from '@material-ui/core/Slide'
import MuiAlert from '@material-ui/lab/Alert'
import { json2excel } from 'js2excel'
import _ from 'lodash'
import {
  isMobile
} from 'react-device-detect'
import { useSelector, useDispatch } from 'react-redux'
import { itemsRedux } from '../store/search'
import CustomImage from '../components/sharing/CustomImage'
import PopupShare from '../components/pdf/popup/popup_share'

function Alert(props) {
  return (
    <MuiAlert
      elevation={6}
      variant='filled'
      {...props}
      style={{ fontFamily: 'ArbFONTS-DroidKufi' }}
    />
  )
}
function TransitionUp(props) {
  return <Slide {...props} direction='up' />
}

function Browse() {
  const { key } = useParams()
  let history = useHistory()
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(55);
  const [activeTab, setActiveTab] = useState(
    parseInt(
      localStorage.getItem('ActiveTabSearch') !== undefined
        ? localStorage.getItem('ActiveTabSearch')
        : 0
    )
  )
  const [activeTab2, setActiveTab2] = useState(
    parseInt(
      localStorage.getItem('ActiveTab2') !== undefined
        ? localStorage.getItem('ActiveTab2')
        : 1
    )
  )
  const getCookie = name => {
    var nameEQ = name + '='
    var ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  }
  const itemsResult = useSelector(itemsRedux);
  const [items, setItems] = useState(null)
  const [subjects, setSubjects] = useState(null)
  const [keyNew, setKeyNew] = useState(key)
  const [searchKey, setSearchKey] = useState(Base64.decode(key))
  const [popupShareData, setPopupShareData] = useState(null)
  const [publisherHidden, setHiddenPublisher] = useState([JSON.parse(getCookie('objectUser'))?.hiddenContent.publishers])
  const [subjectHidden, setSubjectHidden] = useState(JSON.parse(getCookie('objectUser'))?.hiddenContent?.subjects);
  const [bookIdHidden, setBookIdHidden] = useState(JSON.parse(getCookie('objectUser'))?.hiddenContent?.booksIdHidden);
  const [conferIdHidden, setConferIdHidden] = useState(JSON.parse(getCookie('objectUser'))?.hiddenContent?.conferIdHidden);
  const [jourelasIdHidden, setJourIdHidden] = useState(JSON.parse(getCookie('objectUser'))?.hiddenContent?.jourelasIdHidden);
  const [theseIdHidden, setTheseIdHidden] = useState(JSON.parse(getCookie('objectUser'))?.hiddenContent?.theseIdHidden);
  const [subSubjectHidden, setsubSubjectHidden] = useState(JSON.parse(getCookie('objectUser'))?.hiddenContent?.subSubjectHidden);
  const [publishers, setPublishers] = useState(null)
  const [countries, setCountries] = useState(null)
  const [authors, setAuthors] = useState(null)
  const [boxSearch, setBoxSearch] = useState(null)
  const [open, setOpen] = React.useState(false)
  const [loadingCounts, setLoadingCounts] = React.useState(false)
  const [transition, setTransition] = useState(undefined)
  const handleClick = Transition => () => {
    setTransition(() => Transition)
    setOpen(true)
  }
  const location = useLocation();
  const yearArray = []
  var d = new Date()
  var n;
  var end;
  n = d.getFullYear() + 1;
  end = 1930;
  for (let i = n; i >= end; i--) {
    yearArray.push({ title: 'سنة ' + i, id: i + '', count: '0' })
  }
  const [sortby, setSortby] = useState({ id: -1, value: 'yearsValue' })
  const [yearArray1, setYearArray1] = useState(yearArray);
  const [checkedValues5, setCheckedValues5] = useState([])
  const [filterCheck, SetFilterCheck] = useState([])
  const [countQuery, setCountQuery] = useState(0)
  const myRef = useRef(null)
  const executeScroll = () =>
    myRef.current.scrollIntoView({ behavior: 'smooth' })
  const LightTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(20, 123, 252, 0.77)',
      boxShadow: theme.shadows[1],
      fontSize: 12,
      fontFamily: 'ArbFONTS-DroidKufi-bold'
    }
  }))(Tooltip)

  const styles = theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      left: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    },
    changefont: {
      fontFamily: 'ArbFONTS-DroidKufi-bold'
    },

  });

  function handleCheckAllYear(className) {
    const allCheckboxes = document.querySelectorAll(`.${className}`);
    allCheckboxes.forEach(checkbox => checkbox.checked = false);
    if (className == "typeCheck") {
      // setSearchTypes([true, false, false, false, false]);
      dispatch(setSearchTypesRedux([true, false, false, false, false]))
      // setSearchTypesFilter([true, false, false, false, false]);
      dispatch(setSearchTypesFilterRedux([true, false, false, false, false]));
      const filterParams = new URLSearchParams(location.search);
      filterParams.delete('type');
      history.push({ search: filterParams.toString() });
    }
    if (className == "checksubject") {
      dispatch(setCheckedValuesRedux([]));

      SetFilterCheck([])
      const filterParams = new URLSearchParams(location.search);
      filterParams.delete('subject');
      history.push({ search: filterParams.toString() });
    }
    if (className == "checkpublisher") {
      const filterParams = new URLSearchParams(location.search);
      filterParams.delete('publisher');
      history.push({ search: filterParams.toString() });
      // setCheckedValues2([]);
      dispatch(setCheckedValues2Redux([]))
      // SetFilterCheck2([]);
      dispatch(setFilterCheck2Redux([]))
      // window.location.reload();
    }
    if (className == "checkcountries") {
      const filterParams = new URLSearchParams(location.search);
      filterParams.delete('countries');
      history.push({ search: filterParams.toString() });
      // setCheckedValues3([]);
      dispatch(setCheckedValues3Redux([]));
      // SetFilterCheck3([]);
      dispatch(setFilterCheck3Redux([]));
    }
    if (className == "checkyear") {
      const filterParams = new URLSearchParams(location.search);
      filterParams.delete('year');
      history.push({ search: filterParams.toString() });
      dispatch(setCheckedValues4Redux([]));
      // SetFilterCheck4([]);
      dispatch(setFilterCheck4Redux([]));
    }
  }
  const handlerSubmitSort = (e) => {
    dispatch(getResultFromBack(null))
    dispatch(setLoadingPageValue(true))
    // setCheckedValues([])
    dispatch(setCheckedValuesRedux([]));

    setCountQuery(0)
    dispatch(getResultFromBack(null))
    setSortby({ id: 1, value: e.target.value })
    var crypto = require('crypto')
    let hash = crypto
      .createHash('sha256')
      .update(JSON.stringify(formatDate()))
      .digest('hex')
    let formData = new FormData()
    formData.append('hashTime', hash)
    formData.append('activeTab2', activeTab2)
    formData.append('lang', 'ar')
    formData.append('sortby', JSON.stringify(sortby))
    formData.append('searchTypes', JSON.stringify(itemsResult.searchTypes))
    formData.append("hidden", publisherHidden)
    formData.append('subjectHidden', subjectHidden)

    // formData.append('countQuery', countQuery);
    let newKey = Base64.decode(key)

    let search = searchKey
    if (Base64.encode(search).search('/') == -1)
      search = Base64.encode(`${JSON.stringify(search)}`, true)
    else search = Base64.encode(`${search}`, true)
    history.replace({ pathname: `/result/${search}` })
    formData.append('countQuery', 0)
    formData.append('activeTab', 0)
    formData.append('searchKey', searchKey.replace(/['"]+/g, ''))
    axios
      .post(process.env.REACT_APP_BACKEND_URL + '/app/browse/get', formData)
      .then(response2 => {
        if (response2.data.booleanVaule) {
          dispatch(getResultFromBack(null))
          dispatch(getResultFromBack(response2.data.result))
          dispatch(setResultCounts(response2.data.count))
          dispatch(setLoadingPageValue(false))

          setCountQuery(0)
        }
      })
    axios
      .post(process.env.REACT_APP_BACKEND_URL + '/app/subjects/get', formData)
      .then(response2 => {
        if (response2.data.booleanVaule) {
          setSubjects(response2.data.result)
          dispatch(setResultsSubject(response2.data.result))
          getCounts('subjects', response2.data.result)
        }
      })
    axios
      .post(process.env.REACT_APP_BACKEND_URL + '/app/publishers/get', formData)
      .then(response2 => {
        if (response2.data.booleanVaule) {
          setPublishers(response2.data.result)
          dispatch(setResultPublishers(response2.data.result))
          getCounts('publisher', response2.data.result)
          getCounts('years', yearArray1)
        }
      })
    /////////////////////////////////
    axios
      .post(process.env.REACT_APP_BACKEND_URL + '/app/countries/get', formData)
      .then(response2 => {
        if (response2.data.booleanVaule) {
          dispatch(setResultsCounteries(response2.data.result))
          setCountries(response2.data.result)
          getCounts('country', response2.data.result)
        }
      })
    /////////////////////////////////
    axios
      .post(process.env.REACT_APP_BACKEND_URL + '/app/authors/get', formData)
      .then(response2 => {
        if (response2.data.booleanVaule) {
          setAuthors(response2.data.result)
          getCounts('author', response2.data.result)
        }
      })
  }
  React.useEffect(() => {
    let access_token = localStorage.getItem('access_token')
    if (
      access_token !== undefined &&
      access_token !== '' &&
      access_token !== null
    ) {
      dispatch(setLoginStatusRedux(true))
    } else {
      dispatch(setLoginStatusRedux(false))
    }
  }, [])

  const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant='h6'>{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    )
  })

  const DialogContent = withStyles(theme => ({
    root: {
      padding: theme.spacing(2)
    }
  }))(MuiDialogContent)

  const DialogActions = withStyles(theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(1)
    }
  }))(MuiDialogActions)

  useEffect(() => {
    // dispatch(getResultFromBack(null))

    if (itemsResult.searchTypes.filter(item => item).length === 0) {
      // setSearchTypes([true, false, false, false, false])
      dispatch(setSearchTypesRedux([true, false, false, false, false]))
      dispatch(setLoadingPageValue(true))

    }
    if (countQuery == 0) dispatch(setLoadingPageValue(true))
  

    // setCheckedValues([])
    var crypto = require('crypto')
    let hash = crypto
      .createHash('sha256')
      .update(JSON.stringify(formatDate()))
      .digest('hex')

    let formData = new FormData();
    formData.append('bookIdHidden', bookIdHidden);
    formData.append('conferIdHidden', conferIdHidden);
    formData.append('jourelasIdHidden', jourelasIdHidden);
    formData.append('theseIdHidden', theseIdHidden)
    formData.append('subSubjectHidden', JSON.stringify(subSubjectHidden))
    if (location.search.includes('type')) {
      let types = location.search.replace("?", "").split('&');
      types = types.filter(item => item.includes('type'));
      types = types[0].replace('type=', '');
      types = types.split('%2C')?.map((type) => parseInt(type));
      let arrayTr = [false, false, false, false, false];
      types.forEach((type) => arrayTr[type] = true);
      //  setSearchTypes(null);
      //  setSearchTypes(arrayTr);
      // setSearchTypesFilter(arrayTr);
      dispatch(setSearchTypesFilterRedux(arrayTr))
      formData.append('searchTypes', JSON.stringify(arrayTr))


    }
    else {
      formData.append('searchTypes', JSON.stringify(itemsResult.searchTypes))

    }
    if (itemsResult.checkedValues.length === 0) {
      if (location.search.includes('subject')) {
        let subjects = location.search.replace('?', "").split('&');
        subjects = subjects.filter(item => item.includes('subject'))
        subjects = subjects[0]?.replace('subject=', '')
        subjects = subjects?.split('%2C');
        let result = [];

        if (subjects.length !== 0) {
          // setCheckedValues2(subjects)
          SetFilterCheck(subjects)
          subjects.forEach((element, index) => {
            if (element.includes('subject')) {
              if (index === 0 || (!isNaN(subjects[index - 1])) || element.length === 1) {
                result.push(element);
                result.push(" ");
              } else {
                result.push(element);
              }
            } else {
              result.push(element);
            }
          });
          result.forEach((item, index) => {
            if (index % 2 != 0) {
              result[index] = parseInt(item);
            }
          })
          formData.append('checkedValues', JSON.stringify(result))
        }
      }
    }
    else {
      formData.append('checkedValues', JSON.stringify(itemsResult.checkedValues))

    }
    if (itemsResult.checkedValues2.length === 0) {
      if (location.search.includes('publisher')) {
        let publisher = location.search.replace('?', "").split('&');
        publisher = publisher.filter(item => item.includes('publisher'))
        publisher = publisher[0].replace('publisher=', '')
        publisher = publisher.split('%2C')
        if (publisher.length !== 0) {
          // setCheckedValues2(publisher)
          // SetFilterCheck2(publisher)
          dispatch(setFilterCheck2Redux(publisher))
          formData.append('checkedValues2', JSON.stringify(publisher))
        }
      }
    }
    else {
      formData.append('checkedValues2', JSON.stringify(itemsResult.checkedValues2))
    }
    if (itemsResult.checkedValues3.length === 0) {
      if (location.search.includes('countries')) {
        let countries = location.search.replace('?', "").split('&');
        countries = countries.filter(item => item.includes('countries'))
        countries = countries[0].replace('countries=', '')
        countries = countries.split('%2C')
        if (countries.length !== 0) {
          // setCheckedValues2(countries)
          // SetFilterCheck3(countries)
          dispatch(setFilterCheck3Redux(countries))
          formData.append('checkedValues3', JSON.stringify(countries))
        }
      }
    }
    else {
      formData.append('checkedValues3', JSON.stringify(itemsResult.checkedValues3))
    }
    if (itemsResult.checkedValues4.length === 0) {
      if (location.search.includes('year')) {
        let year = location.search.replace('?', "").split('&');
        year = year.filter(item => item.includes('year'))
        year = year[0].replace('year=', '')
        year = year.split('%2C')
        if (year.length !== 0) {
          // setCheckedValues2(year)
          // SetFilterCheck4(year)
          dispatch(setFilterCheck4Redux(year))
          formData.append('checkedValues4', JSON.stringify(year))
        }
      }
    }
    else {
      formData.append('checkedValues4', JSON.stringify(itemsResult.checkedValues4))
    }
    formData.append('userid', getCookie('id'))
    formData.append('hashTime', hash)
    formData.append('activeTab2', activeTab2)
    formData.append('lang', 'ar')
    formData.append('sortby', JSON.stringify(sortby))
    if (!formData.has('checkedValues')) {
      formData.append('checkedValues', JSON.stringify(itemsResult.checkedValues))
    }
    if (!formData.has('checkedValues2')) {
      formData.append('checkedValues2', JSON.stringify(itemsResult.checkedValues2))
    }
    if (!formData.has('checkedValues3')) {
      formData.append('checkedValues3', JSON.stringify(itemsResult.checkedValues3))
    }
    if (!formData.has('checkedValues4')) {
      formData.append('checkedValues4', JSON.stringify(itemsResult.checkedValues4))
    }
    // formData.append('checkedValues', JSON.stringify(checkedValues))
    formData.append('checkedValues5', JSON.stringify(checkedValues5))
    formData.append('access_token', localStorage.getItem('access_token'))

    // formData.append('countQuery', countQuery);
    let newKey = Base64.decode(keyNew)
    newKey = newKey.substring(1)
    newKey = newKey.substring(0, newKey.length - 1)
    if (
      newKey.substring(0, 1) == '[' &&
      newKey.substring(newKey.length - 1, newKey.length) == ']'
    ) {
      setSearchKey('')
      setBoxSearch(JSON.parse(newKey))
      formData.append('activeTab', 1)
      formData.append('searchKey', newKey)
      formData.append('countQuery', countQuery);
      formData.append('subjectHidden', subjectHidden)
      if (publisherHidden?.length !== 0) {
        formData.append("publisherHidden", publisherHidden)
      }
      axios
        .post(
          process.env.REACT_APP_BACKEND_URL + '/advancedsearch/get',
          formData
        )
        .then(response2 => {
          // dispatch(getResultFromBack(null))
          if (response2.data.booleanVaule) {
            if (
              (itemsResult.checkedValues.length !== 0 ||
                itemsResult.checkedValues2.length !== 0 ||
                itemsResult.checkedValues3.length !== 0 ||
                itemsResult.checkedValues4.length !== 0 ||
                checkedValues5.length !== 0) &&
              countQuery === 0
            )
              dispatch(getResultFromBack(response2.data.result))
            else if (itemsResult.items === null) {
              dispatch(getResultNull(response2.data.result))
              dispatch(setResultCounts(response2.data.count))
            } else dispatch(getResultNew(response2.data.result));
            dispatch(setLoadingPageValue(false))
            dispatch(setResultCounts(response2.data.count));


          }
        })
    } else {
      if (publisherHidden?.length !== 0) {
        formData.append("publisherHidden", publisherHidden)
      }
      formData.append('subjectHidden', subjectHidden)
      formData.append('countQuery', countQuery)
      formData.append('activeTab', 0)
      formData.append('searchKey', searchKey.replace(/['"]+/g, ''))
      const request_start_at = performance.now()
      axios
        .post(process.env.REACT_APP_BACKEND_URL + '/app/browse/get', formData)
        .then(response2 => {
          if (activeTab2 == "7" || activeTab2 == 7) {
            dispatch(setNameConferencesRedux(response2.data?.result[0]?.conferencesObj.nameAr))
            dispatch(setConverConferencesRedux(response2.data?.result[0]?.conferencesObj.cover));

          }

          const request_end_at = performance.now()
          const request_duration = request_end_at - request_start_at
          let finalTime = String(request_duration / 900).substring(0, 4)
          dispatch(setTimeTextRedux(''))
          dispatch(setTimeTextRedux(`الوقت المستغرق ${finalTime} ثانية`))
          if (response2.data.booleanVaule) {
            if (itemsResult.searchKey2 !== searchKey) {
              dispatch(getResultFromBack(null))
              dispatch(setSearchKey2Redux(searchKey));
            }
            // dispatch(getResultFromBack(response2.data.result))

            if (
              (itemsResult.checkedValues.length != 0 ||
                itemsResult.checkedValues2 != 0 ||
                itemsResult.checkedValues3 != 0 ||
                itemsResult.checkedValues4 != 0 ||
                itemsResult.searchTypes !=0||
                checkedValues5 != 0) &&
              countQuery == 0
            ) 
              dispatch(getResultFromBack(response2.data.result))
            else if (itemsResult.items == null) {
              dispatch(setResultCounts(response2.data.count))
              dispatch(getResultNull(response2.data.result))
            } else
              dispatch(getResultNew(response2.data.result));
            dispatch(setLoadingPageValue(false))
            dispatch(setResultCounts(response2.data.count));
          }
        })
    }
  }, [
    sortby,
    activeTab,
    itemsResult.checkedAll,
    itemsResult.searchTypes,
    countQuery,
    itemsResult.checkedValues,
    itemsResult.checkedValues2,
    itemsResult.checkedValues3,
    itemsResult.checkedValues4,
    checkedValues5,
    keyNew,
  ])


  const getCounts = (type, data) => {
    setLoadingCounts(true)
    var crypto = require('crypto')
    let hash = crypto
      .createHash('sha256')
      .update(JSON.stringify(formatDate()))
      .digest('hex')

    let formData = new FormData()
    formData.append('hashTime', hash)
    formData.append('activeTab2', activeTab2)
    formData.append('lang', 'ar')
    formData.append('sortby', JSON.stringify(sortby))
    formData.append('searchTypes', JSON.stringify(itemsResult.searchTypes))
    formData.append('checkedValues', JSON.stringify(itemsResult.checkedValues))
    formData.append('checkedValues2', JSON.stringify(itemsResult.checkedValues2))
    formData.append('checkedValues3', JSON.stringify(itemsResult.checkedValues3))
    formData.append('checkedValues4', JSON.stringify(itemsResult.checkedValues4))
    formData.append('checkedValues5', JSON.stringify(checkedValues5))

    // formData.append('countQuery', countQuery);
    let newKey = Base64.decode(key)
    newKey = newKey.substring(1)
    newKey = newKey.substring(0, newKey.length - 1)

    if (
      newKey.substring(0, 1) == '[' &&
      newKey.substring(newKey.length - 1, newKey.length) == ']'
    ) {
      formData.append('countQuery', countQuery)
      formData.append('activeTab', 1)
      formData.append('searchKey', newKey)
      formData.append('typeOf', type)

      axios
        .post(
          process.env.REACT_APP_BACKEND_URL + '/app/advSearch/getCounts',
          formData
        )
        .then(response2 => {
          if (response2.data.booleanVaule) {
            //////////////////
            let newSub = []
            if (type === 'subjects') {
              newSub = data

              newSub.forEach(function (obj) {
                obj.count = '0'
                obj.subSubjects.forEach(function (obj2) {
                  obj2.count = '0'
                })
              })

              var d = []
              if (itemsResult.searchTypes[0])
                d = response2.data.results2[0].concat(
                  response2.data.results2[1],
                  response2.data.results2[2],
                  response2.data.results2[3]
                )
              else
                d = d.concat(
                  itemsResult.searchTypes[1] ? response2.data.results2[0] : [],
                  itemsResult.searchTypes[2] ? response2.data.results2[1] : [],
                  itemsResult.searchTypes[3] ? response2.data.results2[2] : [],
                  itemsResult.searchTypes[4] ? response2.data.results2[3] : []
                )

              d.map(item => {
                newSub.map(itemSub => {
                  if (item._id[0]?.subject === itemSub._id) {
                    itemSub.count = parseInt(itemSub.count) + item.count
                  }

                  itemSub.subSubjects.map((sub2, index) => {
                    if (
                      item._id[0]?.subSubject === index &&
                      item._id[0].subject === itemSub._id
                    ) {
                      sub2.count = parseInt(sub2.count) + item.count
                    }
                  })
                })
              })
              setSubjects([...newSub])
            }
            if (type === 'publisher') {
              newSub = data
              newSub.forEach(function (obj) {
                obj.count = '0'
              })

              var d = response2.data.results2[0].concat(
                response2.data.results2[1],
                response2.data.results2[2],
                response2.data.results2[3]
              )
              newSub.map(itemSub => {
                d.map(item => {
                  if (item._id.indexOf(itemSub._id) !== -1) {
                    itemSub.count = parseInt(itemSub.count) + item.count
                  }
                })
              })

              setPublishers([...newSub])
            }

            if (type === 'country') {
              newSub = data
              newSub.forEach(function (obj) {
                obj.count = '0'
              })

              var d = response2.data.results2[0].concat(
                response2.data.results2[1],
                response2.data.results2[2],
                response2.data.results2[3]
              )
              newSub.map(itemSub => {
                d.map(item => {
                  if (item._id === itemSub._id) {
                    itemSub.count = parseInt(itemSub.count) + item.count
                  }
                })
              })
              setCountries([...newSub])
            }

            if (type === 'years') {
              newSub = yearArray1
              newSub.forEach(function (obj) {
                obj.count = '0'
              })

              var d = response2.data.results2[0].concat(
                response2.data.results2[1],
                response2.data.results2[2],
                response2.data.results2[3]
              )
              newSub.map(itemSub => {
                d.map(item => {
                  if (item._id === itemSub.id) {
                    itemSub.count = parseInt(itemSub.count) + item.count
                  }
                })
              })
              setYearArray1([...newSub])
            }

            if (type === 'author') {
              newSub = data
              newSub.forEach(function (obj) {
                obj.count = '0'
              })

              var d = response2.data.results2[0].concat(
                response2.data.results2[1],
                response2.data.results2[2],
                response2.data.results2[3]
              )
              newSub.map(itemSub => {
                d.map(item => {
                  if (item._id.indexOf(itemSub._id) !== -1) {
                    itemSub.count = parseInt(itemSub.count) + item.count
                  }
                })
              })
              setAuthors([...newSub])
            }

            ////////////////////////

            setLoadingCounts(false)
          }
        })
    } else {
      formData.append('countQuery', countQuery)
      formData.append('activeTab', 0)
      if (
        newKey.substring(0, 1) == '[' &&
        newKey.substring(newKey.length - 1, newKey.length) == ']'
      )
        formData.append('searchKey', JSON.stringify(newKey))
      else formData.append('searchKey', searchKey.replace(/['"]+/g, ''))
      formData.append('typeOf', type)

      axios
        .post(
          process.env.REACT_APP_BACKEND_URL + '/app/advSearch/getCounts',
          formData
        )
        .then(response2 => {
          if (response2.data.booleanVaule) {
            //////////////////
            let newSub = []
            if (type === 'subjects') {
              newSub = data

              newSub.forEach(function (obj) {
                obj.count = '0'
                obj.subSubjects.forEach(function (obj2) {
                  obj2.count = '0'
                })
              })

              var d = []
              if (itemsResult.searchTypes[0])
                d = response2.data.results2[0].concat(
                  response2.data.results2[1],
                  response2.data.results2[2],
                  response2.data.results2[3]
                )
              else
                d = d.concat(
                  itemsResult.searchTypes[1] ? response2.data.results2[0] : [],
                  itemsResult.searchTypes[2] ? response2.data.results2[1] : [],
                  itemsResult.searchTypes[3] ? response2.data.results2[2] : [],
                  itemsResult.searchTypes[4] ? response2.data.results2[3] : []
                )

              d.map(item => {
                newSub.map(itemSub => {
                  if (item._id[0] !== undefined)
                    if (item._id[0].subject === itemSub._id) {
                      itemSub.count = parseInt(itemSub.count) + item.count
                    }

                  if (item._id[0] !== undefined)
                    itemSub.subSubjects.map((sub2, index) => {
                      if (
                        item._id[0].subSubject === index &&
                        item._id[0].subject === itemSub._id
                      ) {
                        sub2.count = parseInt(sub2.count) + item.count
                      }
                    })
                })
              })
              setSubjects([...newSub])
            }
            if (type === 'publisher') {
              newSub = data
              newSub.forEach(function (obj) {
                obj.count = '0'
              })

              var d = []
              if (itemsResult.searchTypes[0])
                d = response2.data.results2[0].concat(
                  response2.data.results2[1],
                  response2.data.results2[2],
                  response2.data.results2[3]
                )
              else
                d = d.concat(
                  itemsResult.searchTypes[1] ? response2.data.results2[0] : [],
                  itemsResult.searchTypes[2] ? response2.data.results2[1] : [],
                  itemsResult.searchTypes[3] ? response2.data.results2[2] : [],
                  itemsResult.searchTypes[4] ? response2.data.results2[3] : []
                )

              newSub.map(itemSub => {
                d.map(item => {
                  if (item._id.indexOf(itemSub._id) !== -1) {
                    itemSub.count = parseInt(itemSub.count) + item.count
                  }
                })
              })
              // publisherHidden
              publisherHidden.map((pub) => {
                newSub.filter((item) => item.id != pub)

              })
              setPublishers([...newSub])
            }

            if (type === 'country') {
              newSub = data
              newSub.forEach(function (obj) {
                obj.count = '0'
              })

              var d = []
              if (itemsResult.searchTypes[0])
                d = response2.data.results2[0].concat(
                  response2.data.results2[1],
                  response2.data.results2[2],
                  response2.data.results2[3]
                )
              else
                d = d.concat(
                  itemsResult.searchTypes[1] ? response2.data.results2[0] : [],
                  itemsResult.searchTypes[2] ? response2.data.results2[1] : [],
                  itemsResult.searchTypes[3] ? response2.data.results2[2] : [],
                  itemsResult.searchTypes[4] ? response2.data.results2[3] : []
                )

              newSub.map(itemSub => {
                d.map(item => {
                  if (item._id === itemSub._id) {
                    itemSub.count = parseInt(itemSub.count) + item.count
                  }
                })
              })
              setCountries([...newSub])
            }

            if (type === 'years') {
              newSub = yearArray1
              newSub.forEach(function (obj) {
                obj.count = '0'
              })

              var d = []
              if (itemsResult.searchTypes[0])
                d = response2.data.results2[0].concat(
                  response2.data.results2[1],
                  response2.data.results2[2],
                  response2.data.results2[3]
                )
              else
                d = d.concat(
                  itemsResult.searchTypes[1] ? response2.data.results2[0] : [],
                  itemsResult.searchTypes[2] ? response2.data.results2[1] : [],
                  itemsResult.searchTypes[3] ? response2.data.results2[2] : [],
                  itemsResult.searchTypes[4] ? response2.data.results2[3] : []
                )

              newSub.map(itemSub => {
                d.map(item => {
                  if (item._id === itemSub.id) {
                    itemSub.count = parseInt(itemSub.count) + item.count
                  }
                })
              })
              setYearArray1([...newSub])
            }

            if (type === 'author') {
              newSub = data
              newSub.forEach(function (obj) {
                obj.count = '0'
              })

              var d = []
              if (itemsResult.searchTypes[0])
                d = response2.data.results2[0].concat(
                  response2.data.results2[1],
                  response2.data.results2[2],
                  response2.data.results2[3]
                )
              else
                d = d.concat(
                  itemsResult.searchTypes[1] ? response2.data.results2[0] : [],
                  itemsResult.searchTypes[2] ? response2.data.results2[1] : [],
                  itemsResult.searchTypes[3] ? response2.data.results2[2] : [],
                  itemsResult.searchTypes[4] ? response2.data.results2[3] : []
                )

              newSub.map(itemSub => {
                d.map(item => {
                  if (item._id.indexOf(itemSub._id) !== -1) {
                    itemSub.count = parseInt(itemSub.count) + item.count
                  }
                })
              })
              setAuthors([...newSub])
            }

            ////////////////////////

            setLoadingCounts(false)
          }
        })
    }
  }

  const nextResult = () => {
    setCountQuery(countQuery + 1)
  }

  const changeTypes = id => {
    setCountQuery(0)
    setItems([])
    if (id === 0)
      dispatch(setSearchTypesRedux(
        itemsResult.searchTypes[id]
          ? [false, true, false, false, false]
          : [true, false, false, false, false]
      ))
    else
      switch (id) {
        case 1:
          dispatch(setSearchTypesRedux([
            false,
            !itemsResult.searchTypes[1],
            itemsResult.searchTypes[2],
            itemsResult.searchTypes[3],
            itemsResult.searchTypes[4]
          ]))
          break
        case 2:
          dispatch(setSearchTypesRedux([
            false,
            itemsResult.searchTypes[1],
            !itemsResult.searchTypes[2],
            itemsResult.searchTypes[3],
            itemsResult.searchTypes[4]
          ]))
          break
        case 3:
          dispatch(setSearchTypesRedux([
            false,
            itemsResult.searchTypes[1],
            itemsResult.searchTypes[2],
            !itemsResult.searchTypes[3],
            itemsResult.searchTypes[4]
          ]))
          break
        case 4:
          dispatch(setSearchTypesRedux([
            false,
            itemsResult.searchTypes[1],
            itemsResult.searchTypes[2],
            itemsResult.searchTypes[3],
            !itemsResult.searchTypes[4]
          ]))
          break
      }
    const filterParams = new URLSearchParams(location.search);
    if (filterParams.has('type')) {
      let pub = filterParams.get('type') + ',' + id;
      filterParams.set('type', pub);
      // filterParams.append('publisher', id);
      // SetFilterCheck2([...itemsResult.filterCheck2, id])
      dispatch(setFilterCheck2Redux([[...itemsResult.filterCheck2, id]]))
    } else {
      filterParams.set('type', id);
      // SetFilterCheck2([...filterCheck2, id])
      dispatch(setFilterCheck2Redux([...itemsResult.filterCheck2, id]))

    }
    history.push({ search: filterParams.toString() });
  }

  useEffect(() => {
    var crypto = require('crypto')
    let hash = crypto
      .createHash('sha256')
      .update(JSON.stringify(formatDate()))
      .digest('hex')

    let formData = new FormData()
    formData.append('hashTime', hash)
    formData.append('lang', 'ar');
    formData.append('subjectHidden', subjectHidden)
    formData.append('subSubjectHidden', JSON.stringify(subSubjectHidden))

    if (publisherHidden?.length !== 0 && publisherHidden[0] !== "") {
      formData.append("hidden", publisherHidden)
    }
    let newKey = Base64.decode(key)
    newKey = newKey.substring(1)
    newKey = newKey.substring(0, newKey.length - 1)
    if (
      newKey.substring(0, 1) == '[' &&
      newKey.substring(newKey.length - 1, newKey.length) == ']'
    ) {
      axios
        .post(process.env.REACT_APP_BACKEND_URL + '/app/subjects/get', formData)
        .then(response2 => {
          if (response2.data.booleanVaule) {
            dispatch(setResultsSubject(response2.data.result));
            setSubjects(response2.data.result)
            // getCounts('subjects', response2.data.result);
          }
        })
      axios
        .post(
          process.env.REACT_APP_BACKEND_URL + '/app/publishers/get',
          formData
        )
        .then(response2 => {
          if (response2.data.booleanVaule) {
            setPublishers(response2.data.result)
            dispatch(setResultPublishers(response2.data.result))
            // getCounts('publisher', response2.data.result);
            // getCounts('years', yearArray1);
          }
        })
      // /////////////////////////////////
      axios
        .post(
          process.env.REACT_APP_BACKEND_URL + '/app/countries/get',
          formData
        )
        .then(response2 => {
          if (response2.data.booleanVaule) {
            dispatch(setResultsCounteries(response2.data.result));
            setCountries(response2.data.result)
            // getCounts('country', response2.data.result);
          }
        })
      // /////////////////////////////////
      // axios.post(process.env.REACT_APP_BACKEND_URL + '/app/authors/get', formData,
      // ).then(response2 => {
      //     if (response2.data.booleanVaule) {
      //         setAuthors(response2.data.result)
      //         getCounts('author', response2.data.result);
      //     }
      // })
    } else {
      axios
        .post(process.env.REACT_APP_BACKEND_URL + '/app/subjects/get', formData)
        .then(response2 => {
          if (response2.data.booleanVaule) {
            setSubjects(response2.data.result)
            dispatch(setResultsSubject(response2.data.result));
            // getCounts('subjects', response2.data.result);
          }
        })
      axios
        .post(
          process.env.REACT_APP_BACKEND_URL + '/app/publishers/get',
          formData
        )
        .then(response2 => {
          if (response2.data.booleanVaule) {
            setPublishers(response2.data.result)
            dispatch(setResultPublishers(response2.data.result))
            // getCounts('publisher', response2.data.result);
            // getCounts('years', yearArray1);
          }
        })
      // /////////////////////////////////
      axios
        .post(
          process.env.REACT_APP_BACKEND_URL + '/app/countries/get',
          formData
        )
        .then(response2 => {
          if (response2.data.booleanVaule) {
            dispatch(setResultsCounteries(response2.data.result))
            setCountries(response2.data.result)
            // getCounts('country', response2.data.result);
          }
        })

    }
  }, [
    itemsResult.checkedValues,
    itemsResult.checkedValues2,
    itemsResult.checkedValues3,
    itemsResult.checkedValues4,
    checkedValues5
  ])

  function formatDate() {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }

  const breadCurmbData = [
    {
      title: 'نتائج البحث',
      link: ''
    }
  ]

  ///////
  const filtterBySubjects = (e, id, id2) => {
    setCountQuery(0)
    if (e.target.checked) {
      dispatch(setCheckedValuesRedux([...itemsResult.checkedValues, id, id2]));
    } else {
      dispatch(setCheckedValuesRedux(itemsResult.filter(ii => ii !== id)));

    }

  }
  //////////
  const filtterByPublisher = (e, id) => {
    setCountQuery(0)
    if (e.target.checked) {
      // setCheckedValues2([...checkedValues2, id])
      dispatch(setCheckedValues2Redux([...itemsResult.checkedValues2, id]))

    } else {
      // setCheckedValues2(checkedValues2.filter(ii => ii !== id))
      dispatch(setCheckedValues2Redux(itemsResult.checkedValues2.filter(ii => ii !== id)))

    }

    const filterParams = new URLSearchParams(location.search);
    if (filterParams.has('publisher')) {
      if (!filterParams.get('publisher').includes(id)) {
        let pub = filterParams.get('publisher') + ',' + id;
        filterParams.set('publisher', pub);
        // filterParams.append('publisher', id);
        // SetFilterCheck2([...itemsResult.filterCheck2, id])
        dispatch(setFilterCheck2Redux([...itemsResult.filterCheck2, id]))
      }
      else {
        let pub = filterParams.get("publisher").split(',').filter((item) => {
          return item !== id;
        }).join(',')
        // SetFilterCheck2([]);
        dispatch(setFilterCheck2Redux([]))
        // SetFilterCheck2([...pub]);
        dispatch(setFilterCheck2Redux([...pub]))
        filterParams.set("publisher", pub);
      }
    } else {
      filterParams.set('publisher', id);
      // SetFilterCheck2([...itemsResult.filterCheck2, id])
      dispatch(setFilterCheck2Redux([...itemsResult.filterCheck2, id]))

    }
    history.push({ search: filterParams.toString() });

  }

  const filtterByCountries = (e, id) => {
    setCountQuery(0)
    if (e.target.checked) {
      // setCheckedValues3([...checkedValues3, id])
      dispatch(setCheckedValues3Redux([...itemsResult.checkedValues3,id]));

    } else {
      // setCheckedValues3(checkedValues3.filter(ii => ii !== id))
      dispatch(setCheckedValues3Redux(itemsResult.checkedValues3.filter(ii => ii !== id)));
    }
    const filterParams = new URLSearchParams(location.search);
    if (filterParams.has('countries')) {
      if (!filterParams.get('countries').includes(id)) {
        let pub = filterParams.get('countries') + ',' + id;
        filterParams.set('countries', pub);
        // filterParams.append('countries', id);
        // SetFilterCheck3([...filterCheck3, id])
        dispatch(setFilterCheck3Redux([...itemsResult.filterCheck3, id]))
      }
      else {
        let pub = filterParams.get('countries').split(',').filter((item) => {
          return item !== id;
        }).join(',')
        // SetFilterCheck3([]);
        dispatch(setFilterCheck3Redux([]))
        // SetFilterCheck3([...pub])
        dispatch(setFilterCheck3Redux([...pub]))
        filterParams.set('countries', pub);
      }
    } else {
      filterParams.set('countries', id);
      // SetFilterCheck3([...filterCheck3, id])
      dispatch(setFilterCheck3Redux([...itemsResult.filterCheck3, id]))
    }
    history.push({ search: filterParams.toString() });
  }

  const filtterByYears = (e, id) => {
    setCountQuery(0)
    if (e.target.checked) {
      dispatch(setCheckedValues4Redux([...itemsResult.checkedValues4,id]));
    } else {
      dispatch(setCheckedValues4Redux(itemsResult.checkedValues4.filter(ii => ii !== id)))
    }
    const filterParams = new URLSearchParams(location.search);
    if (filterParams.has('year')) {
      if (!filterParams.get('year').includes(id)) {
        let pub = filterParams.get('year') + ',' + id;
        filterParams.set('year', pub);
        // filterParams.append('year', id);
        // SetFilterCheck4([...filterCheck4, id]);
        dispatch(setFilterCheck4Redux([...itemsResult.filterCheck4, id]))
      }
      else {
        let pub = filterParams.get('year').split(',').filter((item) => {
          return item !== id;
        }).join(',')
        // SetFilterCheck4([])
        dispatch(setFilterCheck4Redux([]))
        // SetFilterCheck4([...pub])
        dispatch(setFilterCheck4Redux([...pub]))
        filterParams.set('year', pub);
      }
    } else {
      filterParams.set('year', id);
      // SetFilterCheck4([...filterCheck4, id]);
      dispatch(setFilterCheck4Redux([...itemsResult.filterCheck4, id]))
    }

    history.push({ search: filterParams.toString() });
  }

  const filtterbyAuthors = (e, id) => {
    setCountQuery(0)
    if (e.target.checked) {
      setCheckedValues5([...checkedValues5, id])
    } else {
      setCheckedValues5(checkedValues5.filter(ii => ii !== id))
    }


    dispatch(setLoadingPageValue(true))

    var crypto = require('crypto')
    let hash = crypto
      .createHash('sha256')
      .update(JSON.stringify(formatDate()))
      .digest('hex')
    let formData = new FormData()
    formData.append('hashTime', hash)
    formData.append('activeTab', 0)
    formData.append('lang', 'ar')
    formData.append('sortby', sortby)
    formData.append('activeTab2', activeTab2)
    formData.append('searchKey', searchKey)
    formData.append('searchTypes', JSON.stringify(itemsResult.searchTypes))
    if (e.target.checked) {
      let temp2 = checkedValues5
      temp2 = [...checkedValues5, id]
      setCheckedValues5([...checkedValues5, id])

      if (temp2.length !== 0) {
        formData.append('checkedValues5', JSON.stringify(temp2))
        axios
          .post(
            process.env.REACT_APP_BACKEND_URL + '/app/filters/byauthors',
            formData
          )
          .then(response2 => {
            setItems(response2.data.result)


            dispatch(setLoadingPageValue(false))

            // executeScroll()
          })
      } else {
        axios
          .post(process.env.REACT_APP_BACKEND_URL + '/app/browse/get', formData)
          .then(response2 => {
            if (response2.data.booleanVaule) {
              setItems(response2.data.result)



              dispatch(setLoadingPageValue(false))

              // executeScroll()
            }
          })
      }
    } else {
      let temp2 = checkedValues5
      temp2 = checkedValues5.filter(ii => ii !== id)
      setCheckedValues5(checkedValues5.filter(ii => ii !== id))
      if (temp2.length !== 0) {
        formData.append('checkedValues5', JSON.stringify(temp2))
        axios
          .post(
            process.env.REACT_APP_BACKEND_URL + '/app/filters/byauthors',
            formData
          )
          .then(response2 => {
            setItems(response2.data.result)


            dispatch(setLoadingPageValue(false))

            // executeScroll()
          })
      } else {
        axios
          .post(process.env.REACT_APP_BACKEND_URL + '/app/browse/get', formData)
          .then(response2 => {
            if (response2.data.booleanVaule) {
              setItems(response2.data.result)


              dispatch(setLoadingPageValue(false))

              // executeScroll()
            }
          })
      }
    }
  }
  const searchByKey = () => {
    // if (searchKey.length >= 3) {
    // setItems(null)
    dispatch(getResultFromBack(null));
    dispatch(setLoadingPageValue(true))

    dispatch(setCheckedValuesRedux([]));
    var crypto = require('crypto')
    let hash = crypto
      .createHash('sha256')
      .update(JSON.stringify(formatDate()))
      .digest('hex')
    let formData = new FormData()
    formData.append('hashTime', hash)
    formData.append('activeTab2', activeTab2)
    formData.append('lang', 'ar')
    formData.append('sortby', JSON.stringify(sortby))
    formData.append('searchTypes', JSON.stringify(itemsResult.searchTypes))
    formData.append("hidden", publisherHidden)
    formData.append('subjectHidden', subjectHidden)
    formData.append('bookIdHidden', bookIdHidden);
    formData.append('conferIdHidden', conferIdHidden);
    formData.append('jourelasIdHidden', jourelasIdHidden);
    formData.append('theseIdHidden', theseIdHidden)
    formData.append('subSubjectHidden', JSON.stringify(subSubjectHidden))
    if (publisherHidden?.length !== 0) {
      formData.append("publisherHidden", publisherHidden)
    }
    // formData.append('countQuery', countQuery);
    let newKey = Base64.decode(key)
    let search = searchKey
    if (Base64.encode(search).search('/') == -1)
      search = Base64.encode(`${JSON.stringify(search)}`, true)
    else search = Base64.encode(`${search}`, true)
    history.replace({ pathname: `/result/${search}` })
    formData.append('countQuery', 0)
    formData.append('activeTab', 0)
    formData.append('searchKey', searchKey.replace(/['"]+/g, ''))
    axios
      .post(process.env.REACT_APP_BACKEND_URL + '/app/browse/get', formData)
      .then(response2 => {
        if (response2.data.booleanVaule) {
          // setItems(response2.data.result)
          dispatch(setResultCounts(response2.data.count));
          dispatch(getResultFromBack(response2.data.result))
          // setCountItems(response2.data.count)
          // setCountItems2(response2.data.count)

          dispatch(setLoadingPageValue(false))

          setCountQuery(0)
        }
      })

    axios
      .post(process.env.REACT_APP_BACKEND_URL + '/app/subjects/get', formData)
      .then(response2 => {
        if (response2.data.booleanVaule) {
          setSubjects(response2.data.result)
          getCounts('subjects', response2.data.result)
        }
      })
    axios
      .post(process.env.REACT_APP_BACKEND_URL + '/app/publishers/get', formData)
      .then(response2 => {
        if (response2.data.booleanVaule) {
          setPublishers(response2.data.result)
          getCounts('publisher', response2.data.result)
          getCounts('years', yearArray1)
        }
      })
    /////////////////////////////////
    axios
      .post(process.env.REACT_APP_BACKEND_URL + '/app/countries/get', formData)
      .then(response2 => {
        if (response2.data.booleanVaule) {

          setCountries(response2.data.result)
          getCounts('country', response2.data.result)
        }
      })
    /////////////////////////////////
    axios
      .post(process.env.REACT_APP_BACKEND_URL + '/app/authors/get', formData)
      .then(response2 => {
        if (response2.data.booleanVaule) {
          setAuthors(response2.data.result)
          getCounts('author', response2.data.result)
        }
      })
  }
  const removeBox = index => {
    let temp = boxSearch
    // Base64.encode(`"${JSON.stringify(filters)}"`, true)
    temp = temp.filter((i, n) => index != n)
    setBoxSearch(temp)
    temp = Base64.encode(`"${JSON.stringify(temp)}"`, true)
    history.replace({ pathname: `/result/${temp}` })

    dispatch(setCheckedValuesRedux([]))
    setItems(null)
    setKeyNew(temp)
  }
  return (
    <div ref={myRef}>
      {popupShareData !== null ? <PopupShare objPopup={popupShareData} /> : ''}

      <Dialog
        onClose={() => dispatch(setOpenErrorMsgRedux(false))}
        aria-labelledby='customized-Dialog-title'
        open={itemsResult.OpenErrorMsg}
      >
        <DialogTitle
          id='customized-dialog-title'
          onClose={() => dispatch(setOpenErrorMsgRedux(false))}
        >
          <div className='titlePopupMsg'>
            <Error className='iconError' />
            {''}
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <div className='bodyPopupMsg'>{''}</div>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            className='ButtonErrorMsg'
            autoFocus
            onClick={() => dispatch(setOpenErrorMsgRedux(false))}
            color='primary'
          >
            موافق
          </Button>
        </DialogActions>
      </Dialog>

      <Topsection
        data={{ text: 'نتائج البحث', img: require('../assets/images/22.png') }}
      />
      <Breadcrumb data={breadCurmbData} />
      <div
        className='loadingData'
        style={{
          visibility: itemsResult.loadingPage ? 'visible' : 'hidden',
          opacity: itemsResult.loadingPage ? 1 : 0,
          transition: 'visibility 0s, opacity 0.3s linear'
        }}
      >
        <img src={require('../assets/images/bookLoading.gif')} alt='' />
        <div className='textLoading'>
          {activeTab2 === 5
            ? 'جاري التحميل ...  قد يستغرق بعض الوقت'
            : 'جاري التحميل...'}
        </div>
      </div>
      <div className='browse'>
        <div className='searchAdv'>
          <div className='inputGroup'>
            <div className='select222'>
              <select
                name='slct'
                id='slct'
                value={activeTab2}
                onChange={e => {
                  setActiveTab2(e.target.value)
                  localStorage.setItem('ActiveTab2', e.target.value)
                }}
              >
                <option value='1'>في العنوان</option>
                <option value='3'>في المؤلف</option>
                <option value='4'>في الموضوع</option>
                {itemsResult.loginStatus && <option value='5'>في كامل المحتوى</option>}
                <option value='6'>في عنوان المجله</option>
                <option value='7'>في عنوان مؤتمر</option>
              </select>
            </div>

            <input
              type='text'
              placeholder='ابحث في المحتوى الكتروني العربي'
              autoFocus
              value={searchKey.replace(/['"]+/g, '')}
              onChange={e => setSearchKey(e.target.value)}
              onKeyUp={e => {
                if (e.keyCode === 13) searchByKey()
              }}
            />
          </div>
          <LightTooltip
            title='ابحث'
            style={{ cursor: 'pointer' }}
            onClick={() => searchByKey()}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='75'
              height='60'
              viewBox='0 0 60 75'
            >
              <g
                id='Group_9395'
                data-name='Group 9395'
                transform='translate(-190 -541)'
              >
                <circle
                  id='Ellipse_43'
                  data-name='Ellipse 43'
                  cx='37.5'
                  cy='37.5'
                  r='37.5'
                  transform='translate(190 541)'
                  fill='#147bfc'
                />
                <g
                  id='Group_9185'
                  data-name='Group 9185'
                  transform='translate(215.171 566.171)'
                >
                  <circle
                    id='Ellipse_86'
                    data-name='Ellipse 86'
                    cx='10.814'
                    cy='10.814'
                    r='10.814'
                    fill='none'
                    stroke='#fff'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='4'
                  />
                  <line
                    id='Line_5'
                    data-name='Line 5'
                    x2='5.371'
                    y2='5.371'
                    transform='translate(19.286 19.286)'
                    fill='none'
                    stroke='#fff'
                    strokeLinecap='round'
                    strokeWidth='4'
                  />
                </g>
              </g>
            </svg>
          </LightTooltip>
          <div
            className='btnSearchinResult'
            onClick={() =>
              history.push(process.env.REACT_APP_FRONT_URL + 'advancedsearch')
            }
          >
            بحث متقدم
          </div>
        </div>
        <div className='BoxSearch'>
          {boxSearch != null
            ? boxSearch.map((item, index) => {
              if (boxSearch.length === 2) {
                if (parseInt(item.value1) === 0)
                  return (
                    <div className='singleBox'>
                      {'العنوان: '} <b>{item.value3}</b>{' '}
                    </div>
                  )
                if (parseInt(item.value1) === 1)
                  return (
                    <div className='singleBox'>
                      {'الكلمات المفتاحية: '} <b>{item.value3}</b>
                    </div>
                  )
                if (parseInt(item.value1) === 2)
                  return (
                    <div className='singleBox'>
                      {'المؤلف: '} <b>{item.value3}</b>
                    </div>
                  )
                if (parseInt(item.value1) === 3)
                  return (
                    <div className='singleBox'>
                      {'الموضوع: '} <b>{item.value3}</b>
                    </div>
                  )
                if (parseInt(item.value1) === 4)
                  return (
                    <div className='singleBox'>
                      {'الناشر: '} <b>{item.value3}</b>
                    </div>
                  )
              } else {
                if (index === 0) {
                  if (item.value1 === 0) {
                    if (parseInt(item.value1) === 0)
                      return (
                        <div className='singleBox'>
                          {'العنوان: '} <b>{item.value3}</b>{' '}
                          <div
                            className='removeBox'
                            onClick={() => removeBox(index)}
                          >
                            ×
                          </div>
                        </div>
                      )
                    if (parseInt(item.value1) === 1)
                      return (
                        <div className='singleBox'>
                          {'الكلمات المفتاحية: '} <b>{item.value3}</b>
                          <div
                            className='removeBox'
                            onClick={() => removeBox(index)}
                          >
                            ×
                          </div>
                        </div>
                      )
                    if (parseInt(item.value1) === 2)
                      return (
                        <div className='singleBox'>
                          {'المؤلف: '} <b>{item.value3}</b>
                          <div
                            className='removeBox'
                            onClick={() => removeBox(index)}
                          >
                            ×
                          </div>
                        </div>
                      )
                    if (parseInt(item.value1) === 3)
                      return (
                        <div className='singleBox'>
                          {'الموضوع: '} <b>{item.value3}</b>
                          <div
                            className='removeBox'
                            onClick={() => removeBox(index)}
                          >
                            ×
                          </div>
                        </div>
                      )
                    if (parseInt(item.value1) === 4)
                      return (
                        <div className='singleBox'>
                          {'الناشر: '} <b>{item.value3}</b>
                          <div
                            className='removeBox'
                            onClick={() => removeBox(index)}
                          >
                            ×
                          </div>
                        </div>
                      )
                  }
                }
                if (
                  index + 1 == boxSearch.length &&
                  item[0] !== undefined &&
                  (item[0] !== 1931 || item[1] !== 2021)
                ) {
                  return (
                    <div
                      className='singleBox'
                      style={{ padding: '5px 10px' }}
                    >
                      {'سنة النشر: '} <b>{item[0] + ' : ' + item[1]}</b>{' '}
                    </div>
                  )
                } else {
                  if (parseInt(item.value1) === 0)
                    return (
                      <div className='singleBox'>
                        {(parseInt(item.value2) === 0
                          ? 'و '
                          : parseInt(item.value2) === 1
                            ? 'أو '
                            : 'ليس ') + 'العنوان: '}{' '}
                        <b>{item.value3}</b>
                        <div
                          className='removeBox'
                          onClick={() => removeBox(index)}
                        >
                          ×
                        </div>
                      </div>
                    )
                  if (parseInt(item.value1) === 1)
                    return (
                      <div className='singleBox'>
                        {(parseInt(item.value2) === 0
                          ? 'و '
                          : parseInt(item.value2) === 1
                            ? 'أو '
                            : 'ليس ') + 'الكلمات المفتاحية: '}{' '}
                        <b>{item.value3}</b>
                        <div
                          className='removeBox'
                          onClick={() => removeBox(index)}
                        >
                          ×
                        </div>
                      </div>
                    )
                  if (parseInt(item.value1) === 2)
                    return (
                      <div className='singleBox'>
                        {(parseInt(item.value2) === 0
                          ? 'و '
                          : parseInt(item.value2) === 1
                            ? 'أو '
                            : 'ليس ') + 'المؤلف: '}{' '}
                        <b>{item.value3}</b>
                        <div
                          className='removeBox'
                          onClick={() => removeBox(index)}
                        >
                          ×
                        </div>
                      </div>
                    )
                  if (parseInt(item.value1) === 3)
                    return (
                      <div className='singleBox'>
                        {(parseInt(item.value2) === 0
                          ? 'و '
                          : parseInt(item.value2) === 1
                            ? 'أو '
                            : 'ليس ') + 'الموضوع: '}{' '}
                        <b>{item.value3}</b>
                        <div
                          className='removeBox'
                          onClick={() => removeBox(index)}
                        >
                          ×
                        </div>
                      </div>
                    )
                  if (parseInt(item.value1) === 4)
                    return (
                      <div className='singleBox'>
                        {(parseInt(item.value2) === 0
                          ? 'و '
                          : parseInt(item.value2) === 1
                            ? 'أو '
                            : 'ليس ') + 'الناشر: '}{' '}
                        <b>{item.value3}</b>
                        <div
                          className='removeBox'
                          onClick={() => removeBox(index)}
                        >
                          ×
                        </div>
                      </div>
                    )
                }
              }
            })
            : ''}
        </div>
        <div className='reslutlist'>
          <div className='topSec'>
            <div className='titleSec'>تصفية النتائج</div>

            <div className='numberofitem'>
              <div className='itemsCount'>
                {itemsResult.searchTypes[0]
                  ? (itemsResult.countItems[0] > 10 ? itemsResult.countItems[0] * 12 : itemsResult.countItems[0]) +
                  (itemsResult.countItems[1] > 10 ? itemsResult.countItems[1] * 12 : itemsResult.countItems[1]) +
                  (itemsResult.countItems[2] > 10 ? itemsResult.countItems[2] * 12 : itemsResult.countItems[2]) +
                  (itemsResult.countItems[3] > 10 ? itemsResult.countItems[3] * 12 : itemsResult.countItems[3]) + ' '
                  : (itemsResult.searchTypes[1]
                    ? itemsResult.countItems[0] > 10 ?
                      itemsResult.countItems[0] * 12
                      : itemsResult.countItems[0] : 0) +
                  (itemsResult.searchTypes[2] ? itemsResult.countItems[1] > 10 ? itemsResult.countItems[1] * 12 : itemsResult.countItems[1] : 0) +
                  (itemsResult.searchTypes[3] ? itemsResult.countItems[2] > 10 ? itemsResult.countItems[2] * 12 : itemsResult.countItems[2] : 0) +
                  (itemsResult.searchTypes[4] ? itemsResult.countItems[3] > 10 ? itemsResult.countItems[3] * 12 : itemsResult.countItems[3] : 0) +
                  ' '}

                نتيجة
                <br />
                <samp className='timeText'>{itemsResult.timeText}</samp>
              </div>
            </div>

            <Snackbar
              open={open}
              autoHideDuration={3000}
              onClose={() => setOpen(false)}
            >
              <Alert severity='success' color='info'>
                تم نسخ رابط البحث بنجاح!
              </Alert>
            </Snackbar>
            <div className='orderResult'>
              <div
                className='copylink'
                onClick={() => {
                  var dummy = document.createElement('input'),
                    text = window.location.href

                  document.body.appendChild(dummy)
                  dummy.value = text
                  dummy.select()
                  document.execCommand('copy')
                  document.body.removeChild(dummy)
                  setTransition(() => TransitionUp)
                  setOpen(true)
                }}
              >
                <svg
                  id='Group_8870'
                  data-name='Group 8870'
                  xmlns='http://www.w3.org/2000/svg'
                  width='25.13'
                  height='30.395'
                  viewBox='0 0 25.13 30.395'
                >
                  <rect
                    id='Rectangle_323'
                    data-name='Rectangle 323'
                    width='20.382'
                    height='25.647'
                    rx='3'
                    transform='translate(0 4.748)'
                    fill='#147bfc'
                    opacity='0.33'
                  />
                  <rect
                    id='Rectangle_324'
                    data-name='Rectangle 324'
                    width='20.382'
                    height='25.647'
                    rx='3'
                    transform='translate(4.748)'
                    fill='#147bfc'
                  />
                  <g
                    id='Group_8869'
                    data-name='Group 8869'
                    transform='translate(10.191 7.618)'
                  >
                    <line
                      id='Line_20'
                      data-name='Line 20'
                      x2='9.7'
                      transform='translate(0 4.727)'
                      fill='none'
                      stroke='#e2efff'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                    />
                    <line
                      id='Line_21'
                      data-name='Line 21'
                      x2='9.7'
                      fill='none'
                      stroke='#e2efff'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                    />
                    <line
                      id='Line_22'
                      data-name='Line 22'
                      x2='4.411'
                      transform='translate(0 9.454)'
                      fill='none'
                      stroke='#e2efff'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                    />
                  </g>
                </svg>
                نسخ رابط البحث
              </div><br></br>
              {!isMobile && !itemsResult.searchTypes[0] ? (
                <>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='19.687'
                    height='14.872'
                    viewBox='0 0 19.687 14.872'
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      // setItems(null);
                      setSortby({
                        id: sortby.id === -1 ? 1 : -1,
                        value: sortby.value
                      })
                      //   sortData()
                      //items
                      var chars = items

                      chars = chars.sort((a, b) =>
                        sortby.id === -1
                          ? b.yearsValue - a.yearsValue
                          : a.yearsValue - b.yearsValue
                      )

                      setItems([...chars])
                    }}
                  >
                    <g
                      id='Group_9212'
                      data-name='Group 9212'
                      transform='translate(1.414 1)'
                    >
                      <g id='arrow-right' transform='translate(8.933 0)'>
                        <line
                          id='Line_3'
                          data-name='Line 3'
                          y1='12.575'
                          transform='translate(3.963 0.297)'
                          fill='none'
                          stroke={sortby.id === 1 ? '#33384A' : '#87a2be'}
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                        />
                        <path
                          id='Path_3'
                          data-name='Path 3'
                          d='M251.991,489.261l-3.963-3.963-3.963,3.963'
                          transform='translate(-244.066 -485.298)'
                          fill='none'
                          stroke={sortby.id === 1 ? '#33384A' : '#87a2be'}
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                        />
                      </g>
                      <g
                        id='arrow-right-2'
                        data-name='arrow-right'
                        transform='translate(0)'
                      >
                        <line
                          id='Line_3-2'
                          data-name='Line 3'
                          y1='12.575'
                          transform='translate(3.963)'
                          fill='none'
                          stroke={sortby.id === -1 ? '#33384A' : '#87a2be'}
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                        />
                        <path
                          id='Path_3-2'
                          data-name='Path 3'
                          d='M231.881,497.45l3.963,3.963,3.963-3.963'
                          transform='translate(-231.881 -488.541)'
                          fill='none'
                          stroke={sortby.id === -1 ? '#33384A' : '#87a2be'}
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                        />
                      </g>
                    </g>
                  </svg>
                  ترتيب :
                </>
              ) : (
                ''
              )}
              {!itemsResult.searchTypes[0] ? (
                <select
                  className='custom windows'
                  value={sortby.value}
                  onChange={(e) => handlerSubmitSort(e)}
                >
                  <option value='none'>اختر</option>
                  <option value='yearsValue'>تاريخ النشر</option>
                  <option value='nameAr'>العنوان</option>
                  <option value='publisherValue'>الناشر</option>
                  {/* <option value="subjectsValue">الموضوع</option> */}
                </select>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className='bodyResult'>
            <div className='typesReslt'>
              <div className='tab'>
                <div
                  className='head'
                  onClick={() =>
                    tabIndex === 55 ? setTabIndex(null) : setTabIndex(55)
                  }
                >
                  نوع الوثيقة &emsp;
                  {tabIndex === 55 ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='19.358'
                      height='11.179'
                      viewBox='0 0 19.358 11.179'
                    >
                      <path
                        id='Path_3'
                        data-name='Path 3'
                        d='M231.881,505.008l7.558-7.558L247,505.008'
                        transform='translate(-229.76 -495.95)'
                        fill='none'
                        stroke='#147bfc'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='3'
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='19.358'
                      height='11.179'
                      viewBox='0 0 19.358 11.179'
                    >
                      <path
                        id='Path_3'
                        data-name='Path 3'
                        d='M231.881,497.45l7.558,7.558L247,497.45'
                        transform='translate(-229.76 -495.329)'
                        fill='none'
                        stroke='#147bfc'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='3'
                      />
                    </svg>
                  )}
                </div>

                <div className='bodyTab' style={{ overflow: 'hidden' }}>
                  <div
                    className='subBody'
                    style={{
                      maxHeight: tabIndex === 55 ? '250px' : '0',
                      transition: 'all 0.4s',
                      overflowY: 'auto',
                      padding: tabIndex === 55 ? '10px 0' : ''
                    }}
                  >
                    <label className='container' key='0'>
                      <input
                        type='checkbox'
                        onChange={e => handleCheckAllYear("typeCheck")}
                        checked={itemsResult.searchTypesFilter[0]}
                      />
                      <span className='checkmark'></span>

                      الكل

                    </label>
                    {itemsResult.countItems[0] !== 0 ? <label className='container' key='1'>
                      <input
                        className='typeCheck'
                        type='checkbox'
                        onChange={e => changeTypes(1)}
                        checked={itemsResult.searchTypesFilter[1]}
                      />
                      <span className='checkmark'></span>
                      <span className='coount-check'>كتب</span>
                      ({
                        itemsResult.countItems[0] <= 10 ? itemsResult.countItems[0] : itemsResult.countItems[0] * 12})
                    </label> : ""}
                    {itemsResult.countItems[1] !== 0 ? <label className='container' key='2'>
                      <input
                        className='typeCheck'
                        type='checkbox'
                        onChange={e => changeTypes(2)}
                        checked={itemsResult.searchTypesFilter[2]}

                      />
                      <span className='checkmark'></span>
                      <span className='coount-check'>رسائل</span>

                      ({itemsResult.countItems[1] <= 10 ? itemsResult.countItems[1] : itemsResult.countItems[1] * 12})
                    </label> : ""}
                    {itemsResult.countItems[2] !== 0 ? <label className='container' key='3'>
                      <input
                        className='typeCheck'
                        type='checkbox'
                        onChange={e => changeTypes(3)}
                        checked={itemsResult.searchTypesFilter[3]}
                      />
                      <span className='checkmark'></span>
                      <span className='coount-check'> مقالات مجلات  </span>
                      ({
                        itemsResult.countItems[2] <= 10 ? itemsResult.countItems[2] : itemsResult.countItems[2] * 12

                      })
                    </label> : ""}
                    {itemsResult.countItems[3] !== 0 ? <label className='container' key='4'>
                      <input
                        type='checkbox'
                        className='typeCheck'
                        onChange={e => changeTypes(4)}
                        checked={itemsResult.searchTypesFilter[4]}
                      />
                      <span className='checkmark'></span>
                      <span className='coount-check'> أبحاث مؤتمرات</span>
                      ({
                        itemsResult.countItems[3] <= 10 ? itemsResult.countItems[3] : itemsResult.countItems[3] * 12})
                    </label> : ""}
                  </div>
                </div>
              </div>

              <div className='tab'>
                <div
                  className='head'
                  onClick={() => {
                    tabIndex === 0 ? setTabIndex(null) : setTabIndex(0)
                    // if (tabIndex !== 0)
                    //     getCounts('subjects', subjects);
                  }}
                >
                  المواضيع &emsp;
                  {tabIndex === 0 ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='19.358'
                      height='11.179'
                      viewBox='0 0 19.358 11.179'
                    >
                      <path
                        id='Path_3'
                        data-name='Path 3'
                        d='M231.881,505.008l7.558-7.558L247,505.008'
                        transform='translate(-229.76 -495.95)'
                        fill='none'
                        stroke='#147bfc'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='3'
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='19.358'
                      height='11.179'
                      viewBox='0 0 19.358 11.179'
                    >
                      <path
                        id='Path_3'
                        data-name='Path 3'
                        d='M231.881,497.45l7.558,7.558L247,497.45'
                        transform='translate(-229.76 -495.329)'
                        fill='none'
                        stroke='#147bfc'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='3'
                      />
                    </svg>
                  )}
                </div>

                <div className='bodyTab' style={{ overflow: 'hidden' }}>
                  <div
                    className='subBody'
                    style={{
                      maxHeight: tabIndex === 0 ? '250px' : '0',
                      transition: 'all 0.4s',
                      overflowY: 'auto',
                      padding: tabIndex === 0 ? '10px 0' : ''
                    }}
                  >
                    <label className='container' key='0'>
                      <input
                        type='checkbox'
                        onChange={e => dispatch(setCheckedValuesRedux([]))}
                        onClick={() => handleCheckAllYear("checksubject")}

                        checked={itemsResult.checkedValues.length === 0 && filterCheck.length === 0}
                      />
                      <span className='checkmark'></span>
                      الكل
                    </label>
                    {itemsResult.subjects !== null
                      ? itemsResult.subjects.map(item => (
                        <div
                          style={
                            {
                              // display: item.count === '0' ? 'none' : 'block',
                              // height: item.count === '0' ? '0' : 'auto',
                              // opacity: item.count === '0' ? 0 : 1,
                              // transition: 'opacity 0.8s'
                            }
                          }
                          key={item._id}
                        >
                          <label className='container'>
                            <input
                              //  checked={filterCheck.filter(pub=>item._id === pub).length === 0 ? false : true}
                              type='checkbox'
                              className='checksubject'
                              onChange={e => {
                                filtterBySubjects(e, item._id, '')
                              }}
                            />
                            <span className='checkmark'></span>
                            {item.name}
                            {/* ({item.count}) */}
                          </label>
                          <div className='subSubjectsFilters'>
                            {item.subSubjects?.map(item2 => (
                              <label
                                className='container'
                                style={
                                  {
                                    // display: item2.count === '0' ? 'none' : 'block',
                                    // height: item2.count === '0' ? 0 : 'auto',
                                    // opacity: item2.count === '0' ? 0 : 1,
                                    // transition: 'opacity 0.8s'
                                  }
                                }
                              >
                                <input
                                  //  checked={filterCheck.filter(pub=>item2.id === parseInt(pub)).length === 0 ? false : true}
                                  type='checkbox'
                                  className='checksubject'
                                  onChange={e => {
                                    filtterBySubjects(e, item._id, item2.id)
                                  }}
                                />
                                <span className='checkmark'></span>
                                {item2.NameAR}
                                {/* ({item2.count !== undefined ? item2.count : '0'}) */}
                              </label>
                            ))}
                          </div>
                        </div>
                      ))
                      : ''}
                  </div>
                </div>
              </div>
              <div className='tab'>
                <div
                  className='head'
                  onClick={() => {
                    tabIndex === 2 ? setTabIndex(null) : setTabIndex(2)
                    // getCounts('publisher', publishers);
                  }}
                >
                  الناشر &emsp;
                  {tabIndex === 2 ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='19.358'
                      height='11.179'
                      viewBox='0 0 19.358 11.179'
                    >
                      <path
                        id='Path_3'
                        data-name='Path 3'
                        d='M231.881,505.008l7.558-7.558L247,505.008'
                        transform='translate(-229.76 -495.95)'
                        fill='none'
                        stroke='#147bfc'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='3'
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='19.358'
                      height='11.179'
                      viewBox='0 0 19.358 11.179'
                    >
                      <path
                        id='Path_3'
                        data-name='Path 3'
                        d='M231.881,497.45l7.558,7.558L247,497.45'
                        transform='translate(-229.76 -495.329)'
                        fill='none'
                        stroke='#147bfc'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='3'
                      />
                    </svg>
                  )}
                </div>
                <div className='bodyTab' style={{ overflow: 'hidden' }}>
                  <div
                    className='subBody'
                    style={{
                      maxHeight: tabIndex === 2 ? '250px' : '0',
                      transition: 'all 0.4s',
                      overflowY: 'auto',
                      padding: tabIndex === 2 ? '10px 0' : ''
                    }}
                  >
                    <label className='container' key='0'>
                      <input
                        type='checkbox'
                        onChange={e => dispatch(setCheckedValues2Redux([]))}
                        checked={itemsResult.checkedValues2.length === 0 && itemsResult.filterCheck2.length === 0}
                        onClick={() => handleCheckAllYear("checkpublisher")}
                      />
                      <span className='checkmark'></span>
                      الكل
                    </label>
                    {itemsResult.publishers !== null
                      ? itemsResult.publishers.map(item => (
                        <label
                          className='container'
                          key={item._id}
                          style={
                            {
                              // visibility: item.count === '0' ? 'hidden' : 'visible',
                              // height: item.count === '0' ? 0 : 'auto',
                              // opacity: item.count === '0' ? 0 : 1,
                              // transition: 'opacity 0.8s'
                            }
                          }
                        >
                          <input
                            type='checkbox'
                            checked={itemsResult.filterCheck2.filter(pub => item._id === pub).length === 0 && itemsResult.checkedValues2.filter(pub => item._id === pub).length === 0 ? false : true}
                            className='checkpublisher'
                            onChange={e => {
                              filtterByPublisher(e, item._id)
                            }}
                          />
                          <span className='checkmark'></span>
                          {item.name}
                          {/* ({item.count}) */}
                        </label>
                      ))
                      : ''}
                  </div>
                </div>
              </div>

              <div className='tab'>
                <div
                  className='head'
                  onClick={() => {
                    tabIndex === 3 ? setTabIndex(null) : setTabIndex(3)
                    // getCounts('country', countries);
                  }}
                >
                  بلد النشر &emsp;
                  {tabIndex === 3 ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='19.358'
                      height='11.179'
                      viewBox='0 0 19.358 11.179'
                    >
                      <path
                        id='Path_3'
                        data-name='Path 3'
                        d='M231.881,505.008l7.558-7.558L247,505.008'
                        transform='translate(-229.76 -495.95)'
                        fill='none'
                        stroke='#147bfc'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='3'
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='19.358'
                      height='11.179'
                      viewBox='0 0 19.358 11.179'
                    >
                      <path
                        id='Path_3'
                        data-name='Path 3'
                        d='M231.881,497.45l7.558,7.558L247,497.45'
                        transform='translate(-229.76 -495.329)'
                        fill='none'
                        stroke='#147bfc'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='3'
                      />
                    </svg>
                  )}
                </div>
                <div className='bodyTab' style={{ overflow: 'hidden' }}>
                  <div
                    className='subBody'
                    style={{
                      maxHeight: tabIndex === 3 ? '250px' : '0',
                      transition: 'all 0.4s',
                      overflowY: 'auto',
                      padding: tabIndex === 3 ? '10px 0' : ''
                    }}
                  >
                    <label className='container' key='0'>
                      <input
                        type='checkbox'
                        onChange={e => dispatch(setCheckedValues3Redux([]))}
                        onClick={() => handleCheckAllYear("checkcountries")}
                        checked={itemsResult.checkedValues3.length === 0 && itemsResult.filterCheck3.length == 0}
                      />
                      <span className='checkmark'></span>
                      الكل
                    </label>
                    {itemsResult.countries !== null
                      ? Array.from(new Set(itemsResult.countries.map(d => d.name))).map(name => {
                        return itemsResult.countries.find(d => d.name === name);
                      })?.map(item => (
                        <label
                          className='container'
                          key={item?._id}
                          style={
                            {
                              // visibility: item.count === '0' ? 'hidden' : 'visible',
                              // height: item.count === '0' ? 0 : 'auto',
                              // opacity: item.count === '0' ? 0 : 1,
                              // transition: 'opacity 0.8s'
                            }
                          }
                        >
                          <input
                            className='checkcountries'
                            type='checkbox'
                            checked={itemsResult.filterCheck3.filter(pub => item._id === pub).length === 0 && itemsResult.checkedValues3.filter(pub => item._id === pub).length === 0 ? false : true}
                            onChange={e => {
                              filtterByCountries(e, item._id)
                            }}
                          />
                          <span className='checkmark'></span>
                          {item?.name}
                          {/* ({item.count}) */}
                        </label>
                      ))
                      : ''}
                  </div>
                </div>
              </div>

              <div className='tab'>
                <div
                  className='head'
                  onClick={() => {
                    tabIndex === 4 ? setTabIndex(null) : setTabIndex(4)
                    // getCounts('years', yearArray1);
                  }}
                >
                  تاريخ النشر &emsp;
                  {tabIndex === 4 ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='19.358'
                      height='11.179'
                      viewBox='0 0 19.358 11.179'
                    >
                      <path
                        id='Path_3'
                        data-name='Path 3'
                        d='M231.881,505.008l7.558-7.558L247,505.008'
                        transform='translate(-229.76 -495.95)'
                        fill='none'
                        stroke='#147bfc'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='3'
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='19.358'
                      height='11.179'
                      viewBox='0 0 19.358 11.179'
                    >
                      <path
                        id='Path_3'
                        data-name='Path 3'
                        d='M231.881,497.45l7.558,7.558L247,497.45'
                        transform='translate(-229.76 -495.329)'
                        fill='none'
                        stroke='#147bfc'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='3'
                      />
                    </svg>
                  )}
                </div>
                <div className='bodyTab' style={{ overflow: 'hidden' }}>
                  <div
                    className='subBody'
                    style={{
                      maxHeight: tabIndex === 4 ? '250px' : '0',
                      transition: 'all 0.4s',
                      overflowY: 'auto',
                      padding: tabIndex === 4 ? '10px 0' : ''
                    }}
                  >

                    <label className='container' key='0'>
                      <input
                        className='checkedAllyear'
                        type='checkbox'
                        onChange={e => dispatch((setCheckedValues4Redux([])))}
                        checked={itemsResult.checkedValues4.length === 0 && itemsResult.filterCheck4.length === 0}
                        onClick={() => handleCheckAllYear("checkyear")}

                      />
                      <span className='checkmark'></span>
                      الكل
                    </label>
                    {yearArray1 !== null
                      ? yearArray1.map(item => (
                        <label
                          className='container'
                          key={item.id}
                          style={
                            {
                              // visibility: item.count === '0' ? 'hidden' : 'visible',
                              // height: item.count === '0' ? 0 : 'auto',
                              // opacity: item.count === '0' ? 0 : 1,
                              // transition: 'opacity 0.8s'
                            }
                          }
                        >
                          <input
                            className='checkyear'
                            type='checkbox'
                            checked={itemsResult.checkedValues4?.filter((pup) => String(item?.id) === String(pup)).length === 0 && itemsResult.filterCheck4?.filter((pup) => String(item?.id) === String(pup)).length === 0 ? false : true}
                            onChange={e => {
                              filtterByYears(e, item.id)
                            }}
                          />
                          <span className='checkmark'></span>
                          {item.title}
                          {/* ({item.count}) */}
                        </label>
                      ))
                      : ''}
                  </div>
                </div>
              </div>

              <div className='tab' style={{ display: 'none' }}>
                <div
                  className='head'
                  onClick={() => {
                    tabIndex === 5 ? setTabIndex(null) : setTabIndex(5)
                    getCounts('author', authors)
                  }}
                >
                  المؤلف &emsp;
                  {tabIndex === 5 ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='19.358'
                      height='11.179'
                      viewBox='0 0 19.358 11.179'
                    >
                      <path
                        id='Path_3'
                        data-name='Path 3'
                        d='M231.881,505.008l7.558-7.558L247,505.008'
                        transform='translate(-229.76 -495.95)'
                        fill='none'
                        stroke='#147bfc'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='3'
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='19.358'
                      height='11.179'
                      viewBox='0 0 19.358 11.179'
                    >
                      <path
                        id='Path_3'
                        data-name='Path 3'
                        d='M231.881,497.45l7.558,7.558L247,497.45'
                        transform='translate(-229.76 -495.329)'
                        fill='none'
                        stroke='#147bfc'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='3'
                      />
                    </svg>
                  )}
                </div>

                <div className='bodyTab' style={{ overflow: 'hidden' }}>
                  <div
                    className='subBody'
                    style={{
                      maxHeight: tabIndex === 5 ? '250px' : '0',
                      transition: 'all 0.4s',
                      overflowY: 'auto',
                      padding: tabIndex === 5 ? '10px 0' : ''
                    }}
                  >
                    <label className='container' key='0'>
                      <input
                        type='checkbox'
                        onChange={e => setCheckedValues5([])}
                        checked={checkedValues5.length === 0}
                      />
                      <span className='checkmark'></span>
                      الكل
                    </label>
                    {authors !== null
                      ? authors.map(item => (
                        <label
                          className='container'
                          key={item._id}
                          style={{
                            visibility:
                              item.count === '0' ? 'hidden' : 'visible',
                            height: item.count === '0' ? 0 : 'auto',
                            opacity: item.count === '0' ? 0 : 1,
                            transition: 'opacity 0.8s'
                          }}
                        >
                          <input
                            type='checkbox'
                            onChange={e => {
                              filtterbyAuthors(e, item._id)
                            }}
                          />
                          <span className='checkmark'></span>
                          {item.name}
                          {/* ({item.count}) */}
                        </label>
                      ))
                      : ''}
                  </div>
                </div>
              </div>

            </div>

            <div className='results'>
              <div className="titleSec" style={{ marginTop: "30px", marginBottom: "30px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <h3 style={{ opacity: "0.7" }} >{itemsResult.nameConferences}</h3>
                <div style={{ width: "300px" }}>
                  {itemsResult.coverConferences !== '' ? (
                    itemsResult.coverConferences !== undefined &&
                      itemsResult.coverConferences !== '' ? (
                      <CustomImage
                        src={"https://apis.thelearnbook.com/public/" + itemsResult.coverConferences}
                        effect='opacity'
                        style={{ cursor: 'pointer', width: "80px" }}
                      />
                    ) : (

                      <LazyLoadImage
                        src={
                          'https://apis.thelearnbook.com/public/learnBookCover.jpg'
                        }
                        effect='blur'
                        style={{ cursor: 'pointer', height: '100%' }}

                      />
                    )
                  ) : (
                    ''
                  )}
                </div>
              </div>
              {itemsResult.items === null ? (
                ['', '', ''].map((item, index) => (
                  <div className='itemResult' key={index}>
                    <div className='imgandRead'>
                      <Skeleton
                        variant='rect'
                        width={155}
                        height={220}
                        animation='wave'
                        style={{ borderRadius: '10px' }}
                      />
                    </div>
                    <div className='centerSection'>
                      <div className='rowInSec titleCountent boldFont'>
                        <Skeleton
                          variant='text'
                          width={280}
                          height={60}
                          style={{ borderRadius: '5px' }}
                        />
                      </div>

                      <div className='rowInSec w-60'>
                        <Skeleton
                          variant='text'
                          width={210}
                          height={40}
                          style={{ borderRadius: '5px' }}
                        />
                      </div>

                      <div className='rowInSec w-60'>
                        <Skeleton
                          variant='text'
                          width={210}
                          height={40}
                          style={{ borderRadius: '5px' }}
                        />
                      </div>

                      <div className='rowInSec  w-60'>
                        <div>
                          <Skeleton
                            variant='text'
                            width={250}
                            height={40}
                            style={{ borderRadius: '5px' }}
                          />
                        </div>
                      </div>

                      <div className='rowInSec  w-60'>
                        <Skeleton
                          variant='text'
                          width={260}
                          height={40}
                          style={{ borderRadius: '5px' }}
                        />
                      </div>

                      <div className='rowInSec'>
                        <div className='tags'>
                          <Skeleton
                            variant='text'
                            width={80}
                            height={50}
                            style={{ borderRadius: '5px' }}
                          />
                          <Skeleton
                            variant='text'
                            width={80}
                            height={50}
                            style={{ borderRadius: '5px', margin: '0 1vw' }}
                          />
                          <Skeleton
                            variant='text'
                            width={80}
                            height={50}
                            style={{ borderRadius: '5px', margin: '0 1vw' }}
                          />
                          <Skeleton
                            variant='text'
                            width={80}
                            height={50}
                            style={{ borderRadius: '5px', margin: '0 1vw' }}
                          />
                          <Skeleton
                            variant='text'
                            width={80}
                            height={50}
                            style={{ borderRadius: '5px', margin: '0 1vw' }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='leftSec'>
                      <Skeleton
                        variant='text'
                        width={80}
                        height={60}
                        style={{ borderRadius: '5px' }}
                      />
                      <Skeleton
                        variant='circle'
                        width={35}
                        height={35}
                        style={{ marginBottom: '10px' }}
                      />
                      <Skeleton
                        variant='circle'
                        width={35}
                        height={35}
                        style={{ marginBottom: '10px' }}
                      />
                      <Skeleton
                        variant='circle'
                        width={35}
                        height={35}
                        style={{ marginBottom: '10px' }}
                      />
                    </div>
                  </div>
                ))
              ) : itemsResult.countItems[0] +
                itemsResult.countItems[1] +
                itemsResult.countItems[2] +
                itemsResult.countItems[3] ===
                0 && itemsResult.items.length === 0 ? (
                <div className='noResult'>نأسف، لا يوجد نتائج.</div>
              ) : (
                <InfiniteScroll
                  dataLength={itemsResult.items.length}
                  next={
                    itemsResult.countItems[0] +
                      itemsResult.countItems[1] +
                      itemsResult.countItems[2] +
                      itemsResult.countItems[3] !=
                      itemsResult.items.length ||
                      (itemsResult.countItems[0] > 3 &&
                        itemsResult.countItems[1] > 3 &&
                        itemsResult.countItems[2] > 3 &&
                        itemsResult.countItems[3])
                      ? nextResult
                      : ''
                  }
                  hasMore={true}
                  loader={
                    (itemsResult.countItems[0] +
                      itemsResult.countItems[1] +
                      itemsResult.countItems[2] +
                      itemsResult.countItems[3]) ===
                      itemsResult.items?.length ? (
                      <h4 style={{ textAlign: 'center' }}>نهاية النتائج</h4>
                    ) : (

                      <center>
                        <img
                          src={require('../assets/images/load-loadin1g.gif')}
                          alt='loadin1g'
                        />
                      </center>
                    )
                  }
                >
                  {itemsResult.items?.map((item, index) => (
                    <div key={index}>
                      <div className='itemResult'>
                        <div className='imgandRead'>
                          {item !== null ? (
                            item.s3objcover !== undefined &&
                              item.s3objcover !== '' ? (
                              <CustomImage
                                src={
                                  item !== null
                                    ? item.s3objcover !== undefined
                                      ? item.s3objcover
                                      : ''
                                    : ''
                                }
                                src2={
                                  item.s3objcover !== 'undefined'
                                    ? item.s3objcover
                                    : process.env.REACT_APP_BACKEND_URL +
                                    '/public/' +
                                    'learnBookCover.jpg'
                                }
                                effect='opacity'
                                onClick={() =>
                                  history.push(
                                    '/view/' +
                                    item._id.split('_')[1] +
                                    '/' +
                                    item._id.split('_')[0]
                                  )
                                }
                                style={{ cursor: 'pointer' }}
                              />
                            ) : (

                              <LazyLoadImage
                                src={
                                  'https://apis.thelearnbook.com/public/learnBookCover.jpg'
                                }
                                key={index}
                                effect='blur'
                                onClick={() =>
                                  history.push(
                                    '/view/' +
                                    item._id.split('_')[1] +
                                    '/' +
                                    item._id.split('_')[0]
                                  )
                                }
                                style={{ cursor: 'pointer', height: '100%' }}

                              />
                            )
                          ) : (
                            ''
                          )}

                          <div
                            className='btnRead'
                            onClick={() =>
                              history.push(
                                '/ViewBook/' +
                                item._id.split('_')[1] +
                                '/' +
                                item._id.split('_')[0] +
                                '/' + Base64.encode(`${item.yearsValue}`, true))

                            }
                          >
                            اقرأ الوثيقة
                          </div>
                        </div>
                        <div className='centerSection'>

                          <a onClick={() => history.push('/view/' +
                            item._id.split('_')[1] +
                            '/' +
                            item._id.split('_')[0])} >
                            <div
                              className='rowInSec titleCountent boldFont'
                              // onClick={() =>
                              //   window.open(
                              //     '/view/' +
                              //       item._id.split('_')[1] +
                              //       '/' +
                              //       item._id.split('_')[0],"_blank"
                              //   )
                              // }
                              style={{ cursor: 'pointer' }}
                            >
                              {item.highlightsTitle?.length !== 0 &&
                                item.highlightsTitle !== undefined
                                ? item.highlightsTitle?.map(item => (
                                  <div>
                                    {item.texts.map(i => (
                                      <span className={`titleHigh${i.type}`}>
                                        {i.value}
                                      </span>
                                    ))}
                                  </div>
                                ))
                                : item.highlights?.length !== 0 &&
                                  item.highlights !== undefined &&
                                  item.highlights.filter(u => u.path === 'nameAr')
                                    .length != 0
                                  ? item.highlights?.map(item => (
                                    <div>
                                      {item.path === 'nameAr'
                                        ? item.texts.map(i => (
                                          <span
                                            className={`titleHigh${i.type}`}
                                          >
                                            {i.value}
                                          </span>
                                        ))
                                        : item.nameAr}
                                    </div>
                                  ))
                                  : item.nameAr}
                            </div>
                          </a>

                          {item.authorValueNew != '' &&
                            item.authorValueNew !== undefined ? (
                            <div className='rowInSec w-60' key={index}>
                              <div>
                                مؤلف:&nbsp;
                                {item.authorValueNew !== null
                                  ? item.authorValueNew.map((i, index) =>
                                    item.authorValueNew.length - 1 !=
                                      index ? (
                                      <>
                                        <a
                                          onClick={() =>
                                            history.push(
                                              '/browseBy/' +
                                                i.name._id +
                                                '/' +
                                                'authorValue' +
                                                '/' +
                                                i.name !=
                                                null
                                                ? i.name.nameAr
                                                : ''
                                            )
                                          }
                                        >
                                          {i.name != null
                                            ? i.name.nameAr
                                            : ''}
                                        </a>
                                        <b>،</b>{' '}
                                      </>
                                    ) : (
                                      <>
                                        <a
                                          onClick={() =>
                                            history.push(
                                              '/browseBy/' +
                                                i.name._id +
                                                '/' +
                                                'authorValue' +
                                                '/' +
                                                i.name !=
                                                null
                                                ? i.name.nameAr
                                                : ''
                                            )
                                          }
                                        >
                                          {i.name != null
                                            ? i.name.nameAr
                                            : ''}
                                        </a>{' '}
                                      </>
                                    )
                                  )
                                  : ''}
                              </div>
                            </div>
                          ) : (
                            ''
                          )}

                          {item.publishersOBJ != undefined ||
                            item.publishersOBJ.length != 0 ? (
                            <div className='rowInSec w-60'>
                              <div>
                                الناشر:&nbsp;
                                {item.publishersOBJ.map((item333, index) => (
                                  <a
                                    onClick={() =>
                                      history.push(
                                        '/browseBy/' +
                                        item333._id +
                                        '/' +
                                        'publisherValue' +
                                        '/' +
                                        item333.nameAr
                                      )
                                    }
                                  >
                                    {item.publishersOBJ.length != 0
                                      ? item333.nameAr
                                      : ''}

                                    {index + 1 < item.publishersOBJ.length
                                      ? '، '
                                      : ' '}
                                  </a>
                                ))}
                              </div>
                            </div>
                          ) : (
                            ''
                          )}

                          {item.yearsValue != 'null' && item.yearsValue !== 0 ? (
                            <div className='rowInSec w-60'>
                              <div>
                                تاريخ النشر:&nbsp;
                                <a
                                  onClick={() =>
                                    history.push(
                                      '/browseBy/' +
                                      item.yearsValue +
                                      '/' +
                                      'years' +
                                      '/' +
                                      item.yearsValue
                                    )
                                  }
                                >
                                  {' '}
                                  {item.yearsValue}{' '}
                                </a>
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                          {item.titleDawreaa?.nameAr && <div className="rowInSec  w-60">
                            {
                              item.titleDawreaa !== '' && item.titleDawreaa !== 'null' ?
                                <div style={{ display: 'flex', flexDirection: 'row' }}>{item.titleDawreaa !== '' ? 'اسم المجله' : ''}: &nbsp;<div style={{ color: '#147BFC' }}>  {item.titleDawreaa?.nameAr}  </div></div>
                                : ''
                            }
                          </div>}
                          {item.conferencesObj?.nameAr && <div className="rowInSec  w-40">
                            {
                              item.conferencesObj !== '' && item.conferencesObj !== 'null' ?
                                <div style={{ display: 'flex', flexDirection: 'row' }}>{item.conferencesObj !== '' ? 'اسم المؤتمر' : ''}: &nbsp;<div style={{ color: '#147BFC' }}>  {item.conferencesObj?.nameAr}  </div></div>
                                : ''
                            }
                          </div>
                          }

                          {item.degree != 'null' &&
                            item.degree != null &&
                            item.degree != undefined ? (
                            <div className='rowInSec w-60'>
                              <div>
                                نوع البرنامج:&nbsp;
                                <a
                                  onClick={() =>
                                    history.push(
                                      '/browseBy/' +
                                      item.degree +
                                      '/' +
                                      'degree' +
                                      '/' +
                                      item.degree
                                    )
                                  }
                                >
                                  {' '}
                                  {item.degree}{' '}
                                </a>
                              </div>
                            </div>
                          ) : (
                            ''
                          )}

                          {item.hhh !== undefined ? (
                            item.hhh.length > 0 ? (
                              <div className='rowInSec  w-60'>
                                <div>
                                  المواضيع الرئيسية:&nbsp;
                                  {item.hhh.map((i, index) =>
                                    item.hhh.length - 1 != index ? (
                                      <span key={index}>
                                        <a
                                          onClick={() =>
                                            history.push(
                                              '/browseBy/' +
                                              i.subject._id +
                                              '/' +
                                              'subjectsValue' +
                                              '/' +
                                              i.subject.nameAr
                                            )
                                          }
                                        >
                                          {i.subject.nameAr} |{' '}
                                          {i.subSubject[0].NameAR}
                                        </a>{' '}
                                        ,
                                      </span>
                                    ) : (
                                      <span key={index}>
                                        <a
                                          onClick={() =>
                                            history.push(
                                              '/browseBy/' +
                                              i.subject._id +
                                              '/' +
                                              'subjectsValue' +
                                              '/' +
                                              i.subject.nameAr
                                            )
                                          }
                                        >
                                          {i.subject?.nameAr} |{' '}
                                          {i.subSubject[0]?.NameAR}
                                        </a>
                                      </span>
                                    )
                                  )}
                                </div>
                              </div>
                            ) : (
                              ''
                            )
                          ) : (
                            ''
                          )}

                          <div className='rowInSec  w-60'>
                            {item.ISBNValue !== '' &&
                              item.ISBNValue !== 'null' &&
                              item.ISBNValue !== null ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row'
                                }}
                              >
                                {activeTab === 3 ? 'ISSN' : 'ISBN'}: &nbsp;
                                <div> {item.ISBNValue} </div>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                          {item.tagsValue.length > 0 ? (
                            <div className='rowInSec'>
                              <div className='tags'>
                                كلمات مفتاحية:
                                {item.tagsValue.map((i, index) => (
                                  <div
                                    key={i._id}
                                    className='tag'
                                    onClick={() =>
                                      history.push(
                                        '/browseBy/' +
                                        i._id +
                                        '/' +
                                        'tags' +
                                        '/' +
                                        i.nameAr
                                      )
                                    }
                                  >
                                    {i.nameAr}
                                  </div>
                                ))}
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className='leftSec'>
                          {item.type === 'book' ? (
                            <div
                              className='btnBook'
                              onClick={() => changeTypes(1)}
                            >
                              كتاب
                            </div>
                          ) : item.type === 'msg' ? (
                            <div
                              className='btnMag'
                              onClick={() => changeTypes(2)}
                            >
                              رسالة
                            </div>
                          ) : item.type === 'journals' ? (
                            <div
                              className='btnma'
                              onClick={() => changeTypes(3)}
                            >
                              مقال مجلة
                            </div>
                          ) : (
                            <div
                              className='btnma2'
                              onClick={() => changeTypes(4)}
                            >
                              مقال مؤتمر{' '}
                            </div>
                          )}

                          <LightTooltip title='معلومات الوثيقة'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='29'
                              height='29'
                              viewBox='0 0 29 29'
                              onClick={() =>
                                history.push(
                                  '/view/' +
                                  item._id.split('_')[1] +
                                  '/' +
                                  item._id.split('_')[0]
                                )
                              }
                            >
                              <g
                                id='Group_8833'
                                data-name='Group 8833'
                                transform='translate(1 1.273)'
                              >
                                <g id='Group_111' data-name='Group 111'>
                                  <circle
                                    id='Ellipse_28'
                                    data-name='Ellipse 28'
                                    cx='13.5'
                                    cy='13.5'
                                    r='13.5'
                                    transform='translate(0 -0.273)'
                                    fill='none'
                                    stroke='#727c89'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='2'
                                  />
                                </g>
                                <g
                                  id='Group_112'
                                  data-name='Group 112'
                                  transform='translate(10.216 6.652)'
                                >
                                  <ellipse
                                    id='Ellipse_29'
                                    data-name='Ellipse 29'
                                    cx='1.65'
                                    cy='1.553'
                                    rx='1.65'
                                    ry='1.553'
                                    transform='translate(2.665)'
                                    fill='#727c89'
                                  />
                                  <path
                                    id='Path_3385'
                                    data-name='Path 3385'
                                    d='M992.8,717.719c-.013.283.295,1.18,1.892.686a.115.115,0,0,1,.145.142l-.093.34a.38.38,0,0,1-.229.256,8.211,8.211,0,0,1-2.623.578,1.757,1.757,0,0,1-1.942-1.551c-.006-.055-.01-.11-.011-.165.2-1.776,1.108-4.757,1.119-5.313.006-.283-.294-1.179-1.891-.686a.115.115,0,0,1-.144-.078.11.11,0,0,1,0-.065l.093-.339a.38.38,0,0,1,.229-.256,8.207,8.207,0,0,1,2.623-.578,1.763,1.763,0,0,1,1.943,1.561c.005.051.009.1.01.155C993.922,714.138,992.857,716.538,992.8,717.719Z'
                                    transform='translate(-989.021 -706.092)'
                                    fill='#727c89'
                                  />
                                </g>
                              </g>
                            </svg>
                          </LightTooltip>
                          <LightTooltip title='مشاركة'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='28.701'
                              height='28.664'
                              viewBox='0 0 28.701 28.664'
                              onClick={() => {
                                setPopupShareData(null)
                                setTimeout(() => {
                                  setPopupShareData({
                                    showPopup: true,
                                    Item: item,
                                    id: item._id,
                                    type: `${item.type}s`,
                                    loginStatus: () => { }
                                  })
                                }, 100)
                              }}
                            >
                              <g
                                id='Group_8832'
                                data-name='Group 8832'
                                transform='translate(1 1.026)'
                              >
                                <path
                                  id='Path_9495'
                                  data-name='Path 9495'
                                  d='M937.98,693.32l-7.484-7.772a.863.863,0,0,0-1.485.6v3.742h-.288A11.239,11.239,0,0,0,917.5,701.115v1.727a.851.851,0,0,0,.672.825.832.832,0,0,0,.19.023.891.891,0,0,0,.789-.49,9.445,9.445,0,0,1,8.495-5.251h1.368v3.742a.863.863,0,0,0,1.485.6l7.484-7.772A.863.863,0,0,0,937.98,693.32Z'
                                  transform='translate(-911.622 -685.283)'
                                  fill='none'
                                  stroke='#727c89'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  strokeWidth='2'
                                />
                                <path
                                  id='Path_9496'
                                  data-name='Path 9496'
                                  d='M910.642,696.657H907.96a2.3,2.3,0,0,0-2.3,2.3v16.39a2.3,2.3,0,0,0,2.3,2.3h21a2.3,2.3,0,0,0,2.3-2.3v-7.2'
                                  transform='translate(-905.659 -691.012)'
                                  fill='none'
                                  stroke='#727c89'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  strokeWidth='2'
                                />
                              </g>
                            </svg>
                          </LightTooltip>
                          {/* <a href={'http://localhost:3001'+'/view/' + item._id.split('_')[1] + '/' + item._id.split('_')[0]} target='_blank'> */}
                          <LightTooltip title='تصفحها في نافذه جديده'>
                            <svg
                              onClick={() =>
                                window.open(
                                  '/view/' + item._id.split('_')[1] + '/' + item._id.split('_')[0],
                                  "_blank", 'noopener,noreferrer'
                                )
                              }
                              width='60.701'
                              height='60.664'
                              viewBox='0 0 28.701 28.664'
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path fillRule="evenodd" clipRule="evenodd" d="M12 13C12.5523 13 13 12.5523 13 12V3C13 2.44771 12.5523 2 12 2H3C2.44771 2 2 2.44771 2 3V6.5C2 6.77614 2.22386 7 2.5 7C2.77614 7 3 6.77614 3 6.5V3H12V12H8.5C8.22386 12 8 12.2239 8 12.5C8 12.7761 8.22386 13 8.5 13H12ZM9 6.5C9 6.5001 9 6.50021 9 6.50031V6.50035V9.5C9 9.77614 8.77614 10 8.5 10C8.22386 10 8 9.77614 8 9.5V7.70711L2.85355 12.8536C2.65829 13.0488 2.34171 13.0488 2.14645 12.8536C1.95118 12.6583 1.95118 12.3417 2.14645 12.1464L7.29289 7H5.5C5.22386 7 5 6.77614 5 6.5C5 6.22386 5.22386 6 5.5 6H8.5C8.56779 6 8.63244 6.01349 8.69139 6.03794C8.74949 6.06198 8.80398 6.09744 8.85143 6.14433C8.94251 6.23434 8.9992 6.35909 8.99999 6.49708L8.99999 6.49738" fill="currentColor" />
                            </svg>
                          </LightTooltip>

                        </div>
                      </div>
                      {item.highlights?.length !== 0 &&
                        item.highlights !== undefined ? (
                        <div className='highlight' style={{ color: '#777' }}>
                          {item.highlights?.map((item, index) => (
                            <div>
                              {item.path === 'body'
                                ? index < 3
                                  ? item.texts.map(i => (
                                    <span className={i.type}>
                                      {i.value.substring(0, 50)}{' '}
                                    </span>
                                  ))
                                  : ''
                                : ''}
                            </div>
                          ))}
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  ))}
                </InfiniteScroll>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Browse