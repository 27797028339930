import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Popup1 from '../../pdf/popup/popup_1'

function UserOfType (props) {
  const [popupData, setPopupData] = useState(null)


  const changeStep = (id) => {
    if (id === 0)
    setPopupData({
        showPopup: true,
        titlePopup:
          'يمكنكم التواصل مع بوابة الكتاب العلمي للإستفادة من إشتراكات المؤسسات',
        body: ' ',
        titleClassPlus: 'Succ',
        bodyPopup: '',
        btnobj: {
          label: 'info@thelearnbook.com',
          showbtn: true,
          methodbtn: ''
        }
      })
    else if (id === 1) {
      props.setStep(1)
    }
    else if(id===6){
      props.setStep(6)
    }
  }
  return (
    <div className='containerReg1'>
      {popupData !== null ? <Popup1 objPopup={popupData} /> : ''}

      <div className='sectionTitle'>اختر نوع المستفيد</div>
      <div className='regType'>
        <div className='regTypeItem' onClick={() => changeStep(0)}>
          <div className='regTypeItemImg'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='97.598'
              height='90.102'
              viewBox='0 0 97.598 90.102'
            >
              <g
                id='Group_9506'
                data-name='Group 9506'
                transform='translate(-1114.904 -817.516)'
              >
                <path
                  id='Path_9763'
                  data-name='Path 9763'
                  d='M1206.765,893.03v28.254h-83.811V893.03'
                  transform='translate(-1.157 -15.166)'
                  fill='none'
                  stroke='#fff'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
                <path
                  id='Path_9764'
                  data-name='Path 9764'
                  d='M1138.448,923.55V904.688h9.267'
                  transform='translate(-4.169 -17.433)'
                  fill='none'
                  stroke='#fff'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
                <path
                  id='Path_9765'
                  data-name='Path 9765'
                  d='M1199.993,904.688h9.267V923.55'
                  transform='translate(-16.134 -17.433)'
                  fill='none'
                  stroke='#fff'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
                <rect
                  id='Rectangle_2070'
                  data-name='Rectangle 2070'
                  width='40.314'
                  height='25.249'
                  transform='translate(1143.546 880.869)'
                  fill='none'
                  stroke='#fff'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
                <line
                  id='Line_104'
                  data-name='Line 104'
                  y2='18.862'
                  transform='translate(1168.298 887.256)'
                  fill='none'
                  stroke='#fff'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
                <line
                  id='Line_105'
                  data-name='Line 105'
                  y1='18.862'
                  transform='translate(1159.108 887.256)'
                  fill='none'
                  stroke='#fff'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
                <path
                  id='Path_9766'
                  data-name='Path 9766'
                  d='M1149.951,892.141h40.314L1170.1,873Z'
                  transform='translate(-6.405 -11.272)'
                  fill='none'
                  stroke='#fff'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
                <path
                  id='Path_9768'
                  data-name='Path 9768'
                  d='M1117,885.107l4.789,6.383h24.917'
                  transform='translate(0 -13.626)'
                  fill='none'
                  stroke='#fff'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
                <path
                  id='Path_9769'
                  data-name='Path 9769'
                  d='M1194.38,891.489h25.072l4.789-6.383'
                  transform='translate(-13.84 -13.626)'
                  fill='none'
                  stroke='#fff'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
                <path
                  id='Path_9770'
                  data-name='Path 9770'
                  d='M1159.062,877.57H1117v6.071'
                  transform='translate(0 -12.161)'
                  fill='none'
                  stroke='#fff'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
                <path
                  id='Path_9771'
                  data-name='Path 9771'
                  d='M1222.791,883.641V877.57h-42.059'
                  transform='translate(-12.389 -12.161)'
                  fill='none'
                  stroke='#fff'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
                <line
                  id='Line_106'
                  data-name='Line 106'
                  y2='15.359'
                  transform='translate(1125.004 850.05)'
                  fill='none'
                  stroke='#fff'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
                <line
                  id='Line_107'
                  data-name='Line 107'
                  y1='15.359'
                  transform='translate(1202.402 850.05)'
                  fill='none'
                  stroke='#fff'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
                <line
                  id='Line_108'
                  data-name='Line 108'
                  x2='18.004'
                  transform='translate(1154.701 887.256)'
                  fill='none'
                  stroke='#fff'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
                <rect
                  id='Rectangle_2072'
                  data-name='Rectangle 2072'
                  width='9.312'
                  height='9.312'
                  transform='translate(1131.03 856.097)'
                  fill='none'
                  stroke='#fff'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
                <rect
                  id='Rectangle_2073'
                  data-name='Rectangle 2073'
                  width='9.312'
                  height='9.312'
                  transform='translate(1149.75 856.097)'
                  fill='none'
                  stroke='#fff'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
                <rect
                  id='Rectangle_2074'
                  data-name='Rectangle 2074'
                  width='9.312'
                  height='9.312'
                  transform='translate(1196.376 865.409) rotate(180)'
                  fill='none'
                  stroke='#fff'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
                <rect
                  id='Rectangle_2075'
                  data-name='Rectangle 2075'
                  width='9.312'
                  height='9.312'
                  transform='translate(1177.655 865.409) rotate(180)'
                  fill='none'
                  stroke='#fff'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
                <path
                  id='Path_9772'
                  data-name='Path 9772'
                  d='M1139.377,843.663v-4.878l28.675-23.769,28.676,23.769v4.878'
                  transform='translate(-4.175 4)'
                  fill='none'
                  stroke='#fff'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
                <line
                  id='Line_109'
                  data-name='Line 109'
                  x2='84'
                  transform='translate(1122.203 848.516)'
                  fill='none'
                  stroke='#fff'
                  stroke-linecap='round'
                  stroke-width='3'
                />
              </g>
            </svg>
          </div>
          <div className='regTypeItemText'>مشترك مؤسسي</div>
          <div className='regTypeItemSelect'></div>
        </div>

        {/* 2 */}
        {/* <div className='regTypeItem' style={{marginLeft:"30px", marginRight:"30px"}} onClick={() => changeStep(1)}>
          <div className='regTypeItemImg'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='59.493'
              height='68.397'
              viewBox='0 0 59.493 68.397'
            >
              <g
                id='Group_9507'
                data-name='Group 9507'
                transform='translate(-984.777 -397.512)'
              >
                <circle
                  id='Ellipse_36-2'
                  data-name='Ellipse 36-2'
                  cx='12.75'
                  cy='12.75'
                  r='12.75'
                  transform='translate(1001.774 399.012)'
                  fill='none'
                  stroke='#fff'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
                <path
                  id='Rectangle_2076'
                  data-name='Rectangle 2076'
                  d='M16.833,0H39.66A16.833,16.833,0,0,1,56.493,16.833V30.9a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V16.833A16.833,16.833,0,0,1,16.833,0Z'
                  transform='translate(986.277 433.506)'
                  fill='none'
                  stroke='#fff'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
              </g>
            </svg>
          </div>
          <div className='regTypeItemText'>مشترك شخصي</div>
          <div className='regTypeItemSelect'></div>
        </div> */}
        <div className='regTypeItem'onClick={() => changeStep(6)} >
          <div className='regTypeItemImg'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='59.493'
              height='68.397'
              viewBox='0 0 59.493 68.397'
            >
              <g
                id='Group_9507'
                data-name='Group 9507'
                transform='translate(-984.777 -397.512)'
              >
                <circle
                  id='Ellipse_36-2'
                  data-name='Ellipse 36-2'
                  cx='12.75'
                  cy='12.75'
                  r='12.75'
                  transform='translate(1001.774 399.012)'
                  fill='none'
                  stroke='#fff'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
                <path
                  id='Rectangle_2076'
                  data-name='Rectangle 2076'
                  d='M16.833,0H39.66A16.833,16.833,0,0,1,56.493,16.833V30.9a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V16.833A16.833,16.833,0,0,1,16.833,0Z'
                  transform='translate(986.277 433.506)'
                  fill='none'
                  stroke='#fff'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
              </g>
            </svg>
          </div>
          <div className='regTypeItemText'> مشترك جامعي</div>
          <div className='regTypeItemSelect'></div>
        </div>
      </div>

      <div className='guest'>
        <Link to='/'>أو أكمل كزائر</Link>
      </div>
    </div>
  )
}

export default UserOfType
