import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    Item: null,
    Item2: null,
    related: null,
    authors: null,
    yearsNumbers: null,
    yearsNumbers2: null,
    yearsNumbers3: null,
    popupShareData: null,
    years1Selected: null,
    years2Selected: null,
    pdfFile: null,
    loadingPage: false,
    showPopup2: false,
    showPopup3: false,
    openAlert: false,
    loginStatus: false,
    markUrl: '',
    marcData: '',
};

const viewSlice = createSlice({
    name: "view",
    initialState,
    reducers: {
        setItem: (state, actions) => {
            state.Item = actions.payload;
        },
        setItem2: (state, actions) => {
            state.Item2 = actions.payload
        },
        setRelated: (state, actions) => {
            state.related = actions.payload
        },
        setAuthors: (state, actions) => {
            state.authors = actions.payload
        },
        setYearsNumbers: (state, actions) => {
            state.yearsNumbers = actions.payload
        },
        setYearsNumbers2: (state, actions) => {
            state.yearsNumbers2 = actions.payload
        },
        setYearsNumbers3: (state, actions) => {
            state.yearsNumbers3 = actions.payload
        },
        setLoadingPage: (state, actions) => {
            state.loadingPage = actions.payload
        },
        setShowPopup2: (state, actions) => {
            state.showPopup2 = actions.payload
        },
        setShowPopup3: (state, actions) => {
            state.showPopup3 = actions.payload
        },
        setOpenAlert: (state, actions) => {
            state.openAlert = actions.payload
        },
        setmarkUrl: (state, actions) => {
            state.markUrl = actions.payload;
        },
        setMarcData: (state, actions) => {
            state.marcData = actions.payload
        },
        setLoginStatus: (state, actions) => {
            state.loginStatus = actions.payload;
        },
        setPopupShareData: (state, actions) => {
            state.popupShareData = actions.payload;
        },
        setYears1Selected: (state, actions) => {
            state.years1Selected = actions.payload
        },
        setYears2Selected: (state, actions) => {
            state.years2Selected = actions.payload
        },
        setPdfFile: (state, actions) => {
            state.pdfFile = actions.payload
        }
    }
});



export const viewState = (state) => state.view;
export const {
    setItem,
    setItem2,
    setRelated,
    setAuthors,
    setYearsNumbers,
    setYearsNumbers2,
    setYearsNumbers3,
    setLoadingPage,
    setShowPopup2,
    setShowPopup3,
    setOpenAlert,
    setmarkUrl,
    setMarcData,
    setLoginStatus,
    setPopupShareData,
    setYears1Selected,
    setYears2Selected,
    setPdfFile } = viewSlice.actions;
export default viewSlice.reducer