import React, { useState } from 'react'
import Stepwizard from './Stepwizard'
export default function Done (props) {
  React.useEffect(() => {}, [])
  const [slected, setSlected] = useState(0)

  return (
    <div className='stepOne'>
      <div className='containerReg1'>
        <div className='regsteps'>
          <Stepwizard step={5} />
        </div>

        <div className='sectionTitle'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 50 50'
            className='checkmark'
            style={{
              enableBackground: 'new 0 0 50 50'
            }}
            xmlSpace='preserve'
            {...props}
          >
            <circle
              style={{
                fill: '#25ae88'
              }}
              cx={25}
              cy={25}
              r={25}
            />
            <path
              style={{
                fill: 'none',
                stroke: '#fff',
                strokeWidth: 2,
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                strokeMiterlimit: 10
              }}
              d='M38 15 22 33l-10-8'
            />
          </svg>
          <br />
          شكراً لك
        </div>
        <div
          className='pays'
          style={{
            textAlign: 'center'
          }}
        >
          تمت عملية الدفع بنجاح, سيصلك بريد إلكتروني يوجد فيه تفاصبل حسابك.
        </div>
      </div>
    </div>
  )
}
