import React, { useEffect, useRef, useState } from 'react';
import axios from "axios";
import { printPlugin } from '@react-pdf-viewer/print';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/print/lib/styles/index.css';
import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { searchPlugin } from '@react-pdf-viewer/search';


import '@react-pdf-viewer/search/lib/styles/index.css';
import { ScrollMode, scrollModePlugin } from '@react-pdf-viewer/scroll-mode';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';

import '@react-pdf-viewer/page-navigation/lib/styles/index.css';

import { getFilePlugin } from '@react-pdf-viewer/get-file';






function View() {
    const getFilePluginInstance = getFilePlugin();

    const inputEl = useRef(null);

    const printPluginInstance = printPlugin();
    const { Print } = printPluginInstance;
    const searchPluginInstance = searchPlugin();
    const { Search } = searchPluginInstance;
    const scrollModePluginInstance = scrollModePlugin({
        scrollMode: ScrollMode.Vertical,
    });


    const zoomPluginInstance = zoomPlugin();

    const { ZoomIn, ZoomOut } = zoomPluginInstance;

    const pageNavigationPluginInstance = pageNavigationPlugin();
    const { CurrentPageLabel } = pageNavigationPluginInstance;
  
   
    const { GoToNextPage, GoToPreviousPage } = pageNavigationPluginInstance;

    const [Item, setItem] = useState(null);
    useEffect(() => {
        var crypto = require('crypto');
        let hash = crypto.createHash('sha256').update(JSON.stringify(formatDate())).digest('hex');


        let formData = new FormData();
        formData.append('hashTime', hash);
        formData.append('lang', 'ar');
        formData.append('id', '66');
        formData.append('type', 'book');
        axios.post(process.env.REACT_APP_BACKEND_URL + '/app/view/get', formData,
        ).then(response2 => {
            setItem(response2.data.result)
        })
    }, []);


    function formatDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }


    return (
        <div>
            <div className="pdf">
                <div className="searchandnavbook">
                    <div className="searchsec">
                        <Search>
                            {
                                (renderSearchProps) => {
                                    return (
                                        <>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    padding: '0 2px',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                        padding: '4px',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            padding: '0 2px',
                                                        }}
                                                    >
                                                    </div>
                                        
                                                </div>
                                            </div>
                                            <div style={{ padding: '0 2px' }}>
                                
                                            </div>
                                        </>
                                    )
                                }
                            }
                        </Search>
                    </div>
                    <div className="navbook">
                        <div className="zoominout">

                            <ZoomIn>
                                {
                                    (props) => (

                                        <svg
                                            onClick={props.onClick}
                                            xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39">
                                            <g id="Group_9394" data-name="Group 9394" transform="translate(-632 -170)">
                                                <g id="Group_9267" data-name="Group 9267" transform="translate(661.002 198.002) rotate(180)">
                                                    <line id="Line_16" data-name="Line 16" y2="18.002" transform="translate(9.001 0)" fill="none" stroke="#a0acbc" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.4" />
                                                    <line id="Line_17" data-name="Line 17" x1="18.002" transform="translate(0 9.001)" fill="none" stroke="#a0acbc" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.4" />
                                                </g>
                                                <g id="Ellipse_138" data-name="Ellipse 138" transform="translate(632 170)" fill="none" stroke="#a0acbc" stroke-width="2">
                                                    <circle cx="19.5" cy="19.5" r="19.5" stroke="none" />
                                                    <circle cx="19.5" cy="19.5" r="18.5" fill="none" />
                                                </g>
                                            </g>
                                        </svg>

                                    )
                                }
                            </ZoomIn>

                            <ZoomOut>
                                {
                                    (props) => (
                                        <svg
                                            onClick={props.onClick}
                                            xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39">
                                            <g id="Group_9393" data-name="Group 9393" transform="translate(-559 -170)">
                                                <g id="Group_9266" data-name="Group 9266" transform="translate(588.002 198.002) rotate(180)">
                                                    <line id="Line_17" data-name="Line 17" x1="18.002" transform="translate(0 9.001)" fill="none" stroke="#a0acbc" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.4" />
                                                </g>
                                                <g id="Ellipse_137" data-name="Ellipse 137" transform="translate(559 170)" fill="none" stroke="#a0acbc" stroke-width="2">
                                                    <circle cx="19.5" cy="19.5" r="19.5" stroke="none" />
                                                    <circle cx="19.5" cy="19.5" r="18.5" fill="none" />
                                                </g>
                                            </g>
                                        </svg>

                                    )
                                }
                            </ZoomOut>


                        </div>
                        <div
                            style={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                padding: '4px',
                            }}
                        >           
                    </div>

                        <div className="navbooknumber">

                            <GoToPreviousPage>
                                {
                                    (props) => (
                                        <button className="nextbtn" type="button"
                                            disabled={props.isDisabled}
                                            onClick={props.onClick}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="19" viewBox="0 0 9 19">
                                                <path id="Polygon_10" data-name="Polygon 10" d="M8.812.652a1,1,0,0,1,1.375,0l6.99,6.622A1,1,0,0,1,16.49,9H2.51a1,1,0,0,1-.688-1.726Z" transform="translate(9) rotate(90)" fill="#727c89" />
                                            </svg>
                                        </button>

                                    )
                                }
                            </GoToPreviousPage>



                            <CurrentPageLabel>
                                {
                                    (props) => (
                                        <div className="numbernav">

                                            <span>{`${props.currentPage + 1}`}</span>
                                        </div>
                                    )
                                }
                            </CurrentPageLabel>

                            <GoToNextPage>
                                {
                                    (props) => (
                                        <button className="backbtn"
                                            type="button"

                                            disabled={props.isDisabled}
                                            onClick={props.onClick}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="19" viewBox="0 0 9 19">
                                                <path id="Polygon_9" data-name="Polygon 9" d="M8.812.652a1,1,0,0,1,1.375,0l6.99,6.622A1,1,0,0,1,16.49,9H2.51a1,1,0,0,1-.688-1.726Z" transform="translate(0 19) rotate(-90)" fill="#727c89" />
                                            </svg>
                                        </button>

                                    )
                                }
                            </GoToNextPage>


                            <div className="totalPage">
                                <CurrentPageLabel>
                                    {
                                        (props) => (
                                            <span>{`${props.numberOfPages}`}</span>
                                        )
                                    }
                                </CurrentPageLabel>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="viewContent">
                    <div className="titleBook">
                        {Item != null ? Item.nameAr : ''}
                    </div>

                    <div className="bookView" ref={inputEl}
                    >


                        <div className="pdfReader" style={{ direction: 'ltr' }}>
                            <Viewer
                                fileUrl={process.env.REACT_APP_BACKEND_URL + (Item != null ? '/public/' + Item.fileData : '')}
                                plugins={[printPluginInstance, searchPluginInstance, scrollModePluginInstance,
                                    zoomPluginInstance, pageNavigationPluginInstance, getFilePluginInstance

                                ]}
                                defaultScale={SpecialZoomLevel.PageFit}
                                httpHeaders={{
                                    'Access-Control-Allow-Origin': '*',
                                    'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
                                    'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="leftActions">
                    <Print>
                        {
                            (props) => (
                                <div className="itemAction" style={{ left: '-50px', top: '56%' }}
                                    onClick={props.onClick}>

                                    <img src={require('../assets/images/Group 9105.png')} alt="" srcset="" />
                                    طباعة
                                </div>

                            )
                        }
                    </Print>
                    <div className="itemAction" style={{ left: '-50px', top: '63%' }}
                        onClick={() => {
                            fetch(process.env.REACT_APP_BACKEND_URL + (Item != null ? '/public/books/book_66/w_File_1623751834998.pdf' : ''), {
                                method: 'GET'
                            }).then(resp => resp.blob())
                                .then(blob => {
                                    const url = window.URL.createObjectURL(blob);
                                    const a = document.createElement('a');
                                    a.style.display = 'none';
                                    a.href = url;
                                    a.download = Item != null ? Item.nameAr : ''; // the filename you want
                                    document.body.appendChild(a);
                                    a.click();
                                    window.URL.revokeObjectURL(url);
                                })
                        }}
                    >

                        <img src={require('../assets/images/Group 9107.png')} alt="" />
                        تحميل
                    </div>

                </div>
            </div>
        </div >
    );
}

export default View;
