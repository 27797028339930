import React, { useState ,useEffect} from "react";
import Topsection from "../components/sharing/topsection";
import Breadcrumb from "../components/sharing/breadcrumbs";
import axios from "axios";

function AdvancedSearch() {
  const [about, setAbout] = useState("");
  useEffect(() => {
    axios
      .post(process.env.REACT_APP_BACKEND_URL + "/infowebsite/list")
      .then(response2 => {
        setAbout(response2.data.result.fullAbout);
      });
  }, []);

  const breadCurmbData = [
    {
      title: "مساعدة",
      link: ""
    }
  ];

  return (
    <div>
      <Topsection
        data={{ text: "مساعدة", img: require("../assets/images/11.png") }}
      />
      <Breadcrumb data={breadCurmbData} />
      <div className="aboutus">
        <a href={require("../assets/images/guide.pdf")} target="_block">
          <div className="downloadDev"> 
          دليل الإستخدام لمنصة الكتاب العلمي
          </div>

        </a>
        <div className="details">
          <iframe
            src="https://www.youtube.com/embed/LCdwadoX72c"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    </div >
  );
}

export default AdvancedSearch;
