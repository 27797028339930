import React, { useState,useEffect } from 'react';
import Topsection from '../components/sharing/topsection';
import Breadcrumb from '../components/sharing/breadcrumbs';
import axios from "axios";
import { Fade } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import CountryList from '../components/country.json';
function AdvancedSearch() {
    let lang = require("./../lang/ar").default;
    const [loadingPage, setLoadingPage] = useState(true);
    const [formObj, setFormObj] = useState({
        name: "",
        email: "",
        phone: "",
        msg: ""
    });
    const [data, setData] = useState("");
    const [captcha, setCaptcha] = useState(false);
    const [phone, setPhone] = useState([]);
    const [country, setCountry] = useState('الأردن');
    const [success, setSuccess] = useState({
        status: false,
        msg: ""
    });
    useEffect(() => {
        axios
            .post(process.env.REACT_APP_BACKEND_URL + "/infowebsite/list")
            .then(response2 => {
                setData(response2.data.result);
                let ii = response2.data.result.phone.split("/");
                setPhone(ii);
                setLoadingPage(false);
            });
    }, []);

    function onChange(value) {
        if (value) {
            setCaptcha(true);
        }
    }

    const breadCurmbData = [
        {
            title: "اتصل بنا",
            link: '',
        },
    ];
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    const sendMsg = () => {
        let e = true;
        if (formObj.name.length < 2) {
            setSuccess({
                status: false,
                msg: 'الاسم قصير جدا'
            })
            e = false;
            return;
        }
        if (!validateEmail(formObj.email)) {
            setSuccess({
                status: false,
                msg: 'البريد الالكتروني غير صحيح'
            })
            e = false;
            return;
        }

        if (formObj.msg.length < 10) {
            setSuccess({
                status: false,
                msg: 'الرسالة قصيرة جدا'
            })
            e = false;
            return;
        }
        if (!captcha) {
            setSuccess({
                status: false,
                msg: 'الرجاء التحقق من صحة البيانات'
            })
            e = false;
            return;
        }
        if (e) {
            let formData = new FormData();
            //country
            formObj.country = country;
            formData.append('data', JSON.stringify(formObj));
            axios.post(process.env.REACT_APP_BACKEND_URL + '/app/contactus', formData,
            ).then(response2 => {
                setSuccess({
                    status: true,
                    msg: "تم ارسال الرسالة بنجاح"
                })
                setFormObj({
                    name: "",
                    email: "",
                    phone: "",
                    msg: ""
                });
            })
        }
    }
    return (
        <div>
            <Topsection data={{ text: 'اتصل بنا', img: require('../assets/images/11.png') }} />
            <Breadcrumb data={breadCurmbData} />
            <div className="loadingData" style={{
                visibility: loadingPage ? 'visible' : 'hidden',
                opacity: loadingPage ? 1 : 0,
                transition: 'visibility 0s, opacity 0.3s linear'

            }}>
                <img src={require('../assets/images/bookLoading.gif')} alt="loading" />
                <div className="textLoading">جاري التحميل...</div>
            </div>
            <div className="contact-us">
                <div className="formcontact">
                    <div className="container">
                        <form id="contact">

                            {success.msg !== "" && (
                                <fieldset>
                                    {success.status === false && (
                                        <div class="xd-message msg-danger">
                                            <div class="xd-message-icon">
                                            </div>
                                            <div class="xd-message-content">
                                                <p>{success.msg}</p>
                                            </div>
                                        </div>
                                    )}
                                    {success.status === true && (
                                        <div class="xd-message msg-success">
                                            <div class="xd-message-icon">  </div>
                                            <div class="xd-message-content">
                                                <p>{success.msg}</p>
                                            </div>
                                        </div>
                                    )}

                                </fieldset>
                            )}
                         
                            <fieldset>
                                <input value={formObj.name}
                                    onChange={(e) =>
                                        setFormObj({
                                            ...formObj,
                                            name: e.target.value
                                        })
                                    }
                                    placeholder="الاسم*" type="text" tabindex="1" required autofocus />
                            </fieldset>
                            <fieldset>
                                <input value={formObj.email}
                                    onChange={(e) =>
                                        setFormObj({
                                            ...formObj,
                                            email: e.target.value
                                        })
                                    }
                                    placeholder="البريد الإلكتروني*"
                                    type="email"
                                    tabindex="2"
                                    required />
                            </fieldset>
                            <fieldset>
                                <input value={formObj.phone}
                                    onChange={(e) =>
                                        setFormObj({
                                            ...formObj,
                                            phone: e.target.value
                                        })
                                    }
                                    placeholder="رقم الهاتف (إختياري)"
                                    type="tel"
                                    tabindex="3" />
                            </fieldset>
                            <fieldset>
                                <select value={country}
                                    onChange={(e) => setCountry(e.target.value)}
                                >
                                    {Object.values(CountryList).map((keyName, i) => (
                                        <option value={keyName}>{keyName}</option>
                                    ))}
                                </select>
                            </fieldset>

                            <fieldset>
                                <textarea value={formObj.msg}
                                    onChange={(e) =>
                                        setFormObj({
                                            ...formObj,
                                            msg: e.target.value
                                        })
                                    }
                                    placeholder="رسالتك*" tabindex="5" required></textarea>
                            </fieldset>
                            <fieldset>
                                <ReCAPTCHA
                                    sitekey="6Ldu6BkfAAAAAAcWW9lR0IwFpWfALfHRlmIuuhJG"
                                    onChange={onChange}
                                />
                            </fieldset>
                            <fieldset>
                                <button name="button" type="button" onClick={sendMsg}>ارسال</button>
                            </fieldset>

                        </form>
                    </div>
                </div>


                <div className="countact">
                    <div className="boxs">
                        <Fade bottom>
                            <div className="box">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="59"
                                    height="58"
                                    viewBox="0 0 59 58"
                                >
                                    <g
                                        id="Group_9392"
                                        data-name="Group 9392"
                                        transform="translate(-1311.5 -3974.001)"
                                    >
                                        <ellipse
                                            id="Ellipse_126"
                                            data-name="Ellipse 126"
                                            cx="29.5"
                                            cy="29"
                                            rx="29.5"
                                            ry="29"
                                            transform="translate(1311.5 3974.001)"
                                            fill="#147bfc"
                                        />
                                        <path
                                            id="Path_27"
                                            data-name="Path 27"
                                            d="M23.012,11.006c0,7.783-10.006,14.453-10.006,14.453S3,18.789,3,11.006a10.006,10.006,0,1,1,20.012,0Z"
                                            transform="translate(1328.494 3990.925)"
                                            fill="none"
                                            stroke="#fff"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                        />
                                        <ellipse
                                            id="Ellipse_17"
                                            data-name="Ellipse 17"
                                            cx="3.811"
                                            cy="3.811"
                                            rx="3.811"
                                            ry="3.811"
                                            transform="translate(1337.592 3998.092)"
                                            fill="none"
                                            stroke="#fff"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                        />
                                    </g>
                                </svg>
                                <div className="titleBox1">
                                    {lang.countact.address}
                                </div>
                                <div className="bodyBox1">
                                    {data !== "" ? data.addres : ""}
                                </div>
                            </div>
                        </Fade>
                        <Fade bottom>
                            <div className="box">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="59"
                                    height="59"
                                    viewBox="0 0 180 180"
                                >
                                    <defs>
                                        <clipPath id="clipPath">
                                            <path
                                                id="Path_8149"
                                                data-name="Path 8149"
                                                d="M66,0c36.451,0,66,29.773,66,66.5S102.451,133,66,133,0,103.227,0,66.5,29.549,0,66,0Z"
                                                transform="translate(-452 -161)"
                                                fill="#fff"
                                            />
                                        </clipPath>
                                    </defs>
                                    <g
                                        id="Group_8522"
                                        data-name="Group 8522"
                                        transform="translate(-1636 -1722)"
                                    >
                                        <rect
                                            id="Rectangle_3731"
                                            data-name="Rectangle 3731"
                                            width="180"
                                            height="180"
                                            rx="90"
                                            transform="translate(1636 1722)"
                                            fill="#147bfc"
                                        />
                                        <g
                                            id="Mask_Group_138"
                                            data-name="Mask Group 138"
                                            transform="translate(2113 1903)"
                                            clipPath="url(#clipPath)"
                                        >
                                            <path
                                                id="c7b8577d-c5e6-4687-95a9-bd3539af0217"
                                                d="M131.992,64.792v30.69a10.2,10.2,0,0,1-10.152,10.237H81.227v18.757a8.461,8.461,0,1,1-16.922,0V64.792A34.128,34.128,0,0,0,45.115,34.1H74.457V56.267a1.692,1.692,0,1,0,3.385,0V34.1h23.692A30.6,30.6,0,0,1,131.992,64.792ZM77.842,23.871h23.692A6.8,6.8,0,0,0,108.3,17.05V6.821A6.8,6.8,0,0,0,101.533,0H81.227a6.8,6.8,0,0,0-6.77,6.821V34.095h3.385Zm-67.69,81.847C4.545,105.718,0,101.139,0,92.183V65.535C0,48.911,12.8,34.752,29.286,34.123A30.364,30.364,0,0,1,51.57,42.686a30.836,30.836,0,0,1,9.35,22.106v40.926Zm6.77-35.812a1.7,1.7,0,0,0,1.691,1.7H42.3a1.705,1.705,0,0,0,0-3.411H18.616a1.685,1.685,0,0,0-1.2.5A1.711,1.711,0,0,0,16.922,69.907Zm0,13.64a1.7,1.7,0,0,0,1.691,1.7H42.3a1.705,1.705,0,0,0,0-3.411H18.616a1.685,1.685,0,0,0-1.2.5A1.711,1.711,0,0,0,16.922,83.546Z"
                                                transform="translate(-451.996 -161)"
                                                fill="#fff"
                                            />
                                        </g>
                                    </g>
                                </svg>

                                <div className="titleBox1">
                                    {lang.countact.mailBox}
                                </div>
                                <div className="bodyBox1">ص. ب 5897 عمان، 11953 الاردن</div>
                            </div>
                        </Fade>

                        <Fade bottom>
                            <div className="box">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="59"
                                    height="58"
                                    viewBox="0 0 59 58"
                                >
                                    <g
                                        id="Group_9393"
                                        data-name="Group 9393"
                                        transform="translate(-931 -3974.001)"
                                    >
                                        <ellipse
                                            id="Ellipse_127"
                                            data-name="Ellipse 127"
                                            cx="29.5"
                                            cy="29"
                                            rx="29.5"
                                            ry="29"
                                            transform="translate(931 3974.001)"
                                            fill="#147bfc"
                                        />
                                        <path
                                            id="phone"
                                            d="M24.56,18.84v3.386A2.257,2.257,0,0,1,22.1,24.484a22.337,22.337,0,0,1-9.741-3.465,22.01,22.01,0,0,1-6.772-6.772A22.337,22.337,0,0,1,2.121,4.461,2.257,2.257,0,0,1,4.367,2H7.753a2.257,2.257,0,0,1,2.257,1.941,14.493,14.493,0,0,0,.79,3.172,2.257,2.257,0,0,1-.508,2.382L8.859,10.928A18.059,18.059,0,0,0,15.632,17.7l1.433-1.433a2.257,2.257,0,0,1,2.382-.508,14.492,14.492,0,0,0,3.172.79A2.257,2.257,0,0,1,24.56,18.84Z"
                                            transform="translate(946.393 3991.168)"
                                            fill="none"
                                            stroke="#fff"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                        />
                                    </g>
                                </svg>

                                <div className="titleBox">
                                    {lang.countact.phone}
                                </div>
                                <div className="bodyBox">
                                    {phone[0]}
                                    <br />
                                    {phone[1]}
                                </div>
                            </div>
                        </Fade>

                        <Fade bottom>
                            <div className="box">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="59"
                                    height="58"
                                    viewBox="0 0 59 58"
                                >
                                    <g
                                        id="Group_9394"
                                        data-name="Group 9394"
                                        transform="translate(-557 -3974.001)"
                                    >
                                        <ellipse
                                            id="Ellipse_128"
                                            data-name="Ellipse 128"
                                            cx="29.5"
                                            cy="29"
                                            rx="29.5"
                                            ry="29"
                                            transform="translate(557 3974.001)"
                                            fill="#147bfc"
                                        />
                                        <g id="mail" transform="translate(574.771 3994.974)">
                                            <path
                                                id="Path_25"
                                                data-name="Path 25"
                                                d="M4.446,4H24.01a2.453,2.453,0,0,1,2.446,2.446V21.119a2.453,2.453,0,0,1-2.446,2.446H4.446A2.453,2.453,0,0,1,2,21.119V6.446A2.453,2.453,0,0,1,4.446,4Z"
                                                transform="translate(-2 -4)"
                                                fill="none"
                                                stroke="#fff"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                            />
                                            <path
                                                id="Path_26"
                                                data-name="Path 26"
                                                d="M26.456,6,14.228,14.56,2,6"
                                                transform="translate(-2 -3.554)"
                                                fill="none"
                                                stroke="#fff"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                            />
                                        </g>
                                    </g>
                                </svg>

                                <div className="titleBox">
                                    {lang.countact.email}
                                </div>
                                <div className="bodyBox">
                                    <a href={"mailto:info@thelearnbook.com"}>info@thelearnbook.com</a>
                                    <br />
                                    <a href={"mailto:" + (data !== "" ? data.email : "")}>
                                        {data !== "" ? data.email : ""}
                                    </a>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
                <div className="map">
                    <div className='overColor'></div>
                    <iframe title='' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2844.630099973077!2d35.8639347725322!3d32.01905871450297!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151c9f1274bd58a5%3A0xcc92b1ac9fa2e175!2z2LTYsdmD2Ycg2LnZhdixINin2YTYudiz2KfZgSDYudmF2KfYsdmHINix2YLZhTg!5e0!3m2!1sen!2sjo!4v1648370515617!5m2!1sen!2sjo" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div >
    );
}

export default AdvancedSearch;
