import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export default function PopupInfo(props) {
  let history = useHistory();

  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    setShowPopup(props.objPopup.showPopup);
  }, []);
  return (
    <div
      className="popupInfo"
      style={{
        visibility: showPopup ? "visible" : "hidden",
        opacity: showPopup ? "1" : "0",
        transition: "opacity 0.4s"
      }}
    >
      <motion.div
        animate={{ y: 400 }}
        transition={{ ease: "easeOut", duration: 1 }}
      >
        <div className="windowPopup">
          <div className="close" onClick={() => setShowPopup(false)}>
            {" "}&#10006;{" "}
          </div>

          <div className="bodyWindow">
            <div>
              {props.objPopup.Item !== null
                ? props.objPopup.Item.s3objcover !== undefined &&
                  props.objPopup.Item.s3objcover !== ""
                  ? <img
                    src={
                      props.objPopup.Item !== null
                        ? props.objPopup.Item.s3objcover
                        : ""
                    }
                    alt="learnbookcover"
                  />
                  : <img
                    src={
                      "https://apis.thelearnbook.com/public/learnBookCover.jpg"
                    }
                    alt="learnbookcover"
                  />
                : ""}
            </div>
            <div className="information">
              <div className="titleContent">
                {props.objPopup.Item !== null ? props.objPopup.Item.nameAr : ""}
              </div>

              <div>
                {props.objPopup.Item !== null
                  ? <div className="rowInSec w-60">
                    <div>
                      تاريخ النشر:&nbsp;<a
                        onClick={() =>
                          history.push(
                            "/browseBy/" +
                            props.objPopup.Item.yearsValue +
                            "/" +
                            "years" +
                            "/" +
                            props.objPopup.Item.yearsValue
                          )}
                      >
                        {" "}{props.objPopup.Item.yearsValue}{" "}
                      </a>
                    </div>
                  </div>
                  : ""}
              </div>
              {props.objPopup.Item !== null
                ? props.objPopup.Item.publisherValue !== undefined ||
                  props.objPopup.Item.publisherValue.length !== 0
                  ? <div className="rowInSec w-60">
                    <div>
                      الناشر:&nbsp;
                      {props.objPopup.Item.publisherValue.map(
                        (item333, index) =>
                          <a
                            key={index}
                            onClick={() =>
                              history.push(
                                "/browseBy/" +
                                item333._id +
                                "/" +
                                "publisherValue" +
                                "/" +
                                item333.nameAr
                              )}
                          >
                            {props.objPopup.Item.publisherValue.length != 0
                              ? item333.nameAr
                              : ""}

                            {index + 1 <
                              props.objPopup.Item.publisherValue.length
                              ? "، "
                              : " "}
                          </a>
                      )}
                    </div>
                  </div>
                  : ""
                : ""}

              <div className="rowInSec">
                <div>
                  المواضيع الرئيسية:&nbsp;
                  {props.objPopup.Item2 !== null
                    ? props.objPopup.Item2.hhhh.map(
                      (item, index) =>
                        props.objPopup.Item2.hhh.length - 1 !== index
                          ? <span key={index}>
                            <a
                              onClick={() =>
                                history.push(
                                  "/browseBy/" +
                                  item.subject._id +
                                  "/" +
                                  "subjectsValue" +
                                  "/" +
                                  item.subject.nameAr
                                )}
                            >
                              {item.subject.nameAr} |{" "}
                              {item.subSubject[0].NameAR}
                            </a>{" "}
                            ,
                          </span>
                          : <span key={index}>
                            <a
                              onClick={() =>
                                history.push(
                                  "/browseBy/" +
                                  item.subject._id +
                                  "/" +
                                  "subjectsValue" +
                                  "/" +
                                  item.subject.nameAr
                                )}
                            >
                              {item.subject.nameAr} |{" "}
                              {item.subSubject[0].NameAR}
                            </a>
                          </span>
                    )
                    : ""}
                </div>
              </div>
              <div>
                <div>
                  عدد الصفحات:{" "}
                  {props.objPopup.Item != null
                    ? props.objPopup.Item.numberPage
                    : ""}
                </div>
              </div>

              {props.objPopup.Item2 !== null
                ? <div className="rowInSec w-60" key={55}>
                  <div>
                    المؤلف:&nbsp;
                    {props.objPopup.Item2.hhh.length !== 0
                      ? props.objPopup.Item2.hhh.map(
                        (i, index) =>
                          props.objPopup.Item2.hhh.length - 1 !== index
                            ? <span key={index}>
                              <a
                                onClick={() =>
                                  history.push(
                                    "/browseBy/" +
                                    i.name._id +
                                    "/" +
                                    "authorValue" +
                                    "/" +
                                    (i.name !== null
                                      ? i.name.nameAr
                                      : "")
                                  )}
                              >
                                {i.name !== null ? i.name.nameAr : ""}
                              </a>
                              <b>،</b>{" "}
                            </span>
                            : <span key={index}>
                              <a
                                onClick={() =>
                                  history.push(
                                    "/browseBy/" +
                                    i.name._id +
                                    "/" +
                                    "authorValue" +
                                    "/" +
                                    (i.name !== null
                                      ? i.name.nameAr
                                      : "")
                                  )}
                              >
                                {i.name !== null ? i.name.nameAr : ""}
                              </a>{" "}
                            </span>
                      )
                      : ""}
                  </div>
                </div>
                : ""}
              {props.objPopup.Item !== null && props.objPopup.type === "journal"
                ? <span>
                  {props.objPopup.Item.titleDawreaa
                    ? <div className="rowInSec w-60">
                      <div>
                        المجلة:{" "}
                        <a
                          onClick={() =>
                            history.push(
                              "/browseBy/" +
                              props.objPopup.Item.titleDawreaa._id +
                              "/" +
                              "titleDawreaa" +
                              "/" +
                              (props.objPopup.Item.titleDawreaa !== null
                                ? props.objPopup.Item.titleDawreaa.nameAr
                                : "")
                            )}
                        >
                          {props.objPopup.Item.titleDawreaa !== null
                            ? props.objPopup.Item.titleDawreaa.nameAr
                            : ""}
                        </a>
                      </div>
                    </div>
                    : ""}

                  <div className="rowInSec w-60">
                    <div>
                      المجلد والعدد: {props.objPopup.Item.mojaldandNumber}
                    </div>
                  </div>
                  <div className="rowInSec w-60">
                    <div>
                      الصفحات: {props.objPopup.Item.pagesJournals}
                    </div>
                  </div>
                  <div className="rowInSec w-60">
                    <div>
                      {" "}تردد الدورية:{" "}
                      {props.objPopup.Item.rotatingFrequency}
                    </div>
                  </div>
                  <div className="rowInSec w-60">
                    <div>
                      {" "}شكل الوعاء: {props.objPopup.Item.bowlShape}
                    </div>
                  </div>
                </span>
                : ""}

              {props.objPopup.Item !== null && props.objPopup.type === "research"
                ? <div>
                  <div className="rowInSec w-60">
                    <div>
                      اسم المؤتمر:{" "}
                      <a
                        onClick={() =>
                          history.push(
                            "/browseBy/" +
                            props.objPopup.Item.conferencesObj._id +
                            "/" +
                            "nameMoatamer" +
                            "/" +
                            (props.objPopup.Item.conferencesObj !== null
                              ? props.objPopup.Item.conferencesObj.nameAr
                              : "")
                          )}
                      >
                        {props.objPopup.Item.conferencesObj.nameAr}
                      </a>
                    </div>
                  </div>
                  {props.objPopup.Item.numberRes !== "null"
                    ? <div className="rowInSec w-60">
                      <div>
                        رقم المؤتمر: {props.objPopup.Item.numberRes}
                      </div>
                    </div>
                    : ""}

                  {props.objPopup.Item.yearEneqad !== "null"
                    ? <div className="rowInSec w-60">
                      <div>
                        سنة الانعقاد: {props.objPopup.Item.yearEneqad}
                      </div>
                    </div>
                    : ""}

                  {props.objPopup.Item.enqadLoctions !== "null"
                    ? <div className="rowInSec w-60">
                      <div>
                        مكان الانعقاد: {props.objPopup.Item.enqadLoctions}
                      </div>
                    </div>
                    : ""}

                  {props.objPopup.Item.bowlShape !== "null"
                    ? <div className="rowInSec w-60">
                      <div>
                        {" "}شكل الوعاء: {props.objPopup.Item.bowlShape}
                      </div>
                    </div>
                    : ""}
                </div>
                : ""}

              {props.objPopup.Item !== null
                ? <div className="rowInSec w-60 ltrww">
                  {props.objPopup.Item.ISBNValue !== ""
                    ? <div style={{ display: "flex", flexDirection: "row" }}>
                      {props.objPopup.type === "journal" ? "ISSN" : "ISBN"}:
                      &nbsp;<div> {props.objPopup.Item.ISBNValue} </div>
                    </div>
                    : ""}
                </div>
                : ""}

              <div className="rowInSec">
                <div className="tags">
                  <div>كلمات مفتاحية: </div>
                  {props.objPopup.Item !== null
                    ? props.objPopup.Item.tagsValue.map((i, index) =>
                      <div
                        key={i._id}
                        className="tag"
                        onClick={() =>
                          history.push(
                            "/browseBy/" +
                            i._id +
                            "/" +
                            "tags" +
                            "/" +
                            i.nameAr
                          )}
                      >
                        {i.nameAr}
                      </div>
                    )
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}
