import React, { useEffect } from 'react'
import Topsection from '../components/sharing/topsection'
import axios from 'axios'
import {
  browserName,
  osName,
  osVersion,
  browserVersion,
  engineName
} from 'react-device-detect'

function Login (props) {
  const cookieSet = (name, value, days) => {
    var expires = ''
    if (days) {
      var date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = '; expires=' + date.toUTCString()
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/'
  }
  useEffect(() => {
    let token = props.match.params.token
    if (token !== undefined && token !== null && token !== '') {
      let formData = new FormData()
      let device = {
        browserName: browserName,
        osName: osName,
        osVersion: osVersion,
        browserVersion: browserVersion,
        engineName: engineName
      }

      formData.append('deviceInfo', JSON.stringify(device))
      formData.append('token', token)
      axios
        .post(process.env.REACT_APP_BACKEND_URL + '/auth/autoLogin', formData)
        .then(response2 => {
          if (response2.data.booleanValue) {
            cookieSet('fName', response2.data.dataUser.name, 70)
            cookieSet('email', response2.data.dataUser.email, 70)
            cookieSet('id', response2.data.dataUser._id, 70)
            cookieSet('img', response2.data.dataUser.img, 70)
            cookieSet('country', response2.data.dataUser.country, 70)
            localStorage.setItem('access_token', response2.data.access_token)

            cookieSet(
              'objectUser',
              JSON.stringify(response2.data.parentAccount),
              70
            )
            props.history.push(process.env.REACT_APP_FRONT_URL)
          } else {
            props.history.push(process.env.REACT_APP_FRONT_URL)
          }
        })
    } else {
      props.history.push(process.env.REACT_APP_FRONT_URL)
    }
  }, [])

  return (
    <div>
      <Topsection
        data={{
          text: 'جاري تسجيل الدخول....',
          img: require('../assets/images/11.png')
        }}
      />
      <div className='aboutus'>
        <div className='details'>asdasd</div>
      </div>
    </div>
  )
}

export default Login
