import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Fade from "react-reveal/Fade";

import Carousel from "react-elastic-carousel";
import axios from "axios";
import {
  isMobile
} from "react-device-detect";
import CustomImage from "../sharing/CustomImage";
export default function LatestReleases(props) {
  let history = useHistory();
  const [currentItem, setCurrentItem] = useState(0);
  const [data, setData] = useState([]);

  useEffect(
    () => {
      axios
        .post(process.env.REACT_APP_BACKEND_URL + "/latestReleases/list")
        .then(response2 => {
          setData(response2.data.addData);
        });
    },
    [currentItem]
  );

  const onCurrentChange = current => {
    setCurrentItem(current.index);
  };
  const checkIfTextArabic = text => {
    var arabicUnicodes = /[\u0600-\u06FF]/;
    return arabicUnicodes.test(text);
  };

  return (
    <div className="latestReleases" id="LatestReleases">
      <Fade bottom>
        <div className="titleSec">أحدث الإصدارات</div>

        <div className="bodySec">
          صدر حديثا! تصفح أحدث الاصدارات المتميزة من الكتب الاكاديمية العلمية من
          جميع انحاء الوطن العربي{" "}
        </div>
      </Fade>
      <div className="books">
        <Carousel
          focusOnSelect={true}
          itemsToShow={isMobile ? 1 : 5}
          pagination={false}
          outerSpacing={0}
          itemPosition={"flex-start"}
          showEmptySlots={true}
          onNextEnd={onCurrentChange}
          onPrevEnd={onCurrentChange}
          isRTL={true}
          enableAutoPlay={true}
        >
          {data.length !== 0
            ? data.map((i, index) =>
              <div
                key={i._id}
                className="item"
                onClick={() =>
                  history.push(
                    "/view/" + i._id.split("_")[1] + "/" + i._id.split("_")[0]
                  )}
              >
                <div className="imgdiv">
                  <CustomImage
                    src={
                      i.cover !== undefined
                        ?
                        i.cover.Location
                        : "https://apis.thelearnbook.com/public/learnBookCover.jpg"
                    }
                    src2={
                      i.cover !== undefined
                        ?
                        i.cover.Location
                        : "https://apis.thelearnbook.com/public/learnBookCover.jpg"
                    }
                    effect="opacity"
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div
                  className="nameBook"
                  style={{
                    textAlign: checkIfTextArabic(i.nameAr) ? "right" : "left",
                    fontWeight: !checkIfTextArabic(i.nameAr) ? "bold" : ""
                  }}
                >
                  {i.nameAr}
                </div>
                {/* <div className="aoutBook">المؤلف: محمد خليل</div> */}
              </div>
            )
            : <div />}
        </Carousel>
      </div>
      <Link to={'/latest_releases'}><div className="btn">عرض المزيد</div></Link>
    </div>
  );
}
