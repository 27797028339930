import { LazyLoadImage } from "react-lazy-load-image-component";
import React, {  useState } from 'react';

export default function CustomImage(props) {
    const [error, setError] = useState(false);
    const picture = new Image();
    picture.src = props.src;
    picture.onerror = () => setError(true);
    return error ? <LazyLoadImage {...props} src={props.src2}/>
        : <LazyLoadImage {...props} src={props.src}/>
}
