import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    currentPageForBook: null,
    currentPageForBookFromServer: null,
    loginStatus: false,
    accessToken: localStorage.getItem('access_token') || '',
    isModalOpen: false,
    isModalOpen2: false,
    currentKeyword: {
        keyword: '',
        matchCase: false,
        wholeWords: false
    },
    color: "#ff4362",
    message: "",
    notes: [],
    Item: null,
    Item2: null,
    pdfFile: null,
    stateBookmark: 0,
    stateMylibrark: 0,
    bookmarkId: '',
    mylibraryID: '',
    pagesNumer: 0,
    pagesNumer2: 0,
    popupData: null,
    popupQuotationData: null,
    popupInfoData: null,
    popupSeasonsData: null,
    popupShareData: null,
    popupNotesData: null,
    dialogObject: null,
    loadingPopup:{
        show: false,
        message: '',
        percentCompleted: 1
      }
};

const viewBookSlice = createSlice({
    name: "viewBook",
    initialState,
    reducers: {
        setCurrentPage: (state, actions) => {
            state.currentPageForBook = actions.payload
        },
        setCurrentPageFromServer: (state, actions) => {
            state.currentPageForBookFromServer = actions.payload
        },
        setLoginStatus: (state, actions) => {
            state.loginStatus = actions.payload;
        },
        setAccessToken: (state, actions) => {
            state.accessToken = actions.payload;
        },
        setIsModalOpen: (state, actions) => {
            state.isModalOpen = actions.payload;
        },
        setIsModalOpen2: (state, actions) => {
            state.isModalOpen2 = actions.payload
        },
        setCurrentKeyword: (state, actions) => {
            state.currentKeyword = actions.payload
        },
        setColor: (state, actions) => {
            state.color = actions.payload;
        },
        setMessage: (state, actions) => {
            state.message = actions.payload
        },
        setNotes: (state, actions) => {
            state.notes = actions.payload
        },
        setItem: (state, actions) => {
            state.Item = actions.payload;
        },
        setItem2: (state, actions) => {
            state.Item2 = actions.payload;
        },
        setPdfFile: (state, actions) => {
            state.pdfFile = actions.payload;
        },
        setStateBookmark: (state, actions) => {
            state.stateBookmark = actions.payload
        },
        setStateMylibrar: (state, actions) => {
            state.stateMylibrark = actions.payload
        },
        setBookmarkId: (state, actions) => {
            state.bookmarkId = actions.payload
        },
        seMylibraryID: (state, actions) => {
            state.mylibraryID = actions.payload;
        },
        setPagesNumer: (state, actions) => {
            state.pagesNumer = actions.payload;
        },
        setDialogObject: (state, actions) => {
            state.dialogObject = actions.payload
        },
        setLoadingPopup:(state, actions)=>{
            state.loadingPopup = actions.payload;
        }
    }
});


export const viewBookState = (state) => state.viewBook;
export const {
    setCurrentPage,
    setCurrentPageFromServer,
    setLoginStatus,
    setAccessToken,
    setIsModalOpen,
    setIsModalOpen2,
    setCurrentKeyword,
    setColor,
    setMessage,
    setNotes,
    setItem,
    setItem2,
    setPdfFile,
    setStateBookmark,
    setStateMylibrar,
    setBookmarkId,
    seMylibraryID,
    setPagesNumer,
    setDialogObject,
    setLoadingPopup
} = viewBookSlice.actions

export default viewBookSlice.reducer