import React, { useState } from 'react'
import UserOfType from '../components/sharing/register/userOfType'
import Subscriptions from '../components/sharing/register/Subscriptions'
import Pay from '../components/sharing/register/pay'
import Summary from '../components/sharing/register/Summary'
import Done from '../components/sharing/register/done'
import Info from '../components/sharing/register/info'
import Unviersity from '../components/sharing/register/Unviersty'

function Register () {
  const [step, setStep] = useState(0)
  return (
    <div className='register'>
      {step === 0 && <UserOfType setStep={setStep} />}
      {step === 1 && <Subscriptions setStep={setStep} />}
      {step === 2 && <Summary setStep={setStep} />}
      {step === 3 && <Info setStep={setStep} />}
      {step === 4 && <Pay setStep={setStep} />}
      {step === 5 && <Done setStep={setStep} />}
      {step === 6 && <Unviersity setStep={setStep} />}
    </div>
  )
}

export default Register
