import React, { useEffect, useState } from 'react'
import Slider from '../components/home/slider'
import Search from '../components/home/search'
import Sec3 from '../components/home/sec3'
import LatestReleases from '../components/home/latestReleases'
import Clients from '../components/home/clients'
import Countact from '../components/home/countact'
import Loading from '../components/sharing/loading'
import { Helmet } from 'react-helmet'
import axios from 'axios'
function HomePage (props) {
  const [loaded, setLoaded] = useState(true)

  const imgs = [
    {
      t1: 'منصة ذكية قابلة للبحث في النص الكامل',
      t2:
        'خيارات بحث متقدمة، خدمات الكترونية مبتكرة، للمؤسسات والأفراد والناشرين',
      btn: '',
      img: require('../assets/images/slider/1.jpg'),
      postion: 'center'
    },
    {
      t1: 'بوابة المصادر العربية الموثوقة',
      t2:
        'آلاف المصنفات من المحتوى العربي الالكتروني من الكتب العلمية والرسائل الجامعية والمجلات العربية المحكّمة والمؤتمرات الدولية',
      btn: '',
      img: require('../assets/images/slider/2.jpg'),
      postion: 'center'
    },
    {
      t1: 'قاعدة بيانات رقمية سهلة الوصول',
      t2:
        'غنية بمصادر المعرفة المتنوعة للمؤسسات التعليمية والباحثين في جميع المجالات',
      btn: '',
      img: require('../assets/images/slider/3.jpg'),
      postion: 'right'
    }
  ]

  const countact = {
    address: 'عمان، شارع الملكة رانيا العبد الله عمارة العساف، الأردن',
    phone: ['+962 6 533 5510', '+962 6 533 7798'],
    email: 'info@thelearnbook.com'
  }

  const eraseCookie = name => {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  }

  const logout = () => {
    let formData = new FormData()
    formData.append('token', localStorage.getItem('access_token'))
    axios
      .post(process.env.REACT_APP_BACKEND_URL + '/app/logoutDevice', formData)
      .then(response2 => {
        localStorage.removeItem('access_token')
        eraseCookie('fName')
        eraseCookie('email')
        eraseCookie('id')
        eraseCookie('img')
        eraseCookie('objectUser')
      })
  }

  useEffect(() => {
    setTimeout(() => {
      setLoaded(false)
    }, 1000)
    ///

    if (document.cookie.indexOf('cookieconsent_status') === -1) {
      const expires = new Date(Date.now() + 1000 * 60 * 60 * 1)
      document.cookie = `cookieconsent_status=allow; expires=${expires}; path=/`
      let formData = new FormData()
      formData.append('access_token', localStorage.getItem('access_token'))
      axios.post(
        process.env.REACT_APP_BACKEND_URL + '/app/content/sessions',
        formData
      )
      logout()
    } else {
      console.log('cookie already exists')
    }
  }, [])

  return (
    <div>
      <Helmet>
        <title>{`الصفحة الرئيسية | ${process.env.REACT_APP_FRONT_TITLE}`}</title>
      </Helmet>
      <Loading loadingValue={loaded} />
      <div style={{ display: !loaded ? 'block' : 'none' }}>
        <Slider images={imgs} />
        <Search />
        <Sec3 myRef={props.myRef} />
        <LatestReleases />
        <Clients />
        <Countact countact={countact} />
      </div>
    </div>
  )
}

export default HomePage
