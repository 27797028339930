import React, { useEffect, useRef } from 'react';
import axios from "axios";
import {
    setActiveTab,
    setOpenErrorMsg,
    setSortby,
    setSelectedDate,
    setLengthData,
    setData
} from '../store/myLibrary'
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { mylibraryRedux } from '../store/myLibrary'
import Topsection from '../components/sharing/topsection';
import Breadcrumb from '../components/sharing/breadcrumbs';
import { Typography, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Error from '@material-ui/icons/Error';
import _ from 'lodash';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import CustomImage from '../components/sharing/CustomImage';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import FlipMove from 'react-flip-move';
import { Base64 } from 'js-base64';



function Browse() {
    let history = useHistory();
    const dispatch = useDispatch();
    const myRef = useRef(null)
    const myLibraryRedux = useSelector(mylibraryRedux)


    const sortArry = (e) => {
        dispatch(setSortby(e.target.value))
        let temp =
            myLibraryRedux.activeTab === 0 ?
                myLibraryRedux.download :
                myLibraryRedux.activeTab === 11 ?
                    myLibraryRedux.mylibrary :
                    myLibraryRedux.activeTab === 2 ?
                        myLibraryRedux.quotation :
                        myLibraryRedux.activeTab === 3 ?
                            myLibraryRedux.bookmarks :
                            myLibraryRedux.activeTab === 4 ?
                                myLibraryRedux.highlight :
                                myLibraryRedux.activeTab === 5 ?
                                    myLibraryRedux.print :
                                    myLibraryRedux.activeTab === 6 ?
                                        myLibraryRedux.sendDoC
                                        : []

            ;
        temp = temp.sort(function (a, b) {
            var nameA = '';
            var nameB = '';
            if (e.target.value === 'type') {
                nameA = a.id.split('_')[0];
                nameB = b.id.split('_')[0];
            }
            else {
                nameA = a.info[0].created;
                nameB = b.info[0].created;;
            }
            if (nameA < nameB) {
                return 1;
            }
            if (nameA > nameB) {
                return -1;
            }
            return 0;
        });
        switch (myLibraryRedux.activeTab) {
            case 0:
                dispatch(setData({name:"download",data:temp}))
                break;
            case 11:
                dispatch(setData({name:"mylibrary",data:temp}))
                break;
            case 2:
                dispatch(setData({name:"quotation",data:temp}))
                break;
            case 3:
                dispatch(setData({ name: "bookmarks", data: temp }))
                break;
            case 4:
                dispatch(setData({name:"highlight",data:temp}))
                break;
            case 5:
                dispatch(setData({name:"print",data:temp}))
                break;
            case 6:
                dispatch(setData({name:"sendDoC",data:temp}))
                break;

            default:
                break;
        }
    }

    const handleCallback = (start, end, label) => {
        var newDate = new Date(start._d);
        var newDate2 = new Date(end._d);
        dispatch(setSelectedDate([newDate.getTime(), newDate2.getTime()]));

        //download
        let tempdownload =
            myLibraryRedux.activeTab === 0 ?
                myLibraryRedux.downloadOrg :
                myLibraryRedux.activeTab === 11 ?
                    myLibraryRedux.mylibraryOrg :
                    myLibraryRedux.activeTab === 2 ?
                        myLibraryRedux.quotationOrg :
                        myLibraryRedux.activeTab === 3 ?
                            myLibraryRedux.bookmarksOrg :
                            myLibraryRedux.activeTab === 4 ?
                                myLibraryRedux.highlightOrg :
                                myLibraryRedux.activeTab === 5 ?
                                    myLibraryRedux.printOrg :
                                    myLibraryRedux.activeTab === 6 ?
                                        myLibraryRedux.sendDocOrg
                                        : []

            ;

        tempdownload = tempdownload.filter(item => item.info[item.info.length - 1].created >= newDate && item.info[item.info.length - 1].created <= newDate2)

        switch (myLibraryRedux.activeTab) {
            case 0:
                dispatch(setLengthData({ name: "download", length: tempdownload.length }))
                dispatch(setData({name:"download",data:tempdownload}))
                break;
            case 11:
                dispatch(setLengthData({ name: "mylibrary", length: tempdownload.length }))
                dispatch(setData({name:"mylibrary",data:tempdownload}))
                break;
            case 2:
                dispatch(setLengthData({ name: "quotation", length: tempdownload.length }))
                dispatch(setData({name:"quotation",data:tempdownload}))
                break;
            case 3:
                dispatch(setLengthData({ name: "bookmarks", length: tempdownload.length }))
                dispatch(setData({ name: "bookmarks", data: tempdownload }))
                break;
            case 4:
                dispatch(setLengthData({ name: "highlight", length: tempdownload.length }))
                dispatch(setData({name:"highlight",data:tempdownload}))
                break;
            case 5:
                dispatch(setLengthData({ name: "print", length: tempdownload.length }))
                dispatch(setData({name:"print",data:tempdownload}))
                break;
            case 6:
                dispatch(setLengthData({ name: "sendDoc", length: tempdownload.length }))
                dispatch(setData({name:"sendDoC",data:tempdownload}))
                break;

            default:
                break;
        }

    }




    const styles = (theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            left: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        changefont: {
            fontFamily: 'ArbFONTS-DroidKufi-bold'

        }
    });

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    const DialogContent = withStyles((theme) => ({
        root: {
            padding: theme.spacing(2),
        },
    }))(MuiDialogContent);

    const DialogActions = withStyles((theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(1),
        },
    }))(MuiDialogActions);


    useEffect(() => {
        if (localStorage.getItem('access_token') === undefined) {
            history.push(process.env.REACT_APP_FRONT_URL + '');
        }
    }, [])



    useEffect(() => {
        let formData = new FormData();
        formData.append('lang', 'ar');
        /////////////////////
        formData.append('access_token', localStorage.getItem('access_token'));
        axios.post(process.env.REACT_APP_BACKEND_URL + '/app/content/getBookmarks', formData,
        ).then(response2 => {
            if (response2.data.booleanVaule) {
                dispatch(setLengthData({ name: "bookmarks", length: response2.data.result.length }));

                let hh = response2.data.result;
                hh = hh.map(item => {
                    return {
                        _id: item._id, created: item.created, type: item.type,
                        content: item.type === 'books' ? item.content1[0] :
                            item.type === 'researches' ? item.content2[0] :
                                item.type === 'theses' ? item.content3[0] :
                                    item.type === 'journals' ? item.content4[0] : ''
                    }
                })
                hh = _.map(
                    _.groupBy(hh, elem => elem.content._id),
                    (vals, key) => {
                        return { id: key, info: vals }
                    }
                )
                dispatch(setData({name:"bookmarks",data:hh}))
                dispatch(setLengthData({ name: "bookmarks", length: hh.length }))
                dispatch(setData({ name: "bookmarksOrg", data: hh }));

            }

        })
    }, [])


    useEffect(() => {
        let formData = new FormData();
        formData.append('lang', 'ar');
        /////////////////////
        formData.append('access_token', localStorage.getItem('access_token'));
        axios.post(process.env.REACT_APP_BACKEND_URL + '/app/content/getMylibrary', formData,
        ).then(response2 => {
            if (response2.data.booleanVaule) {
                dispatch(setLengthData({ name: "mylibrary", length: response2.data.result.length }));
                let hh = response2.data.result;
                hh = hh.map(item => {
                    return {
                        _id: item._id, created: item.created, type: item.type,
                        content: item.type === 'books' ? item.content1[0] :
                            item.type === 'researches' ? item.content2[0] :
                                item.type === 'theses' ? item.content3[0] :
                                    item.type === 'journals' ? item.content4[0] : ''
                    }
                })
                hh = _.map(
                    _.groupBy(hh, elem => elem.content._id),
                    (vals, key) => {
                        return { id: key, info: vals }
                    }
                )
                dispatch(setData({name:"mylibrary",data:hh}))
                dispatch(setLengthData({ name: "mylibrary", length: hh.length }));
                dispatch(setData({name:"mylibraryOrg",data:hh}))
            }

        })
    }, [])



    useEffect(() => {
        let formData = new FormData();
        formData.append('lang', 'ar');
        /////////////////////
        formData.append('access_token', localStorage.getItem('access_token'));
        axios.post(process.env.REACT_APP_BACKEND_URL + '/app/content/downloadLogs', formData,
        ).then(response2 => {
            if (response2.data.booleanVaule) {
                dispatch(setLengthData({ name: "download", length: response2.data.result.length }))
                let hh = response2.data.result;
                hh = hh?.map(item => {
                    return {
                        _id: item._id, created: item.created, type: item.type,
                        content: item.type === 'books' ? item.content1[0] :
                            item.type === 'researches' ? item.content2[0] :
                                item.type === 'theses' ? item.content3[0] :
                                    item.type === 'journals' ? item.content4[0] : ''
                    }
                })

                hh = _.map(
                    _.groupBy(hh, 'content._id'),
                    (vals, key) => {
                        return { id: key, info: vals };
                    }
                );
                dispatch(setData({name:"download",data:hh}))
                dispatch(setLengthData({ name: "download", length: hh.length }))
                dispatch(setData({name:"downloadOrg",data:hh}))
            }

        })
    }, [])



    useEffect(() => {
        let formData = new FormData();
        formData.append('lang', 'ar');
        /////////////////////
        formData.append('access_token', localStorage.getItem('access_token'));
        axios.post(process.env.REACT_APP_BACKEND_URL + '/app/content/quotationLogs', formData,
        ).then(response2 => {
            if (response2.data.booleanVaule) {
                let hh = response2.data.result;
                hh = hh.map(item => {
                    return {
                        _id: item._id, created: item.created, type: item.type,
                        content: item.type === 'books' ? item.content1[0] :
                            item.type === 'researches' ? item.content2[0] :
                                item.type === 'theses' ? item.content3[0] :
                                    item.type === 'journals' ? item.content4[0] : ''
                    }
                })
                hh = _.map(
                    _.groupBy(hh, elem => elem.content._id),
                    (vals, key) => {
                        return { id: key, info: vals }
                    }
                )
                dispatch(setData({name:"quotation",data:hh}))
                dispatch(setLengthData({ name: "quotation", length: hh.length }))
                dispatch(setData({name:"quotationOrg",data:hh}))
            }

        })
    }, [])


    useEffect(() => {
        let formData = new FormData();
        formData.append('lang', 'ar');
        /////////////////////
        formData.append('access_token', localStorage.getItem('access_token'));
        axios.post(process.env.REACT_APP_BACKEND_URL + '/app/content/highlightLogs', formData,
        ).then(response2 => {
            if (response2.data.booleanVaule) {
                let hh = response2.data.result;
                hh = hh.map(item => {
                    return {
                        _id: item._id, created: item.created, type: item.type, notes: item.notes,
                        content: item.type === 'books' ? item.content1[0] :
                            item.type === 'researches' ? item.content2[0] :
                                item.type === 'theses' ? item.content3[0] :
                                    item.type === 'journals' ? item.content4[0] : ''
                    }
                })
                hh = _.map(
                    _.groupBy(hh, elem => elem.content._id),
                    (vals, key) => {
                        return { id: key, info: vals }
                    }
                )

                dispatch(setData({name:"highlight",data:hh}))
                dispatch(setLengthData({ name: "highlight", length: hh.length }))
                dispatch(setData({name:"highlightOrg",data:hh}))
            }

        })
    }, [])

    useEffect(() => {
        let formData = new FormData();
        formData.append('lang', 'ar');
        /////////////////////
        formData.append('access_token', localStorage.getItem('access_token'));
        axios.post(process.env.REACT_APP_BACKEND_URL + '/app/content/documentationLogs', formData,
        ).then(response2 => {
            if (response2.data.booleanVaule) {
                let hh = response2.data.result;
                hh = hh.map(item => {
                    return {
                        _id: item._id, created: item.created, type: item.type, notes: item.notes,
                        content: item.type === 'books' ? item.content1[0] :
                            item.type === 'researches' ? item.content2[0] :
                                item.type === 'theses' ? item.content3[0] :
                                    item.type === 'journals' ? item.content4[0] : ''
                    }
                })
                hh = _.map(
                    _.groupBy(hh, elem => elem.content._id),
                    (vals, key) => {
                        return { id: key, info: vals }
                    }
                )
                dispatch(setData({name:"sendDoC",data:hh}))
                dispatch(setLengthData({ name: "sendDoc", length: hh.length }))
                dispatch(setData({name:"sendDocOrg",data:hh}))
            }

        })
    }, [])



    useEffect(() => {
        let formData = new FormData();
        formData.append('lang', 'ar');
        /////////////////////
        formData.append('access_token', localStorage.getItem('access_token'));
        axios.post(process.env.REACT_APP_BACKEND_URL + '/app/content/printLogs', formData,
        ).then(response2 => {
            if (response2.data.booleanVaule) {
                let hh = response2.data.result;
                hh = hh.map(item => {
                    return {
                        _id: item._id, created: item.created, type: item.type, notes: item.notes,
                        content: item.type === 'books' ? item.content1[0] :
                            item.type === 'researches' ? item.content2[0] :
                                item.type === 'theses' ? item.content3[0] :
                                    item.type === 'journals' ? item.content4[0] : ''
                    }
                })
                hh = _.map(
                    _.groupBy(hh, elem => elem.content?._id),
                    (vals, key) => {
                        return { id: key, info: vals }
                    }
                )

                dispatch(setData({name:"print",data:hh}))
                dispatch(setLengthData({ name: "print", length: hh.length }))
                dispatch(setData({name:"printOrg",data:hh}))
            }

        })
    }, [])


    //searchLogs

    useEffect(() => {
        let formData = new FormData();
        formData.append('lang', 'ar');
        /////////////////////
        formData.append('access_token', localStorage.getItem('access_token'));
        axios.post(process.env.REACT_APP_BACKEND_URL + '/app/content/searchLogs', formData,
        ).then(response2 => {
            if (response2.data.booleanVaule) {
                let hh = response2.data.result;
                hh = hh.map(item => {
                    return {
                        _id: item._id,
                        created: prettyDate2(item.created),
                        dataSearch: item.dataSearch,
                        searchType: item.searchType,
                    }
                })
                hh = _.map(
                    _.groupBy(hh, elem => elem.created),
                    (vals, key) => {
                        return { id: key, info: vals }
                    }
                )
                dispatch(setData({name:"searchLog",data:hh}))
                dispatch(setLengthData({ name: "searchLog", length: hh.length }))
                dispatch(setData({name:"searchLogOrg",data:hh}))
            }

        })
    }, [])
    const breadCurmbData = [
        {
            title: "مكتبتي",
            link: '',
        },
    ];

    const checkIfTextArabic = (text) => {
        var arabicUnicodes = /[\u0600-\u06FF]/;
        return arabicUnicodes.test(text)
    }


    const changePage = (id) => {
        dispatch(setActiveTab(id))
        dispatch({ payload: "scas", type: "changeTabe" })
    }

    const prettyDate = (date) => {
        var d = new Date(date);
        return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();
    }
    const prettyDate2 = (date) => {
        var d = new Date(date);
        return d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
    }

    function getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }

    return (
        <div ref={myRef}>

            <Dialog onClose={() => dispatch(setOpenErrorMsg(false))} aria-labelledby="customized-Dialog-title" open={myLibraryRedux.OpenErrorMsg}>
                <DialogTitle id="customized-dialog-title" onClose={() => dispatch(setOpenErrorMsg(false))}
                >
                    <div className="titlePopupMsg"><Error className="iconError" />{''}</div>
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        <div className="bodyPopupMsg">{''}</div>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button className="ButtonErrorMsg" autoFocus onClick={() => dispatch(setOpenErrorMsg(false))} color="primary">
                        موافق
                    </Button>
                </DialogActions>
            </Dialog>



            <Topsection data={{ text: 'مكتبتي', img: require('../assets/images/profile.png') }} />
            <Breadcrumb data={breadCurmbData} />
            <div className="loadingData" style={{
                visibility: myLibraryRedux.loadingPage ? 'visible' : 'hidden',
                opacity: myLibraryRedux.loadingPage ? 1 : 0,
                transition: 'visibility 0s, opacity 0.3s linear'

            }}>
                <img src={require('../assets/images/bookLoading.gif')} alt="loading" />
                <div className="textLoading">جاري التحميل...</div>
            </div>
            <div className="browse2">


                <div className="reslutlist">
                    <div className="topSec">
                        <div className="titleSec">مكتبتي</div>

                        <div className="numberofitem">
                            <div className="itemsCount">
                                {
                                    myLibraryRedux.activeTab === 0 ? <span>التحميلات <b>({myLibraryRedux.downloadLength} نتيجة)</b></span>
                                        : myLibraryRedux.activeTab === 11 ? <span>مكتبتي <b>({myLibraryRedux.mylibraryLength} نتيجة)</b></span>
                                            : myLibraryRedux.activeTab === 2 ? <span>الاقتباسات <b>({myLibraryRedux.quotationLength} نتيجة)</b></span>
                                                : myLibraryRedux.activeTab === 3 ? <span>العلامات المرجعية <b>({myLibraryRedux.bookmarksLength} نتيجة)</b></span>
                                                    : myLibraryRedux.activeTab === 4 ? <span>النصوص المظللة <b>({myLibraryRedux.highlightLength} نتيجة)</b></span>
                                                        : myLibraryRedux.activeTab === 5 ? <span>المطبوعات <b>({myLibraryRedux.printLength} نتيجة)</b></span>
                                                            : myLibraryRedux.activeTab === 6 ? <span>الوثائق المُرسلة <b>({myLibraryRedux.sendDocLength} نتيجة)</b></span>
                                                                : <span>سجلّ البحث <b>({myLibraryRedux.searchLogLength} نتيجة)</b></span>
                                }
                            </div>
                        </div>


                        <div className="orderResult">
                            <div>


                                <DateRangePicker onCallback={handleCallback}>
                                    <button className="dateSelectionBtn">التاريخ</button>
                                </DateRangePicker>
                            </div>


                            {myLibraryRedux.activeTab !== 7 && (
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19.687" height="14.872" viewBox="0 0 19.687 14.872">
                                        <g id="Group_9212" data-name="Group 9212" transform="translate(1.414 1)">
                                            <g id="arrow-right" transform="translate(8.933 0)">
                                                <line id="Line_3" data-name="Line 3" y1="12.575" transform="translate(3.963 0.297)" fill="none" stroke="#87a2be" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                <path id="Path_3" data-name="Path 3" d="M251.991,489.261l-3.963-3.963-3.963,3.963" transform="translate(-244.066 -485.298)" fill="none" stroke="#87a2be" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                            </g>
                                            <g id="arrow-right-2" data-name="arrow-right" transform="translate(0)">
                                                <line id="Line_3-2" data-name="Line 3" y1="12.575" transform="translate(3.963)" fill="none" stroke="#87a2be" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                <path id="Path_3-2" data-name="Path 3" d="M231.881,497.45l3.963,3.963,3.963-3.963" transform="translate(-231.881 -488.541)" fill="none" stroke="#87a2be" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                            </g>
                                        </g>
                                    </svg>
                                    ترتيب حسب:


                                    <select className='custom windows' value={myLibraryRedux.sortby} onChange={(e) => sortArry(e)}>
                                        <option value="type">نوع الوثيقة</option>
                                        <option value="date">التاريخ</option>
                                    </select>
                                </div>
                            )


                            }


                        </div>


                    </div>



                    <div className="bodyResult">
                        <div className="typesReslt">
                            <div className={myLibraryRedux.activeTab === 0 ? "tab activeTab" : "tab"} onClick={() => changePage(0)}>
                                <div className="head">
                                    <label className="container"  >
                                        <svg className={myLibraryRedux.activeTab === 0 ? "activeSvg" : ""} width="24.626" height="24.814" viewBox="0 0 24.626 24.814">
                                            <g id="Group_9107" data-name="Group 9107" transform="translate(-684.403 -482.493)">
                                                <g id="arrow-right">
                                                    <line id="Line_3" data-name="Line 3" y1="11.798" transform="translate(696.716 483.493)" fill="none" stroke="#727c89" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                    <path id="Path_3" data-name="Path 3" d="M692.647,494.773l4.069,4.822,4.07-4.822Z" fill="#727c89" stroke="#727c89" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                </g>
                                                <path id="Path_9497" data-name="Path 9497" d="M685.4,498.7v3.321a4.283,4.283,0,0,0,4.283,4.283h14.06a4.283,4.283,0,0,0,4.283-4.281V498.7" fill="none" stroke="#727c89" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                            </g>
                                        </svg>

                                        التحميلات
                                    </label>
                                    <div className="numbersOfResult">{myLibraryRedux.downloadLength}</div>
                                    <div className="plusMin">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11.179" height="19.359" viewBox="0 0 11.179 19.359">
                                            <path id="Path_3" data-name="Path 3" d="M231.881,497.45l7.558,7.558L247,497.45" transform="translate(506.508 -229.76) rotate(90)" fill="none" stroke="#147bfc" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className={myLibraryRedux.activeTab === 11 ? "tab activeTab" : "tab"} onClick={() => changePage(11)}>
                                <div className="head">
                                    <label className="container"  >
                                        <svg className={myLibraryRedux.activeTab === 1 ? "activeSvg" : ""} width="29.675" height="32.395" viewBox="0 0 29.675 32.395">
                                            <g id="Group_9686" data-name="Group 9686" transform="translate(-1421.472 -456.486)">
                                                <path id="Path_9926" data-name="Path 9926" d="M1441.906,475.756l-.385-9.061a.687.687,0,0,0-.684-.658h-17.011a.687.687,0,0,0-.684.658l-.667,15.8a3.67,3.67,0,0,0,3.667,3.821H1437.9" transform="translate(0 -1.241)" fill="none" stroke="#727c89" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <path id="Path_9927" data-name="Path 9927" d="M1428.661,467.48v-5.425a4.569,4.569,0,0,1,4.569-4.569h0a4.57,4.57,0,0,1,4.57,4.569v5.425" transform="translate(-0.899)" fill="none" stroke="#727c89" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <circle id="Ellipse_256" data-name="Ellipse 256" cx="6.763" cy="6.763" r="6.763" transform="translate(1436.619 474.354)" fill="none" stroke="#727c89" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <path id="Path_9928" data-name="Path 9928" d="M1449.046,483.152l-3.827,3.827-1.914-1.913" transform="translate(-3.024 -3.726)" fill="none" stroke="#727c89" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                            </g>
                                        </svg>

                                        مكتبتي
                                    </label>
                                    <div className="numbersOfResult">{myLibraryRedux.mylibraryLength}</div>
                                    <div className="plusMin">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11.179" height="19.359" viewBox="0 0 11.179 19.359">
                                            <path id="Path_3" data-name="Path 3" d="M231.881,497.45l7.558,7.558L247,497.45" transform="translate(506.508 -229.76) rotate(90)" fill="none" stroke="#147bfc" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className={myLibraryRedux.activeTab === 2 ? "tab activeTab" : "tab"} onClick={() => changePage(2)}>
                                <div className="head">
                                    <label className="container"  >
                                        <svg className={myLibraryRedux.activeTab === 2 ? "activeSvg" : ""} width="18.987" height="16.066" viewBox="0 0 18.987 16.066">
                                            <g id="Group_8965" data-name="Group 8965" transform="translate(-687.046 -844.217)">
                                                <path id="Path_9531" data-name="Path 9531" d="M698.709,857.094a.876.876,0,0,0-.45,1.154l.01.021.721,1.515a.878.878,0,0,0,1.142.426,10.134,10.134,0,0,0,3.174-2.058,7.683,7.683,0,0,0,2.132-3.366,18.492,18.492,0,0,0,.588-5.174V845.1a.889.889,0,0,0-.883-.882h-5.776a.887.887,0,0,0-.882.882v5.777a.887.887,0,0,0,.882.882h2.764a6.194,6.194,0,0,1-1.03,3.571A5.576,5.576,0,0,1,698.709,857.094Z" fill="none" />
                                                <path id="Path_9532" data-name="Path 9532" d="M687.57,857.094a.876.876,0,0,0-.45,1.154l.01.021.721,1.5a.878.878,0,0,0,1.143.426,10.645,10.645,0,0,0,3.161-2.043,7.835,7.835,0,0,0,2.146-3.382,18.23,18.23,0,0,0,.573-5.158V845.1a.889.889,0,0,0-.882-.882h-5.777a.887.887,0,0,0-.882.882v5.776a.889.889,0,0,0,.882.883h2.778a6.2,6.2,0,0,1-1.028,3.572A5.585,5.585,0,0,1,687.57,857.094Z" fill="none" />
                                            </g>
                                        </svg>


                                        الاقتباسات
                                    </label>
                                    <div className="numbersOfResult">{myLibraryRedux.quotationLength}</div>
                                    <div className="plusMin">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11.179" height="19.359" viewBox="0 0 11.179 19.359">
                                            <path id="Path_3" data-name="Path 3" d="M231.881,497.45l7.558,7.558L247,497.45" transform="translate(506.508 -229.76) rotate(90)" fill="none" stroke="#147bfc" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className={myLibraryRedux.activeTab === 3 ? "tab activeTab" : "tab"} onClick={() => changePage(3)}>
                                <div className="head">
                                    <label className="container">
                                        <svg className={myLibraryRedux.activeTab === 3 ? "activeSvg" : ""} width="17.14" height="25.405" viewBox="0 0 17.14 25.405">
                                            <path id="Path_9591" data-name="Path 9591" d="M703.313,684.62l-6.01-3.73a.759.759,0,0,0-.8,0l-6.01,3.73a.759.759,0,0,1-1.16-.646V662.092a.759.759,0,0,1,.759-.759h13.622a.759.759,0,0,1,.759.759v21.883a.761.761,0,0,1-.762.759A.746.746,0,0,1,703.313,684.62Z" transform="translate(-688.333 -660.333)" fill="none" stroke="#727c89" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                        </svg>

                                        العلامات المرجعية
                                    </label>
                                    <div className="numbersOfResult">{myLibraryRedux.bookmarksLength}</div>
                                    <div className="plusMin">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11.179" height="19.359" viewBox="0 0 11.179 19.359">
                                            <path id="Path_3" data-name="Path 3" d="M231.881,497.45l7.558,7.558L247,497.45" transform="translate(506.508 -229.76) rotate(90)" fill="none" stroke="#147bfc" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className={myLibraryRedux.activeTab === 4 ? "tab activeTab" : "tab"} onClick={() => changePage(4)}>
                                <div className="head">
                                    <label className="container"  >
                                        <svg className={myLibraryRedux.activeTab === 4 ? "activeSvg" : ""} width="27.635" height="26.517" viewBox="0 0 27.635 26.517">
                                            <g id="Group_8962" data-name="Group 8962" transform="translate(-683.133 -749.23)">
                                                <path id="Path_9524" data-name="Path 9524" d="M688.046,764.423l7.525,7.536-6.3.9a.467.467,0,0,0-.241.129l-.959.959-2.085-2.085.959-.958a.493.493,0,0,0,.129-.242Z" fill="none" stroke="#727c89" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <path id="Path_9525" data-name="Path 9525" d="M688.1,773.911l-.836.836-3.128-1.042,1.879-1.879Z" fill="none" stroke="#727c89" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <path id="Path_9526" data-name="Path 9526" d="M709.393,755.627,695.57,771.959l-7.525-7.536L704.377,750.6a1.19,1.19,0,0,1,1.6.075l3.348,3.337A1.205,1.205,0,0,1,709.393,755.627Z" fill="none" stroke="#727c89" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                            </g>
                                        </svg>


                                        النصوص المظللة
                                    </label>
                                    <div className="numbersOfResult">{myLibraryRedux.highlightLength}</div>
                                    <div className="plusMin">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11.179" height="19.359" viewBox="0 0 11.179 19.359">
                                            <path id="Path_3" data-name="Path 3" d="M231.881,497.45l7.558,7.558L247,497.45" transform="translate(506.508 -229.76) rotate(90)" fill="none" stroke="#147bfc" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div className={myLibraryRedux.activeTab === 5 ? "tab activeTab" : "tab"} onClick={() => changePage(5)}>
                                <div className="head">
                                    <label className="container"  >
                                        <svg className={myLibraryRedux.activeTab === 5 ? "activeSvg" : ""} width="28.944" height="28.982" viewBox="0 0 28.944 28.982">
                                            <g id="Group_9105" data-name="Group 9105" transform="translate(-682.431 -390.901)">
                                                <path id="Path_9518" data-name="Path 9518" d="M705.666,412.969h.82a3.9,3.9,0,0,0,3.889-3.889v-6.44a3.891,3.891,0,0,0-3.889-3.889H687.32a3.887,3.887,0,0,0-3.889,3.885v6.444a3.891,3.891,0,0,0,3.889,3.889h.82" fill="none" stroke="#727c89" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <path id="Path_9519" data-name="Path 9519" d="M688.139,398.751V395.79a3.884,3.884,0,0,1,3.881-3.889h9.757a3.888,3.888,0,0,1,3.889,3.885v2.965" fill="none" stroke="#727c89" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <path id="Path_9520" data-name="Path 9520" d="M705.666,407.99v8.937a1.953,1.953,0,0,1-1.948,1.956h-13.63a1.951,1.951,0,0,1-1.948-1.956V407.99" fill="none" stroke="#727c89" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <line id="Line_28" data-name="Line 28" x2="4.511" transform="translate(694.54 411.349)" fill="none" stroke="#727c89" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <line id="Line_29" data-name="Line 29" x2="4.511" transform="translate(694.54 414.668)" fill="none" stroke="#727c89" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <line id="Line_30" data-name="Line 30" x2="19.491" transform="translate(687.049 407.987)" fill="none" stroke="#727c89" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <line id="Line_31" data-name="Line 31" x2="3.447" transform="translate(687.049 402.88)" fill="none" stroke="#727c89" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                            </g>
                                        </svg>


                                        المطبوعات
                                    </label>
                                    <div className="numbersOfResult">{myLibraryRedux.printLength}</div>
                                    <div className="plusMin">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11.179" height="19.359" viewBox="0 0 11.179 19.359">
                                            <path id="Path_3" data-name="Path 3" d="M231.881,497.45l7.558,7.558L247,497.45" transform="translate(506.508 -229.76) rotate(90)" fill="none" stroke="#147bfc" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div className={myLibraryRedux.activeTab === 6 ? "tab activeTab" : "tab"} onClick={() => changePage(6)}>
                                <div className="head">
                                    <label className="container"  >
                                        <svg className={myLibraryRedux.activeTab === 6 ? "activeSvg" : ""} width="25.465" height="25.399" viewBox="0 0 25.465 25.399">
                                            <g id="Group_9109" data-name="Group 9109" transform="translate(-684.403 -571.124)">
                                                <path id="Path_9495" data-name="Path 9495" d="M708.558,579.179l-6.575-6.827a.758.758,0,0,0-1.3.526v3.288h-.253a9.873,9.873,0,0,0-9.862,9.862v1.517a.748.748,0,0,0,.59.725.665.665,0,0,0,.167.021.783.783,0,0,0,.693-.431,8.3,8.3,0,0,1,7.463-4.613h1.2v3.289a.758.758,0,0,0,1.3.526l6.575-6.828A.759.759,0,0,0,708.558,579.179Z" fill="none" stroke="#727c89" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                <path id="Path_9496" data-name="Path 9496" d="M689.781,577.079h-2.356A2.022,2.022,0,0,0,685.4,579.1v14.4a2.022,2.022,0,0,0,2.022,2.022h18.448A2.022,2.022,0,0,0,707.9,593.5v-6.322" fill="none" stroke="#727c89" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                            </g>
                                        </svg>


                                        الوثائق المُرسلة
                                    </label>
                                    <div className="numbersOfResult">{myLibraryRedux.sendDocLength}</div>
                                    <div className="plusMin">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11.179" height="19.359" viewBox="0 0 11.179 19.359">
                                            <path id="Path_3" data-name="Path 3" d="M231.881,497.45l7.558,7.558L247,497.45" transform="translate(506.508 -229.76) rotate(90)" fill="none" stroke="#147bfc" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                        </svg>
                                    </div>
                                </div>
                            </div>


                            <div className={myLibraryRedux.activeTab === 7 ? "tab activeTab" : "tab"} onClick={() => changePage(7)}>
                                <div className="head">
                                    <label className="container"  >
                                        <svg className={myLibraryRedux.activeTab === 7 ? "activeSvg" : ""} width="31.511" height="31.511" viewBox="0 0 31.511 31.511">
                                            <g id="Group_9687" data-name="Group 9687" transform="translate(-1687 -1243.558)">
                                                <circle id="Ellipse_86" data-name="Ellipse 86" cx="2.413" cy="2.413" r="2.413" transform="translate(1698.348 1254.906)" fill="#727c89" />
                                                <g id="Group_9613" data-name="Group 9613">
                                                    <path id="Ellipse_86-2" data-name="Ellipse 86" d="M1418.994,525.958a12.763,12.763,0,1,1,12.625,10.888,12.725,12.725,0,0,1-4.8-.932" transform="translate(269.142 733.235)" fill="none" stroke="#727c89" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                    <line id="Line_5" data-name="Line 5" x2="7.313" y2="7.313" transform="translate(1709.784 1266.342)" fill="none" stroke="#727c89" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                    <path id="Path_9929" data-name="Path 9929" d="M1431.619,517.708v6.376l3.4,3.257" transform="translate(269.142 733.235)" fill="none" stroke="#727c89" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                    <line id="Line_123" data-name="Line 123" transform="translate(1689.464 1263.301)" fill="none" stroke="#727c89" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                    <line id="Line_124" data-name="Line 124" transform="translate(1692.668 1266.991)" fill="none" stroke="#727c89" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                </g>
                                            </g>
                                        </svg>


                                        سجلّ البحث
                                    </label>
                                    <div className="numbersOfResult">{myLibraryRedux.searchLogLength}</div>
                                    <div className="plusMin">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11.179" height="19.359" viewBox="0 0 11.179 19.359">
                                            <path id="Path_3" data-name="Path 3" d="M231.881,497.45l7.558,7.558L247,497.45" transform="translate(506.508 -229.76) rotate(90)" fill="none" stroke="#147bfc" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                        </svg>
                                    </div>
                                </div>
                            </div>




                        </div>


                        <div className="results">
                            <div className="bookMarkList">

                                {
                                    myLibraryRedux.activeTab === 3 ?
                                        myLibraryRedux.bookmarks?.length === 0 ?
                                            <FlipMove typeName={null}></FlipMove>
                                            :
                                            <FlipMove typeName={null}>
                                                {myLibraryRedux.bookmarks?.map(item => {
                                                    return <div className="itemBook" key={item.id}>

                                                        <CustomImage
                                                            src={item.info[0]?.content.cover !== '' && item.info[0].content.cover !== undefined ? item.info[0].content.cover : 'https://apis.thelearnbook.com/public/learnBookCover.jpg'}
                                                            src2={item.info[0].content.cover !== '' && item.info[0].content.cover !== undefined ? item.info[0].content.cover : 'https://apis.thelearnbook.com/public/learnBookCover.jpg'}
                                                            effect="opacity"
                                                            onClick={() => history.push("/view/" + item.info[0].content._id.split('_')[1] + '/' + item.info[0].content._id.split('_')[0])}
                                                            style={{ cursor: 'pointer' }}
                                                        />

                                                        <div className={checkIfTextArabic(item.info[0].content.nameAr) ? "titleCountent" : "titleCountent englishLang"} onClick={() => history.push("/view/" + item.info[0].content._id.split('_')[1] + '/' + item.info[0].content._id.split('_')[0])}>
                                                            {item.info[0]?.content.nameAr}
                                                        </div>

                                                        <div className="boxInfo">
                                                            <div className="dateAction">
                                                                <div className="vlaueAction">{prettyDate(item.info[0].created)}</div>
                                                                <div className="titleAction">التاريخ</div>
                                                            </div>

                                                        </div>
                                                        <div className="btnRead" onClick={() => history.push("/ViewBook/" + item.info[0].content._id.split('_')[1] + '/' + item.info[0].content._id.split('_')[0] + '/' + Base64.encode(`${item.info[0].content.yearsValue}`))}>اقرأ الوثيقة</div>
                                                    </div>
                                                }
                                                )}
                                            </FlipMove>

                                        :
                                        myLibraryRedux.activeTab === 11 ?
                                            myLibraryRedux.mylibrary.length === 0 ?
                                                <FlipMove typeName={null}></FlipMove>
                                                :
                                                <FlipMove typeName={null}>
                                                    {myLibraryRedux.mylibrary?.map(item => {
                                                        return <div className="itemBook" key={item.id}>

                                                            <CustomImage
                                                                src={item.info[0].content.cover !== '' && item.info[0].content.cover !== undefined ? item.info[0].content.cover : 'https://apis.thelearnbook.com/public/learnBookCover.jpg'}
                                                                src2={item.info[0].content.cover !== '' && item.info[0].content.cover !== undefined ? item.info[0].content.cover : 'https://apis.thelearnbook.com/public/learnBookCover.jpg'}
                                                                effect="opacity"
                                                                onClick={() => history.push("/view/" + item.info[0].content._id.split('_')[1] + '/' + item.info[0].content._id.split('_')[0])}
                                                                style={{ cursor: 'pointer' }}
                                                            />

                                                            <div className={checkIfTextArabic(item.info[0].content.nameAr) ? "titleCountent" : "titleCountent englishLang"} onClick={() => history.push("/view/" + item.info[0].content._id.split('_')[1] + '/' + item.info[0].content._id.split('_')[0])}>
                                                                {item.info[0].content.nameAr}
                                                            </div>

                                                            <div className="boxInfo">
                                                                <div className="dateAction">
                                                                    <div className="vlaueAction">{prettyDate(item.info[0].created)}</div>
                                                                    <div className="titleAction">التاريخ</div>
                                                                </div>
                                                            </div>
                                                            <div className="btnRead" onClick={() => history.push("/ViewBook/" + item.info[0].content._id.split('_')[1] + '/' + item.info[0].content._id.split('_')[0]+'/'+Base64.encode(`${item.info[0].content.yearsValue}`))}>اقرأ الوثيقة</div>
                                                        </div>
                                                    }
                                                    )}
                                                </FlipMove>

                                            :

                                            myLibraryRedux.activeTab === 0 ?
                                                myLibraryRedux.download.length === 0 ?
                                                    <FlipMove typeName={null}></FlipMove>
                                                    :
                                                    <FlipMove typeName={null}>
                                                        {myLibraryRedux.download?.map(item => {
                                                            return <div className="itemBook" key={item.id}>

                                                                <CustomImage
                                                                    src={item.info[0].content?.cover !== '' && item.info[0].content?.cover !== undefined ? item.info[0].content?.cover : 'https://apis.thelearnbook.com/public/learnBookCover.jpg'}
                                                                    src2={item.info[0].content?.cover !== '' && item.info[0].content?.cover !== undefined ? item.info[0].content?.cover : 'https://apis.thelearnbook.com/public/learnBookCover.jpg'}
                                                                    effect="opacity"
                                                                    onClick={() => history.push("/view/" + item.info[0].content._id.split('_')[1] + '/' + item.info[0].content._id.split('_')[0])}
                                                                    style={{ cursor: 'pointer' }}
                                                                />

                                                                <div className={checkIfTextArabic(item.info[0].content?.nameAr) ? "titleCountent" : "titleCountent englishLang"} onClick={() => history.push("/view/" + item.info[0].content._id.split('_')[1] + '/' + item.info[0].content._id.split('_')[0])}>
                                                                    {item.info[0].content?.nameAr}
                                                                </div>

                                                                <div className="boxInfo">
                                                                    <div className="dateAction">
                                                                        <div className="vlaueAction">{prettyDate(item.info[0]?.created)}</div>
                                                                        <div className="titleAction">تاريخ التحميل</div>
                                                                    </div>
                                                                    <div className="counterAction">
                                                                        <div className="vlaueAction">{item.info?.length}</div>
                                                                        <div className="titleAction">عدد مرات التحميل</div>
                                                                    </div>
                                                                </div>
                                                                <div className="btnRead" onClick={() => history.push("/ViewBook/" + item.info[0].content._id.split('_')[1] + '/' + item.info[0].content._id.split('_')[0]+'/'+Base64.encode(`${item.info[0].content.yearsValue}`))}>اقرأ الوثيقة</div>
                                                            </div>
                                                        }
                                                        )}
                                                    </FlipMove>

                                                :
                                                myLibraryRedux.activeTab === 4 ?
                                                    myLibraryRedux.highlight.length === 0 ?
                                                        <FlipMove typeName={null}></FlipMove>
                                                        :
                                                        <FlipMove typeName={null}>
                                                            {myLibraryRedux.highlight?.map(item => {
                                                                return <div className="itemBook" key={item.id}>

                                                                    <CustomImage
                                                                        src={item.info[0].content.cover !== '' && item.info[0].content.cover !== undefined ? item.info[0].content.cover : 'https://apis.thelearnbook.com/public/learnBookCover.jpg'}
                                                                        src2={item.info[0].content.cover !== '' && item.info[0].content.cover !== undefined ? item.info[0].content.cover : 'https://apis.thelearnbook.com/public/learnBookCover.jpg'}
                                                                        effect="opacity"
                                                                        onClick={() => history.push("/view/" + item.info[0].content._id.split('_')[1] + '/' + item.info[0].content._id.split('_')[0])}
                                                                        style={{ cursor: 'pointer' }}
                                                                    />

                                                                    <div className={checkIfTextArabic(item.info[0].content.nameAr) ? "titleCountent" : "titleCountent englishLang"} onClick={() => history.push("/view/" + item.info[0].content._id.split('_')[1] + '/' + item.info[0].content._id.split('_')[0])}>
                                                                        {item.info[0].content.nameAr}
                                                                    </div>

                                                                    <div className="boxInfo">
                                                                        <div className="dateAction">
                                                                            <div className="vlaueAction">{prettyDate(item.info[0].created)}</div>
                                                                            <div className="titleAction">تاريخ أخر ملاحظة</div>
                                                                        </div>
                                                                        <div className="counterAction">
                                                                            <div className="vlaueAction">{item.info[0].notes.length}</div>
                                                                            <div className="titleAction">عدد الملاحظات</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="btnRead" onClick={() => history.push("/ViewBook/" + item.info[0].content._id.split('_')[1] + '/' + item.info[0].content._id.split('_')[0]+'/'+Base64.encode(`${item.info[0].content.yearsValue}`))}>اقرأ الوثيقة</div>
                                                                </div>
                                                            }
                                                            )}
                                                        </FlipMove>

                                                    :
                                                    myLibraryRedux.activeTab === 2 ?
                                                        myLibraryRedux.quotation.length === 0 ?
                                                            <FlipMove typeName={null}></FlipMove>
                                                            :
                                                            <FlipMove typeName={null}>
                                                                {myLibraryRedux.quotation?.map(item => {
                                                                    return <div className="itemBook" key={item.id}>

                                                                        <CustomImage
                                                                            src={item.info[0].content.cover !== '' && item.info[0].content.cover !== undefined ? item.info[0].content.cover : 'https://apis.thelearnbook.com/public/learnBookCover.jpg'}
                                                                            src2={item.info[0].content.cover !== '' && item.info[0].content.cover !== undefined ? item.info[0].content.cover : 'https://apis.thelearnbook.com/public/learnBookCover.jpg'}
                                                                            effect="opacity"
                                                                            onClick={() => history.push("/view/" + item.info[0].content._id.split('_')[1] + '/' + item.info[0].content._id.split('_')[0])}
                                                                            style={{ cursor: 'pointer' }}
                                                                        />

                                                                        <div className={checkIfTextArabic(item.info[0].content.nameAr) ? "titleCountent" : "titleCountent englishLang"} onClick={() => history.push("/view/" + item.info[0].content._id.split('_')[1] + '/' + item.info[0].content._id.split('_')[0])}>
                                                                            {item.info[0].content.nameAr}
                                                                        </div>

                                                                        <div className="boxInfo">
                                                                            <div className="dateAction">
                                                                                <div className="vlaueAction">{prettyDate(item.info[0].created)}</div>
                                                                                <div className="titleAction">تاريخ أخر إقتباس</div>
                                                                            </div>
                                                                            <div className="counterAction">
                                                                                <div className="vlaueAction">{item.info.length}</div>
                                                                                <div className="titleAction">عدد الإقتباسات</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="btnRead" onClick={() => history.push("/ViewBook/" + item.info[0].content._id.split('_')[1] + '/' + item.info[0].content._id.split('_')[0]+'/'+Base64.encode(`${item.info[0].content.yearsValue}`))}>اقرأ الوثيقة</div>
                                                                    </div>
                                                                }
                                                                )}
                                                            </FlipMove>
                                                        :
                                                        myLibraryRedux.activeTab === 5 ?
                                                            myLibraryRedux.print.length === 0 ?
                                                                <FlipMove typeName={null}></FlipMove>
                                                                :
                                                                <FlipMove typeName={null}>
                                                                    {myLibraryRedux.print?.map(item => {
                                                                        return <div className="itemBook" key={item.id}>

                                                                            <CustomImage
                                                                                src={item.info[0].content.cover !== '' && item.info[0].content.cover !== undefined ? item.info[0].content.cover : 'https://apis.thelearnbook.com/public/learnBookCover.jpg'}
                                                                                src2={item.info[0].content.cover !== '' && item.info[0].content.cover !== undefined ? item.info[0].content.cover : 'https://apis.thelearnbook.com/public/learnBookCover.jpg'}
                                                                                effect="opacity"
                                                                                onClick={() => history.push("/view/" + item.info[0].content._id.split('_')[1] + '/' + item.info[0].content._id.split('_')[0])}
                                                                                style={{ cursor: 'pointer' }}
                                                                            />

                                                                            <div className={checkIfTextArabic(item.info[0].content.nameAr) ? "titleCountent" : "titleCountent englishLang"} onClick={() => history.push("/view/" + item.info[0].content._id.split('_')[1] + '/' + item.info[0].content._id.split('_')[0])}>
                                                                                {item.info[0].content.nameAr}
                                                                            </div>

                                                                            <div className="boxInfo">
                                                                                <div className="dateAction">
                                                                                    <div className="vlaueAction">{prettyDate(item.info[0].created)}</div>
                                                                                    <div className="titleAction">تاريخ أخر طباعة</div>
                                                                                </div>
                                                                                <div className="counterAction">
                                                                                    <div className="vlaueAction">{item.info.length}</div>
                                                                                    <div className="titleAction">مرات الطباعة</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="btnRead" onClick={() => history.push("/ViewBook/" + item.info[0].content._id.split('_')[1] + '/' + item.info[0].content._id.split('_')[0] + '/' + Base64.encode(`${item.info[0].content.yearsValue}`,true))}>اقرأ الوثيقة</div>
                                                                        </div>
                                                                    }
                                                                    )}
                                                                </FlipMove>
                                                            :
                                                            myLibraryRedux.activeTab === 6 ?
                                                                myLibraryRedux.sendDoC.length === 0 ?
                                                                    <FlipMove typeName={null}></FlipMove>
                                                                    :
                                                                    <FlipMove typeName={null}>
                                                                        {myLibraryRedux.sendDoC?.map(item => {
                                                                            return <div className="itemBook" key={item.id}>

                                                                                <CustomImage
                                                                                    src={item.info[0].content.cover !== '' && item.info[0].content.cover !== undefined ? item.info[0].content.cover : 'https://apis.thelearnbook.com/public/learnBookCover.jpg'}
                                                                                    src2={item.info[0].content.cover !== '' && item.info[0].content.cover !== undefined ? item.info[0].content.cover : 'https://apis.thelearnbook.com/public/learnBookCover.jpg'}
                                                                                    effect="opacity"
                                                                                    onClick={() => history.push("/view/" + item.info[0].content._id.split('_')[1] + '/' + item.info[0].content._id.split('_')[0])}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                />

                                                                                <div className={checkIfTextArabic(item.info[0].content.nameAr) ? "titleCountent" : "titleCountent englishLang"} onClick={() => history.push("/view/" + item.info[0].content._id.split('_')[1] + '/' + item.info[0].content._id.split('_')[0])}>
                                                                                    {item.info[0].content.nameAr}
                                                                                </div>

                                                                                <div className="boxInfo">
                                                                                    <div className="dateAction">
                                                                                        <div className="vlaueAction">{prettyDate(item.info[0].created)}</div>
                                                                                        <div className="titleAction">تاريخ أخر إرسال</div>
                                                                                    </div>
                                                                                    <div className="counterAction">
                                                                                        <div className="vlaueAction">{item.info.length}</div>
                                                                                        <div className="titleAction">عدد المشاركات</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="btnRead" onClick={() => history.push("/ViewBook/" + item.info[0].content._id.split('_')[1] + '/' + item.info[0].content._id.split('_')[0]+'/'+Base64.encode(`${item.info[0].content.yearsValue}`))}>اقرأ الوثيقة</div>
                                                                            </div>
                                                                        }
                                                                        )}
                                                                    </FlipMove>
                                                                :
                                                                myLibraryRedux.activeTab === 7 ?
                                                                    myLibraryRedux.searchLog.length === 0 ?
                                                                        <FlipMove typeName={null}></FlipMove>
                                                                        :
                                                                        <FlipMove typeName={null}>
                                                                            <div className="searchLogs">
                                                                                {myLibraryRedux.searchLog?.map(item => {
                                                                                    return <div className="itemSearh" key={item.id}>
                                                                                        <div className="headItem">
                                                                                            <div className="dateItem">{item.id}</div>
                                                                                        </div>

                                                                                        <FlipMove typeName={null}>
                                                                                            <div className="bodyLogs">
                                                                                                {getUniqueListBy(item.info, 'dataSearch.keyword').map(i =>
                                                                                                    <div className="itemLog">
                                                                                                        <div className="typeSearch">{i.dataSearch.type === 'title' ? "عنوان" : i.dataSearch.type === 'author' ? "مؤلف" : i.dataSearch.type === 'subject' ? "موضوع" : "كامل المحتوى"}</div>: {i.dataSearch.keyword.replace(/\\/g, '')}

                                                                                                        {/* <div className="numberSearch">(1)</div> */}
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                        </FlipMove>
                                                                                    </div>
                                                                                }
                                                                                )}
                                                                            </div>
                                                                        </FlipMove>
                                                                    :
                                                                    <FlipMove typeName={null}></FlipMove>
                                }
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div >
    );
}

export default Browse;
