import React from 'react';
import { Helmet } from 'react-helmet';
export default function Topsection(props) {
    return (
        <div className="Topsection" style={{ backgroundImage: `url(${props.data.img})` }}>
            <Helmet>
                <title>{`${props.data.text} | ${process.env.REACT_APP_FRONT_TITLE}`}</title>
            </Helmet>
            <div className="titleSec">{props.data.text}</div>
        </div>
    );
}