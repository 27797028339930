import Axios from 'axios'
import { trim } from 'lodash'
import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Loading from './loading'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import CountryList from '../country.json'
import Popup1 from '../pdf/popup/popup_1';
import { auth, provider } from '../googleSignin/Config';
import { signInWithPopup } from 'firebase/auth';
import {
  browserName,
  osName,
  osVersion,
  browserVersion,
  engineName
} from 'react-device-detect'

import {
  isMobile
} from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import { 
  headerState,
  setScrollTop,
  setShow,
  setShow2,
  setErrorShow,
  setErrorMsg,
  setLoading,
  setMenuMobile,
  setPopupData,
  setParentAccount,
  setEmail,
  setLogoutDevisePopup,
  setLogoutDevisePopup2,
  setDeviceList,
  setPassword,
  setFlag,
  setLoginStatus
} from '../../store/header';
export default function Header(props) {
  let history = useHistory()
  const dispatch = useDispatch();
  const headerData = useSelector(headerState);

  
  const menu = [
    { title: 'من نحن', url: '/aboutus' },
    { title: 'تصفح', url: '/browse' },
    { title: 'أحدث الإصدارات', url: '/latest_releases' },
    // { title: "قواعد البيانات", url: "#database" },
    { title: 'عملاؤنا', url: '/clients' },
    { title: 'مساعدة', url: '/help' },
    { title: 'اتصل بنا', url: '/contact-us' },
  ]

  let lang = require('./../../lang/ar').default


  useEffect(async () => {
    try {
      const backend_url = process.env.REACT_APP_BACKEND_URL;
      await axios.post(`${backend_url}/app/inservistore`).then((res) => {
      })
    } catch (error) {
      console.log(error);
    }


  }, []);

  const handleClickGoogle = () => {
    signInWithPopup(auth, provider).then((data) => {
      let email = data.user.email;
      var crypto = require('crypto')
      let hash = crypto
        .createHash('sha256')
        .update(JSON.stringify(formatDate()))
        .digest('hex')
      let formData = new FormData()
      formData.append('hashTime', hash);
      formData.append('email', (trim(email)));
      formData.append('lang', 'ar');
      let device = {
        browserName: browserName,
        osName: osName,
        osVersion: osVersion,
        browserVersion: browserVersion,
        engineName: engineName
      }

      formData.append('deviceInfo', JSON.stringify(device))

      Axios.post(
        process.env.REACT_APP_BACKEND_URL + '/app/users/loginWithGoogle',
        formData
      ).then(response2 => {
        if (response2.data.booleanValue) {

          cookieSet('fName', response2.data.dataUser.name, 70)
          cookieSet('email', response2.data.dataUser.email, 70)
          cookieSet('id', response2.data.dataUser._id, 70)
          cookieSet('img', response2.data.dataUser.img, 70)
          cookieSet('country', response2.data.dataUser.country, 70)
          localStorage.setItem('access_token', response2.data.access_token)
          dispatch(setShow(false))

          cookieSet(
            'objectUser',
            JSON.stringify(response2.data.parentAccount),
            70
          )

          dispatch(setParentAccount(JSON.parse(getCookie('objectUser'))))
          dispatch(setLoginStatus(true))
          setTimeout(() => {
            dispatch(setLoading(false))
          }, 500)
        }
        else {
          cookieSet('Name', "", 70);
          cookieSet('emailGoogle', "", 70)
          cookieSet('Name', data.user.displayName, 70)
          cookieSet('emailGoogle', data.user.email, 70)
          history.push(process.env.REACT_APP_FRONT_URL + 'register')

        }
      })
    })
  }
  useEffect(() => {
    Axios.get('https://ipapi.co/json/').then((response) => {
      let data = response.data;
      let country = data.country_code.toLowerCase();
      dispatch(setFlag(country))
    }).catch((error) => {
      console.log(error);
    });
  }, []);
  const getKeyCountry = key => {
    Object.keys(CountryList).map((keyName, i) => {
      if (CountryList[keyName] === key) {
        key = keyName
      }
    })
    return key
  }


  const setShowPopupMethod = o => {
    dispatch(setPopupData(null))
    setTimeout(() => {
      dispatch(setPopupData(o))
    }, 100)
  }

  const setDatePopUp = () => {
    setShowPopupMethod({
      showPopup: true,
      titlePopup: 'عزيزي المستخدم',
      bodyPopup: 'لقد انتهت صلاحية الجلسة يرجى تسجيل الدخول مرة اخرى',
      btnobj: {
        label: 'اشترك الآن',
        show: true,
        methodbtn: ''
      }
    })
  }

  const logout = () => {
    let formData = new FormData()
    formData.append('token', localStorage.getItem('access_token'))
    Axios.post(
      process.env.REACT_APP_BACKEND_URL + '/app/logoutDevice',
      formData
    ).then(response2 => {
      localStorage.removeItem('access_token')
      eraseCookie('fName')
      eraseCookie('email')
      eraseCookie('id')
      eraseCookie('img')
      eraseCookie('objectUser')
      dispatch(setLoginStatus(false))
      dispatch(setShow2(false))
      history.push(process.env.REACT_APP_FRONT_URL)
      // window.location.reload();
    })
  }

  const logoutِAfterOneHoure = () => {
    let formData = new FormData()
    formData.append('token', localStorage.getItem('access_token'))
    Axios.post(
      process.env.REACT_APP_BACKEND_URL + '/app/logoutDevice',
      formData
    ).then(response2 => {
      localStorage.removeItem('access_token')
      eraseCookie('fName')
      eraseCookie('email')
      eraseCookie('id')
      eraseCookie('img')
      eraseCookie('objectUser')
      dispatch(setLoginStatus(false))
      dispatch(setShow2(false))
      // history.push(process.env.REACT_APP_FRONT_URL)
      // window.location.reload();
    })
  }
  const checkIfTextArabic = text => {
    var arabicUnicodes = /[\u0600-\u06FF]/
    return arabicUnicodes.test(text)
  }

  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      const clearAfterTwoHoures = () => {
        setTimeout(() => {
          logoutِAfterOneHoure();
          setDatePopUp();
        }, 60 * 60 * 1000)
        //  2 * 60 * 60 * 1000  2 hours
      }
      clearAfterTwoHoures();

    }
  }, [localStorage.getItem('access_token')]);


  const getSrc = () => {
    let beas = 'https://apis.thelearnbook.com/public/flags/'
    if (checkIfTextArabic(getCookie('country'))) {

      let country = getKeyCountry(getCookie('country')).toLowerCase()

      return beas + country + '.png'
    } else {

      return beas + getCookie('country').toLowerCase() + '.png'
    }
  }



  useEffect(() => {
    const onScroll = e => {
      dispatch(setScrollTop(e.target.documentElement.scrollTop))
    }
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [headerData.scrollTop])

  const getCookie = name => {
    var nameEQ = name + '='
    var ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  }

  useEffect(() => {
    let access_token = localStorage.getItem('access_token')
    if (
      access_token !== undefined &&
      access_token !== '' &&
      access_token !== null
    ) {
      dispatch(setLoginStatus(true))

      dispatch(setParentAccount(JSON.parse(getCookie('objectUser'))))
    } else {
      localStorage.removeItem('access_token')
      eraseCookie('fName')
      eraseCookie('email')
      eraseCookie('id')
      eraseCookie('img')
      eraseCookie('country')
      eraseCookie('objectUser')
      dispatch(setLoginStatus(false))
      dispatch(setShow2(false))
    }
  }, [headerData.loginStatus, getCookie('fName')])

  useEffect(() => {
    let url_string = window?.location?.href
    var url = new URL(url_string)
    var token = url.searchParams.get('token')

    if (token) {
      if (token.length > 30 && token.length < 40) {
        localStorage.setItem('custom_token', token)
        history.push('/regqrcode')
        return
      } else if (token.length > 15) {
        var decoded = jwt_decode(token)
        let formData = new FormData()
        formData.append('lang', 'ar')

        formData.append('access_token', token)
        let device = {
          browserName: browserName,
          osName: osName,
          osVersion: osVersion,
          browserVersion: browserVersion,
          engineName: engineName
        }

        formData.append('deviceInfo', JSON.stringify(device))
        axios
          .post(
            process.env.REACT_APP_BACKEND_URL + '/app/getuserDetails',
            formData
          )
          .then(response2 => {

            if (response2.data.booleanValue) {
              cookieSet('fName', response2.data.dataUser.name, 70)
              cookieSet('email', response2.data.dataUser.email, 70)
              cookieSet('id', response2.data.dataUser._id, 70)
              cookieSet('img', response2.data.dataUser.img, 70)
              cookieSet('country', response2.data.dataUser.country, 70)
              localStorage.setItem('access_token', response2.data.access_token)
              dispatch(setShow(false))

              cookieSet(
                'objectUser',
                JSON.stringify(response2.data.parentAccount),
                70
              )

              dispatch(setParentAccount(JSON.parse(getCookie('objectUser'))))
              dispatch(setLoginStatus(true))
              setTimeout(() => {
                dispatch(setLoading(false))
              }, 500)
              window.history.pushState({}, document.title, '/' + '')
            } else {
              if (response2.data.limited === true) {
                dispatch(setLogoutDevisePopup(true))
                dispatch(setLoading(false))
                dispatch(setShow(false))
                dispatch(setDeviceList(response2.data.loggedin))
                return
              } else {
                dispatch(setErrorMsg(
                  response2.data.message !== undefined
                    ? response2.data.message
                    : ''
                ))
                dispatch(setErrorShow(true))
                setTimeout(() => {
                  dispatch(setLoading(false))
                }, 500)
              }
            }
          })
      }
    }
  }, [headerData.loginStatus, getCookie('fName')])

  useEffect(() => {
    ///site/open

    if (
      window.location.href.indexOf('https://thelearnbook.com') === -1 &&
      window.location.href.indexOf('localhost') === -1
    ) {
      window.location.replace(
        'https://thelearnbook.com/?Referer=' + window.location.href
      )
    }
    // redirect to https://thelearnbook.com with X-Referer header

    // if Referer header is set, redirect to https://thelearnbook.com
    if (window.location.href.indexOf('Referer') > -1) {
      let Referer = window.location.href.split('Referer=')[1]
      axios
        .get(process.env.REACT_APP_BACKEND_URL + '/site/open', {
          headers: {
            'X-Referer': Referer
          }
        })
        .then(response2 => {
          if (response2.data.booleanValue) {
            cookieSet('fName', response2.data.dataUser.name, 70)
            cookieSet('email', response2.data.dataUser.email, 70)
            cookieSet('id', response2.data.dataUser._id, 70)
            cookieSet('img', response2.data.dataUser.img, 70)
            cookieSet('country', response2.data.dataUser.country, 70)
            localStorage.setItem('access_token', response2.data.access_token)
            dispatch(setShow(false))

            cookieSet(
              'objectUser',
              JSON.stringify(response2.data.parentAccount),
              70
            )

            dispatch(setParentAccount(JSON.parse(getCookie('objectUser'))))
            dispatch(setLoginStatus(true))
            setTimeout(() => {
              // redirect to https://thelearnbook.com with X-Referer header
              window.location.replace('https://thelearnbook.com/')
            }, 500)
          } else {
            window.location.replace('https://thelearnbook.com/')
          }
        })
    }
  }, [])

  useEffect(() => {
    setInterval(() => {
      if (localStorage.getItem('access_token') !== null) {
        var decoded = jwt_decode(localStorage.getItem('access_token'))
        let formData = new FormData()
        formData.append('lang', 'ar')
        /////////////////////
        formData.append('token', localStorage.getItem('access_token'))
        axios
          .post(
            process.env.REACT_APP_BACKEND_URL + '/app/verifyToken',
            formData
          )
          .then(response2 => {
            if (response2.data.booleanVaule) {
              dispatch(setLogoutDevisePopup2(false))
            } else {
              dispatch(setLogoutDevisePopup2(true))
              dispatch(setLoginStatus(false))
              dispatch(setShow2(false))
              localStorage.removeItem('access_token')
              eraseCookie('fName')
              eraseCookie('email')
              eraseCookie('id')
              eraseCookie('img')
              eraseCookie('country')
            }
          })
      }
    }, 10000)
  }, [])

  const eraseCookie = name => {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  }

  const login = () => {
    dispatch(setErrorShow(false))
    dispatch(setLoading(true))
    var crypto = require('crypto')
    let hash = crypto
      .createHash('sha256')
      .update(JSON.stringify(formatDate()))
      .digest('hex')
    let formData = new FormData()
    formData.append('hashTime', hash)
    formData.append('email', trim(headerData.email))
    formData.append('password', headerData.password)
    formData.append('lang', 'ar')
    let device = {
      browserName: browserName,
      osName: osName,
      osVersion: osVersion,
      browserVersion: browserVersion,
      engineName: engineName
    }

    formData.append('deviceInfo', JSON.stringify(device))

    Axios.post(
      process.env.REACT_APP_BACKEND_URL + '/app/users/login',
      formData
    ).then(response2 => {
      if (response2.data.booleanValue) {
        cookieSet('fName', response2.data.dataUser.name, 70)
        cookieSet('email', response2.data.dataUser.email, 70)
        cookieSet('id', response2.data.dataUser._id, 70)
        cookieSet('img', response2.data.dataUser.img, 70)
        cookieSet('country', response2.data.dataUser.country, 70)
        localStorage.setItem('access_token', response2.data.access_token)
        dispatch(setShow(false))
        cookieSet(
          'objectUser',
          JSON.stringify(response2.data.parentAccount),
          70
        )

        dispatch(setParentAccount(JSON.parse(getCookie('objectUser'))))
        dispatch(setLoginStatus(true))
        setTimeout(() => {
          dispatch(setLoading(false))
        }, 500)
      } else {
        if (response2.data.limited === true) {
          dispatch(setLogoutDevisePopup(true))
          dispatch(setLoading(false))
          dispatch(setShow(false))
          dispatch(setDeviceList(response2.data.loggedin))
          return
        } else {
          dispatch(setErrorMsg(
            response2.data.message !== undefined ? response2.data.message : ''
          ))
          dispatch(setErrorShow(true))
          setTimeout(() => {
            dispatch(setLoading(false))
          }, 500)
        }
      }
    })
  }

  const logoutAPI = () => {
    dispatch(setErrorShow(false))
    dispatch(setLoading(true))
    let formData = new FormData()
    formData.append('token', headerData.deviceList[0].access_token)
    Axios.post(
      process.env.REACT_APP_BACKEND_URL + '/app/logoutDevice',
      formData
    ).then(response2 => {
      dispatch(setLogoutDevisePopup(false))
      login()
    })
  }

  const cookieSet = (name, value, days) => {
    var expires = ''
    if (days) {
      var date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = '; expires=' + date.toUTCString()
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/'
  }

  function formatDate() {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }

  function timeDifference(date1) {

    var difference = date1 - new Date().getTime()

    var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24)
    difference -= daysDifference * 1000 * 60 * 60 * 24

    var hoursDifference = Math.floor(difference / 1000 / 60 / 60)
    difference -= hoursDifference * 1000 * 60 * 60

    var minutesDifference = Math.floor(difference / 1000 / 60)
    difference -= minutesDifference * 1000 * 60

    return daysDifference
  }

  return (
    <div
      className={
        headerData.scrollTop > 150 || window.location.href.split('/')[3] == 'ViewBook'
          ? 'header2'
          : 'header'
      }
    >
      {headerData.popupData !== null ? <Popup1 objPopup={headerData.popupData} /> : ''}
      {headerData.logoutDevisePopup && (
        <div className='logoutDeviseBG'>
          <div className='logoutDevise'>
            <div className='bodyLogoutDevise'>
              {' '}
              لقد تجاوزت الحد المسموح به من الأشخاص المتصلين لنفس الحساب.
            </div>
            <div className='bodyLogoutDevise'>
              يمكنك إخراج إحدى المستخدمين والدخول مكانه
            </div>

            <div className='btns'>
              <div className='btnLogout redBtn' onClick={logoutAPI}>
                إحدى المستخدمين
              </div>
              <div
                className='btnLogout'
                onClick={() => dispatch(setLogoutDevisePopup(false))}
              >
                إلغاء
              </div>
            </div>
          </div>
        </div>
      )}
      {headerData.logoutDevisePopup2 && (
        <div className='alertLogOutBG'>
          <div className='alertLogOut'>
            <div className='bodyLogoutDevise'>
              {' '}
              لقد تم تسجيل الخروج لهذه الحساب.
            </div>

            <div className='btns'>
              <div
                className='btnLogout'
                onClick={() => dispatch(setLogoutDevisePopup2(!headerData.logoutDevisePopup2))}
              >
                إغلاق
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className='mobileMenu'
        style={{
          visibility: headerData.menuMobile ? 'visible' : 'hidden',
          opacity: headerData.menuMobile ? 1 : 0,
          zIndex: headerData.menuMobile ? 998 : 0,
          transition: 'opacity 1s'
        }}
      >
        <div className='menus'>
          <ul>
            {menu.map((item, index) => {
              if (index === 3)
                return (
                  <li
                    key={index}
                    onClick={() => {
                      dispatch(setMenuMobile(!headerData.menuMobile))
                    }}
                  >
                    <Link to={item.url}>{item.title}</Link>
                  </li>
                )
              else
                return (
                  <li
                    key={index}
                    onClick={() => {
                      dispatch(setMenuMobile(!headerData.menuMobile))
                    }}
                  >
                    <Link to={item.url}>{item.title}</Link>
                  </li>
                )
            })}
          </ul>
        </div>

        {headerData.loginStatus ? (
          <div
            className='loginUsers'
            onClick={() => dispatch(setShow2(headerData.show ? false : true))}
          >
            {timeDifference(headerData.parentAccount?.subscriptionPeriod?.endDate) < 8 &&
              !headerData.parentAccount?.trial && (
                <div className='expire'>
                  سينتهي إشتراكك خلال{' '}
                  <a>
                    {timeDifference(headerData.parentAccount?.subscriptionPeriod?.endDate)}
                  </a>{' '}
                  أيام
                </div>
              )}

            {headerData.parentAccount?.trial && <div className='expire'>إشتراك تجريبي</div>}

            <div className='nameUser'>
              مرحبا {getCookie('fName')}
              <br />
              {JSON.parse(getCookie('objectUser'))?.login && (
                <a className='subName'>{headerData.parentAccount?.displayName}</a>
              )}
              <br />
            </div>

            <div className='imgUser'>
              {headerData.parentAccount?.userImage !== null &&
                headerData.parentAccount?.userImage !== '' &&
                headerData.parentAccount?.userImage !== undefined ? (
                <img
                  src={
                    process.env.REACT_APP_BACKEND_URL +
                    '/public/' +
                    JSON.parse(getCookie('objectUser'))?.userImage
                  }
                  alt='user'
                />
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='70'
                  height='70'
                  viewBox='0 0 70 70'
                >
                  <defs>
                    <clipPath id='clipPath'>
                      <circle
                        id='Ellipse_87'
                        data-name='Ellipse 87'
                        cx='35'
                        cy='35'
                        r='35'
                        fill='#7187ff'
                        stroke='#dddee8'
                        strokeWidth='10'
                      />
                    </clipPath>
                  </defs>
                  <g
                    id='Mask_Group_10'
                    data-name='Mask Group 10'
                    clipPath='url(#clipPath)'
                  >
                    <g
                      id='Group_2162'
                      data-name='Group 2162'
                      transform='translate(14 16)'
                    >
                      <rect
                        id='Rectangle_497'
                        data-name='Rectangle 497'
                        width='42'
                        height='48'
                        rx='21'
                        transform='translate(0 35)'
                        fill='#f7f8fc'
                      />
                      <rect
                        id='Rectangle_498'
                        data-name='Rectangle 498'
                        width='10'
                        height='22'
                        rx='5'
                        transform='translate(16 24)'
                        fill='#dddee8'
                      />
                      <g
                        id='Rectangle_496'
                        data-name='Rectangle 496'
                        transform='translate(3.188 -3)'
                        fill='#fff'
                        stroke='#fff'
                        strokeWidth='1'
                      >
                        <rect
                          width='35.625'
                          height='38'
                          rx='17.813'
                          stroke='none'
                        />
                        <rect
                          x='0.5'
                          y='0.5'
                          width='34.625'
                          height='37'
                          rx='17.313'
                          fill='none'
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              )}
            </div>
            {getCookie('country') && (
              <div className='flag'>
                <img src={getSrc()} alt='flag' />
              </div>
            )}
          </div>
        ) : (
          <div className='btnsMobile'>
            <div
              className='button1'
              onClick={() =>
                // to /register
                history.push('/register')
              }
            >
              {lang.header.signup}
            </div>
            <div
              className='butn butn__new'
              onClick={() => dispatch(setShow(headerData.show ? false : true))}
            >
              {lang.header.login}
            </div>
          </div>
        )}
      </div>

      <Loading loadingValue={headerData.loading} />
      <div className='logo'>
        { }
        <Link to='/'>
          {' '}
          <img src={props.logo} alt='logo' />
        </Link>
        <div className='betaV'>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64.143 79.593'>
            <g
              id='Group_9562'
              data-name='Group 9562'
              transform='translate(-1819.929 9.796)'
            >
              <path
                id='Path_9857'
                data-name='Path 9857'
                d='M0,0H60.143V75.593L30.2,61.292,0,75.593Z'
                transform='translate(1821.929 -7.796)'
                fill='#2fb5fc'
                stroke='#2fb5fc'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='4'
              />
              <g id='Group_9561' data-name='Group 9561'>
                <text
                  id='نـســـخـة'
                  transform='translate(1902 22)'
                  fill='#fff'
                  fontSize='16'
                  fontFamily='DroidArabicKufi, Droid Arabic Kufi'
                >
                  <tspan x='-26.004' y='0'>
                    نـســـخـة
                  </tspan>
                </text>
                <text
                  id='تجريبية'
                  transform='translate(1905 39)'
                  fill='#fff'
                  fontSize='16'
                  fontFamily='DroidArabicKufi-Bold, Droid Arabic Kufi'
                  fontWeight='700'
                >
                  <tspan x='-27.09' y='0'>
                    تجريبية
                  </tspan>
                </text>
              </g>
            </g>
          </svg>
        </div>
      </div>

      <div className='menu'>
        <ul>
          {menu.map((item, index) => {
            if (index === 1 || index === 5)
              return (
                <li key={index}>
                  <Link to={item.url}>{item.title}</Link>
                </li>
              )
            if (index === 6)
              return (
                <li key={index}>
                  <a href={item.url} target='_blank' className='redBT'>
                    {item.title}
                  </a>
                </li>
              )
            else
              return (
                <li key={index}>
                  <Link to={item.url}>{item.title}</Link>
                </li>
              )
          })}
        </ul>
      </div>
      <div className='borgerMenu'>
        <label htmlFor='check'>
          <input
            type='checkbox'
            id='check'
            checked={headerData.menuMobile}
            onChange={e => dispatch(setMenuMobile(!headerData.menuMobile))}
          />
          <span />
          <span />
          <span />
        </label>
      </div>

      {!isMobile && (
        <>
          {!headerData.loginStatus ? (
            <div className='btns'>
              <div className='button1' onClick={() =>
                // to /register
                history.push('/register')
              }>{lang.header.signup}</div>
              <div
                className='butn butn__new'
                onClick={() => dispatch(setShow(headerData.show ? false : true))}
              >
                {lang.header.login}
              </div>
              <div
                onClick={() => { history.push('/register') }}
                style={{ fontSize: "15px" }}
                className='butn butn__new'
              > تسجيل الدخول كمستخدم جامعي
              </div>
              <div className='borgerMenu'>
                <label htmlFor='check'>
                  <input
                    type='checkbox'
                    id='check'
                    checked={headerData.menuMobile}
                    onChange={e => dispatch(setMenuMobile(!headerData.menuMobile))}
                  />
                  <span />
                  <span />
                  <span />
                </label>
              </div>
            </div>
          ) : (
            <div
              className='loginUsers'
              onClick={() => dispatch(setShow2(headerData.show ? false : true))}
            >
              {timeDifference(headerData.parentAccount?.subscriptionPeriod?.endDate) < 8 &&
                !headerData.parentAccount?.trial && (
                  <div className='expire'>
                    سينتهي إشتراكك خلال{' '}
                    <a>
                      {timeDifference(
                        headerData.parentAccount?.subscriptionPeriod?.endDate
                      )}
                    </a>{' '}
                    أيام
                  </div>
                )}

              {headerData.parentAccount?.trial && (
                <div className='expire'>إشتراك تجريبي</div>
              )}

              <div className='nameUser'>
                مرحبا {getCookie('fName')}
                <br />
                {JSON.parse(getCookie('objectUser'))?.login && (
                  <a className='subName'>{headerData.parentAccount?.displayName}</a>
                )}
                <br />
              </div>

              <div className='imgUser'>
                {headerData.parentAccount?.userImage !== null &&
                  headerData.parentAccount?.userImage !== '' &&
                  headerData.parentAccount?.userImage !== undefined ? (
                  <img
                    src={
                      process.env.REACT_APP_BACKEND_URL +
                      '/public/' +
                      JSON.parse(getCookie('objectUser'))?.userImage
                    }
                    alt='user'
                  />
                ) : (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='70'
                    height='70'
                    viewBox='0 0 70 70'
                  >
                    <defs>
                      <clipPath id='clipPath'>
                        <circle
                          id='Ellipse_87'
                          data-name='Ellipse 87'
                          cx='35'
                          cy='35'
                          r='35'
                          fill='#7187ff'
                          stroke='#dddee8'
                          strokeWidth='10'
                        />
                      </clipPath>
                    </defs>
                    <g
                      id='Mask_Group_10'
                      data-name='Mask Group 10'
                      clipPath='url(#clipPath)'
                    >
                      <g
                        id='Group_2162'
                        data-name='Group 2162'
                        transform='translate(14 16)'
                      >
                        <rect
                          id='Rectangle_497'
                          data-name='Rectangle 497'
                          width='42'
                          height='48'
                          rx='21'
                          transform='translate(0 35)'
                          fill='#f7f8fc'
                        />
                        <rect
                          id='Rectangle_498'
                          data-name='Rectangle 498'
                          width='10'
                          height='22'
                          rx='5'
                          transform='translate(16 24)'
                          fill='#dddee8'
                        />
                        <g
                          id='Rectangle_496'
                          data-name='Rectangle 496'
                          transform='translate(3.188 -3)'
                          fill='#fff'
                          stroke='#fff'
                          strokeWidth='1'
                        >
                          <rect
                            width='35.625'
                            height='38'
                            rx='17.813'
                            stroke='none'
                          />
                          <rect
                            x='0.5'
                            y='0.5'
                            width='34.625'
                            height='37'
                            rx='17.313'
                            fill='none'
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                )}
              </div>
              {getCookie('country') && (
                <div className='flag'>
                  <img src={getSrc()} alt='flag' />
                </div>
              )}
            </div>
          )}
        </>
      )}

      <div className={headerData.show ? 'loginForm showDev' : 'loginForm hideDev'}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 21 18'
          className='topLogin'
        >
          <path
            id='Polygon_12'
            data-name='Polygon 12'
            d='M7.909,4.442a3,3,0,0,1,5.183,0l5.277,9.046A3,3,0,0,1,15.777,18H5.223a3,3,0,0,1-2.591-4.512Z'
            fill='#fff'
          />
        </svg>
        <div className='loginDev'>
          <div className='rowLogin'>
            <div className='labelRow'>البريد الإلكتروني / إسم المستخدم</div>
            <div className='inputRow'>
              <input
                type='text'
                placeholder='name@email.com'
                onChange={e => dispatch(setEmail(e.target.value))}
              />
            </div>
          </div>
          <div className='rowLogin'>
            <div className='labelRow'>كلمة المرور</div>
            <div className='inputRow'>
              <input
                type='password'
                placeholder='********'
                onChange={e => dispatch(setPassword(e.target.value))}
              />
            </div>
          </div>

          <div className='forgetpassword'>
            <Link to='/forgetpassword'>هل نسيت كلمة المرور؟</Link>
          </div>

          <div className='otherLogin'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 343 27'>
              <g
                id='Group_9500'
                data-name='Group 9500'
                transform='translate(4703.5 -5973)'
              >
                <line
                  id='Line_98'
                  data-name='Line 98'
                  x2='129'
                  transform='translate(-4489.5 5986)'
                  fill='none'
                  stroke='#b4c5d6'
                  strokeWidth='1'
                />
                <path
                  id='Path_9762'
                  data-name='Path 9762'
                  d='M0,0H123.529'
                  transform='translate(-4703.5 5986)'
                  fill='none'
                  stroke='#b4c5d6'
                  strokeWidth='1'
                />
                <text
                  id='أو_الدخول_عبر'
                  data-name='أو الدخول عبر'
                  transform='translate(-4455.25 5991)'
                  fill='#33384a'
                  fontSize='14'
                  fontFamily='DroidArabicKufi, Droid Arabic Kufi'
                  opacity='0.635'
                >
                  <tspan x='-40.171' y='0'>
                    أو الدخول عبر
                  </tspan>
                </text>
              </g>
            </svg>
          </div>

          <div className='socialMediaBtns'>
            <div className='btnSM'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 21.05 20.922'
              >
                <path
                  id='Path_9761'
                  data-name='Path 9761'
                  d='M354.383,143.858a10.525,10.525,0,1,0-12.17,10.4V146.9h-2.672v-3.042h2.672v-2.319c0-2.638,1.571-4.095,3.976-4.095a16.185,16.185,0,0,1,2.356.206v2.59h-1.327a1.521,1.521,0,0,0-1.715,1.644v1.974h2.919l-.467,3.042H345.5v7.355a10.528,10.528,0,0,0,8.881-10.4'
                  transform='translate(-333.333 -133.333)'
                  fill='#1877f2'
                />
              </svg>
            </div>
            <div className='btnSM'>
              <svg
                onClick={handleClickGoogle}
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20.754 21.178'
              >
                <g
                  id='Group_9503'
                  data-name='Group 9503'
                  transform='translate(0)'
                >
                  <path
                    id='Path_9755'
                    data-name='Path 9755'
                    d='M1232.4,1235.918a9.071,9.071,0,0,0-.224-2.165h-9.942v3.93h5.836a5.173,5.173,0,0,1-2.165,3.435l-.02.132,3.144,2.435.218.022a10.354,10.354,0,0,0,3.153-7.789'
                    transform='translate(-1211.641 -1225.094)'
                    fill='#4285f4'
                  />
                  <path
                    id='Path_9756'
                    data-name='Path 9756'
                    d='M1173.091,1267.173a10.094,10.094,0,0,0,7.012-2.565l-3.342-2.589a6.266,6.266,0,0,1-3.671,1.059,6.374,6.374,0,0,1-6.024-4.4l-.124.011-3.269,2.529-.043.119a10.581,10.581,0,0,0,9.459,5.836'
                    transform='translate(-1162.503 -1245.996)'
                    fill='#34a853'
                  />
                  <path
                    id='Path_9757'
                    data-name='Path 9757'
                    d='M1161.2,1222.841a6.515,6.515,0,0,1-.353-2.094,6.85,6.85,0,0,1,.341-2.094l-.006-.14-3.31-2.57-.108.051a10.568,10.568,0,0,0,0,9.507l3.436-2.659'
                    transform='translate(-1156.636 -1210.158)'
                    fill='#fbbc05'
                  />
                  <path
                    id='Path_9758'
                    data-name='Path 9758'
                    d='M1173.091,1184.206a5.869,5.869,0,0,1,4.094,1.576l2.989-2.918a10.174,10.174,0,0,0-7.083-2.753,10.581,10.581,0,0,0-9.459,5.835l3.424,2.659a6.4,6.4,0,0,1,6.036-4.4'
                    transform='translate(-1162.503 -1180.112)'
                    fill='#eb4335'
                  />
                </g>
              </svg>
            </div>
          </div>

          <div className='btnLogin' onClick={login}>
            تسجيل الدخول
          </div>
          <div className='btnLogin' onClick={() => { history.push('/register') }} style={{ marginTop: "15px" }}>
            تسجيل الدخول كمستخدم جامعي
          </div>
          <div className={!headerData.errorShow ? 'errorMsg' : 'errorMsg2'}>
            {headerData.errorMsg.length === 0
              ? 'البريد الإلكتروني او كلمة المرور غير صحيحة!'
              : headerData.errorMsg}
          </div>
        </div>
      </div>

      <div className={headerData.show2 ? 'loginForm2 showDev' : 'loginForm2 hideDev'}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 21 18'
          className='topLogin'
        >
          <path
            id='Polygon_12'
            data-name='Polygon 12'
            d='M7.909,4.442a3,3,0,0,1,5.183,0l5.277,9.046A3,3,0,0,1,15.777,18H5.223a3,3,0,0,1-2.591-4.512Z'
            fill='#fff'
          />
        </svg>
        <div className='loginDev'>
          <div className='rowLogin rowLoginUserData'>
            <div className='rowDataName'>{getCookie('fName')}</div>
            <div
              className='LinkData'
              onClick={() => {
                history.push(process.env.REACT_APP_FRONT_URL + 'profile')
                dispatch(setMenuMobile(!headerData.menuMobile))
                dispatch(setShow2(false))
              }}
            >
              عرض الصفحة الشخصية
            </div>
          </div>

          <div className='rowLogin rowLoginUserData'>
            <div
              className='rowData'
              onClick={() => {
                history.push(process.env.REACT_APP_FRONT_URL + 'mylibrary')
                dispatch(setMenuMobile(!headerData.menuMobile))
                dispatch(setShow2(false))
              }}
            >
              مكتبتي
            </div>
          </div>

          {getCookie('id') && getCookie('id').split('_')[0] === 'userApp' && (
            <div className='rowLogin rowLoginUserData'>
              <div
                className='rowData'
                onClick={() => {
                  history.push(
                    process.env.REACT_APP_FRONT_URL + 'changePassword'
                  )
                  dispatch(setShow2(false))
                }}
              >
                تغير كلمة المرور
              </div>
            </div>
          )}

          <div className='rowLogin rowLoginUserData'>
            <div className='rowData' onClick={logout}>
              تسجيل الخروج
            </div>
          </div>
        </div>
      </div>

      <div
        className='bgLogin'
        style={{ display: headerData.show || headerData.show2 ? 'block' : 'none' }}
        onClick={() => {
          dispatch(setShow(false))
          dispatch(setShow2(false))
        }}
      >
        {' '}
      </div>
    </div>
  )
}
