import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    activeTab: 0,
    bookmarksLength: 0,
    mylibraryLength: 0,
    downloadLength: 0,
    quotationLength: 0,
    highlightLength: 0,
    printLength: 0,
    sendDocLength: 0,
    searchLogLength: 0,
    sortby: 'type',
    bookmarks: [],
    sendDoC: [],
    bookmarksOrg: [],
    mylibraryOrg: [],
    printOrg: [],
    sendDocOrg: [],
    print: [],
    searchLog: [],
    mylibrary: [],
    searchLogOrg: [],
    downloadOrg: [],
    download: [],
    quotationOrg: [],
    quotation: [],
    highlightOrg: [],
    highlight: [],
    selectedDate: [],
    loadingPage: false,
    OpenErrorMsg: false
}
export const mylibrarySlice = createSlice({
    name: "mylibrary",
    initialState,
    reducers: {
        setActiveTab: (state, actions) => {
            
            state.activeTab = actions.payload
        },
        setLengthData: (state, actions) => {
            const { name, length } = actions.payload;
            state[`${name}Length`] = length;
        },
        setData:(state, actions)=>{
          const { name , data} = actions.payload;
          state[name] = data;
        },
        setOpenErrorMsg: (state, actions) => {
            state.OpenErrorMsg = actions.payload
        },
        setSortby: (state, actions) => {
            state.sortby = actions.payload;
        },
        setSelectedDate: (state, actions) => {
            state.selectedDate = actions.payload
        }
    }
})


export const mylibraryRedux = (state) => state.mylibrary
export const {
    setActiveTab,
    setOpenErrorMsg,
    setSortby,
    setSelectedDate,
    setLengthData,
    setData
} = mylibrarySlice.actions;
export default mylibrarySlice.reducer