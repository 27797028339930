import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    tabIndex: 55,
    activeTab: parseInt(localStorage.getItem('ActiveTabSearch') !== undefined ? localStorage.getItem('ActiveTabSearch') : 0),
    activeTab2: parseInt(localStorage.getItem('ActiveTab2') !== undefined ? localStorage.getItem('ActiveTab2') : 1),
    items: null,
    subjects: null,
    loadingPage: false,
    timeText: '',
    keyNew: '',
    searchKey: '',
    searchKey2: '',
    popupShareData: null,
    OpenErrorMsg: false,
    TextErrorMsg: '',
    TitleErrorMsg: '',
    publishers: null,
    countries: null,
    authors: null,
    checkedAll: true,
    boxSearch: null,
    searchTypes: [true, false, false, false, false],
    countItems: [],
    countItems2: [],
    open: false,
    loadingCounts: false,
    sortby: { id: '1', value: 'yearsValue' },
    checkedValues: [],
    checkedValues2: [],
    checkedValues3: [],
    checkedValues4: [],
    checkedValues5: [],
    countQuery: 0

};


const resultAllSlice = createSlice({
    name: "resultAll",
    initialState,
    reducers: {
        setTabIndex: (state, actions) => {
            state.tabIndex = actions.payload
        },
        setActiveTab: (state, actions) => {
            state.activeTab = actions.payload;
        },
        setActiveTab2: (state, actions) => {
            state.activeTab2 = actions.payload
        },
        setItems: (state, actions) => {
            state.items = actions.payload;
        },
        setSubjects: (state, actions) => {
            state.subjects = actions.payload;
        },
        setLoadingPage: (state, actions) => {
            state.loadingPage = actions.payload
        },
        setTimeText: (state, actions) => {
            state.timeText = actions.payload
        },
        setKeyNew: (state, actions) => {
            state.keyNew = actions.payload
        },
        setSearchKey: (state, actions) => {
            state.searchKey = actions.payload
        },
        setSearchKey2: (state, actions) => {
            state.searchKey2 = actions.payload
        },
        setPopupShareData: (state, actions) => {
            state.popupShareData = actions.payload
        },
        setOpenErrorMsg: (state, actions) => {
            state.OpenErrorMsg = actions.payload;
        },
        setPublishers: (state, actions) => {
            state.publishers = actions.payload
        },
        setCountries: (state, actions) => {
            state.countries = actions.payload
        },
        setAuthors: (state, actions) => {
            state.authors = actions.payload
        },
        setBoxSearch: (state, actions) => {
            state.boxSearch = actions.payload
        },
        setSearchTypes: (state, actions) => {
            state.searchKey = actions.payload
        },
        setCountItems: (state, actions) => {
            state.countItems = actions.payload
        },
        setCountItems2: (state, actions) => {
            state.countItems2 = actions.payload
        },
        setOpen: (state, actions) => {
            state.open = actions.payload
        },
        setLoadingCounts: (state, actions) => {
            state.loadingCounts = actions.payload
        },
        setSortby: (state, actions) => {
            state.sortby = actions.payload
        },
        setCheckedValues: (state, actions) => {
            state.checkedValues = actions.payload;
        },
        setCheckedValues2: (state, actions) => {
            state.checkedValues2 = actions.payload;
        },
        setCheckedValues3: (state, actions) => {
            state.checkedValues3 = actions.payload
        },
        setCheckedValues4: (state, actions) => {
            state.checkedValues4 = actions.payload
        },
        setCheckedValues5: (state, actions) => {
            state.checkedValues5 = actions.payload
        },
        setCountQuery: (state, actions) => {
            state.countQuery = actions.payload
        }
    }
})

export const resultAllState = (state) => state.resultAll;

export const {
    setTabIndex,
    setActiveTab,
    setActiveTab2,
    setItems,
    setSubjects,
    setLoadingPage,
    setTimeText,
    setKeyNew,
    setSearchKey,
    setSearchKey2,
    setPopupShareData,
    setOpenErrorMsg,
    setPublishers,
    setCountries,
    setAuthors,
    setBoxSearch,
    setCountItems,
    setCountItems2,
    setOpen,
    setLoadingCounts,
    setSortby,
    setCheckedValues,
    setCheckedValues2,
    setCheckedValues3,
    setCheckedValues4,
    setCheckedValues5,
    setSearchTypes,
    setCountQuery
} = resultAllSlice.actions;

export default resultAllSlice.reducer;

