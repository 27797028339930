import React, { useEffect, useState } from 'react'
import CountryList from '../../country.json'
import ReCAPTCHA from 'react-google-recaptcha'
import PhoneInput from 'react-phone-number-input'
import axios from 'axios'
import Popup1 from '../../pdf/popup/popup_1'
export default function StepOne (props) {
  useEffect(() => {}, [])

  const [formObj, setFormObj] = useState({
    name: '',
    email: '',
    phone: '',
    work: ''
  })
  const [popupData, setPopupData] = useState(null)
  const [popupData2, setPopupData2] = useState(null)

  const [captcha, setCaptcha] = useState(false)
  const [country, setCountry] = useState('الأردن')
  const [success, setSuccess] = useState({
    status: false,
    msg: ''
  })
  function onChange (value) {
    if (value) {
      setCaptcha(true)
    }
  }
  const validateEmail = email => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }
  const setShowPopupMethod = o => {
    setPopupData(null)
    setTimeout(() => {
      setPopupData(o)
    }, 100)
  }
  const setShowPopupMethod2 = o => {
    setPopupData2(null)
    setTimeout(() => {
      setPopupData2(o)
    }, 100)
  }

  const setDatePopUp = () => {
    setShowPopupMethod({
      showPopup: true,
      titlePopup: 'عزيزي المستخدم',
      bodyPopup: 'لقد تم انشاء الحساب بنجاح  تم ارسال كلمة السر الى بريدك الالكتروني',
      btnobj: {
        label: 'اشترك الآن',
        show: true,
        methodbtn: ''
      }
    })
  }
  const setDatePopUp2 = () => {
    setShowPopupMethod2({
      showPopup: true,
      titlePopup: 'عزيزي المستخدم',
      bodyPopup: 'جامعتك غير مشتركة في بوابة الكتاب العلمي',
      btnobj: {
        label: 'اشترك الآن',
        show: true,
        methodbtn: ''
      }
    })
  }
  const emailCheck = async () => {
 
    const res = await axios.post(
      process.env.REACT_APP_BACKEND_URL + '/app/users/emailCheck',
      {
        email: formObj.email
      }
    )
    if (res.data.available) return true
    else return false
  }
  const sendData = async (event) => {
    event.preventDefault();

    let formData=new FormData();

    let e = true
    if (formObj.name.length < 2) {
      setSuccess({
        status: false,
        msg: 'الاسم قصير جدا'
      })
      e = false
      return
    }
    if (!validateEmail(formObj.email)) {
      setSuccess({
        status: false,
        msg: 'البريد الالكتروني غير صحيح'
      })
      e = false
      return
    }
    let emailavailable = await emailCheck()
    
    if (!emailavailable) {
      setSuccess({
        status: false,
        msg: 'البريد الالكتروني مستخدم من قبل'
      })
      e = false
      return
    }
   

  
   
  
    if (formObj.phone.length < 10) {
      setSuccess({
        status: false,
        msg: 'رقم الهاتف غير صحيح'
      })
      e = false
      return
    }

    if (!captcha) {
      setSuccess({
        status: false,
        msg: 'الرجاء التحقق من صحة البيانات'
      })
      e = false
      return
    }

    if (e) {
      formObj.country = country
      delete formObj.repassword
      localStorage.setItem('register', JSON.stringify(formObj))
    }
    formData.append('email',formObj.email);
    formData.append('username',formObj.name);
    formData.append('country',country);
    formData.append('phone', formObj.phone);
    formData.append('name',formObj.work);
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/getParentAccountUnv`,formData).then((res)=>{
        console.log("res.data.>>>>",res.data)
        if(res.data.booleanValue){
         let parentAccountId=res.data.parentUserId;
         formData.append('parentAccount',parentAccountId)
         console.log("res<<<",parentAccountId)
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/addSubUserForUnviersty`,formData).then((res)=>{
            console.log("getParentAccountUnv>>>>>>>",res.data)
            formData.append('user_id',res.data.user_id);
         }).then((res)=>{
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/setNewPasswordForSubUnver`,formData).then((res)=>{
              console.log("res>>>>>>>>>>>>>>>",res.data);
              if(res.data.booleanVaule){
                setDatePopUp();
                setFormObj({
                  email: '', name:'', phone:'', work:''
                })
              }
            }).catch((err)=>console.log(err));
         })
        }
        else{
          setDatePopUp2();
          setFormObj({
            email: '', name:'', phone:'', work:''
          })
        }
    })
  }
  return (
    <>
    {popupData !== null ? <Popup1 objPopup={popupData} /> : ''}
    {popupData2 !== null ? <Popup1 objPopup={popupData2}/>: ''}
    <div className='stepOne'>
      <div className='containerReg1'>
        <div className='sectionTitle'>إنشاء حساب في بوابة الكتاب العلمي</div>
        <div className='formcontact'>
          <div className='container'>
            <form id='contact' onSubmit={sendData}>
              {success.msg !== '' && (
                <fieldset>
                  {success.status === false && (
                    <div class='xd-message msg-danger'>
                      <div class='xd-message-icon'></div>
                      <div class='xd-message-content'>
                        <p>{success.msg}</p>
                      </div>
                    </div>
                  )}
                  {success.status === true && (
                    <div class='xd-message msg-success'>
                      <div class='xd-message-icon'> </div>
                      <div class='xd-message-content'>
                        <p>{success.msg}</p>
                      </div>
                    </div>
                  )}
                </fieldset>
              )}
              <fieldset>
                <label>الاسم</label>
                <input
                  value={formObj.name}
                  onChange={e =>
                    setFormObj({
                      ...formObj,
                      name: e.target.value
                    })
                  }
                  placeholder='الاسم'
                  type='text'
                  tabindex='1'
                  required
                  autofocus
                />
              </fieldset>

              <fieldset>
                <label>البلد</label>
                <select
                  value={country}
                  onChange={e => setCountry(e.target.value)}
                >
                  {Object.values(CountryList).map((keyName, i) => (
                    <option value={keyName}>{keyName}</option>
                  ))}
                </select>
              </fieldset>

              <fieldset>
                <label>الاسم الكامل</label>
                <input
                  value={formObj?.work}
                  onChange={e =>
                    setFormObj({
                      ...formObj,
                      work: e.target.value
                    })
                  }
                  placeholder='الاسم الكامل'
                  type='text'
                  tabindex='1'
                  required
                />
              </fieldset>

              <fieldset>
                <label>البريد الجامعي</label>
                <input
                  value={formObj.email}
                  onChange={e =>
                    setFormObj({
                      ...formObj,
                      email: e.target.value
                    })
                  }
                  placeholder='البريد الجامعي'
                  type='email'
                  tabindex='2'
                  required
                />
              </fieldset>

              <fieldset>
                <label>رقم الهاتف</label>
                <PhoneInput
                  international
                  initialValueFormat='national'
                  countryCallingCodeEditable={false}
                  defaultCountry='JO'
                  value={formObj.phone}
                  tabindex='3'
                  onChange={phone =>
                    setFormObj({
                      ...formObj,
                      phone: phone
                    })
                  }
                />
              </fieldset>

              <fieldset>
                <ReCAPTCHA
                  sitekey='6Ldu6BkfAAAAAAcWW9lR0IwFpWfALfHRlmIuuhJG'
                  onChange={onChange}
                />
              </fieldset>
              <fieldset>
                <button name='button' type='submit' >
                  المتابعة
                </button>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
