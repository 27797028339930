
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider } from 'firebase/auth';
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyBCxx-zkTfYuKCw1knMyNfrLms0oceKsww",
  authDomain: "thelearnbook.firebaseapp.com",
  projectId: "thelearnbook",
  storageBucket: "thelearnbook.appspot.com",
  messagingSenderId: "64707616673",
  appId: "1:64707616673:web:4e05805aa38cf4a67df5e0",
  measurementId: "G-55JTGF349Y"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
export { auth, provider };