import React,{useState, useEffect} from "react";
import RouterPublic from "./RouterPublic";
import axios from "axios";
import jwt_decode from "jwt-decode";
import {
  browserName,
  osName,
  osVersion,
  browserVersion,
  engineName
} from 'react-device-detect'

import {
  isMobile
} from "react-device-detect";
import ReactGA from "react-ga";

import TagManager from "react-gtm-module";
import { trim } from 'lodash'

const env = process.env.NODE_ENV;

if (env !== "development") {
  console.log = () => { };
}

function App() {
  ReactGA.initialize("UA-210214313-2");
  const [logoutDevisePopup, setLogoutDevisePopup] = useState(false)
  const [deviceList, setDeviceList] = useState(null)
  const [show, setShow] = useState(false)
  const [errorShow, setErrorShow] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [loading, setLoading] = useState(false)

  const [loginStatus, setLoginStatus] = useState(false)
  const [parentAccount, setParentAccount] = useState({})

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  ReactGA.pageview(window.location.pathname + window.location.search);
  if (!isMobile) {
    const web = import("./assets/App.scss");
  } else {
    const mobile = import("./assets/Mobile.scss");
  }
  const getCookie = name => {
    var nameEQ = name + '='
    var ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  }

  function formatDate() {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }
  const cookieSet = (name, value, days) => {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  };
  const eraseCookie = name => {
    document.cookie =
      name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  };

  const tagManagerArgs = {
    gtmId: "GTM-WJP6VCS"
  };

  TagManager.initialize(tagManagerArgs);
  const login = () => {
    setErrorShow(false)
    setLoading(true)
    var crypto = require('crypto')
    let hash = crypto
      .createHash('sha256')
      .update(JSON.stringify(formatDate()))
      .digest('hex')
    let formData = new FormData()
    formData.append('hashTime', hash)
    formData.append('email', trim(email))
    formData.append('password', password)
    formData.append('lang', 'ar')
    let device = {
      browserName: browserName,
      osName: osName,
      osVersion: osVersion,
      browserVersion: browserVersion,
      engineName: engineName
    }

    formData.append('deviceInfo', JSON.stringify(device))

    axios.post(
      process.env.REACT_APP_BACKEND_URL + '/app/users/login',
      formData
    ).then(response2 => {
      if (response2.data.booleanValue) {
        cookieSet('fName', response2.data.dataUser.name, 70)
        cookieSet('email', response2.data.dataUser.email, 70)
        cookieSet('id', response2.data.dataUser._id, 70)
        cookieSet('img', response2.data.dataUser.img, 70)
        cookieSet('country', response2.data.dataUser.country, 70)
        localStorage.setItem('access_token', response2.data.access_token)
        setShow(false)
        cookieSet(
          'objectUser',
          JSON.stringify(response2.data.parentAccount),
          70
        )

        setParentAccount(JSON.parse(getCookie('objectUser')))
        setLoginStatus(true)
        setTimeout(() => {
          setLoading(false)
        }, 500)
      } else {
        if (response2.data.limited === true) {
          setLogoutDevisePopup(true)
          setLoading(false)
          setShow(false)
          setDeviceList(response2.data.loggedin)
          return
        } else {
          setErrorMsg(
            response2.data.message !== undefined ? response2.data.message : ''
          )
          setErrorShow(true)
          setTimeout(() => {
            setLoading(false)
          }, 500)
        }
      }
    })
  }
const logoutAPI = () => {
    setErrorShow(false)
    setLoading(true)
    let formData = new FormData()
    formData.append('token', deviceList[0].access_token)
    axios.post(
      process.env.REACT_APP_BACKEND_URL + '/app/logoutDevice',
      formData
    ).then(response2 => {
      setLogoutDevisePopup(false)
      login()
    })
  }

  useEffect(() => {
    //get referrer url and save it in cookie
    // if (document.referrer) {
      const fetchReferrer = async () => {
        try {
          const referrerUrl = document.referrer;
          console.log("Referrer URL:", referrerUrl);
    
          if (referrerUrl) {
            cookieSet("referrer", referrerUrl, 70);
            const response = await axios.post(
              `${process.env.REACT_APP_BACKEND_URL}/app/users/autologin`,
              { referrer: referrerUrl }
            );
    
    
            if (response.data.booleanValue) {
              cookieSet("fName", response.data.dataUser.name, 70);
              cookieSet("email", response.data.dataUser.email, 70);
              cookieSet("id", response.data.dataUser._id, 70);
              cookieSet("img", response.data.dataUser.img, 70);
              cookieSet("objectUser", JSON.stringify(response.data.parentAccount), 70);
    
              localStorage.setItem("access_token", response.data.access_token);
            } else {
              localStorage.removeItem("access_token");
              eraseCookie("fName");
              eraseCookie("email");
              eraseCookie("id");
              eraseCookie("img");
            }
          }
        } catch (error) {
          console.error(error);
          // Handle error case
        }
      };
    
      fetchReferrer();



    if (localStorage.getItem("access_token") === null)
      axios
        .post(process.env.REACT_APP_BACKEND_URL + "/app/userCheck")
        .then(response2 => {
          if (response2.data.limited === true) {
            setLogoutDevisePopup(true)
            setDeviceList(response2.data.loggedin)
            return
          } 
          if (response2.data.booleanValue) {
            cookieSet("fName", response2.data.dataUser.name, 70);
            cookieSet("email", response2.data.dataUser.email, 70);
            cookieSet("id", response2.data.dataUser._id, 70);
            cookieSet("img", response2.data.dataUser.img, 70);
            cookieSet("objectUser", JSON.stringify(response2.data.parentAccount), 70);

            localStorage.setItem("access_token", response2.data.access_token);
          } else {
            localStorage.removeItem("access_token");
            eraseCookie("fName");
            eraseCookie("email");
            eraseCookie("id");
            eraseCookie("img");
          }
        });
    else {
      var decoded = jwt_decode(localStorage.getItem("access_token"));
      // console.log(decoded.main_user_type);
      let formData = new FormData();
      formData.append('lang', 'ar');
      /////////////////////
      formData.append('token', localStorage.getItem('access_token'));
      axios.post(process.env.REACT_APP_BACKEND_URL + '/app/verifyToken', formData,
      ).then(response2 => {
        if (response2.data.booleanVaule) {

        }
        else {
          localStorage.removeItem("access_token");
          eraseCookie("fName");
          eraseCookie("email");
          eraseCookie("id");
          eraseCookie("img");
        }

      })
    }
  }, []);

  return (
    <div className="App">
          {logoutDevisePopup && (
        <div className='logoutDeviseBG'>
          <div className='logoutDevise'>
            <div className='bodyLogoutDevise'>
              {' '}
              لقد تجاوزت الحد المسموح به من الأشخاص المتصلين لنفس الحساب.
            </div>
            <div className='bodyLogoutDevise'>
              يمكنك إخراج إحدى المستخدمين والدخول مكانه
            </div>

            <div className='btns'>
              <div className='btnLogout redBtn' onClick={logoutAPI}>
                إحدى المستخدمين
              </div>
              <div
                className='btnLogout'
                onClick={() => setLogoutDevisePopup(false)}
              >
                إلغاء
              </div>
            </div>
          </div>
        </div>
      )}
      <RouterPublic />
    </div>
  );
}
export default App;
