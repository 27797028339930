import Fade from "react-reveal/Fade";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Search(props) {
  const [about, setAbout] = useState("");

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_BACKEND_URL + "/infowebsite/list")
      .then(response2 => {
        setAbout(response2.data.result.about);
      });
  }, []);

  return (
    <div className="secs" >
      <Fade bottom>
        <div className="sec1">
          <div
            className="details"
            dangerouslySetInnerHTML={{ __html: about }}
          />
          <Link to="/aboutus"><div className="btn">اقرأ المزيد</div></Link>
        </div>
      </Fade>
      <div className="sec2" id="database">
        <Fade bottom>
          <div className="titleSec">قواعد البيانات</div>
        </Fade>

        <div className="boxs" id="secs">
          <Fade bottom>
            <div className="box1">
              <img
                src={require("../../assets/images/Mask Group 33.png")}
                alt="img1"
              />
              <div className="tilteBox">وقائع اعمال المؤتمرات والندوات</div>
              <div className="bodyBox">
                أحدث ابحاث المؤتمرات الاقليمية والدولية المحكّمة والمنعقدة في
                الوطن العربي في مختلف الموضوعات.
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="box2">
              <img
                src={require("../../assets/images/Mask Group 34.png")}
                alt="img2"
              />
              <div className="tilteBox">المجلات والدوريات العلمية المحكمة</div>
              <div className="bodyBox">
                قاعدة متميزة من المجلات والدوريات العربية المحكمة من أفضل
                الجامعات والمؤسسات التعليمية لتقديم أحدث المقالات والاوراق
                البحثية.
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="box1">
              <img
                src={require("../../assets/images/Mask Group 32.png")}
                alt="img3"
              />
              <div className="tilteBox">الرسائل الجامعية والاطروحات</div>
              <div className="bodyBox">
                مجموعة شاملة من الأطروحات بدرجتي الماجستير والدكتوراه في جميع
                التخصصات في منطقة الشرق الأوسط.
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="box2">
              <img
                src={require("../../assets/images/Mask Group 31.png")}
                alt="img4"
              />
              <div className="tilteBox">الكتب الالكترونية الاكاديمية</div>
              <div className="bodyBox">
                قاعدة بيانات تضم آلاف الكتب العربية الكترونية من أحدث الكتب
                الاكاديمية العلمية من جميع انحاء الوطن العربي.
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}
